
//param: tableMap, 표질문.  재요청용으로 2개추가.
export const findMaxDepth = (tableCmFieldKvMap) => {
    let maxDepth = 1;
    const keys = Object.keys(tableCmFieldKvMap);
    console.log('addTableMapOneRow keys:', keys);

    let maxDepthCmField; //대여일_ij
    //maxDepth찾기.
    for (let key of keys) {
        let depth = getNotationDepth(key);
        if (depth > maxDepth) {
            maxDepth = depth;
            maxDepthCmField = key; //대여일_ij
        }
    }
    return maxDepth;
}

//2차원 tableMap을 하나 찾아서, pv의 순서를 리턴.
export const findPvIndex = (tableMap, pv) => {
    const keys = Object.keys(tableMap);
    //2차원 찾기.
    let maxLength = 0;
    let maxLengthCmField; //대여일_ij
    //maxDepth찾기.
    for (let key of keys) {
        let depth = getNotationDepth(key);
        let length = tableMap[key].length;
        if (depth === 2) {
            if (length > maxLength) {
                maxLength = length;
                maxLengthCmField = key; //대여일_ij
            }
        }
    }

    const values = tableMap[maxLengthCmField];
    console.log('findPvIndex values:', values);

    for (let i = 0 ; i <values.length; i++ ) {
        console.log('findPvIndex values i:', values[i]);
        const v = Object.keys(values[i])[0];
        console.log('findPvIndex v:', v);
        if (v.includes(pv)) return i;
    }

}


//공통함수, 원장에서 cmField추출 utility함수.
// @parameter cm:채권마스터.원장 임.
export const getCmField = (cm원장, cmField) => {
    const fieldArr = cmField.split('.');
    fieldArr.shift(); //'원장' 제거

    let curArr = cm원장; //cm.원장 (원장을 input으로 고정)
    for (let i = 0; i < fieldArr.length; i++) {
        const field = fieldArr[i];

        if (i === fieldArr.length - 1) {
            return Array.isArray(curArr)? curArr.find((tuple) => tuple.key === field )?.value : '';
        }else {
            let childObj = Array.isArray(curArr)? curArr.find((cmObject) => cmObject.key === field):[];
            curArr = (childObj)?childObj.value:[];
        }
    }

    return undefined; //못 찾으면.
}

//공통함수: 서브질문용  VArray만 추출 됨. (VarKey는 정확하게 0,1,2 순서대로 배치되어야 array로 사용가능)
// => (date_ij)에 적용시:  ['2024-01-01', '2024-02-23'] 리턴.
//  input은 cmBaseField (원장.date_ij), cmVarField (원장.date_ij|0) 다 됨.
export const getCmNotationFieldVArr = (cm원장, cmField) => {
    const fieldArr = cmField.split('.');
    fieldArr.shift(); //'원장' 제거
    //const depth = getNotationDepth(cmBaseField); //1로 시작. _ij만나면 2로 됨.

    let curArr = cm원장; //cm.원장 (원장을 input으로 고정)

    for (let i = 0; i < fieldArr.length; i++) {
        const field = fieldArr[i];

        if (i === fieldArr.length - 1) { //depth 매치.
            if (Array.isArray(curArr)) {
                //BoAdmin은 이게 맞는데, front는 아래가 맞음..  const tupleList = curArr.filter((tuple) => getNotationDepth(tuple.key)===i && tuple.key.startsWith(field) ); //채권_i|0 => 채권_i로 시작.
                const tupleList = curArr.filter((tuple) => tuple.key.startsWith(field) ); //채권_i|0 => 채권_i로 시작.
                //varKey도 리턴필요시 사용. const tupleBase = cmBaseField.substring(0, cmBaseField.lastIndexOf('.')); //"원장.테스트폴더.질문T";

                if (tupleList.length > 0) {
                    let retList = tupleList.map( (tuple) => tuple.value ); //tuple.key= 채권_i|0 => 원장.채권_i|0
                    console.log(' ** getCmNotationFieldVArr retList', retList);
                    return retList;
                }
            }else {
                console.log("*** getCmNotationFieldVArr: 이 부분은 호출 안되어야 함.. ");
            }

        }else { //1 depth forward
            let childObj = Array.isArray(curArr)? curArr.find((cmObject) => cmObject.key === field):[];
            curArr = (childObj)?childObj.value:[];

        }
    }
    return [undefined]; //값없을때 빈값하나 리턴.
}


//공통함수: 테이블용/히스토리용. KVArray 추출.
//@param  cmField:  원장.채권_i .   
//        stColObjs (Optional Param): 최초 자동출력용도
//@return [원장.채권_i|0 : v1,  원장.채권_i|1 : v2  ]
export const getCmNotationFieldKVArr = (cm원장, cmBaseField, stColObjs) => {
    if (!cmBaseField) {
        console.error('ERR: getCmNotationFieldKVArr: cmBaseField NULL' );
        return;
    }
    const fieldArr = cmBaseField.split('.');
    fieldArr.shift(); //'원장' 제거

    //let retArr = [];
    const depth = getNotationDepth(cmBaseField); //1로 시작. _ij만나면 2로 됨.

    let curArr = cm원장; //cm.원장 (원장을 input으로 고정)
    for (let i = 0; i < fieldArr.length; i++) {
        const field = fieldArr[i];

        if (i === fieldArr.length - 1) { //depth 매치.
            if (Array.isArray(curArr)) {

                // const tupleList = curArr.filter((tuple) => tuple.key && tuple.key.startsWith(field) ); //채권_i|0 => 채권_i로 시작. 240213 ERROR fix
                const tupleList =
                    curArr.filter((tuple) =>
                        (tuple.key && tuple.key.indexOf('|') > 0 &&  tuple.key.substring(0, tuple.key.indexOf('|'))===field )
                        ||
                        (tuple.key && tuple.key.indexOf('|') < 0 &&  tuple.key ===field)

                        ); //채권_i|0 => 채권_i로 매칭.
                const tupleBase = cmBaseField.substring(0, cmBaseField.lastIndexOf('.')); //"원장.테스트폴더.질문T";

                if (tupleList.length > 0) {
                    let retList = tupleList.map( (tuple) => {return {[ tupleBase + '.' + tuple.key]:tuple.value};} ); //tuple.key= 채권_i|0 => 원장.채권_i|0
                    console.log(' ** getCmNotationFieldKVArr retList', retList);
                    return retList;

                }else {
                    //강제 생성. //"tableMap최초 생성시점"
                    let k = cmBaseField + '|' +  ((depth===4)?'0_0_0_0': (depth===3)?'0_0_0':(depth===2)?'0_0':'0');
                    let v = undefined;

                    //todo 최초1건 자동출력 여기서 수행.
                    if (stColObjs && depth === 1) { //자동출력필드 먼저 찾기.
                        let autoField = find자동출력(stColObjs, depth);
                        if (autoField === cmBaseField) {
                            let 자동출력Flag = is자동출력(autoField, stColObjs);
                            console.log('getCmNotationFieldKVArr maxDepthCmField2 ', autoField, 자동출력Flag);
                            if (자동출력Flag) {
                                let 자동출력형식 = (자동출력Flag) ? get자동출력형식(autoField, stColObjs) : ''; //채권i => todo i_j
                                v = 자동출력형식 ? 자동출력형식.replace('i', ('1')) : undefined;
                            }
                        }
                    }

                    return( [{[k]: v}]  );
                }
            }else {
                console.log("*** getCmNotationFieldKVArr 이 부분은 호출아되어야 함.. ");
            }
            //return Array.isArray(curArr)? curArr.find((tuple) => tuple.key === field )?.value : '';

        }else { //1 depth forward
            let childObj = Array.isArray(curArr)? curArr.find((cmObject) => cmObject.key === field):[];
            curArr = (childObj)?childObj.value:[];

        }
    }
}

//colObj.cmField 와 ds의 실제 record를 이용해서 TableMapVarKey  채권_i:0  등을 가져오기.
export const getTableMapVarKeyFromRecord = (cmField, record, index ) => {

    let DEPTH = getNotationDepth(cmField);
    let i = -1;
    let j = -1; //todo K,P (3,4)

    let recordVarCmField;  //대여금_ij|1_2  (record에서 있으면 찾기)
    //ds에서 |0 찾을 수 있나? 아니면 1번인 tableMap에 넣는게 좋음.  i,j를 알 수 있나? i=idx일 경우만 쉬움.
    if (DEPTH == 1) {
        i = index;
    }if (DEPTH == 2) {
        recordVarCmField = getRecordVarCmFieldJ(record, i);
        i = parseCmFieldI(recordVarCmField);
        j = parseCmFieldJ(recordVarCmField); // 대여금_ij:1_2 를 넣어야 함.
    }
    let tableMapVarKey = cmField + '|' + i;  /////   중요  ///////////
    if ( j >= 0) {//todo K, P
        tableMapVarKey += ('_' + j);
    }

    return tableMapVarKey;
}


//return
//  notations:  'i', 'ij', 'ijk'   등 notation기호.
//  firsts:  '채권_i', '날짜_ij', '변제일_ijk'  등  첫번째 만난 notiotion => [+]버튼 출력용으로 필요.
export const parseNotation = (askQuestion) => {
    const colArr = askQuestion.표질문;

    const notationsArr=[];
    const firstsArr=[];

    for (let col of colArr) {
        const cmField = col.cmField;
        const notationNo = col.notattionNo; //i, ij, ijk

        if (!notationsArr.includes(notationNo)) {
            notationsArr.push(notationNo);
            firstsArr.push(cmField);
        }
    }

    return {notations:notationsArr, firsts:firstsArr};
}


//채권_i =>  1,   금액_ij =>  2
export const getNotationDepth = (cmField) => {
    if ( cmField.lastIndexOf('_') < 0) return 0;

    let depth = cmField.substring( cmField.lastIndexOf('_') + 1).length;
    if (depth <= 0) {
        console.log(" ##### CmField notationDepth ERROR: 노테이션이 없는 cmField가 잘못호출되었음 ######## ");
    }
    return depth;
}

//대여일_ij|1_1 에서 i가져오기, 혹은 채권_i|1 에서 1가져오기.
export const parseCmFieldI = (cmField) => {
    if(!cmField) {
        //2402
        console.log('parseCmFieldI', cmField );
        return 0;
    }


    //1_1가져오기
    let num = cmField.substring( cmField.lastIndexOf('|') + 1);

    //depth > 1일 경우.
    if (num.includes('_')) {
        num = num.substring(0, num.indexOf('_'));
    }
    return parseInt(num);
}
//대여일_ij|0_1 에서 j가져오기, 혹은 _ijk|3_1_2 에서 1가져오기.
export const parseCmFieldJ = (cmField) => {
    console.log('parseCmFieldJ cmField', cmField);
    if(!cmField) {
        //2402
        console.log('parseCmFieldI', cmField );
        return 0;
    }
    let num = cmField.substring( cmField.lastIndexOf('|') + 1); //3_1_2 or 0_1 o

    if (num.includes('_')) {
        num = num.substring(num.indexOf('_')+1); //1(j) or 1(j)_2 or 1_2_x
        if (num.includes('_')) {
            num = num.substring(0, num.indexOf('_')); //1(j)
        }
    }else {
        console.log('parseCmFieldJ 에러. ', cmField);
    }

    return parseInt(num);
}

//param: 대여일_ij|0_1 => return 대여일_ij
export const getBaseKey = (cmVarField) => {
    return cmVarField.substring(0, cmVarField.indexOf('|'));
}

//depth에 맞는 record하나 찾기. return 대여일_ij|0_1
export const getDepthRecord = (record, depth) => {
    let keys = Object.keys(record); //key= 대여일_ij|0_1
    for (const key of keys) {
        if( getNotationDepth(getBaseKey(key)) === depth) {
            //240208 오류 :  "return record[key]" 가 undefined 라서  => key.substring 으로 수정.
            if (key.indexOf('.') > 0) {
                return key.substring(key.lastIndexOf('.') + 1);
            } else {
                console.log('getDepthRecord 에러 record에 .없음 #### ');
            }
        }
    }
    console.log('getDepthRecord 에러 #### ', record, depth);
    return '';
}

//param: record:{채권_i|0:xx, 대여금_ij|0_1:xx   } => return 대여금_ij|0_1
export const getRecordVarCmFieldJ = (record, i) => {

    const varCmFieldIJ = getDepthRecord(record, 2);

    return varCmFieldIJ;
}



/** MAX 4depth로 예상.
 *
 * @param tableMap
 *  { 채권_i:[ 채권_i|0:'채권1', 채권_i|1:'채권2' ],                              //depth1
 *    금액_i:[ 금액_i|0:'100', 금액_i|1::'200; ],
 *
 *    대여일_ij: [대여일_ij|0_0:'2024-01-01',  대여일_ij|0_1:'2024-01-02',  대여일_ij|1_0:'2024-01-01',  대여일_ij|1_1:'2024-01-02', 대여일_ij|1_2:'2024-01-03',   ]   //depth2
 *  }
 *
 * @return
 *  [채권_i|0:V, 채권_i|1:v,  금액_i|0:V, 금액_i|1:v,   대여일_ij|0_0:v, 대여일_ij|0_1:v, 대여일_ij|1_0:v, 대여일_ij|1_1:v, ]
 *
 */
export const tableMap2kv = (tableMap) => {

    //유효성 체크:
    let cmFields = Object.keys(tableMap); //[채권_i, 대여일_ij]

    console.log(cmFields);
    //todo depth로 소팅하는게 안전함.
    try {
        cmFields.sort((a, b) => {
            const aLength = a.split('_')[1].length;
            const bLength = b.split('_')[1].length;
            if (aLength < bLength) {
                return -1;
            }
            if (aLength > bLength) {
                return 1;
            }
            return 0;
        });
    }catch (e) {
        console.log('.sort ERROR');
    }

    // console.log('sorted cmField:', cmFields);
    console.log('** tableMap2kv:', tableMap);

    const TOTAL_FIELDS_COUNT = cmFields.length;
    let retKv = [];

    for (let mapIdx = 0 ; mapIdx < TOTAL_FIELDS_COUNT; mapIdx++) {

        let cmField = cmFields[mapIdx];
        let depth = getNotationDepth(cmField); //1로 시작. _ij만나면 2로 됨.
        // if (currentDepth == 1) {
            //start Data찾기. I,J,K

        let tableCmFieldKvArr = tableMap[cmField];
        console.log("** tableCmFieldKvArr: ", tableCmFieldKvArr);

        for (let kv of tableCmFieldKvArr) {
            if (!kv) { //todo check: 240123 미입력 저장시, undefined 들어옴. 0번째로 강제로 만드는 중. => tableMap최초 생성시점으로 옮겨야 할듯.
                //continue;
                let k = cmField + '|' +  ((depth===4)?'0_0_0_0': (depth===3)?'0_0_0':(depth===2)?'0_0':'0');
                retKv.push({[k]: undefined});

            }else {

                let k = Object.keys(kv)[0];
                let v = kv[k];
                //newCmFieldKv = {...newCmFieldKv, [k]:v}
                retKv.push({[k]: v});
            }
        }

        // }else if (currentDepth == 2) { //j가 필요함.
        //     //start Data찾기
        //     let tableCmFieldKvArr = tableMap[cmFields[mapIdx]];
        //     for (let kv of tableCmFieldKvArr) {
        //         let k = Object.keys(kv)[0];
        //         let v = kv[k];
        //         //newCmFieldKv = {...newCmFieldKv, [k]:v}
        //         retKv.push({[k]: v});
        //     }
        // }

    } //for


    return retKv;
} //tableMap2kv

// export const  getCmFieldKv = (cm, cmFields) => {
//
//
// }

//빈값 자동으로 하나씩 들어가는 구조. (stColObjs 는 최초 채권1 출력시만 사용)
export const getTableMapFromCm = (cm원장, cmFields, stColObjs) => {

    let tableCmFieldKvMap = {};

    console.log("* getTableMapFromCm:", cmFields);

    for (const cmField of cmFields) {
        console.log("  * getTableMapFromCm: ", cmField);
        if ( !tableCmFieldKvMap[cmField] ) {

            // let v = getCmField(cm원장, cmField);
            let kvArr = getCmNotationFieldKVArr(cm원장, cmField, stColObjs);

            tableCmFieldKvMap[cmField] = kvArr; //[v];

        }else {
            //tableCmFieldKvMap[cmField].push(getCmField(cm원장, cmField));
            console.log("*tableMap 최초생성시점 kvArr구조라서 여기는 안타야 함. :", tableCmFieldKvMap);
        }
    }
    console.log("*tableMap 최초생성시점:", tableCmFieldKvMap);

    return tableCmFieldKvMap;

}

//@param  record:  {채권_i|0 : aaa,  대여일_ij:0_1: bbb  }
//        cmFieldBase: '..채권_i';
export const findVByCmFieldBase = (record, cmFieldBase) => {
    let keys = Object.keys(record);
    for (const key of keys) {
        if (key.startsWith(cmFieldBase)) {
            return record[key];
        }
    }
    console.log('$$$ findVByCmFieldBase ERROR: ', record, cmFieldBase);
    return '';
}

export const find자동출력 = (stColObjs, depth) => {
    if( Array.isArray(stColObjs) ) {
        for (const colObj of stColObjs) {
            if ( getNotationDepth(colObj.cmField) === depth ) {
                if (colObj.답변유형 === '자동출력') {
                    return colObj.cmField;
                }
            }
        }
    }
    return null;
}
export const is자동출력 = (cmField, stColObjs) => {
    if( Array.isArray(stColObjs) ) {
        for (const colObj of stColObjs) {
            if (colObj.cmField === cmField && colObj.답변유형 === '자동출력') {
                return true;
            }
        }
    }
    return false;
}
export const get자동출력형식 = (cmField, stColObjs) => {
    if( Array.isArray(stColObjs) ) {
        for (const colObj of stColObjs) {
            if (colObj.cmField === cmField) {
                return colObj.자동출력형식.replace(colObj.notationNo, 'i');   //todo 자동출력i_j등 반영. (현재는 채권i 등 마지막에 notation이 있따고 가정. )
            }
        }
    }
    console.log('## ERROR  get자동출력형식 NOT FOUND:', cmField, stColObjs);
    return '';
}


//param: tableMpa, 표질문.
export const addTableMapOneData = (tableCmFieldKvMap, stColObjs, notationV) => {
    let maxDepth = 0;
    const keys = Object.keys(tableCmFieldKvMap);
    console.log('addTableMapOneRow keys:', keys);

    let maxDepthCmField; //대여일_ij
    //maxDepth찾기.
    for (let key of keys) {
        let depth = getNotationDepth(key);
        if (depth > maxDepth) {
            maxDepth = depth;
            maxDepthCmField = key; //대여일_ij
        }
    }

    //console.log('maxDepthCmField1 ',  maxDepthCmField);
    if (maxDepth === 1) { //자동출력필드 먼저 찾기.

        const autoField = find자동출력(stColObjs, maxDepth);
        maxDepthCmField = (autoField)? autoField:maxDepthCmField;
    }
    console.log('maxDepthCmField2 ',  maxDepthCmField);

    let 자동출력Flag = is자동출력(maxDepthCmField, stColObjs);
    console.log('자동출력Flag ',  자동출력Flag);
    let 자동출력형식 = (자동출력Flag)? get자동출력형식(maxDepthCmField,stColObjs ):''; //채권i => todo i_j
    console.log('자동출력형식 ',  자동출력형식);
    //let baseKey = getBaseKey(k); //대여일_ij

    let maxArr = tableCmFieldKvMap[maxDepthCmField]; //[ {대여일_ij|0_0:'2024-01-01'},  대여일_ij|0_1:'2024-01-02'   ]
    console.log('maxArr:', maxArr);
    if (!maxArr) {
        maxArr = [''];
    }

    //if (Array.isArray(maxArr)) {
    // for (let kvObj of maxArr) { //여러개 넣기? 하나만 넣어도 될듯.
    let kvObj = maxArr[0];

    if (kvObj) {
        //자기 넣기 k? :v
        // let k = Object.keys(kvObj)[0]; // 대여일_ij|0_0
        // const baseKey = getBaseKey(k); //대여일_ij
        let v = tableCmFieldKvMap[maxDepthCmField];  //대여일_ij |

        let newK = maxDepthCmField + '|' +  v.length; //   todo 대여일_ij | 0_1
        //(서브질문용 notationV 0 등 추가 - 240208
        if(notationV != undefined) {
            newK = maxDepthCmField + '|' + notationV + '_' + v.length;
        }

        let newV = 자동출력Flag? 자동출력형식.replace('i', (v.length+1)):'';
        tableCmFieldKvMap = {...tableCmFieldKvMap,  [maxDepthCmField]: [...v,   {[newK]:newV}] };

    } else {
        console.log('## addTableMapOneData: No kvObj'  );
        let newK = maxDepthCmField + '|' + '0'//   todo 대여일_ij | 0_1
        let newV = 자동출력Flag? 자동출력형식.replace('i', '1'):'';

        tableCmFieldKvMap = {...tableCmFieldKvMap, [maxDepthCmField]:[{[newK]:newV}] };
    }

    return tableCmFieldKvMap;

    // }
}

//fromHistory 히스토리 서브질문용으로 notationV가 일치하는 항목만 전환. => ds[1개] 만 리턴.
// return  [{ 채권i|0 : v1, ..} ]
export const convertTableMap2DsFromHistory = (tableCmFieldKvMap, notationV) => {

    let notationDepth = getNotationVDepth(notationV);
    const keys = Object.keys(tableCmFieldKvMap); //대여일_ij
    console.log('fromHistory  keys:', keys);

    let retDsOneRow = {};
    //same Depth찾기.
    for (let key of keys) {
        let depth = getNotationDepth(key);
        if (depth == notationDepth) { ///depth가 동일한 것 1차 매치.
            let k = key; //대여일_ij
            let kvArr  = tableCmFieldKvMap[k];

            for (let kv of kvArr) {
                let k = Object.keys(kv)[0];
                let v = kv[k];

                if (k.includes('|' + notationV)) { //exactMatch 2차 매치.
                    console.log('one kv of kvArr found fromHistory: k=' + k + ' ,v=' + v);
                    retDsOneRow = {...retDsOneRow, [k]:v};
                }
            }

        }
    }
    //let maxArr = tableCmFieldKvMap[maxDepthCmField]; //[ {대여일_ij|0_0:'2024-01-01'},  대여일_ij|0_1:'2024-01-02'   ]
    console.log('fromHistory [retDsOneRow]:', retDsOneRow);

    return [retDsOneRow];
}
//하나씩 빈값이 tableMap에 있다고 치고, 가장 긴값을 기준으로 dataSource 생성. 컬럼별로 {k:, v:} 구조
//  ==> 240123 구조개선: 간단하게 데이터 다 제외하고, line수만 맞추기.
export const convertTableMap2Ds = (tableCmFieldKvMap, READONLY_DEPTH) => {

    //NEW maxLength
    let maxLength = 0;
    let maxLengthCmField;

    const keys = Object.keys(tableCmFieldKvMap); //대여일_ij

    //readOnly이 존재하는 depth중 max를 선택할 필요있음


    for (let key of keys) {
        if (READONLY_DEPTH > 0) {
            let keyLength = getNotationDepth(key);
            if (keyLength !== READONLY_DEPTH) {
                continue;
            }
        }
        let length = tableCmFieldKvMap[key].length;
        if (length > maxLength) {
            maxLength = length;
            maxLengthCmField = key; //대여일_ij
        }
    }
    let maxArr =  tableCmFieldKvMap[maxLengthCmField];

    //OLD maxDepth
    // let maxDepth = 0;
    // const keys = Object.keys(tableCmFieldKvMap); //대여일_ij
    // console.log('convertTableMap2Ds keys:', keys);
    //
    // let maxDepthCmField;
    // //maxDepth찾기.
    // for (let key of keys) {
    //     let depth = getNotationDepth(key);
    //     if (depth > maxDepth) {
    //         maxDepth = depth;
    //         maxDepthCmField = key; //대여일_ij
    //     }
    // }
    //
    // let maxArr =  tableCmFieldKvMap[maxDepthCmField]; //[ {대여일_ij|0_0:'2024-01-01'},  대여일_ij|0_1:'2024-01-02'   ]

    console.log('convertTableMap2Ds maxArr:', maxArr);
    if (!maxArr) {
        maxArr = [''];
    }

    return maxArr;

    // //*** 자기 넣기 k :v
    // if (Array.isArray(maxArr))
    // for (let kvObj of maxArr) { //대여일_ij|0_0:'2024-01-01'}
    //
    //     let oneDs = null;
    //
    //     let baseCmField;
    //     let baseI = -1;
    //     let baseJ = -1;
    //
    //     if (kvObj) {
    //         //자기 넣기 k? :v
    //         let k = Object.keys(kvObj)[0];   //대여일_ij|0_0:
    //         let v = kvObj[k];                // '2024-01-01'
    //         oneDs = {[k]:v};               //대여일_ij|0_0:'2024-01-01'
    //
    //         baseCmField = k; //대여일_ij|0_1
    //         baseI = parseCmFieldI(baseCmField);
    //         baseJ = parseCmFieldJ(baseCmField);
    //         //TODO K, P
    //
    //     }else {
    //         //no KEY : 최초 빈값 ''일 경우는 어떻게 처리하나?
    //         // dataSource.push({k: '', v: ''});
    //         console.log('###convertTableMap2Ds 빈값,', kvObj);
    //         oneDs = null;
    //     }
    //
    //     //모든 kv 다 넣기. 다른필드들.
    //     const otherKeys = Object.keys(tableCmFieldKvMap).filter( (item) => (item !== maxDepthCmField));
    //     for (const oneKey of otherKeys) {
    //         const vArr = tableCmFieldKvMap[oneKey]; ////[ {대여일_ij|0_0:'2024-01-01'},  대여일_ij|0_1:'2024-01-02'   ]
    //
    //         if( Array.isArray(vArr))
    //         for (let kvObj of vArr) {
    //             if (!kvObj) {
    //                 console.log('convertTableMap2Ds otherKeys: (kvObj, vArr)', kvObj, vArr);
    //                 continue;
    //             }
    //
    //             //해당되는  i,j에 맞게 넣어야 할 것 같은데..
    //             if (getNotationDepth(oneKey) == 1) {
    //
    //                 let k = Object.keys(kvObj)[0]; //cmField가변 .
    //                 if (parseCmFieldI(k) === baseI) {
    //                     let v = kvObj[k];
    //                     oneDs = {...oneDs, [k]:v};
    //                 }
    //
    //             } else if (getNotationDepth(oneKey) == 2) {
    //
    //                 let k = Object.keys(kvObj)[0]; //cmField가변 .
    //                 if (parseCmFieldI(k) === baseI && parseCmFieldJ(k) == baseJ) {
    //                     let v = kvObj[k];
    //                     oneDs = {...oneDs, [k]:v};
    //                 }
    //
    //             } else if (getNotationDepth(oneKey) == 3) { //TODO
    //
    //             } else if (getNotationDepth(oneKey) == 4) { //TODO
    //
    //             }
    //         }
    //
    //     }
    //
    //     if (oneDs) {
    //         dataSource.push(oneDs);
    //     }else {
    //         console.log('convertTableMap2Ds : NO tableMap DATA return empty ds');
    //     }
    // }
    //
    //
    // return dataSource;
}

//서브질문Select 시 "예|4" 형태의 pipeline추가해서 저장. todo 예|4_15 에서 15(askFlowIdList의 n번째) 찾는 방법 필요.
export const getSubAskColIndex = (stColObjs, cmField, value) => {

    // let stColObjs = askQuestion.표질문;
    let colObj = stColObjs.find((stColObj) => stColObj.cmField === cmField);

    console.log('서브질문:', value, colObj);

    let valueIndex = colObj.selectBoxQs.findIndex((item) => item===value);
    const checkBoxQ = colObj.checkedBoxQs[valueIndex];

    console.log('서브질문:checkBoxQ' , checkBoxQ);

    //true일때 checkedBoxQs의 colIndex찾아서 답변하기 버튼 출력.
    if (checkBoxQ && checkBoxQ.checked) {
        const subAskColIndex = checkBoxQ.colIndex; //todo 어딘가에 저장 필요.

        //예|4_15 에서 15(askFlowIdList의 n번째) 찾는 방법
        if (stColObjs[subAskColIndex].답변유형 === '서브질문node매칭') {
            const askFlowIdNth = stColObjs[subAskColIndex].askFlowIdList[valueIndex];
            console.log('서브질문:askFlowIdNth : ' , askFlowIdNth);
            return subAskColIndex + '_' + askFlowIdNth; //4_15리턴.
        }

        //분할변제여부   "예|4"  로 저장가능한지 검토중.
        //handleTableMapTextValueChange(tableMapVarKey, value+'|'+ subAskColIndex );
        return subAskColIndex;
    }

    return undefined;
}

export const getMyColIndex = (stColObjs, title) => {
    return stColObjs.findIndex((stColObj) => stColObj.title === title);
}

//"예|4" => "예" 출력용.
export const removePipeline = (value) => {
    if (value && value.indexOf('|') > 0) {
        return value.substring(0, value.indexOf('|')) ;
    }
    return value;
}

//서브질문Select 중에 myColIndex가 있는지 찾기.
// 표질문(StColObjs)중에 답변유형이 '서브질문Select'인걸 찾아서 cmField로 tableMap에서 찾기.
//  단, notation_pv '|1_0', '|1_1'등이 같아야함.
export const existMyColIndex = (stColObjs, tableMap, myColIndex, notation_PV) => {

    const 서브질문SelectCols = stColObjs.filter( (colObj) => colObj.답변유형 === '서브질문Select');
    for (const col of 서브질문SelectCols) {
        const cmField = col.cmField;
        const tableMapVArr = tableMap[cmField]; // [ {채권_i|0 : v1}, ... ]

        if (Array.isArray(tableMapVArr)) {
            for (const kv of tableMapVArr) {
                if (kv && Object.keys(kv).length > 0) {
                    let k = Object.keys(kv)[0];
                    let v = kv[k];  //'예|4' 혹은 '아니오' : v1형식.

                    if (k.includes(notation_PV) && v && v.indexOf('|') > 0) {
                        let foundIndex = v.substring(v.indexOf('|') + 1);
                        // 서브질문node매칭 case: 예|4_15 에서 4_15리턴.
                        if (foundIndex.indexOf('_') > 0) {
                            const 서브질문매칭colIndex = foundIndex.substring(0, foundIndex.indexOf('_'));
                            if (myColIndex === Number(서브질문매칭colIndex)) {
                                return foundIndex; //서브질문node매칭 예:2_2 -> 2_2 리턴.
                            }
                        }

                        if (myColIndex === Number(foundIndex)) {
                            return foundIndex; //just 서브질문node  예:4 -> 4 리턴.
                        }
                    }
                }//if kv
            }//for
        }//if
    }
    return undefined;
}

//param: V(1_1) or Pv(|1_1)모두 가능. => return 2
export const getNotationVDepth = (notationV) => {
    if (notationV.indexOf('_') > 0) {
       return notationV.split('_').length;
    }
    return 1;
}

//param V(1_1) or Pv(|1_1)모두 가능 => return 1 (_1 제거)
export const notationMinusDepth = (notationV) => {
    return notationV.substring(0, notationV.lastIndexOf('_') );
}

//param: 원장.xx.대여일_ij -> return 2
export const getCmFieldDepth = (cmField) => {
    if (cmField.indexOf('|') > 0) {
        cmField = cmField.substring( cmField.lastIndexOf('|') )
    }

    if (cmField.indexOf('_') > 0) {
        const notationNo = cmField.substring( cmField.lastIndexOf('_')+1 );
        return notationNo.length;
    }
    return 0;
}