import React, {createContext, useEffect, useRef, useState} from "react"
import {useRecoilState, useRecoilValue} from "recoil";
import { BoTokenAtom, BoUserIdAtom, BoUserTypeAtom, UserObjectIdAtom} from "../Atoms";
import useAxios from "../hooks/useAxios";
import {BoNetUtil} from "./Util";

export const AuthContext = createContext(null);


//token 변경시 자동호출 context. : 로그인시에 변경가능.
export default function AuthContextProvider({children}) {

    //loclStorage로 저장되는 4항목.
    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);                        //위 boToken에서 파생.
    const userObjectId = useRecoilValue(UserObjectIdAtom);                //signin, signup 시에만 세팅됨.
    const [boUserType, setBoUserType]  = useRecoilState(BoUserTypeAtom);  //anonymous  google kakao naver

    const isAnonymous = (boUserType==='anonymous');

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    //로그는 atoms.effects안에서 : console.log('AuthContextProvider boToken,boUserId = '+ boToken + ',' + boUserId);

    //2311 gary -> 기존코드 App.js(anonymous + signUp한번)와  MainPage.js BurgerMenu.js(anonymous) 3가지를 합해 봄.
    const initialized = useRef(false); //Util.js 의 useEffectOnce효과를 여기서 직접 사용.
    useEffect( () => {

        checkAnonymousAndSignUpOnce();

    }, [boUserId])

    //signUp은 전체 App에 걸쳐 한번만 수행.
    const checkAnonymousAndSignUpOnce = async() => {
        
        //anonymous 체크.
        let retBoUserId = await checkAnonymousNew();

        /////기존 사용자../////////////////////////////
        if (boUserId === retBoUserId) {
            return;
        }

        //새 사용자이면, user가 존재하는지 체크.
        let accountTable = await  axiosGet('/vws/bo/user/info', {username: retBoUserId})
        if (accountTable.username) { //uid 존재

            //최초 한번만 dcolSignInUp 수행.: 기존 App.js 70라인. 옮겨옴.
            if (!initialized.current) {
                initialized.current = true;

                BoNetUtil.dcolSignInUp(()=>{}, ()=>{});
            }

        }
        
    }


    const checkAnonymousNew = async () => {
        //기존 사용자. : 자동로그인 되는지 check 필요.
        if (boUserId) {
            console.log("checkAnonymousNew. already have userid. %o", boUserId);
            return boUserId; //기존 사용자../////////////////////////////
        }

        //JwtAnonymousResponse : {type, userId, accessToken, refreshToken}
        let jwtResponse = await axiosGet('/vws/bo/auth/anonymous');
        if (!jwtResponse) {
            console.log('===== BACKEND auth/anonymous ERROR ================');
            return null;
        }

        setBoToken({ accessToken: jwtResponse.accessToken, refreshToken: jwtResponse.refreshToken });
        
        //TODO check-  잘못하면 google 같은 걸 덮어쓸듯. => 위 기존 사용자에서 걸러져샤 함.
        console.log("checkAnonymousNew. NEW userid. %o", jwtResponse.userId);
        setBoUserType(jwtResponse.type);  //여기는 항상 anonymous 임.
        
        return jwtResponse.userId;
    }



    return(
        <AuthContext.Provider  value={{boToken, boUserId, userObjectId, boUserType, isAnonymous}}>
            {children}
        </AuthContext.Provider>
    )
}