import { useEffect, useState } from 'react';
import { OrangeButton, WhiteTopBar } from "../common/Common";
import { useRecoilValue } from 'recoil';
import { BACKEND_URL } from '../Atoms';
import { InputText } from 'primereact/inputtext';
import { BoNetUtil } from '../common/Util';

// 이건 엄밀하게 말하면 Email Login 페이지임. 
export function LoginPage(props) {

    return (
        <div className="p-3">
            <WhiteTopBar title=""/>
            <div className="">
                <br/>
                <br/>
                <br/>
                {/*<img src={`/favicon.png`} className="mr-2" style={{height: "2.5rem"}}/>*/}
                {/*<img src={`/assets/bondcity.svg`} alt="채권도시C" style={{height: "2.5rem"}}/>*/}
                <img src={`/assets/logoC.png`} alt="채권도시C" style={{height: "5rem"}}/>
            </div>
            <br/>
            <br/>
            <EmailLogin/>
            <br/>
            <br/>
            <hr/>
            <div className="text-sm text-600 relative" style={{top: "-18px"}}>
                <span className="surface-0 px-3">SNS계정으로 간편 로그인</span>
            </div>
            <SnsLogin/>
        </div>
    );
}

function EmailLogin(props) {
    const [email, setEmail] = useState("");
    const [pwd, setPwd] = useState("");
    return (
        <div>
            <div className="mb-2">
                <InputText value={email} onChange={(e) => setEmail(e.target.value)}
                    placeholder='이메일 주소를 입력해주세요' className="w-full"/>
            </div>
            <div className="mb-2">
                <InputText value={pwd} onChange={(e) => setPwd(e.target.value)} type="password"
                    placeholder='비밀번호를 입력해 주세요' className="w-full"/>
            </div>
            <br/>
            <OrangeButton label="로그인"/>            
            <div className="flex">
                <div className="text-sm text-600 cursor-pointer">회원가입</div>
                <div className="text-sm ml-auto text-600 cursor-pointer">비밀번호 재설정</div>
            </div>
        </div>
    );
}

function SnsLogin(props) {

    const onKakaoLogin = () => {
        // kakao에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        // #22 익명 사용자의 Access Token이 헤더에 존재하는 상태로 /vws/bo/auth/oauth2/naver 호출하면 토큰에서 username을 추출해 state 파라메터를 달아서 리턴하므로 따로 치환하지 않아도 됩니다.
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/kakao`, null,
            (resp) => {
                // "https://kauth.kakao.com/oauth/authorize?response_type=code&client_id=4bb21e8acd4ffac485c2e418a034840f&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_kakao"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_kakao`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onNaverLogin = () => {
        // naver에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/naver`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_naver`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onGoogleLogin = () => {
        // google에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/google`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_google`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }
    const onAppleLogin = () => {
        // apple에서 Authorization Code를 받을 수 있는 URL을 받아온다. (client_id 포함)
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/auth/oauth2/apple`, null,
            (resp) => {
                // "https://nid.naver.com/oauth2.0/authorize?response_type=code&client_id=9KbeugMPkp6Rc7hHduBT&state=none&redirect_uri=http%3A%2F%2Flocalhost%3A3030%2Fcallback_naver"
                console.dir(resp.data);                
                const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_apple`;
                console.log(redirectUrl);
                window.location.href = resp.data.redirectUri + encodeURIComponent(redirectUrl);
            },
            (err) => console.error(err)
        );
    }

    return (
        <div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#FEE500"}}
                onClick={onKakaoLogin}>
                <img src="/assets/kakao.svg"/>
                <span>&nbsp;카카오로 시작하기</span>
            </div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#1DC800"}}
                onClick={onNaverLogin}>
                <img src="/assets/naver.svg"/>
                <span className="text-0">&nbsp;네이버로 시작하기</span>
            </div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#F0F0F0"}}
                onClick={onGoogleLogin}>
                <img src="/assets/google.svg"/>
                <span>&nbsp;구글로 시작하기</span>
            </div>
            <div className="flex justify-content-center align-items-center p-3 border-round cursor-pointer mb-2" style={{backgroundColor: "#111111"}}
                onClick={onAppleLogin}>
                <img src="/assets/apple.svg"/>
                <span className="text-0">&nbsp;애플로 시작하기</span>
            </div>
        </div>
    );
}