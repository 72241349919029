import React, {useState, useEffect} from "react";
import {Button, Space} from "antd";
import {Flex, FlexCenter, FlexColumn, FlexRight, TitleBox, GrayDiv, GrayBox} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import B2bLayout from "./B2bLayout";


//props: type='signup', 'predata' 2가지로 구부해서 전송.
const B2bSignupWait = (props) => {


    return (
        <B2bLayout title={'채권도시 기업회원 로그인'}>

            <TitleBox style={{marginTop: 70}}> 회사 검증이 통과되면 </TitleBox>
            <TitleBox style={{marginTop: 0}}> 회원가입여부를 보내드립니다. </TitleBox>

        </B2bLayout>
    )
}

export default B2bSignupWait;