import B2bLayout from "./B2bLayout"
import {BlackDiv, DarkBlueDiv, GrayDiv, TitleBox, BlackCheckbox, BlackButton} from "../../common/B2bComponent";
import React, {useState, useEffect} from "react";
import {Button, Card, Checkbox, Input, Space, Spin} from "antd";
import axios from "axios";
import {BACKEND_URL, BoTokenAtom, BoUserIdAtom, BoUserTypeAtom, UserObjectIdAtom} from "../../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "../../hooks/useAxios";
import {useNavigate} from "react-router-dom";

function sleep(ms) {
    return new Promise((r) => setTimeout(r, ms));
}

const B2bPlanConfirm = (props) => {
    const navigate = useNavigate();
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    const boUserId = useRecoilValue(BoUserIdAtom);
    const [fullscreenSpinning, setFullScreenSpinning] = useState(false);
    const [plan, setPlan] = useState({title: "서비스 플랜", upfront: "", contingency: "", valid: false});
    const [agreementChecked, setAgreementChecked] = useState(false);
    const [agreementChecked2, setAgreementChecked2] = useState(false);

    useEffect(() => {
        axiosGet(`/vws/bo/user/info`, {username: boUserId})
            .then((userInfo) => {
                console.log(userInfo);
                if (
                    userInfo.bizType === "b2b" && userInfo.b2bStatus >= 3
                    && userInfo.planName && userInfo.planInitFee && userInfo.planSuccessFee
                ) {
                    setPlan({
                        title: userInfo.planName,
                        upfront: userInfo.planInitFee,
                        contingency: userInfo.planSuccessFee,
                        valid: true,
                        confirmed: userInfo.b2bStatus >= 4
                    });
                } else {
                    throw new Error("계정의 상태가 유효하지 않음");
                }
            })
            .catch((reason) => {
                console.error(reason);
                // FIXME
                alert("오류가 발생했습니다");
                navigate('/b2b');
            });
    }, [boUserId]);

    const onConfirmButtonClicked = () => {
        setFullScreenSpinning(true);
        doB2BConfirmPlan()
            .then((confirmedPlan) => {
                alert("플랜 확인이 완료되었습니다");
                setPlan({...plan, confirmed:true});
                window.location.href = '/b2bContractWait'; //240109 b2b->contractWait(계약서 이메일 대기)로 변경.

            })
            .catch((reason) => {
                console.error(reason);
                alert("오류가 발생했습니다");
            })
            .finally(() => {
                setFullScreenSpinning(false);
            })
    };

    const doB2BConfirmPlan = async () => {
        await axiosPost("/api/b2b/plan/confirm", plan);
    };

    console.log(plan);

    return (
        <B2bLayout title="채권도시 기업회원">
            <TitleBox style={{marginTop:70}}> 기업회원 서비스 플랜을 확인해주세요 </TitleBox>
            <Space direction="vertical" size={11} style={{width:821, textAlign:"left", marginTop:24}}>
                
                {/*<Card title="기본 서비스 제공 항목" style={{textAlign:"left"}} headStyle={{fontWeight:"bold"}} loading={!plan.valid}>*/}
                {/*    <ul style={{marginTop:-5, marginBottom:-5}}>*/}
                {/*        <li><BlackDiv>변제기 이후 채권에대하여 채권관리 및 회수절차 진행</BlackDiv></li>*/}
                {/*        <ul>*/}
                {/*            <li><BlackDiv>성공보수 미수금 기준 : 독촉 이후 1주 안에 지급 안하는 경우(무응답 포함)</BlackDiv></li>*/}
                {/*            <li><BlackDiv>성공보수 미수금 안에 안 들어가는 경우 : 진행한 실비 비용만 청구(내용증명 등)</BlackDiv></li>*/}
                {/*        </ul>*/}
                {/*        <li><BlackDiv>파트너 로펌 진행 : 1.소송(민사소송 또는 지급명령신청), 2.강제집행(특정재산 있으면 그 재산에 집행, 없으면 예금채권압류, 채무불이행자명부, 재산명시, 재산조회 진행)</BlackDiv></li>*/}
                {/*        <li><BlackDiv>파트너 신용정보회사 진행 : 신용조사, 채권추심 등</BlackDiv></li>*/}
                {/*    </ul>*/}
                {/*</Card>*/}

                <Card title={plan.title} hoverable="true" style={{width:"100%"}} loading={!plan.valid}>
                    <Space direction="vertical" size={11} style={{marginTop:0, width:"100%"}}>
                        <div>
                            <GrayDiv>선금 및 성공보수</GrayDiv>
                            <DarkBlueDiv style={{fontSize:16, fontWeight:"bold"}}>{plan.upfront}</DarkBlueDiv>
                        </div>
                        <div>
                            <GrayDiv>위임내용</GrayDiv>
                            <Input.TextArea readOnly={true} style={{backgroundColor:'#fafafa'}} autoSize={{ minRows: 10, maxRows: 100 }} value={plan.contingency}/>
                            {/*<DarkBlueDiv style={{fontSize:16, fontWeight:"bold"}}>{plan.contingency}</DarkBlueDiv>*/}
                        </div>
                        {plan && plan.confirmed &&
                            <>
                                <Checkbox style={{fontSize:11}} checked disabled>
                                    (필수) 본 사건의 진행과 관련된 문자, 이메일 등 정보 수신에 동의합니다.
                                </Checkbox>
                                <Checkbox style={{fontSize:11}} checked disabled>
                                    (필수) 위임계약에 동의합니다.
                                </Checkbox>
                                <Button type="primary" block="true" disabled>이미 확인하셨습니다</Button>
                            </>
                        }
                        {plan && !plan.confirmed &&
                            <>
                                <BlackCheckbox style={{fontSize:12, color:'gray'}}
                                          onChange={(ev) => {setAgreementChecked(ev.target.checked)}}>
                                    (필수) 본 사건의 진행과 관련된 문자, 이메일 등 정보 수신에 동의합니다.
                                </BlackCheckbox>
                                <BlackCheckbox style={{fontSize:12, color:'gray'}}
                                               onChange={(ev) => {setAgreementChecked2(ev.target.checked)}}>
                                    (필수) 위임계약에 동의합니다.
                                </BlackCheckbox>
                                <BlackButton  block="true" disabled={!plan.valid || !agreementChecked || !agreementChecked2} onClick={() => onConfirmButtonClicked()}>확인</BlackButton>
                            </>
                        }
                    </Space>
                </Card>
            </Space>
            <Spin size="large" spinning={fullscreenSpinning} fullscreen />
        </B2bLayout>
    )
}

export default B2bPlanConfirm;
