import { OrangeButton, WhiteButton, WhiteTopBar } from "../common/Common";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { ToggleButton } from 'primereact/togglebutton';
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { BoNetUtil } from "../common/Util";
import { useRecoilValue } from "recoil";
import { BACKEND_URL } from "../Atoms";

const quitReason = [
    "탈퇴 후 재가입을 위해서",
    "자주 이용하지 않아서",
    "서비스 및 고객지원이 만족스럽지 않아서",
    "광고성 알림이 너무 많이 와서"
]

export function QuitReasonPage(props) {
    const [reason, setReason] = useState(-1);  // 위의 quitReason중 어느거?
    const [reasonText, setReasonText] = useState("");
    const navigate = useNavigate();

    const saveReason = () => {
        /*
        BoNetUtil.post(`${boUrl}/vws/bo/user/quit`, { quitReason: reason >= 0 ? quitReason[reason] : "", quitReasonComment: reasonText },
            (resp) => {
                console.log(`quit reason saved`);
                console.dir(resp);
                navigate("/qend");
            },
            (err) => console.error(err)
        );
        */
        // 입력을 담아 다음 URL로 전달하여 처리, 
        navigate("/qend", {state: {quitReason: reason >= 0 ? quitReason[reason] : "", quitReasonComment: reasonText}});
    }

    return (
        <div className="px-2">
            <WhiteTopBar title="회원탈퇴" />
            <div className="text-left p-3">
                <div className="text-lg font-bold pt-3 pb-3">
                    탈퇴 사유 선택
                </div>
                <div>
                    <ToggleButton onLabel={quitReason[0]} offLabel={quitReason[0]} checked={reason === 0} onChange={(e) => { setReason(0) }} className="w-full p-3 mb-2" />
                    <ToggleButton onLabel={quitReason[1]} offLabel={quitReason[1]} checked={reason === 1} onChange={(e) => { setReason(1) }} className="w-full p-3 mb-2" />
                    <ToggleButton onLabel={quitReason[2]} offLabel={quitReason[2]} checked={reason === 2} onChange={(e) => { setReason(2) }} className="w-full p-3 mb-2" />
                    <ToggleButton onLabel={quitReason[3]} offLabel={quitReason[3]} checked={reason === 3} onChange={(e) => { setReason(3) }} className="w-full p-3 mb-2" />
                    <InputTextarea className="w-full" value={reasonText} onChange={(e) => setReasonText(e.target.value)}
                        placeholder="이 외에 불편하셨던 점을 알려주세요 (선택)" rows="5">
                    </InputTextarea>
                </div>
                <div className="text-m text-700 p-10 my-4">
                    잦은 알림에 탈퇴를 원하신다면 탈퇴 대신 광고성 알림 수신 거부해주세요.
                </div>
                <div className="w-full grid">
                    <div className="col-6">
                        <WhiteButton label="취소하기" onClick={() => navigate(-2)} />
                    </div>
                    <div className="col-6">
                        <OrangeButton label="다음" onClick={saveReason} />
                    </div>
                </div>
            </div>
        </div>
    );
}