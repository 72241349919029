import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrangeButton, WhiteTopBar} from "../common/Common";
import {FlexColumnCenter} from "../common/DeskComponent";
import {Card} from "antd";
import useAxios from "../hooks/useAxios";
import {BACKEND_URL} from "../Atoms";
import axios from "axios";



const GasUnpaidView = (props) => {

    /** 채팅방ID (chatId = debtorUserChatObjectId) 을 기준으로 cmp에서 고객을 찾아서, 미납내역 이미지 URL을 찾음 */

    const { chatId } = useParams();
    console.log('chatId:' + chatId);
    const {axiosGet} = useAxios();

    const navigate = useNavigate();
    const [docImageUrl, setDocImageUrl] = useState();

    useEffect(() => {
        if (chatId) {
            firstSearch();
        }

    }, []);

    const firstSearch = async () => {

        // let data = await axiosGet(`/api/ipoUser/gasUnpaidView/docImageUrl/${chatId}`);

        //authentication없이 직접 접속.
        let url = `${BACKEND_URL}/api/ipoUser/gasUnpaidView/docImageUrl/${chatId}`
        let {data} = await axios.get(url);
        console.log('image Url:', data);

        setDocImageUrl(data);
    }

    if (chatId && !docImageUrl) return null; //미납내역 로딩전.

    //chatId없는 /gv 접속시 안내페이지
    if (!docImageUrl || docImageUrl==='NOT_EXIST') return (<>
        <div className="">
            <div className="sticky top-0 z-5">
                <WhiteTopBar debtor={true} noBackButton={true} title={'미납내역 확인 안내'}/>
            </div>

            <br/><br/><br/><br/><br/>
            <FlexColumnCenter>
                <Card title={<div style={{fontSize: 18}}>안 내</div>} hoverable="true"
                      style={{fontSize: 18, width: "90%"}}>
                    {/*<div className="px-3">*/}
                        <div style={{fontSize:14, textAlign:'left'}}>
                            1. 미납요금 확인 - 씨엔씨티에너지 콜센터(1666-0009) <br/>
                            2. 기타 채무에 관련된 의논사항 - 씨엔씨티에너지 법률대리인 채권도시 법률사무소 <br/>
                            &nbsp;&nbsp;  ■ 전화 : 02-573-0954<br/>
                            &nbsp;&nbsp;  ■ 카카오톡 - <a href="http://pf.kakao.com/_xbxbZFG/chat"> http://pf.kakao.com/_xbxbZFG/chat </a><br/>
                            &nbsp;&nbsp;  ■ 카카오톡 : 010-8859-5159<br/> <br/>
                            <div style={{fontSize:12}}>
                            *.주의 : 씨엔씨티에너지 콜센터에서는 채무에관한 의논이나 협의를 할 수 없습니다. 채무에관한 의논은 법률대리인인 채권도시 법률사무소와만 대화하실 수 있습니다.<br/>
                            </div>
                        </div>
                        {/*<br/>*/}
                        {/*<div className="text-lg"> 미납요금에 대하여 확인하고 싶으신 경우</div>*/}
                        {/*<div className="text-lg" style={{fontWeight: 700}}>씨엔씨티에너지 콜센터(1666-0009)</div>*/}
                        {/*<div className="text-lg">로 문의하시기 바랍니다. </div>*/}
                        {/*<br/>*/}
                    {/*</div>*/}
                </Card>

                <br/><br/><br/><br/><br/><br/>
                <img style={{width: '70%'}} src={'/assets/logoC.png'}/>

            </FlexColumnCenter>
        </div>
    </>);

    return(
        <>
            <div className="">
                <div className="sticky top-0 z-5">
                    <WhiteTopBar noBackButton={true} title={'월별 미납내역'}/>
                </div>
                <br/>

                <img style={{width: '90%'}} src={docImageUrl}/>
            </div>
        </>
    )
}

export default GasUnpaidView;