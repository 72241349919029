import { WhiteTopBar } from "../common/Common";
import { OrangeButton } from '../common/Common';
import { WhiteButton } from '../common/Common';
import { useNavigate } from "react-router";

export function UserQuitPage(props) {

    const navigate = useNavigate();

    return (
        <div className="px-2">
            <WhiteTopBar title="회원탈퇴"/>
            <div className="text-left p-3">
                <div className="text-lg font-bold pt-3 pb-3">
                    회원님,<br />
                    채권 자산을<br />
                    포기하실 건가요?
                </div>
                <OrangeButton label="채권 자산 유지하기" 
                    onClick={() => navigate(-1)} />
                <WhiteButton label="혜택 포기하고 탈퇴하기" 
                    onClick={() => navigate('/qreason')} />
            </div>
        </div>
    )
}