import { useEffect, useState } from 'react';
import { OrangeButton, WhiteTopBar, UserPortrait } from "../common/Common";
import { useNavigate } from "react-router-dom";
import {
    BoUserIdAtom,
    BACKEND_URL,
    BoTokenAtom,
    BoUserTypeAtom,
    CounselEnrollAtom,
    CounselProgressAtom,
    UserObjectIdAtom
} from '../Atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BoNetUtil } from "../common/Util";

export function MyPage(props) {
    const boUserId = useRecoilValue(BoUserIdAtom); 
    const boUserType = useRecoilValue(BoUserTypeAtom);

    console.dir(boUserType);
    const logged = !boUserType || boUserType !== "anonymous";

    return (
        <div className="px-2">
            <WhiteTopBar title="마이페이지"/>
            { logged ? <MyPageUser/> : <MyPageAnonymous/>}
        </div>
    )
}

export function MyPageAnonymous(props) {
    const navigate = useNavigate();

    const gotoChat = (e) => {
        if (window.location?.host.startsWith('2ai')) {
            alert('시스템 점검 중입니다. ');
            return;
        }
        navigate("/chat");
    }
    return (
        <div>
            <UserPortrait userInfo={null}/>
            <OrangeButton label="채권등록" onClick={gotoChat}/>
            <br/>
            <hr/>
            <MyPageLinkBar icon="pi-info-circle" text="공지사항" url="/news"/>
            <MyPageLinkBar icon="pi-comments" text="자주 묻는 질문" url="/faq"/>
            <MyPageLinkBar icon="pi-book" text="이용약관" url="/terms"/>       
            <MyPageLinkBar icon="pi-id-card" text="개인정보처리방침" url="/privacy"/>     
            <MyPageLinkBar icon="pi-check-square" text="면책공고" url="/disclaimer"/>            
            {/* <MyPageLinkBar icon="pi-building" text="회사소개" url="/company"/> */}
        </div>
    );
}

export function MyPageUser(props) {
    const boUserId = useRecoilValue(BoUserIdAtom);
    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const [boUserType, setBoUserType] = useRecoilState(BoUserTypeAtom);
    const [userObjectId, setUserObjectId] = useRecoilState(UserObjectIdAtom);

    const [enrolledCount, setEnrolledCount] = useRecoilState(CounselEnrollAtom);
    const [progressCount, setProgressCount] = useRecoilState(CounselProgressAtom);

    const [userInfo, setUserInfo] = useState({});

    /*
    useEffect(() => {
        BoNetUtil.get_plain(`${boUrl}/vws/bo/auth/token`, { username: boUserId },
            (resp) => {
                // { accessToken: "AA", "refreshToken": "BB", type: "USER", userId: "aaabbb" }
                console.dir(resp.data);
            },
            (err) => console.error(err)
        );
    }, [boUserId]);
    */
   /*
    useEffect(() => {
        // anonymous 정보를 localStorage에 넣기 위해, 실제 하는건 없지만 호출함. 
        BoNetUtil.get(`${boUrl}/vws/bo/user/oauth2/info`, { username: boUserId },
            (resp) => {
                console.log("user/oauth2/info");
                console.dir(resp.data);
            },
            (err) => console.error(err)
        );
    }, [boUserId]);
    */
    useEffect(() => {
        console.log("MyPage REFRESH====");
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, { username: boUserId },
            (resp) => {
                /*
                    {
                        "idx": 32,
                        "username": "55ab1dfb00e74145c94e7c10d98050c2Nl7mVa5n",
                        "name": "이재규",
                        "nickname": "이재규 Jake Lee",
                        "email": "leejaku@gmail.com",
                        "createdAt": "2023-10-27T17:42:57.305058",
                        "socialType": "KAKAO",
                        "socialId": "3136059212",
                        "imageUrl": "http://k.kakaocdn.net/dn/UlinA/btr7hUztUiC/oXzPaP4Nfk9iA9Nx6KrKz0/img_640x640.jpg",
                        "phone": "+82 10-4547-5042",
                        "socialInfo": {
                            "id": 3136059212,
                            "properties": {
                                "nickname": "이재규 Jake Lee",
                                "profile_image": "http://k.kakaocdn.net/dn/UlinA/btr7hUztUiC/oXzPaP4Nfk9iA9Nx6KrKz0/img_640x640.jpg",
                                "thumbnail_image": "http://k.kakaocdn.net/dn/UlinA/btr7hUztUiC/oXzPaP4Nfk9iA9Nx6KrKz0/img_110x110.jpg"
                            },
                            "connected_at": "2023-10-27T06:33:03Z",
                            "kakao_account": {
                                "name": "이재규",
                                "email": "leejaku@gmail.com",
                                "profile": {
                                    "nickname": "이재규 Jake Lee",
                                    "is_default_image": false,
                                    "profile_image_url": "http://k.kakaocdn.net/dn/UlinA/btr7hUztUiC/oXzPaP4Nfk9iA9Nx6KrKz0/img_640x640.jpg",
                                    "thumbnail_image_url": "http://k.kakaocdn.net/dn/UlinA/btr7hUztUiC/oXzPaP4Nfk9iA9Nx6KrKz0/img_110x110.jpg"
                                },
                                "birthyear": "1970",
                                "has_email": true,
                                "phone_number": "+82 10-4547-5042",
                                "has_birthyear": true,
                                "is_email_valid": true,
                                "has_phone_number": true,
                                "is_email_verified": true,
                                "name_needs_agreement": false,
                                "email_needs_agreement": false,
                                "birthyear_needs_agreement": false,
                                "phone_number_needs_agreement": false,
                                "profile_image_needs_agreement": false,
                                "profile_nickname_needs_agreement": false
                            }
                        },
                        "refreshToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJSZWZyZXNoVG9rZW4iLCJleHAiOjE2OTk2MDU3Nzd9.8mfdGfIiX75z-eRbXS5iu_OyeOfsrKq5VUEVr-QRG8c",
                        "reference": "none",
                        "userrole": "ROLE_USER"
                    }
                */
                console.log("user/info=%o", resp.data);                
                setUserInfo(resp.data);
            },
            (err) => console.error(err)
        );
    }, [boUserId]);

    const onLogout = () => {
        setBoToken({});
        setBoUserType("");
        setUserObjectId("");
        setEnrolledCount(0);
        setProgressCount(0);
        //window.location.reload();  // 이건 localStorage["bo-uid"]를 지우기 위해서 불러줌
        window.location.href = "/";
    }

    return (
        <div>
            <UserPortrait userInfo={userInfo}/>
            <hr/>
            <MyPageLinkBar icon="pi-list" text="채권목록" url="/chatlist"/>            
            <hr/>
            <MyPageLinkBar icon="pi-user-edit" text="프로필 수정" url="/profile"/>            
            <MyPageLinkBar icon="pi-pencil" text="회원정보 수정" url="/edituser"/>            
            <hr/>
            <MyPageLinkBar icon="pi-info-circle" text="공지사항" url="/news"/>
            <MyPageLinkBar icon="pi-comments" text="자주 묻는 질문" url="/faq"/>
            <MyPageLinkBar icon="pi-book" text="이용약관" url="/terms"/>       
            <MyPageLinkBar icon="pi-id-card" text="개인정보처리방침" url="/privacy"/>     
            <MyPageLinkBar icon="pi-check-square" text="면책공고" url="/disclaimer"/>            
            {/*<MyPageLinkBar icon="pi-building" text="회사소개" url="/company"/>*/}
            <hr/>
            <MyPageLinkBar icon="pi-sign-out" text="로그아웃" onClick={onLogout}/>       
        </div>
    )
}

function MyPageLinkBar(props) {
    // props.icon
    // props.text
    // props.url
    const navigate = useNavigate();

    return (
        <div className="flex align-items-center px-2 py-3 cursor-pointer" onClick={() => navigate(props.url)} {...props} >
            <i className={`mx-2 pi ${props.icon}`}/>
            <span className="mx-2">{props.text}</span>
            <i className="pi pi-chevron-right ml-auto px-2"/>
        </div>
    );
}