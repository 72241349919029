import React from "react";
import {WhiteTopBar} from "../../common/Common";
import {FlexColumnCenter} from "../../common/DeskComponent";
import {Space} from "antd";

const CncityFinalNoti = () => {
    return (
        <div>
            <div className="sticky top-0 z-5">
                <WhiteTopBar debtor={true} noBackButton={true} title={'채무 변제에 관한 내용증명'}/>
            </div>

            <FlexColumnCenter>
                <Space direction="vertical">
                    <img style={{width: "100%"}} src="/cncity_final_noti_static_1.png"/>
                    <img style={{width: "100%"}} src="/cncity_final_noti_static_2.png"/>
                    <img style={{width: "100%"}} src="/cncity_final_noti_static_3.png"/>
                    <img style={{width: "100%"}} src="/cncity_final_noti_static_4.png"/>
                </Space>
            </FlexColumnCenter>
        </div>
    );
};

export default CncityFinalNoti;