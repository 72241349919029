import React, {useState, useEffect} from "react";
import {Button, Space, Input, Select, Checkbox, message} from "antd";
import {Flex, FlexCenter, FlexColumn, BlackButton, GrayDiv, Div, GrayBox, EmptyBox, GrayLine, TwoColumnContainer, Ul, Li } from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import {BACKEND_URL} from "../../Atoms";
import axios from "axios";
import B2bLayout from "./B2bLayout";
import {isMobile} from 'react-device-detect';
import useAxios from "../../hooks/useAxios";
import PrivateFileUploader from "../../components/PrivateFileUploader";
import PublicFileUploader from "../../components/PublicFileUploader";
import {removePipeline} from "../../common/CmUtil";


const NEW_DEPART_NAME_INPUT = '+ 새로운 부서 입력 ';

const B2bSignup = (props) => {

    const navigate = useNavigate();
    // const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    const [newUser, setNewUser] = useState({bizType:'b2b'});

    //old private-file const [uploadingFile, setUploadingFile] = useState();
    //부서명 자동선택 관련.
    const [departNameList, setDepartNameList] = useState();
    const [tempDepartName, setTempDepartName] = useState();

    useEffect(()=> {
        if (tempDepartName) {
            if (tempDepartName !== NEW_DEPART_NAME_INPUT) {
                console.log("부서명 useEffect:", tempDepartName);
                setNewUser(prev => ({...prev, departName:tempDepartName}))

            }else { //새부서입력 선택시 reset
                setNewUser(prev => ({...prev, departName:undefined}))
            }
        }

    }, [tempDepartName])


    const [checkAll, setCheckAll] = useState(false);
    const [check1, setCheck1] = useState(false);
    const [check2, setCheck2] = useState(false);

    const [passwordOk, setPasswordOk] = useState(false);
    const [passwordConfirmOk, setPasswordConfirmOk] = useState(false);

    const openPopup = (url) => {
        window.open(url, '팝업', 'width=800,height=600,toolbar=no,location=no');
    }

    const handleCorpNoChange = (e) => {
        if (e.target.value && e.target.value.length > 12) return;

        // 입력 요소의 값이 변경될 때 상태를 업데이트합니다.
        let dashedValue = (e.target.value && (e.target.value.length === 3 || e.target.value.length === 6 ))?
                            e.target.value + '-' : e.target.value;


        setNewUser(prev => ({...prev, corpNo:dashedValue})); //email => abc로 세팅.
    }

    const handleCheckBox = (e) => {
        if (e.target.name === 'checkAll') {
            if (!checkAll) { //전체동의 미선택상태에서 -> 선택시.
                setCheck1(true);
                setCheck2(true);
            }else {
                setCheck1(false);
                setCheck2(false);
            }
            setCheckAll(prev => !prev)
        }
        if (e.target.name === 'check1') {
            setCheck1(prev => !prev)
        }
        if (e.target.name === 'check2') {
            setCheck2(prev => !prev)
        }
    }

    const onEmailConfirm = async() => {

        if (!newUser.email) {
            message.info("이메일을 입력해 주세요.");
            return;
        }else {
            const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
            let emailValid = emailPattern.test(newUser.email);
            if (!emailValid) {
                message.info("이메일 형식이 잘못되었습니다.");
                return;
            }
        }

        let {data:retBool} = await axios.get(`${BACKEND_URL}/apiOpen/b2b/emails/${newUser.email}`);
        console.log("onEmailConfirm:", retBool);
        if (!retBool) {
            message.info("동일한 이메일이 존재합니다.");
            return;
        }

        message.info("사용가능한 이메일입니다.");
        setNewUser(prev => ({...prev, emailConfirm:true}));

        //동잃한 회사정보 있으면 가져와서 세팅.
        let {data:existingCompanyUser} = await axios.get(`${BACKEND_URL}/apiOpen/b2b/companyDomainUser/${newUser.email}`);

        console.log('existingCompanyUser:', existingCompanyUser);
        if (existingCompanyUser) {
            setNewUser(prev => ({...prev, corpName:existingCompanyUser.corpName,
                                                corpNo:existingCompanyUser.corpNo,
                                                corpRegistFileName:existingCompanyUser.corpRegistFileName,
                                                addr1:existingCompanyUser.addr1,
                                                ceo:existingCompanyUser.ceo }));

            //부서명 리스트 가져오기.
            let {data:dbDepartNameList} = await axios.get(`${BACKEND_URL}/apiOpen/b2b/companyDomainDepartName/${newUser.email}`);

            console.log('dbDepartNameList:', dbDepartNameList);

            if (dbDepartNameList.length > 0) {

                dbDepartNameList.push(NEW_DEPART_NAME_INPUT);
                await setDepartNameList(dbDepartNameList);

                console.log('dbDepartNameList2:', dbDepartNameList);

            }else {
                await setDepartNameList(undefined);
            }
        }

    }



    const handleInputChange = (e) => {
        // 입력 요소의 값이 변경될 때 상태를 업데이트합니다.
        setNewUser(prev => ({...prev, [e.target.name]:e.target.value})); //email => abc로 세팅.
    }


    const handlePasswordBlur = () => {
        if (!newUser.password) {
            message.info('비밀번호를 입력해 주세요')
            return;
        }else {
            let specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
            let letterRegex = /[a-zA-Z]/;

            // 특수 기호와 영문자가 모두 포함되어 있는지 확인
            let hasSpecialCharacter = specialCharacterRegex.test(newUser.password);
            let hasLetter = letterRegex.test(newUser.password);

            if(!hasSpecialCharacter || !hasLetter) {
                message.info('비밀번호에 영문과 특수기호 및 숫자가 포함되어야합니다.')
                return;
            }
        }
        setPasswordOk(true);
    }

    const handlePasswordConfirmBlur = () => {
        if (newUser.password !== newUser.passwordConfirm) {
            message.info('비밀번호 확인이 잘못되었습니다. 다시 입력해 주세요')
            return;
        }
        setPasswordConfirmOk(true);
    }

    const onSave = async() => {

        if (!newUser.emailConfirm) {
            message.info('이메일 중복확인을 해주세요')
            return;
        }
        if (!newUser.password) {
            message.info('비밀번호를 입력해 주세요')
            return;
        }else {
            let specialCharacterRegex = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/;
            let letterRegex = /[a-zA-Z]/;

            // 특수 기호와 영문자가 모두 포함되어 있는지 확인
            let hasSpecialCharacter = specialCharacterRegex.test(newUser.password);
            let hasLetter = letterRegex.test(newUser.password);

            if(!hasSpecialCharacter || !hasLetter) {
                message.info('비밀번호에 영문과 특수기호가 포함되어야합니다.')
                return;
            }
        }


        if (!newUser.corpName) {
            message.info('법인명을 입력해주세요 ')
            return;
        }
        if (!newUser.corpNo || newUser.corpNo.length < 10) {
            message.info('사업자등록번호를 10자리 입력해 주세요')
            return;
        }
        if (!newUser.addr1) {
            message.info('주소를 입력해 주세요')
            return;
        }
        if (!newUser.ceo) {
            message.info('대표자 이름을 입력해 주세요')
            return;
        }
        if (!newUser.staff || !newUser.staffPhone  ) {
            console.log('staff', newUser.staff, newUser.staffPhone);
            message.info('담당직원 이름과 휴대폰을 입력해 주세요.')
            return;
        }else {
            let regex = /^010[0-9]{8}$/;
            // "-"를 제거하고 숫자만 남기기
            let cleanedPhoneNumber = newUser.staffPhone.replace(/-/g, '');
            // 정규 표현식과 매치되는지 확인
            let validPhone =  regex.test(cleanedPhoneNumber);
            if (!validPhone) {
                message.info('휴대폰 번호형식이 잘못되었습니다.')
                return;
            }
        }
        if (!newUser.creditType) {
            message.info('계속발생 채권종류를 선택해 주세요.')
            return;
        }

        if (!check1 || !check2) {
            message.info('필수동의 항목을 선택해 주세요')
            return;
        }

        if (!newUser.corpRegistFileName) {
            message.info('사업자 등록증을 선택해 주세요')
            return;
        }
        // //사업자 등록증 upload.
        // const formData = new FormData();
        // formData.append("file", uploadingFile);
        //
        // let {data:retStr} = await axios.post(`${BACKEND_URL}/api/file/privateFile`,
        //         formData, {headers: {"Content-Type": "multipart/form-data"}} );
        //
        // console.log(retStr);
        // //corpRegistFileName 에 저장.
        // if (!retStr) {
        // //     setNewUser(prev => ({...prev, corpRegistFileName:retStr })); //시간걸림.
        // // }else {
        //     message.info('사업자 등록증 업로드 실패: 파일형식 및 파일사이즈를 체크해주세요.')
        //     return;
        // }


        let {data:retInt} = await axios.post(`${BACKEND_URL}/apiOpen/b2b/signup`, newUser);
        console.log("onSave:", retInt)
        if (retInt < 0) {
            // alert("회원가입 오류가 발생했습니다.");
            messageApi.error("회원가입 오류가 발생했습니다.");
        }else {
            navigate('/B2bSignupWait')
        }
    }


    return (
        <B2bLayout title={'채권도시 기업회원 로그인'}>
        {contextHolder}
        <FlexColumn style={{marginLeft:isMobile?0:400}}>
          <FlexCenter >
            {/*<FlexColumn style={{marginTop:50, marginRight:100, marginBottom: isMobile?0:1350}}>*/}
            <FlexColumn style={{width:500}}>

                <Div> 이메일(아이디) </Div>
                <Flex>
                    <Space>
                        <Input placeholder={'담당자 회사 이메일 입력'} style={{width:412}} name={'email'} value={newUser.email} onChange={handleInputChange}/>
                        <Button onClick={onEmailConfirm}> 중복확인 </Button>
                    </Space>
                </Flex>
                {/*<GrayDiv style={{marginTop:10}}> 가입이 가능한 이메일입니다.</GrayDiv>*/}

                <Div> 비밀번호 </Div>
                <Input disabled={!newUser.emailConfirm} type={'password'} name={'password'} onBlur={handlePasswordBlur} value={newUser.password} onChange={handleInputChange}/>
                <GrayDiv> 영문, 특수문자, 숫자를 포함하여 입력하여 주세요.</GrayDiv>

                <Div> 비밀번호 확인</Div>
                <Input disabled={!passwordOk} type={'password'} name={'passwordConfirm'} onBlur={handlePasswordConfirmBlur} value={newUser.passwordConfirm} onChange={handleInputChange}/>

                <Div> 법인명</Div>
                <Input disabled={!passwordConfirmOk} name={'corpName'} value={newUser.corpName} onChange={handleInputChange}/>

                <Div> 사업자등록번호</Div>
                <Input disabled={!newUser.corpName} name={'corpNo'} value={newUser.corpNo} onChange={handleCorpNoChange}/>

                <Div> 법인 본인인증</Div>
                {/*<Input disabled={!newUser.corpNo}  type={'file'} style={{width:500}} onChange={onFileChange}/>*/}
                <PublicFileUploader imageUrl={newUser.corpRegistFileName} disabled={!newUser.corpNo}
                                    downloadName = {'사업자등록증'}
                                    onChange={(returnUrl) => setNewUser(prev => ({...prev, corpRegistFileName:returnUrl}))}/>
                <GrayDiv> 사업자등록증을 이미지나 pdf로 첨부해주세요.</GrayDiv>

                <Div> 본점 주소 </Div>
                <FlexColumn>
                    <Input disabled={!newUser.corpRegistFileName}  placeholder={'주소'} name={'addr1'} value={newUser.addr1} style={{width:500}} onChange={handleInputChange}/>
                    {/*<Input placeholder={'상세 주소'} name={'addr2'} value={newUser.addr2} style={{width:400, marginTop:10}} onChange={handleInputChange}/>*/}
                </FlexColumn>

                <Div> 대표자 이름</Div>
                <Input disabled={!newUser.addr1} name={'ceo'} value={newUser.ceo} onChange={handleInputChange}/>

                <Div> 부서명 </Div>
                <Space>
                    {departNameList &&
                        <Select
                            placeholder={'부서를 선택해 주세요'} style={{width: 240}}
                            value={tempDepartName}
                            onChange={(value) => setTempDepartName(value)}
                            options={
                                departNameList.map((item) => ({value: item}))
                            }
                        />
                    }

                    {(!departNameList || tempDepartName === NEW_DEPART_NAME_INPUT) &&
                        <Input name={'departName'} value={newUser.departName} style={{width: departNameList?240:500}} onChange={handleInputChange}/>
                    }
                </Space>

                <Div> 담장직원 이름</Div>
                <Input disabled={!newUser.departName} name={'staff'} value={newUser.staff} onChange={handleInputChange}/>

                <Div> 담장직원 휴대폰</Div>
                <Input disabled={!newUser.staff} name={'staffPhone'} value={newUser.staffPhone} onChange={handleInputChange}/>

                <Div> 계속적으로 발생하는 채권 종류 </Div>
                <Select disabled={!newUser.staffPhone}
                    placeholder={'선택해 주세요'}
                    style={{ width: 500 }}
                    value = {newUser.creditType}
                    onChange={value => setNewUser(prev => ({...prev, creditType:value }))}
                    options={[
                        { value: '1. 서비스 제공과 과련된 약정금' },
                        { value: '2. 상품에 대한 대금' },
                        { value: '3. 대출금' },
                        { value: '4. 기타' },
                    ]}
                />

                <Div> 채무자(개인사업자 또는 개인인경우) 주민번호 확보 여부 </Div>
                <Select disabled={!newUser.creditType}
                    placeholder={'선택해 주세요'}
                    style={{ width: 500 }}
                    value = {newUser.juminObtain}
                    onChange={value => setNewUser(prev => ({...prev, juminObtain:value }))}
                    options={[
                        { value: '예' },
                        { value: '아니오' },
                    ]}
                />

                <br/>

                <FlexColumn>
                    <Flex>
                        <Checkbox name={'checkAll'} checked={checkAll} style={{margin:3}} onChange={handleCheckBox}> </Checkbox>
                        <Div style={{margin:4}}><b> 전체 동의</b></Div>
                    </Flex>

                    <GrayLine style={{width:500}}/>
                    <Flex style={{marginTop:10}}>
                        <Checkbox name={'check1'} checked={check1} style={{margin:3}} onChange={handleCheckBox}> </Checkbox>
                        <Space>
                            <Div style={{margin:5}}> 채권도시B 서비스 이용약관 동의(필수)</Div>
                            <GrayDiv style={{margin:0}} onClick={()=> openPopup('http://creditncity.com/regulation.html')}>자세히</GrayDiv>
                        </Space>
                    </Flex>

                    <Flex style={{marginTop:0}}>
                        <Checkbox name={'check2'} checked={check2} style={{margin:3}} onChange={handleCheckBox}> </Checkbox>
                        <Space>
                            <Div style={{margin:5}}> 개인정보 수집 및 이용 동의(필수)</Div>
                            <GrayDiv style={{margin:0}} onClick={()=> openPopup('http://creditncity.com/infoProcess.html')}>자세히</GrayDiv>
                        </Space>
                    </Flex>

                </FlexColumn>

                <br/>
                <BlackButton onClick={onSave}  style={{marginTop:5, width:500}}> 가입완료 </BlackButton>
                <br/>
                <br/>

                <GrayBox style={{width:500}}>
                    <div style={{fontSize:16, fontWeight:'bold', textAlign: 'left'}}> 기본 서비스 제공 항목 </div>
                    <div style={{fontSize:14, marginLeft:-30, textAlign: 'left'}}>
                        <Ul>
                            <li>변제기 도래 1일차 채권부터 토탈로 채권관리 및 회수절차 진행</li>
                            <li style={{marginLeft:20}}>비용, 회수율, 기회비용, 편리성 모든 부분에서 최적의 방법</li>
                            <li>VWS는 채권관리 플랫폼을 통해 채권관리 진행 </li>
                        {/*</Ul>*/}
                        {/*<Ul style={{marginLeft:0}}>*/}
                            <li style={{marginLeft:20}}>파트너 로펌(채권마루법률사무소)과 파트너 신용정보회사의 최적화 진행</li>
                            <li style={{paddingLeft:20}}>로펌 진행 : 1.소송(민사소송 또는 지급명령신청), 2.강제집행(특정 재산 있으<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;면 그 재산에 집행, 없으면 예금채권압류, 채무불이행자명부, 재산명시, 재산조<br/>
                                &nbsp;&nbsp;&nbsp;&nbsp;회 등 진행)</li>
                            <li style={{marginLeft:20}}>신용정보회사 진행 : 신용조사, 재산조사, 채권추심 등</li>
                        </Ul>


                    </div>
                </GrayBox>

                <br/>
                <br/>
            </FlexColumn>
          </FlexCenter>
        </FlexColumn>
        </B2bLayout>
    )
}

export default B2bSignup;