import React, {useState, useEffect} from "react";
import {Button, Space} from "antd";
import {Flex, FlexCenter, FlexColumn, FlexRight, TitleBox, GrayDiv, GrayBox} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import B2bLayout from "./B2bLayout";



const B2bPredataWait = (props) => {


    return (
        <B2bLayout title={'채권도시 기업회원 로그인'}>

            <TitleBox style={{marginTop: 70}}> 올려주신 회사 상황에 맞추어</TitleBox>
            <TitleBox style={{marginTop: 0}}> 가격 플랜이 제시됩니다.</TitleBox>

        </B2bLayout>
    )
}

export default B2bPredataWait;