import React, {useState, useEffect} from "react";
import {LayoutWrapper, Header, Footer, Content, GrayLine, GrayDiv, FlexColumn, LNB, MainMenu, SubMenu} from "../../common/B2bComponent";
import {Space} from "antd";
import {useNavigate} from "react-router-dom";
import CreditList from "./creditManage/CreditList";
import CreditUnitAdd from "./creditAdd/CreditUnitAdd";

//backend B2bStatus와 동일.
export const B2bStatus = {
    USER_SIGNUP_WAITING:  0,
    ADMIN_SIGNUP_CONFIRM: 1,    //=회원가입완료. 사용자 predata waiting 상태
    USER_PREDATA_INPUT_DONE: 2, // 프리데이터 입력완료
    ADMIN_PREDATA_CONFIRM: 3,   //= 관리자 plan세팅 완료
    USER_PLAN_CONFRIM_OK:  4,    //사용자의 계약 완료, account테이블에만 존재.
    ADMIN_CONTRACT_SEND_OK: 5,   //관리자의 계약서 이메일 송부 완료, account테이블에만 존재.
}


const B2bLayout = ({title, children, showLNB}) => {

    const navigate = useNavigate();


    //1. Layout깜빡임 제거 1.
    const [child, setChild] = useState(children);

    const [childName, setChildName] = useState();

    //2. childEquals : 개발에선 되는데 배포하면안됨.
    const childNameEquals = (sourceName) => {
        if (sourceName === childName) {
            return true;
        }
        return false;
    }

    const setPage = (childNm) => {
        switch(childNm) {
            case 'CreditList':  setChild(<CreditList/>);
                  break;
            case 'CreditUnitAdd':  setChild(<CreditUnitAdd/>);
                break;

            default: setChild(<CreditList/>);
                break;
        }

        setChildName(childNm);
    }

    const openPopup = (url) => {
        window.open(url, '팝업', 'width=800,height=600,toolbar=no,location=no');
    }




    return (
        <LayoutWrapper>
            <FlexColumn>
                <Header>
                    <img onClick={()=>{navigate('/')}} style={{width:130, height:'auto', marginTop:5, marginBottom:5, marginLeft:5, marginRight:10}} src="/assets/logoB.png" alt="Logo" />
                    {/*<h4s>{title}</h4s>*/}
                    <div style={{fontSize:'14px'}}><b> {title? title:'채권도시 기업회원'} </b></div>
                </Header>
                <GrayLine/>
            </FlexColumn>

            {!showLNB && //로그인관련 화면들.
            <Content>{children}</Content>
            }

            {showLNB && //로그인,프리데이터,플랜컨펌 후 Left Navigation Bar필요.
                <div style={{ display: 'flex' }}>
                    <LNB>

                        <MainMenu > 채권관리 </MainMenu>
                        {/*<SubMenu selected={pageEquals('/b2b/creditList')} onClick={() => {setPage('/b2b/creditList')} }> 채권 목록 </SubMenu>*/}
                        <SubMenu selected={childNameEquals('CreditList')} onClick={() => {setPage('CreditList')} }> 채권 목록 </SubMenu>

                        <MainMenu > 채권등록</MainMenu>
                        {/*<SubMenu selected={pageEquals('/b2b/creditUnitAdd') || pageEquals('/b2b')} onClick={() => {setPage('/b2b/creditUnitAdd')} }> 단위 등록 </SubMenu>*/}
                        <SubMenu selected={childNameEquals('CreditUnitAdd')} onClick={() => {setPage('CreditUnitAdd')} }> 단위 등록 </SubMenu>
                        <SubMenu > api 등록 </SubMenu>
                        <SubMenu > 엑셀 업로드 </SubMenu>

                        <MainMenu > 마이페이지 </MainMenu>
                        <SubMenu > 위임계약 </SubMenu>
                        <SubMenu > 정산 </SubMenu>

                    </LNB>
                    {/*<Content>{children}</Content>*/}
                    <Content>{child}</Content>
                </div>
            }

            <Footer>
                <GrayLine/>
                <Space>
                    <GrayDiv onClick={()=> openPopup('http://creditncity.com/regulation.html')}> 이용약관 </GrayDiv>
                    <GrayDiv  onClick={()=> openPopup('http://creditncity.com/infoProcess.html')}> 개인정보처리방침 </GrayDiv>
                    <div style={{marginRight:30}}> </div>
                </Space>
            </Footer>
        </LayoutWrapper>
    )
}

export default B2bLayout;