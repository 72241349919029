import React, {useState, useEffect} from "react";
import {Button, Space} from "antd";
import {Flex, FlexCenter, FlexColumn, FlexRight, TitleBox, GrayDiv, GrayBox} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import B2bLayout from "./B2bLayout";



const B2bContractWait = (props) => {


    return (
        <B2bLayout title={'채권도시 기업회원 로그인'}>

            {/*<TitleBox style={{marginTop: 70}}> 선택된 플랜으로 계약서를 작성하여</TitleBox>*/}
            {/*<TitleBox style={{marginTop: 0}}> 이메일로 송부드리겠습니다.</TitleBox>*/}
            <TitleBox style={{marginTop: 70}}> 계약이 성립되었습니다. </TitleBox>
            <TitleBox style={{marginTop: 0}}> 계약승인 후 사건이 바로 착수되며, 사건진행경과 및 협업화면을 확인하는데는 </TitleBox>
            <TitleBox style={{marginTop: -20}}> 지금부터 1~2일이 소요됩니다. 이메일로 알려드리겠습니다.</TitleBox>

        </B2bLayout>
    )
}

export default B2bContractWait;