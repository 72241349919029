import {useRef, useState} from "react";
import {Button, Card, ConfigProvider, Image, Input, message, Modal, Popconfirm, Result, Segmented, Space} from "antd";
import {QrcodeOutlined, SearchOutlined, SendOutlined, SmileOutlined} from "@ant-design/icons";
import {goToKakaoChannel} from "../common/Util";
import {BACKEND_URL} from "../Atoms";
import GReCaptchaV3Tokener from "./GReCaptchaV3Tokener";

const isValidPhoneNumber = (str) => {
    return str && str.startsWith("010") && (str.length === 10 || str.length === 11);
};

const KakaoChannelGuideModal = ({open, setOpen}) => {
    const [messageApi, contextHolder] = message.useMessage();

    const [mode, setMode] = useState("SMS");

    const [mobileNo, setMobileNo] = useState("");
    const [sending, setSending] = useState(false);

    const [successfulMobileNo, setSuccessfulMobileNo] = useState();

    const refSendButton = useRef();
    const refReCaptcha = useRef();

    const [confirmSendOpen, setConfirmSendOpen] = useState(false);

    const onSendButtonClicked = async () => {
        setSending(true);

        const token = await refReCaptcha.current.getToken();
        console.log("Google reCaptcha v3 token =", token);

        fetch(
            `${BACKEND_URL}/apiOpen/b2c/guides/kakaochannel/textmessage`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify({
                    toMobileNo: mobileNo,
                    gReCaptchaV3Token: token
                })
            }
        )
            .then((res) => {
                console.log("/apiOpen/b2c/guides/kakaochannel/textmessage <-", res);
                res.json()
                    .then((json) => {
                        // console.log(json);
                        if (res.ok) {
                            onSendSucceed();
                        } else {
                            onSendFailed(json.message ? json.message : "알 수 없는 오류");
                        }
                    })
                    .catch((jsonErr) => {
                        // console.log(jsonErr);
                        onSendFailed("JSON 에러");
                    })
            })
            .catch((err) => {
                console.log("/apiOpen/b2c/guides/kakaochannel/textmessage <-", err);
                onSendFailed("통신 오류");
            });
    };

    const onSendSucceed = () => {
        // alert("성공");
        messageApi.success("문자를 전송했습니다");
        setSending(false);
        setSuccessfulMobileNo(mobileNo);
        setMobileNo("");
    };

    const onSendFailed = (errMsg) => {
        // alert(`실패: ${errMsg}`)
        messageApi.error(`문자를 전송하지 못했습니다: ${errMsg}`)
        setSending(false);
    };

    return (
        <ConfigProvider theme={
            {
                components: {
                    Result: {titleFontSize: 18}
                }
            }
        }>
            {contextHolder}
            <Modal title="채권도시 법률사무소 카카오톡 채널에 방문하세요" zIndex={2000} centered
                   keyboard={false}
                   afterClose={() => setConfirmSendOpen(false)}
                   footer={[]}
                   open={open}
                   onCancel={() => setOpen(false)}
            >
                <GReCaptchaV3Tokener action="SmsGuideKakaoChannel" ref={refReCaptcha} />
                <p>모바일 또는 PC 카카오톡앱에서 상담이 가능합니다.</p>
                <p>PC 카카오톡앱을 사용하시고 있다면, <Button size="small" type="link" style={{padding:0}} onClick={() => {goToKakaoChannel(); setOpen(false);}}>여기</Button>를 클릭하세요.</p>
                <p>모바일로 이동하시려면, 아래의 안내를 따라주세요.</p>
                <Segmented block size="large"
                           options={[
                               {label: "문자로 링크 받기", value: "SMS", icon: <SendOutlined />},
                               {label: "QR코드", value: "QR", icon: <QrcodeOutlined />},
                               {label: "채널 검색", value: "SEARCH", icon: <SearchOutlined />}
                           ]}
                           onChange={(val) => setMode(val)}
                />
                <Card size="small">
                    {mode === "SMS" && (
                        <Space direction="vertical" style={{width: "100%", paddingBottom:290, minHeight:600}} align="center">
                            {!successfulMobileNo && (
                                <Result key="input" status="info"
                                     title="핸드폰 번호를 입력하세요"
                                     subTitle="카카오톡 채널 링크를 문자로 보내드립니다"
                                     icon={<SendOutlined/>}
                                     extra={[
                                         <Space.Compact key="input" size="large">
                                             <Input placeholder="핸드폰 번호"
                                                    count={{
                                                        max: 11,
                                                        exceedFormatter: (val, {max}) => val.substring(0, max)
                                                    }}
                                                    disabled={sending}
                                                    value={mobileNo}
                                                    onChange={(ev) => setMobileNo(ev.target.value.replace(/\D/g, ''))}
                                                    onPressEnter={() => {
                                                        refSendButton.current.click();
                                                    }}
                                             />
                                             <Popconfirm title="핸드폰 번호를 확인하세요"
                                                         description={`${mobileNo} 이 번호로 문자를 받으시겠습니까?`}
                                                         open={confirmSendOpen}
                                                         onOpenChange={setConfirmSendOpen}
                                                         onConfirm={() => {
                                                             setConfirmSendOpen(false);
                                                             onSendButtonClicked();
                                                         }}
                                                         okText="예"
                                                         cancelText="아니오"
                                             >
                                                 <Button type="primary"
                                                         loading={sending}
                                                         disabled={!isValidPhoneNumber(mobileNo)}
                                                         ref={refSendButton}
                                                 >링크 받기</Button>
                                             </Popconfirm>
                                         </Space.Compact>
                                     ]}
                                />
                            )}
                            {successfulMobileNo && (
                                <Result status="success"
                                        title="카카오톡 채널 링크를 문자로 보내드렸습니다"
                                        subTitle={successfulMobileNo}
                                        icon={<SmileOutlined />}
                                        extra={[
                                            <Button key="again" onClick={() => setSuccessfulMobileNo(null)}>다른 번호로 받기</Button>
                                        ]}
                                />
                            )}
                        </Space>
                    )}
                    {mode === "QR" && (
                        <Space direction="vertical" style={{width: "100%", backgroundColor: "#ffe800", paddingBottom: 129, minHeight:600}}>
                            <p style={{textAlign: "center", paddingTop: 20}}><Image src="/assets/qr_kakao_channel_chat.png" width="50%"/></p>
                            <p><Image preview={false} src="/assets/guide_kakao_qr_scan.png"/></p>
                        </Space>
                    )}
                    {mode === "SEARCH" && (
                        <Space direction="vertical" style={{width: "100%", backgroundColor: "#ffe800", minHeight:600}}>
                            <p><Image src="/assets/quide_kakao_channel_search_1.png" preview={false}/></p>
                            <p><Image src="/assets/quide_kakao_channel_search_2.png" preview={false}/></p>
                        </Space>
                    )}
                </Card>
            </Modal>
        </ConfigProvider>
    );
};

export default KakaoChannelGuideModal;