import { atom, selector } from 'recoil';
import { jwtDecode } from "jwt-decode";

export const BACKEND_URL = 'https://creditncity-c.com';


/** 중요: TODO 2311 현재 여기에 추가시 Atoms.prod에도 복사 필요 */


// userId 를 저장함
// dcol-backend에서 쓰는 user항목의 _id (index), username과는 달라서 따로 관리함.
export const UserObjectIdAtom = atom({
    key: 'UserObjectIdAtom',
    effects: [
        ({ setSelf, onSet }) => {
            let uoid = window.localStorage['userObjectId'];
            setSelf(uoid);

            onSet(newSet => {
                window.localStorage['userObjectId'] = newSet;
                console.log("======== NEW USER_OBJECT_ID ATOM=========", newSet);
            });
        }
    ]
});

// token을 저장함
// // {"type":"anonymous","token":"eyJh..."}
// export const TokenAtom = atom({
//     key: 'TokenAtom',
//     effects: [
//         ({ setSelf, onSet }) => {
//             let tk = {
//                 type: 'anonymous',
//                 token: ''
//             };
//             try {
//                 tk = JSON.parse(window.localStorage['token']);
//             } catch (e) {
//                 //
//             }
//             setSelf(tk);
//             onSet(newSet => {
//                 window.localStorage['token'] = JSON.stringify(newSet);
//             });
//         }
//     ]
// });

export const BoTokenAtom = atom({
    key: 'BoTokenAtom',
    default:'',
    effects: [
        ({ setSelf, onSet }) => {
            let tk = {
                accessToken: '',
                refreshToken: ''
            };
            try {
                tk = JSON.parse(window.localStorage['bo-token']);
            } catch (e) {
                //
            }
            setSelf(tk);
            onSet(newSet => {
                window.localStorage['bo-token'] = JSON.stringify(newSet);
                console.log("======== NEW TOKEN ATOM=========", newSet);
            });
        }
    ]
});

/*
export const BoUserIdAtom = atom({
    key: 'BoUserIdAtom',
    effects: [
        ({ setSelf, onSet }) => {
            let uid = window.localStorage['bo-uid'];
            setSelf(uid);
            onSet(newSet => {
                window.localStorage['bo-uid'] = newSet;
            });
        }
    ]
});
*/
// BoUserIdAtom은 BoTokenAtom의 accessToken을 Parsing해서 얻어짐.
export const BoUserIdAtom = selector({
    key: 'BoUserIdAtom',
    get: ({get}) => {
        const tpair = get(BoTokenAtom);
        try {
            const uid = jwtDecode(tpair?.accessToken)?.sub;
            window.localStorage['bo-uid'] = uid;
            return uid;
        } catch (err) {
            //console.error(err);
            window.localStorage['bo-uid'] = "";
            return "";
        }
    }
});

export const BoUserTypeAtom = atom({
    key: 'BoUserTypeAtom',
    default: 'anonymous',
    effects: [
        ({ setSelf, onSet }) => {
            let storedType = window.localStorage['bo-utype'];
            if (storedType) {
                setSelf(storedType);
            }
            onSet(newSet => {
                window.localStorage['bo-utype'] = newSet;
                console.log("======== NEW USER_TYPE ATOM=========", newSet);
            });
        }
    ]
});

/** 중요: TODO 2311 현재 여기에 추가시 Atoms.dev, Atoms.prod에도 복사 필요 */
//채권등록완료 count
export const CounselEnrollAtom = atom({
    key: 'CounselEnrollAtom',
    default: 0
});
//상담진행중 count
export const CounselProgressAtom = atom({
    key: 'CounselProgressAtom',
    default: 0
});
