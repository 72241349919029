import styled from 'styled-components';
import {Row, Col, Button, Checkbox, Table} from "antd";


export const LayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

export const Header = styled.header`
  background-color: #fff;
  color: black;
  display: flex;
  align-items: center; /* 이미지와 텍스트를 수직 정렬 */
`;

export const Footer = styled.footer`
  background-color: #fff;
  color: gray;
  padding: 10px;
  text-align: right;
`;

export const Content = styled.div`
  flex: 1;
  padding: 20px;
`;

// LNB 스타일드 컴포넌트
export const LNB = styled.nav`
  background-color: #fff; /* 배경색 설정 */
  color: #000; /* 글자색 설정 */
  width: 200; /* LNB 너비 설정 */
  height: 100vh; 
  padding: 30px; /* 여백 설정 */
  /* 필요한 다른 스타일 속성을 추가하세요 */
`;
export const MainMenu = styled.div`
  margin-top: 16px;
  padding-bottom: 5px;
  font-size: 14px;
  font-weight: bold;
  text-align: left;
`;
export const SubMenu = styled.div`
  margin-top: 5px;
  margin-left: 14px;
  padding-bottom: 5px;
  font-size: 12px;
  text-align: left;
  font-weight: ${props => (props.selected ? 'bold' : 'normal')};
  
`;

export const Title2 = styled.div`
  border: none;
  padding-bottom: 16px;
  padding-top: 26px;
  font-weight: bold; /* Makes the text bold */
  font-size: 16px; /* Sets the font size to 26 */
`;

export const Title3 = styled.div`
  border: none;
  padding-bottom: 14px;
  padding-top: 14px;
  font-weight: bold; /* Makes the text bold */
  font-size: 14px; /* Sets the font size to 26 */
`;

export const ContentFlex = styled.div`
  margin: 20;
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start; /* 수직 상단 정렬 */
`;


export const B2bTable = styled(Table)`
  .ant-table-thead > tr > th {
    background-color: black; // 원하는 배경색으로 변경
    color: white; // 텍스트 색상 변경
  }

  .ant-table-tbody > tr > td {
    border-top: 1px solid #d9d9d9; // 셀 상단 테두리 적용
    border-bottom: 1px solid #d9d9d9; // 셀 하단 테두리 적용
  }

  /* 헤더가 hover 상태일 때 배경색 고정 */
  .ant-table-thead > tr > th:hover {
    background-color:  black; /* 배경색을 투명으로 설정하거나 원하는 색상으로 변경 */
    color: white; /* 텍스트 색상을 상속받도록 설정 */
  }

  /* 헤더가 선택된 상태일 때 배경색 고정 */
  .ant-table-thead > tr > th.ant-table-column-sort {
    background-color: black; /* 배경색을 투명으로 설정하거나 원하는 색상으로 변경 */
    color: yellow; /* 텍스트 색상을 상속받도록 설정 */
  }

`;




//화면전체 크기 test용도로도 사용가능.
export const GrayBox = styled.div`
  border: 1px solid #d9d9d9; // 회색 테두리
  padding: 16px; // 내부 여백
  border-radius: 4px; // 테두리 둥글게
  width: 100%;
  height: 100%;
`;
export const EmptyBox = styled.div`
  // border: 1px solid #d9d9d9; 
  padding: 16px; // 내부 여백
  border-radius: 4px; // 테두리 둥글게
  width: 100%;
  height: 100%;
`;

export const TwoColumnContainer = styled.div`
  display: flex;
  justify-content: center; /* 좌우 정렬 */
  // align-items: flex-start; /* 수직 상단 정렬 */
  
  align-items: ${props => (props.mobileFlag ? 'flex-start' : 'center')}; /* 수직 상단 또는 중앙 정렬 */
  flex-direction: ${props => (props.mobileFlag ? 'column' : 'row')}; /* isMobile인 경우 세로로 배치 */
`;

export const Div = styled.div`
  margin-top: 30px;
  padding-bottom: 5px;
  font-size: 14px;
`;

export const Ul = styled.ul`
  list-style-type: none;
    ::before {
    content: '•';
    color: #aaa;
    margin-right: 8px;
  }
`;

export const Li = styled.li`
  ::before {
    content: '•';
    color: #ccc;
    margin-right: 8px;
  }
`;
//box임.
// export const Div = styled.div`
//   background-color: papayawhip;
//   padding: 4em;
// `;

export const TitleBox = styled.div`
  border: none;
  padding-bottom: 16px;
  padding-top: 26px;
  font-weight: bold; /* Makes the text bold */
  font-size: 24px; /* Sets the font size to 26 */
`;


export const Flex = styled.div`
  display: flex;
  justify-content:flex-start;
  align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexCenter = styled.div`
  display: flex;
  justify-content:center;
  align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexRight = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-start; /* 수직 상단 정렬 */
`;
export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: flex-start; /* 수직 상단 정렬 */  

`;

export const FlexColumnCenter = styled.div`
  display: flex;
  flex-direction: column;
  justify-content:flex-start;
  align-items: center; /* 수직 상단 정렬 */
`;

export const BlackDiv = styled.div`
  margin-top:3px;
  color: #000;  
  font-size: 14px;
  // font-weight: bold;
`;

export const GrayDiv = styled.div`
  margin-top:7px;
  margin-bottom:2px;
  color: gray;  
  font-size: 12px;
`;
export const DarkDiv = styled.div`
  margin-top:7px;
  margin-bottom:7px;
  color: black;  
  font-size: 14px;
`;
export const DarkDiv2 = styled.div`
  margin-top:17px;
  margin-bottom:7px;
  color: black;  
  font-size: 14px;
  text-align: left; /* Aligns text to the left */
`;

export const GrayDiv2 = styled.div`
  margin-top:17px;
  margin-bottom:2px;
  color: gray;  
  font-size: 12px;
`;

export const StyledRow = styled(Row)`
  // border: 1px solid #d9d9d9; // 테두리 추가
  padding: 0px; // 콘텐츠와 테두리 간의 간격
  color: gray;  
  font-size: 12px;
`;
export const EmptyCol = styled(Col)`
  padding-top: 0px; // 콘텐츠와 테두리 간의 간격
  padding-left: 10px; // 콘텐츠와 테두리 간의 간격
  padding-right: 10px; // 콘텐츠와 테두리 간의 간격
  color: gray;  
  font-size: 12px;
  text-align: left;
`;

export const StyledCol = styled(Col)`
  border: 1px solid #d9d9d9; // 테두리 추가
  padding: 10px; // 콘텐츠와 테두리 간의 간격
  color: gray;  
  font-size: 12px;
`;


// styled-components를 사용하여 그리드 컨테이너를 생성합니다.
export const GridContainer = styled.div`
  display: grid;
  /* 첫 번째 컬럼의 너비를 작게 설정하고 두 번째 컬럼은 1fr로 설정합니다. */
  grid-template-columns: 100px auto;
  // grid-template-columns: 10px auto;
  grid-auto-flow: row;
  gap: 2px; // 컬럼 사이의 간격을 조절합니다.
  color: gray;  
  font-size: 12px;
  width: 500px;
  justify-items: start;
  
  // border: 1px solid #d9d9d9; /* 회색 테두리 추가 */
  // border-radius: 4px; /* 테두리 둥글게 */
  
`;

// 각 그리드 아이템에 스타일을 적용합니다.
export const GridItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  
  color: gray;  
  font-size: 12px;
 
`;

export const BlackCheckbox = styled(Checkbox)`
  &&& {
    // 체크 상태에서 배경 및 테두리 색상
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: black;
      border-color: black;
    }

    // 호버 상태에서 테두리 색상
    .ant-checkbox-wrapper:hover .ant-checkbox-inner,
    .ant-checkbox:hover .ant-checkbox-inner {
      border-color: black;
    }

    // 호버 상태에서 체크된 체크박스의 배경 및 테두리 색상
    .ant-checkbox-wrapper:hover .ant-checkbox-checked .ant-checkbox-inner,
    .ant-checkbox-checked:hover .ant-checkbox-inner {
      background-color: black;
      border-color: black;
    }
  }
`;

export const BlackButton = styled(Button)`
  background-color: black;
  color: white;  
`;


export const GrayLine = styled.div`
  background-color: #ccc; /* 회색 */
  height: 1px; /* 선의 두께 */
  width: 100%; /* 전체 너비 */
`;

export const GrayDivLink = styled.div`
  margin-top:7px;
  margin-bottom:2px;
  color: gray;  
  font-size: 12px;
  
  &:hover {
    color: blue; /* 마우스 오버 시 색상 변경 예시 */
    cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  }
`;

export const DarkBlueDiv = styled.div`
  margin-top:7px;
  margin-bottom:2px;
  color: #0A174E;  
  font-size: 12px;
`;
