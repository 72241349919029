import React, {forwardRef, useEffect, useState} from 'react';
import {
    Button,
    Space,
    Input,
    Select,
    Checkbox,
    Image,
    Radio,
    Modal,
    message,
} from "antd";


//react-datepicker 5줄.


import { Portal } from "react-overlays";
import DatePicker from 'react-datepicker';
// import 'react-datepicker/dist/react-datepicker.css';
import './DatepickrStyles.css'

import { registerLocale, setDefaultLocale } from  "react-datepicker";
import ko from 'date-fns/locale/ko';

import { getMonth, getYear, getDate } from 'date-fns';
import {Flex, FlexColumn, FlexColumnCenter} from "../../common/DeskComponent";
import {date2str, yyyyMMdd2Arr} from "../../common/Util";

registerLocale('ko', ko)




const DateMaskPicker = ({selected, disabled, onChange}) => {
    // const [selectedDate, setSelectedDate] = useState(null);

    // const [dateMask, setDateMask] = useState(selected? selected: {date:'', mask:0});
    const [dateMask, setDateMask] = useState((selected?.date)? selected: {date:'', mask:0});
    // console.log('dateMaskPicker:', selected);


    useEffect(()=>{
        setDateMask(selected);
    }, [selected])

    // const [mask, setMask] = useState((selected?.mask)? selected.mask:0);

    const [messageApi, contextHolder] = message.useMessage();

    const setttingMask = (mask) => {
        console.log('setttingMask:', mask);
        // setMask(mask);
        setDateMask(prev => ({...prev, mask:mask}));

        if (mask === 1) {
            messageApi.info('연도가 맞는 적당한 날짜를 달력에서 골라주세요 ' );
        }else if (mask === 2) {
            messageApi.info('연월이 맞는 적당한 날짜를 달력에서 골라주세요 ' );
        }

        onChange( {date:dateMask?.date, mask:mask} );
    }


    //date type을 -> dateMask type으로 바꿔서 리턴.
    const callParentOnChange = (date) => {

        console.log('dateMaskPicker  onSave:', date, dateMask.mask);
        let dateStr = date2str(date);

        console.log(date, dateStr, dateMask.mask);

        setDateMask(prev => ({...prev, date:dateStr}));
        //
        onChange( {date:dateStr, mask:dateMask.mask?dateMask.mask:0} );
    }


    // const CustomInput = ({selected, disabled}) => {
    //
    //     return <Input disabled={disabled} value={selected}> </Input>
    // }

    // const ExampleCustomInput = forwardRef(({ value, onClick, disabled }, ref) => {
    const ExampleCustomInput = ({ value, onClick, disabled, dateMask }) => {
       // console.log('dateMaskPicker ExampleCustomInput:', dateMask);

       const yyMMddArr =  yyyyMMdd2Arr(value);
       const yyyy = yyMMddArr[0];
       const mm = yyMMddArr[1];
       const dd = yyMMddArr[2];

       const fgColor = disabled?'gray':'black';

       return(<button className="custom-datepicker" readOnly={disabled}
                      onClick={onClick} >
               {/*ref={ref}>*/}

               <span style={{color:fgColor}}>{yyyy}</span>-<span style={{color:(dateMask.mask==1)?'lightgray':fgColor}}>{mm}</span>-<span style={{color:(!dateMask.mask)?fgColor:'lightgray'}}>{dd}</span>

        </button>
       )
    };
    //});

    return (
        // <CalendarContainer>
        <div>
            {contextHolder}
            <DatePicker className={'custom-datepicker'}
                        disabled={disabled}
                        selected={(selected?.date)? new Date(selected?.date) : undefined}
                        onChange={callParentOnChange}
                        locale={'ko'}
                        dateFormat={'yyyy-MM-dd'}
                        placeholderText={'ㅁ 날짜 선택'}

                        customInput = {<ExampleCustomInput dateMask={dateMask}/>}

                        renderCustomHeader={({
                                                 date,
                                                 changeYear,
                                                 changeMonth,
                                                 decreaseMonth,
                                                 increaseMonth,
                                                 prevMonthButtonDisabled,
                                                 nextMonthButtonDisabled,
                        })=>
                            <FlexColumnCenter>
                            <div
                                style={{
                                    margin: 10,
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <button
                                    onClick={decreaseMonth}
                                    disabled={prevMonthButtonDisabled}
                                >
                                    {'<'}
                                </button>

                                <div style={{margin:5}}> {getYear(new Date(date))}년 </div>
                                <div style={{margin:5}}>{getMonth(new Date(date))+1}월 </div>

                                <button
                                    onClick={increaseMonth}
                                    disabled={nextMonthButtonDisabled}
                                >
                                    {'>'}
                                </button>

                            </div>

                            <Flex style={{marginTop:-5}}><Space>
                                <div>연도만 앎</div>
                                <Checkbox checked={dateMask.mask===1} onChange={(e) => setttingMask(e.target.checked?1:0)}> </Checkbox>

                                <div style={{width:10}}></div>

                                <div>연월만 앎</div>
                                <Checkbox checked={dateMask.mask===2} onChange={(e) => setttingMask(e.target.checked?2:0)}> </Checkbox>

                            </Space></Flex>
                            {/*<div style={{marginTop:3, marginBottom:5, fontSize:10}}>※ 연도나 연월만 알 경우에도 비슷한 날짜를 선택하세요.</div>*/}

                            </FlexColumnCenter>
                        }



                //popperContainer={ExampleCustomInput}
                // popperContainer={CalendarContainer}
            />
        </div>
        // </CalendarContainer>
    );
};

export default DateMaskPicker;