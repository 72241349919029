import { useLocation, useNavigate } from "react-router-dom";
import { Avatar } from 'primereact/avatar';
import { useEffect, useState, useRef, useCallback } from 'react';
import { BoUserTypeAtom } from '../Atoms';
import { useRecoilValue } from 'recoil';
import classnames from 'classnames';
import { InputText } from 'primereact/inputtext';
import commaNumber from "comma-number";
import {HomeOutlined} from "@ant-design/icons";
import Icon from "antd/es/icon";

// 상단의 흰색 TopBar
export function WhiteTopBar(props) {
    // props.title
    // props.onClick
    const navigate = useNavigate();

    const onClick = (e) => {
        if (props.onClick) props.onClick(e);
        else navigate(-1, { preventScrollReset: false });
    }


    const debtorBack = () => {
        navigate('/');
    }

    return (
        <header>
            <div className="py-3 px-2 bg-white border-bottom-1" style={{ borderColor: "#F5F4F3", color: "#191919" }}>
                {/*{props.debtor ?*/}
                    <div className="absolute">
                        {props.noBackButton?
                            <></>
                            :
                            <i className="pi pi-chevron-left cursor-pointer" onClick={onClick}/>
                        }
                    </div>
                    {/*:*/}
                    {/*<div className="absolute">*/}
                    {/*    <i className="pi pi-chevron-left cursor-pointer" onClick={onClick}/>*/}
                    {/*</div>*/}
                {/*}*/}
                <div className="font-bold">
                    {props.title}
                </div>
            </div>
        </header>
    );
}

// 많이 쓰이는 오렌지 버튼
export function OrangeButton(props) {
    // props.label
    // props.disabled
    // props.onClick
    // props.desc
    const onClick = (e) => {
        if (props.disabled !== true && props.onClick) props.onClick(e);
    }
    return (
        <div onClick={onClick} className={classnames("py-3 border-round-sm border-1 mb-2 text-center font-bold",
            { "orange-back cursor-pointer": props.disabled !== true }, { "bg-white text-500 border-solid border-1 border-400": props.disabled === true })}>
            <div>{props.label}</div>
            <div className="font-light" style={{ fontSize: "0.6rem" }}>{props.desc}</div>
        </div>
    );
}

export function WhiteButton(props) {
    // props.label
    return (
        <div {...props} className="bg-white px-2 py-3 border-round-sm border-1 cursor-pointer mb-2 text-center font-bold">
            {props.label}
        </div>
    );
}

// 회원 표시
export function UserPortrait(props) {
    // props.userInfo: /vws/bo/user/info 의 응답 
    const boUserType = useRecoilValue(BoUserTypeAtom);
    const [userType, setUserType] = useState("");
    const [isAnonymous, setIsAnonymous] = useState(true);

    //TODO: 현재는 socialType 으로 보지만, 이름이 애매함. 

    useEffect(() => {
        /*
        if (!!!boUserType || boUserType === "anonymous") {
            //setUserType("anonymous");
            setIsAnonymous(true);
        } else {
            setIsAnonymous(false);
            //setUserType(boUserType);
        } 
        */
        if (!props.userInfo?.userrole || props.userInfo?.userrole === "ROLE_GUEST") {
            setIsAnonymous(true);
        } else {
            setIsAnonymous(false);
        }
    }, [props.userInfo]);

    return (
        <>
            {
                isAnonymous ?
                    <AnonymousUser userInfo={props.userInfo} /> :
                    <SignedUser userInfo={props.userInfo} />
            }
        </>
    );
}

function AnonymousUser(props) {
    // props.userInfo: /vws/bo/user/info 의 응답 
    return (
        <div className="flex align-items-center m-3 px-2">
            <div style={{ width: "20%" }} className="text-center">
                <Avatar image="/assets/anonymous_icon.png" size="large" shape="circle" />
            </div>
            <div className="text-sm text-left px-2">
                <div>지금 회원가입하시고</div>
                <div>{(window.location?.host.startsWith('m'))?'채권도시M':(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시C'}의 혜택을 누리세요.</div>
            </div>
        </div>
    );
}

function SignedUser(props) {
    // props.userInfo: /vws/bo/user/info 의 응답 
    return (
        <div className="flex align-items-center m-3 px-2">
            <div style={{ width: "20%" }} className="text-center">
                {props.userInfo?.imageUrl ?
                    <Avatar image={props.userInfo.imageUrl} size="large" shape="circle" />
                    :
                    <Avatar label={(props.userInfo?.name)?props.userInfo?.name.substring(0, 1):'이름'} size="large" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff' }} />
                }
            </div>
            <div className="text-left px-2">
                {/*<div>{props.userInfo?.name}님, 환영합니다.</div>*/}
                <div>{props.userInfo?.nickname}님, 환영합니다.</div>
                <div className="text-sm text-500">{props.userInfo?.email}</div>
            </div>
        </div>
    );
}

// react-horizontal-scrolling-menu 의 drag 구현을 위해 
// Ref: https://codesandbox.io/s/react-horizontal-scrolling-menu-v2-drag-by-mouse-o3u2t?file=/src/useDrag.ts
export function useDrag() {
    const [clicked, setClicked] = useState(false);
    const [dragging, setDragging] = useState(false);
    const position = useRef(0);

    const dragStart = useCallback(ev => {
        position.current = ev.clientX;
        setClicked(true);
    }, []);

    const dragStop = useCallback(() => {
        window.requestAnimationFrame(() => {
            setDragging(false);
            setClicked(false);
        })
    }, []
    );

    const dragMove = (ev, cb) => {
        const newDiff = position.current - ev.clientX;
        const movedEnough = Math.abs(newDiff) > 5;
        if (clicked && movedEnough) {
            setDragging(true);
        }
        if (dragging && movedEnough) {
            position.current = ev.clientX;
            cb(newDiff);
        }
    };

    return {
        dragStart,
        dragStop,
        dragMove,
        dragging,
        position,
        setDragging
    };
}

// @Deprecated
export function CircleXButton(props) {
    return (
        <div style={{ borderRadius: "50%", width: "3rem", height: "3rem" }} className="surface-400">
            <i className="pi pi-times" />
        </div>
    );
}

export function GrayLine(props) {
    return (
        <div style={{ height: "0.5rem" }} className="surface-100"></div>
    );
}

export function InputMoney(props) {
    // props.onChange
    // props.value
    const [value, setValue] = useState(props.value || "₩");
    const onChange = (v) => {
        //console.log(`v=${v}`);
        let hv = v;
        if (hv === undefined || hv === null || hv === "") {
            hv = "";
        }
        hv = hv.toString();
        if (hv.startsWith("₩")) {
            hv = hv.slice(1);
        }
        let output = v.slice(1).replace(/\D/g, '');

        hv = commaNumber(output);

        if (!hv.startsWith("₩")) {
            hv = "₩" + hv;
        }

        setValue(hv);
        if (props.onChange) props.onChange(output);
        //console.log(`money = ${hv}, ${output}`);
    }
    useEffect(() => {
        if (props.value) {
            let rv = props.value.toString();
            rv = commaNumber(rv);
            setValue("₩" + rv);
        }
    }, [props.value]);
    return (
        <InputText {...props} value={value} onChange={(e) => onChange(e.target.value)} inputMode="numeric"/>
    );
}

export function InputNumberOnly(props) {
    // props.onChange
    // props.value
    const [value, setValue] = useState(props.value);
    const onChange = (v) => {
        //console.log(`v=${v}`);
        let hv = v;
        if (hv === undefined || hv === null || hv === "") {
            hv = "";
        }
        hv = hv.toString();
        let output = hv.replace(/\D/g, '');

        setValue(output);
        if (props.onChange) props.onChange(output);
        //console.log(`money = ${hv}, ${output}`);
    }
    useEffect(() => {
        if (props.value) {
        }
    }, [props.value]);
    return (
        <InputText {...props} value={value} onChange={(e) => onChange(e.target.value)} inputMode="numeric"/>
    );
}


export function ScrollToTop(props) {
    const { pathname } = useLocation();

    useEffect(() => {
        // "document.documentElement.scrollTo" is the magic for React Router Dom v6
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            behavior: "instant", // Optional if you want to skip the scrolling animation
        });
    }, [pathname]);

    return null;
}

export function AddressFinder(props) {
    return (
        <div>
            
        </div>
    )
}