import { useEffect, useState, useRef } from "react";
import { WhiteTopBar } from "../common/Common";
import { BACKEND_URL } from "../Atoms";
import { useRecoilValue } from "recoil";
import { BoNetUtil } from "../common/Util";
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import classnames from 'classnames';

export function FaqPage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title="자주 묻는 질문"/>
            <br/>
            <FaqContent/>
        </div>
    )
}

function FaqContent(props) {

    const [categories, setCategories] = useState([]);
    const [panels, setPanels] = useState([]);
    const getCategoryName = (categoryId) => {
        const found = categories.find((elem) => elem._id === categoryId);
        return found?.name;        
    };
    useEffect(() => {
        // https://vatdahm.com/api/v1/faqCategories?limit=200&page=1   
        BoNetUtil.get(`${BACKEND_URL}/api/v1/faqCategories`, {limit: 200, page: 1},
            (resp) => {
                if (resp.data.data) {
                    setCategories(resp.data.data);
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);

    useEffect(() => {
        const p = [];
        p.push(<TabPanel header="전체보기" key="all" headerTemplate={faqTabHeaderTemplate}><FaqAccodion getName={getCategoryName} /></TabPanel>);
        categories.map((cat) => {
            p.push(<TabPanel header={cat.name} key={cat._id} headerTemplate={faqTabHeaderTemplate}><FaqAccodion category={cat} getName={getCategoryName}/></TabPanel>);
        });
        //console.dir(p);
        setPanels(p);
    }, [categories]);

    const faqTabHeaderTemplate = (options) => {
        console.dir(options);
        // options.selected
        // options.index
        const bc = classnames("p-2 mx-1 border-round-3xl cursor-pointer text-sm", {
            'orange-back font-bold': options.selected,
            'surface-300 text-600': !options.selected
        });
        return (
            <div className={bc} onClick={options.onClick}>
                {options.titleElement}
            </div>
        );
    }

    return (
        <TabView className="faq-tab-view">
            {panels}
        </TabView>
    );
}

function FaqAccodion(props) {
    // props.category
    // props.getName: categoryId 로부터 category.name 을 가져오는 함수, all에서 필요함 

    const [items, setItems] = useState([]);
    const [widgets, setWidgets] = useState([]);
    useEffect(() => {
        if (!props.category) {
            BoNetUtil.get(`${BACKEND_URL}/api/v1/faqs`, {limit: 200, page: 1},
                (resp) => {
                    if (resp.data.data) {
                        setItems(resp.data.data);
                    }
                },
                (err) => console.error(err)
            );
        } else {
            BoNetUtil.get(`${BACKEND_URL}/api/v1/faqs`, {limit: 200, page: 1, faqCategoryObjectId: props.category._id},
                (resp) => {
                    if (resp.data.data) {
                        setItems(resp.data.data);
                    }
                },
                (err) => console.error(err)
            );
        }
    }, [props.category])

    const faqHeaderTemplate = (data) => {
        //console.dir(data);
        return (
            <div className="flex align-items-center">
                <div className="text-xs font-normal text-500 text-left" style={{width: "80px"}}>
                    {props.getName(data.data.faqCategoryObjectId)}
                </div>
                <div className="flex-grow-1 text-sm">{data.data.title}</div>
            </div>
        );
    }

    useEffect(() => {
        const w = [];
        items.map((it) => {
            w.push(
                <AccordionTab headerTemplate={faqHeaderTemplate} data={it} >
                    <div dangerouslySetInnerHTML={{__html: it.contents}}></div>
                </AccordionTab>
            );
        });
        setWidgets(w);
    }, [items]);

    return (
        <Accordion className="faq-accordion" collapseIcon={<i className="pi pi-angle-down text-600"/>} expandIcon={<i className="pi pi-angle-right text-600"/>}>
            {widgets}
        </Accordion >
    );
}