import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { BoNetUtil } from "../common/Util";
import { useRecoilValue } from "recoil";
import { BACKEND_URL } from "../Atoms";
import { OrangeButton } from "../common/Common";

export function PaymentCallback(props) {
    const { oid } = useParams();

    const [result, setResult] = useState(null);
    const [message, setMessage] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        console.log(`PAYMENT CALLBACK!!!, OID=${oid}`);
        // vws/bo/payment/result?orderNo=20231117140707926PoVbdt
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/payment/result`, {orderNo: oid},
            (resp) => {
                setResult(resp.data);
                if (resp.data?.status === "00" || resp.data?.status === "0000") {

                    if (resp.data.payMethod === 'VBANK') {
                        setMessage(`<div>아래 가상계좌로 10일내에 입금해 주세요 <br/><br/> 은행명: ${resp.data.bankName} <br/> 계좌번호: ${resp.data.accountNum} <br/> 예금주: ${resp.data.accountOwner}</div>`);
                    }
                    else {
                        setMessage("결제에 성공하였습니다.");
                    }

                } else {
                    setMessage(`${resp.data?.status}: ${resp.data?.message}`);
                }
            },
            (err) => console.error(err)
        );
    }, [oid]);
    return (
        <div className="px-3">
            <br/><br/><br/><br/><br/><br/>
            {/*<div className="text-lg">{message}</div>*/}
            <div className="text-lg" dangerouslySetInnerHTML={{ __html: message }}></div>
            <br/><br/>
            <OrangeButton label="확인" onClick={() => navigate("/")}/>
        </div>
    );
}