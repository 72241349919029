import React, {useState, useEffect} from "react";
import {
    Button,
    Space,
    Input,
    Select,
    Checkbox,
    message,

    Collapse,
    Image,
    Radio,
    Modal,
    Timeline,
    Upload, List, Spin
} from "antd";
import {AliwangwangOutlined, CaretRightOutlined, InboxOutlined, PlusOutlined} from '@ant-design/icons';
import {
    TitleBox,
    Title2,
    Title3,
    Flex,
    FlexColumn,
    GrayBox,
    StyledCol,
    StyledRow, EmptyCol, EmptyBox, GrayDiv, SmallGrayBox,
    UserTable, PlusButton, QustionAreaTitle2, WhiteButton,
    AutoHeightSelect, Title2Gray, WhiteRedButton,
} from "../../common/DeskComponent";
import useAxios from "../../hooks/useAxios";
import {isMobile} from 'react-device-detect';
import { useParams } from 'react-router-dom';
import PublicFileUploader from "../../components/PublicFileUploader";
import locale from "antd/es/date-picker/locale/ko_KR";
import dayjs from "dayjs";
import {
    addTableMapOneData,
    convertTableMap2Ds, convertTableMap2DsFromHistory,
    existMyColIndex,
    findVByCmFieldBase,
    getBaseKey,
    getCmField, getCmFieldDepth,
    getCmNotationFieldKVArr,
    getCmNotationFieldVArr,
    getMyColIndex,
    getNotationDepth, getNotationVDepth,
    getRecordVarCmFieldJ,
    getSubAskColIndex,
    getTableMapFromCm,
    getTableMapVarKeyFromRecord, notationMinusDepth,
    parseCmFieldI,
    parseCmFieldJ,
    parseNotation,
    removePipeline,
    tableMap2kv,
    findMaxDepth,
    findPvIndex
} from "../../common/CmUtil";
import useModal from "../../hooks/useModal";
import {BACKEND_URL} from "../../Atoms";
import axios, { AxiosResponse } from 'axios';
import {addComma, checkNumberAddComma, isDevEnv, isFileListArrayDifferent} from "../../common/Util";
import DateMaskPicker from "./DateMaskPicker";
import EvidenceFolder from "./EvidenceFolder";

//증거보관함 관련.
import "./draggable.css";
import EvidenceUploader from "../../components/EvidenceUploader";
///datePicker 6줄//////////
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from  "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ko from "date-fns/locale/ko";
import './DatepickrStyles.css'
registerLocale('ko', ko)



const { Dragger } = Upload;
const draggerProps = {
    name: 'file',
    multiple: true,
    listType: "picture",

    //action: 'https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188',

    // onChange(info) {
    //     const { status } = info.file;
    //     if (status !== 'uploading') {
    //         console.log(info.file, info.fileList);  //drop한 파일들 여기에 출력됨.
    //     }
    //     if (status === 'done') {
    //         message.success(`${info.file.name} 증거보관함에 저장되었습니다..`);
    //     } else if (status === 'error') {
    //         message.error(`${info.file.name} 저장 오류가 발생하였습니다. 사이즈가 너무크면 줄여주세요.`);
    //     }
    // },
    onDrop(e) {
        console.log('Dropped files', e.dataTransfer.files);
    },
};


/** 서브질문 관련 : notationV 존재시 서브질문임.
 *    openSubAskModal 은 parent(StoryTelling)에 존재함.
 *    StoryTelling -> openSubAskModal 하면서
 *            fromHistory=true 로 하면 표가 한줄만 출력되도록 개발 중.
 *
 *
 *  재요청 관련1: selectedPv, openSubAskModal=빈값.  setParentHistoryItems reRequestInputModal 혹은 reRequestObjArr 필요.  checkSelectedPvIndex: 빨간색칠 용함수 ipoViewerReview2ndProc.jsx에서 복사해서 추가.
 *          2: 2404-질문단위 재요청 은 저장버튼을 StoryReReqeust.js에 추가. reQuestionMap=true => 질문추가설명은 제외.
 * */
export const OneQuestion = ({reQuestionMap, hideDiv, evidenceFolderFileList, VIEW_MODE, selectedPv, reRequestInputModal=()=>{}, setParentHistoryItems, idx, askQuestion={표질문:[], 답변형식:undefined}, cm, cmFieldKv, setCmFieldKv, saveAll, openSubAskModal=()=>{}, notationV, fromHistory, setHistoryAddIdx, historyAddIdx}) => {

    const {axiosGet, axiosPost} = useAxios();

    //BoAdmin.askQuestionAdd 유효성체크에도 존재.
    const NORMAL_Q = (askQuestion?.답변형식 === '표'|| askQuestion?.답변형식 === '히스토리')?false:true;

    //객관식 Checkbox
    const [normalCheckboxArr, setNormalCheckboxArr] = useState([]);

    //날짜들 [date] : 240123-잠깐 미사용하고 있음.
    const [normalDateArr, setNormalDateArr] = useState([]);

    const [모름, set모름] = useState(false); //2401 우선 normalQ에 대해서 구현: setNormalQ모롬


    console.log("########evi One Question:, notationV", askQuestion, notationV, cm);

    /** 서브질문 관련 : notationV 존재시 서브질문임. */
     //1. 현재는 테이블 용도, 히스토리에도 적용검토필요. 2.기존코드 재활용이되는지, 서브질문 따로 개발해야하는지 판단 필요.
     // notationV 존재시 서브질문임. : _ij형태의 서브질문임. 여러 case가 있으려나? 일단 data 처리용.  ///////////////////////
    const [notationVDateArr, setNotationVDateArr] = useState([]);
    const [dynamicQ, setDynamicQ] = useState();

    /** 히스토리질문 관련 */
    const [historyItems, setHistoryItems] = useState([]);

    //히스토리에는 notationV가 1_1 2단계로 들어오지만, -1 depth를 주로 사용해서 필요.ㅣ
    const notationVminus1 = (fromHistory && notationV && notationV.indexOf('_') > 0)? notationV.substring(0, notationV.indexOf('_')):notationV;


    /** 표질문 관련 ///////////////////////////////////////////////////////// */
    const [emptyRow, setEmptyRow] = useState({}); //todo tableCmFieldMap으로 전환.

    const [tableMap, setTableMap] = useState({});
    const [dataSource, setDataSource] = useState([emptyRow]);

    let save채권들선택자동func = undefined;

    if (!askQuestion) {
        console.log("NO askQ:");
        //안됨. return <></>;
    }
    const NOTATION_EXISTS = askQuestion.표질문.find( (colObj) => !!colObj.notationNo )? true:false;
    const READONLY_EXISTS = (NOTATION_EXISTS && askQuestion.표질문.find( (colObj) => colObj.답변유형==='readOnly' ))? true:false;
    console.log("NOTATION_EXISTS, READONLY_EXISTS:", NOTATION_EXISTS, READONLY_EXISTS);
    const READONLY_DEPTH = READONLY_EXISTS?  getNotationDepth(askQuestion.표질문.find((colObj) => colObj.답변유형==='readOnly' )?.cmField):0;   //readOnly없으면 0.
    console.log('READONLY_DEPTH', READONLY_DEPTH);

    //{notations, firsts} : notations=i,ij,ijk,ijkp   firsts=원장.채권_i, 원장.날짜_ij 등 +버튼용.
    //const NOTATINON_INFO = (NOTATION_EXISTS)? parseNotation(askQuestion):{};



    //tableMap구조 baseKey:[varKey:v1, varKey2:v2  ] 에서..   v가져오기./////////////////////////////////////////////////////////////////////
    const getTableMapV = (tableMapBaseKey, tableMapVarKey) => {

        let tableMapVArr = tableMap[tableMapBaseKey];
        // console.log('** getTableMapV:', tableMapVArr);

        console.log('** getTableMap:', tableMap);


        if (Array.isArray(tableMapVArr)) {
            for (let kv of tableMapVArr) {
                // if (!kv) { //todo check: 240123 미입력 저장시, undefined 들어옴.
                //     continue;
                // }

                if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                    return kv[tableMapVarKey];
                }
            }
        }
        console.log('$$getTableMapV NOT FOUND (arr, vKey):', tableMapVArr, tableMapVarKey)
        return undefined;
    }


    //2402 재요청용 추가: return index.//////////////////////////////////
    const checkSelectedPvIndex = (selectedPv) => {
        if (!selectedPv) return -1;

        let tableRowId;

        //tableMap과 selectedPv 비교.
        if ( getNotationVDepth(selectedPv) === 1 ) { //return |1 -> 1리턴. (여기잘 안탐 보통 |1_1 들어옴)
            tableRowId = Number(selectedPv.substring(1));

        }else { //return i_j의 index? tableMap과 비교해야함.
            let maxDepth = findMaxDepth(tableMap);
            //maxDepth=1일경우 위와동일.

            if (maxDepth === 1) { //return |1_2 -> 1리턴. // 보통 |1_1 들어와서 간단한테이블도 여기를 탐. )
                const notationI = selectedPv.substring(1, selectedPv.indexOf('_'));
                tableRowId =  Number(notationI);
            }else {
                //maxDepth=2일경우, find _ij필드
                tableRowId = findPvIndex(tableMap, selectedPv);
            }
        }

        //parent함수(IpoViewer) 호출.
        //setCrIdxQRowListTableRow(crIdx, askQuestion._id, tableRowId);
        return tableRowId;
    }

    //컬럼Select 용 필터 함수.
    const check기본숨김open = (myColIdx, colObj, selectedPv) => {

        //항상 1개짜리Row 만 처리중. : selectedPv - 재요청용도
        if (!notationV && !selectedPv) return true;
        if (selectedPv) { //2403 추가. history추가입력으로 들어오는 경우임.
            notationV = selectedPv.substring( selectedPv.lastIndexOf('|') + 1);
        }

        if (colObj.기본숨김) { //value매칭 되면 오픈 목표

            //return true;
            console.log('컬럼Select 기본숨김', myColIdx, colObj);

            const 컬럼SelectCols = askQuestion.표질문.filter((colObj) => colObj.답변유형 === '컬럼Select'); //todo 여러개시에는 filter및 아래 loop필요.
            //my가 아닌 컬럼SelectCol의 v를 봐야함.

            for (const 컬럼SelectCol of 컬럼SelectCols) {
                //0. 컬럼SelectCol: 기본변수 복사 영역
                let 컬럼SelectColValue;
                {
                    let tableMapBaseKey = 컬럼SelectCol.cmField;

                    //240219 이상함. notationV를 쓰도록 수정해 봄.
                    // let tableMapVarKey = (tableMapBaseKey) ? getTableMapVarKeyFromRecord(tableMapBaseKey, dataSource[0], 0) : '';
                    let tableMapVarKey = (tableMapBaseKey) ? tableMapBaseKey + "|" + notationV : ''; //2단계 서브인가?
                    if (!tableMapVarKey) {
                        //이전 코드로 한번 더 시도해봄 //todo test or delete
                        tableMapVarKey = (tableMapBaseKey) ? getTableMapVarKeyFromRecord(tableMapBaseKey, dataSource[0], 0) : '';
                    }

                    //컬럼SelectCol의 Value
                    컬럼SelectColValue = (tableMapBaseKey) ? getTableMapV(tableMapBaseKey, tableMapVarKey) : undefined;

                    if (!컬럼SelectColValue) return false; //기본숨김 상태.
                }

                //1. 비교 영역: askQuestion에서 컬럼Select필드를 찾고 -> colSelectColumns를 찾아서

                if (컬럼SelectCol) {

                    const cmField = 컬럼SelectCol.cmField;
                    const tableMapVArr = tableMap[cmField]; // [ {채권_i|0 : v1}, ... ]
                    if (Array.isArray(tableMapVArr)) {
                        for (const kv of tableMapVArr) {
                            if (kv && Object.keys(kv).length > 0) {
                                let k = Object.keys(kv)[0];
                                let v = kv[k];  //'예' 혹은 '아니오'
                                if (v === 컬럼SelectColValue) {
                                    //colSelectColumns 중에 매칭 되는 arr찾아야함.
                                    //for (const arr of 컬럼SelectCol.colSelectColumns) {
                                    for (let i = 0; i < 컬럼SelectCol.selectBoxQs.length; i++) {
                                        if (컬럼SelectCol.selectBoxQs[i] !== v) continue;
                                        const arr = 컬럼SelectCol.colSelectColumns[i];

                                        for (const colIdx of arr) {
                                            if (colIdx === myColIdx) {
                                                return true; //SHOW.
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }


                }
            }//for
        }



        if (colObj.기본숨김) return false; //value매칭 실패면 숨김.
        return true;
    }

    const columns = (askQuestion?.답변형식==='표')?
        //// notation없는 심플표.
        (!NOTATION_EXISTS)? askQuestion.표질문.flatMap((colObj, idx) => {  //증거때문에 flatMap사용.
                const ONE_COL = {  //colObj=StColumnQuestionObject
                    title: colObj.title,
                    key: colObj.cmField, dataIndex: colObj.cmField,
                    render: (text, record, index) => {

                        //TODO pre, readOnly, 서브질문Select이후 모두 boAdmin의 OneQeustion으로 복사 필요.
                        //심플표 는 아래 노테이션표에서 복사했음 - 2402
                        let selectBoxMaxWidth = 140;
                        const SELECTBOX_MAX_WIDTH = 400;
                        if( colObj.selectBoxQs ) {
                            let maxItem = colObj.selectBoxQs.reduce((max, current) => (current.length > max.length ? current : max), "");
                            selectBoxMaxWidth = Math.max(selectBoxMaxWidth, maxItem.length * 11); //maxItem.length * 11) ;// (maxItem.length * 13 > selectBoxMaxWidth)? maxItem.length * 13: selectBoxMaxWidth; //font 14로 가정.

                            //심플표에서는 최대한 길게 해보는 중 if (selectBoxMaxWidth > SELECTBOX_MAX_WIDTH) selectBoxMaxWidth=SELECTBOX_MAX_WIDTH;
                            //console.log('fromHistory maxItem2:', maxItem , selectBoxMaxWidth);
                        }

                        switch (colObj.답변유형) {
                            case 'readOnly': return <div> {(cmFieldKv[colObj.cmField]?.date)? cmFieldKv[colObj.cmField].date : cmFieldKv[colObj.cmField]} </div>
                            case 'number':
                                return <Input readOnly={VIEW_MODE} name={colObj.cmField} type={'text'} value={addComma(cmFieldKv[colObj.cmField])} placeholder={colObj.항목입력지침}
                                              onChange={handleNumberInputChange} style={{width: 140}}/>
                            case 'date' :
                                //antd return <DatePicker name={colObj.cmField}
                                //                    locale={locale}
                                //                    value={cmFieldKv[colObj.cmField] ? dayjs(cmFieldKv[colObj.cmField]) : ''}
                                //                    onChange={(date, dateString) => handleDatePickerChange(date, dateString, colObj.cmField)}/>
                                return <DatePicker name={colObj.cmField} disabled={VIEW_MODE}
                                                   locale={'ko'}    className={'simple-datepicker'}
                                                   selected={cmFieldKv[colObj.cmField] ? new Date(cmFieldKv[colObj.cmField]) : ''}
                                                   dateFormat="yyyy-MM-dd" placeholderText={'연도는 숫자입력'}
                                                   onChange={(dates) => handleReactDatePickerChange(dates, dates?.toString(), colObj.cmField)}/>

                            case 'dateMask':
                                return <DateMaskPicker disabled={VIEW_MODE}
                                                       selected = {cmFieldKv[colObj.cmField] ?  cmFieldKv[colObj.cmField] : ''}
                                                       onChange={(dateMask ) => handleDatePickerChange(null, dateMask, colObj.cmField)}
                                />
                            case 'text':
                                return <Input readOnly={VIEW_MODE} name={colObj.cmField} type={'text'} value={cmFieldKv[colObj.cmField]}  placeholder={colObj.항목입력지침}
                                              onChange={handleInputChange} style={{width: 140}}/>

                            case 'selectBox':
                                return <Select disabled={VIEW_MODE}
                                    placeholder={(colObj.항목입력지침)? colObj.항목입력지침:'선택해 주세요'}  style={{width: 140}}
                                    value={cmFieldKv[colObj.cmField]}
                                    onChange = { (value) => setCmFieldKv(prev => ({...prev, [colObj.cmField] : value})) }
                                    options={ //[{ value: 'jack', label: 'Jack' },]
                                        colObj.selectBoxQs.map((item) => ({value: item}))
                                    }
                                />
                            case '서브질문Select': //(5%)모두 분할변제인경우 질문때문에 추가. (심플표-> 서브질문존재)
                                return <Select disabled={VIEW_MODE}
                                               placeholder={(colObj.항목입력지침)? colObj.항목입력지침:'선택해 주세요'}  style={{width: selectBoxMaxWidth}}
                                                // value={tableMapV}
                                               value={removePipeline(cmFieldKv[colObj.cmField])} //'예|4' 로 저장하고 출력은 '예'만 함.
                                               onChange = { (value) => handleTableMap서브질문SelectChange(colObj.cmField, null, value, true ) }
                                               options={ //[{ value: 'jack', label: 'Jack' },]
                                                   colObj.selectBoxQs.map((item) => ({value: item}))
                                               }
                                />
                            case '서브질문node':
                            case '서브질문node매칭':  //2402-현재 이전질문만 봄.
                                const myColIndex = getMyColIndex(askQuestion.표질문, colObj.title); //서브질문node는 cmField가 없을수도 있음.
                                // 서브질문node는 cmField가 없을수 있으므로, 한칸 col의 tableMapVarKey 찾기.
                                let proceedingColObj = askQuestion.표질문[myColIndex-1];
                                let prevColValue = cmFieldKv[proceedingColObj.cmField];

                                const isExistColIndex = prevColValue?.indexOf('|') > 0? prevColValue.substring(prevColValue?.indexOf('|')+1) : undefined;

                                const myNotationV = (notationV)?notationV :'X'; //하드코딩 추가 심플표->서브질문으로 가는 case를 표시

                                const subNodeFlowId = (isExistColIndex && isExistColIndex.indexOf('_') > 0)? isExistColIndex.substring(isExistColIndex.indexOf('_')+1) : undefined;
                                console.log('서브질문node매칭 ', subNodeFlowId);

                                /** 서브질문 3-1 : 서브질문에서 2단계 팝업호출시 SUB_ASK_DEPTH=2로 전달필요. fromHistory는? 1줄표시용도로 쓰이는 중.  */
                                return (!isExistColIndex)?  <> - </> :
                                    (subNodeFlowId)? <Button type={VIEW_MODE?'normal':'default'} onClick={() => openSubAskModal(Number(subNodeFlowId), colObj.title, myNotationV, fromHistory, notationV?2:1) }>  답변하기 </Button>
                                        : //밑에는 일반 서브질문node
                                        <Button type={VIEW_MODE?'normal':'default'} onClick={() => openSubAskModal(colObj.askFlowId, colObj.title, myNotationV, fromHistory, notationV?2:1) }>  답변하기 </Button>;



                            default:
                                return <>추가예정1</>

                        } //switch
                    }//render
                } //oneCol

                //마지막컬럼이면 증거 추가. (아래에 반복됨, 아래가 더 중요)
                if (idx === askQuestion.표질문.length - 1 &&  askQuestion.표증거원장.cmField) {
                    const 증거컬럼 = {  //colObj=StColumnQuestionObject
                        title: '증거첨부',
                        key: colObj.cmField, dataIndex: colObj.cmField,
                        render: (text, record, index) => <Flex>
                            <Space>
                                {/*<PublicFileUploader  imageUrl={cmFieldKv[askQuestion.표증거원장?.cmField]} downloadOnly={VIEW_MODE}*/}
                                {/*                     onChange={(returnUrl) => setCmFieldKv(prev => ({...prev, [askQuestion.표증거원장?.cmField]:returnUrl}) ) }*/}
                                {/*                     buttonText={''}  name={askQuestion.표증거원장.cmField} />*/}
                                <EvidenceUploader cmId={cm.cmId} fileList={cmFieldKv[askQuestion.표증거원장?.cmField]} downloadOnly={VIEW_MODE} evidenceFileList={evidenceFolderFileList}
                                                  onChange={(returnFileList) => setCmFieldKv(prev => ({...prev, [askQuestion.표증거원장?.cmField]:returnFileList}) ) }
                                                  />
                            </Space>
                        </Flex>
                    }
                    return [ONE_COL, 증거컬럼]
                }

                return [ONE_COL];

            }) //notation없는 심플표 END.
            :
            //// notation 있는 반복 표.//////////////////////////////////////////////////////
            askQuestion.표질문.filter((colObj,idx) => check기본숨김open(idx, colObj, selectedPv)).flatMap((colObj, idx) => {


                const ONE_COL = {  //colObj=StColumnQuestionObject
                  title: colObj.title,
                  key: colObj.cmField, dataIndex: colObj.cmField, //채권_i, 대여금_ij
                  render:(text, record, index) => {

                      /**  1-> 3 으로 바로.. tableMap->cmFieldKv로 바로 저장하는 방법으로 전환.  */
                      // let DEPTH = getNotationDepth(colObj.cmField);
                      // let i = -1;
                      // let j = -1; //todo K,P (3,4)
                      //
                      // let recordVarCmField;  //대여금_ij:1_2  (record에서 있으면 찾기)
                      // //ds에서 |0 찾을 수 있나? 아니면 1번인 tableMap에 넣는게 좋음.  i,j를 알 수 있나? i=idx일 경우만 쉬움.
                      // if (DEPTH == 1) {
                      //     i = index;
                      // }if (DEPTH == 2) {
                      //     recordVarCmField = getRecordVarCmFieldJ(record, i);
                      //     i = parseCmFieldI(recordVarCmField);
                      //     j = parseCmFieldJ(recordVarCmField); // 대여금_ij:1_2 를 넣어야 함.
                      // }
                      // let tableMapVarKey = colObj.cmField + '|' + i;  /////   중요  ///////////
                      // if ( j >= 0) {//todo K, P
                      //     tableMapVarKey += ('_' + j);
                      // }

                      let test = tableMap;
                      //채권_i (단 서브질문node 일때는 빈값)
                      let tableMapBaseKey = colObj.cmField;
                      //채권_i|0 (위 코멘트 코드를 표증거에서도 사용하기 위해 함수로 전환) : todo 240125- i_j레벨에서는 에러나는 경우 존재? 밑에 fromHistory에서 커버중.
                      let tableMapVarKey = (tableMapBaseKey)? getTableMapVarKeyFromRecord(colObj.cmField, record, index) : '';
                      //출력값.
                      let tableMapV = (tableMapBaseKey)? getTableMapV(tableMapBaseKey, tableMapVarKey) : undefined;


                      //i->i 에인질문도 i, 서브질문도 i레벨인 경우. (ij->ij도 존재하려나?) tableMapVarKey다시계산.
                      if (notationV && notationV != 'X' && notationVminus1 === notationV) {
                          //todo ij일때는 1_1같은게 들어가면 에러남. 추가개발 필요.
                          tableMapVarKey = (tableMapBaseKey)? getTableMapVarKeyFromRecord(colObj.cmField, record, Number(notationV)) : '';
                          tableMapV = (tableMapBaseKey)? getTableMapV(tableMapBaseKey, tableMapVarKey) : undefined;

                          if (index === 0) {
                              console.log('i->i 체크3-1:', notationV, notationVminus1, index, record);
                              console.log('i->i 체크3-2:', tableMapBaseKey, tableMapVarKey, tableMapV);
                          }
                      }

                      // if (colObj.cmField.includes('대여일')) {
                      /** 서브질문 2 - 히스토리 서브질문 처리 : depth-1 변수를 세팅해 줌. */
                      let selectBoxMaxWidth = 140;
                      const SELECTBOX_MAX_WIDTH = 350;

                      if (fromHistory && notationVminus1 && tableMapBaseKey) { //tableMapBaseKey는 서브질문node시 빈값이라서 체크.

                          //field가 -1 레벨일때.
                          if (getNotationDepth(tableMapBaseKey) === getNotationVDepth(notationVminus1)) {
                              let test = tableMap;
                              tableMapVarKey = tableMapBaseKey + '|' + notationVminus1;
                              tableMapV = getTableMapV(tableMapBaseKey, tableMapVarKey);

                              //feild가 notationV와 동일 depth일때.
                          } else if (getNotationDepth(tableMapBaseKey) === getNotationVDepth(notationV)) {
                              tableMapVarKey = tableMapBaseKey + '|' + notationV;
                              tableMapV = getTableMapV(tableMapBaseKey, tableMapVarKey);
                          } else {
                              console.log("$$$$$$$$$$$$$$$$$$ TO DO 추가구현 혹은 버그 체크");
                          }

                          console.log('*fromHistory colObj.cmField:', colObj.cmField); //채권_i, 대여금_ij
                          console.log('*fromHistory colObj.record:', record); //채권_i, 대여금_ij
                          console.log('*fromHistory tableMap KEY(baseK,varK) :', tableMapBaseKey, tableMapVarKey); //채권_i, 대여금_ij
                          console.log('*fromHistory tableMapV  :', tableMapV); //채권_i, 대여금_ij
                      }

                      if( colObj.selectBoxQs ) {
                          let maxItem = colObj.selectBoxQs.reduce((max, current) => (current.length > max.length ? current : max), "");
                          selectBoxMaxWidth = Math.max(selectBoxMaxWidth, maxItem.length * 13); //maxItem.length * 11) ;// (maxItem.length * 13 > selectBoxMaxWidth)? maxItem.length * 13: selectBoxMaxWidth; //font 14로 가정.

                          if (selectBoxMaxWidth > SELECTBOX_MAX_WIDTH) selectBoxMaxWidth=SELECTBOX_MAX_WIDTH;
                          console.log('selectBoxMaxWidth maxItem2:', maxItem , selectBoxMaxWidth);
                      }


                      //let DEFAULT_WIDTH = (selectBoxMaxWidth===SELECTBOX_MAX_WIDTH)? 100: 140;
                      //////////////// 주 코딩 부분  ////////////////

                      switch(colObj.답변유형) {
                          case '컬럼Select':
                              return <AutoHeightSelect readOnly={VIEW_MODE}
                                  placeholder={(colObj.항목입력지침)? colObj.항목입력지침:'선택해 주세요'}  style={{width: selectBoxMaxWidth, height:selectBoxMaxWidth===SELECTBOX_MAX_WIDTH?50:50 }}
                                  value={tableMapV}
                                  onChange = { (value) => handleTableMapTextValueChange(tableMapVarKey, value) }
                                  options={ //[{ value: 'jack', label: 'Jack' },]
                                      colObj.selectBoxQs.map((item) => ({value: item}))
                                  }
                              />

                          case '자동출력':
                              return <FlexColumn >
                                  {/*{findVByCmFieldBase(record, colObj.cmField)}   240213 for BoAdmin ERR FIx: Front에도 오류? */}
                                  {tableMapV?tableMapV:findVByCmFieldBase(record, colObj.cmField)}
                              </FlexColumn>
                          case 'number':

                              return <Input readOnly={VIEW_MODE} name={tableMapVarKey} type={'text'} value={addComma(tableMapV)} placeholder={colObj.항목입력지침}
                                            onChange={(e)=>handleTableMapInputChange(e,true)} style={{width: 140}}/>
                          case 'date' :
                              //antd return <DatePicker name={tableMapVarKey} disabled={VIEW_MODE}
                              //                    locale={locale}   // value={creditList[index][item.title]? moment(creditList[index][item.title]):''} //defaultValue={moment()}
                              //                    value={tableMapV ? dayjs(tableMapV) : ''}
                              //                    onChange={(date, dateString) => handleTableMapDatePickerChange(date, dateString, tableMapVarKey)}/>
                              return <DatePicker name={tableMapVarKey} disabled={VIEW_MODE} placeholderText={'연도는 숫자입력'}
                                                 locale={'ko'}  className={'simple-datepicker'}
                                                 selected={tableMapV ? new Date(tableMapV) : ''}
                                                 dateFormat="yyyy-MM-dd"
                                                 onChange={(dates) => handleTableMapReactDatePickerChange(dates, dates?.toString(), tableMapVarKey)}/>

                          case 'dateMask':
                              return <DateMaskPicker name={tableMapVarKey} disabled={VIEW_MODE}
                                                 selected = {tableMapV ? tableMapV : ''}
                                                 onChange={(dateMask ) => handleTableMapDatePickerChange(null, dateMask, tableMapVarKey)}
                                  />
                          case 'float':
                              return <Input style={{width: 140}} readOnly={VIEW_MODE} name={tableMapVarKey} type={'number'} value={tableMapV?Number(tableMapV)/100:undefined} placeholder={colObj.항목입력지침}
                                            //onChange={handleTableMapInputChange}
                                            onChange={(e) => handleTableMapInputChange({ ...e, target: { name:e.target.name, value:Number(e.target.value)*100 } })}
                                            />

                          case 'text':
                              return <Input type={'text'}  name={tableMapVarKey} disabled={VIEW_MODE}
                                        value={tableMapV} onChange={handleTableMapInputChange} style={{width:140}}
                                        placeholder={colObj.항목입력지침}/>

                          case 'selectBox':
                              return <AutoHeightSelect disabled={VIEW_MODE} max={selectBoxMaxWidth === SELECTBOX_MAX_WIDTH}
                                  placeholder={(colObj.항목입력지침)? colObj.항목입력지침:'선택해 주세요'}  style={{width: selectBoxMaxWidth}}
                                  value={tableMapV}
                                  onChange = { (value) => handleTableMapTextValueChange(tableMapVarKey, value) }
                                  options={ //[{ value: 'jack', label: 'Jack' },]
                                      colObj.selectBoxQs.map((item) => ({value: item}))
                                  }
                              />
                          case '서브질문Select':
                              return <AutoHeightSelect disabled={VIEW_MODE} max={selectBoxMaxWidth === SELECTBOX_MAX_WIDTH}
                                             placeholder={(colObj.항목입력지침)? colObj.항목입력지침:'선택해 주세요'}  style={{width: selectBoxMaxWidth}}
                                             value={removePipeline(tableMapV)} //'예|4' 로 저장하고 출력은 '예'만 함.
                                             onChange = { (value) => handleTableMap서브질문SelectChange(tableMapBaseKey, tableMapVarKey, value) }
                                             options={
                                                 colObj.selectBoxQs.map((item) => ({value: item}))
                                             }
                                       />

                          case '채권들 선택': //todo boAdmin에도 복사.

                              //2403 test BUT 미사용예정.
                              if (colObj.cmItemBoxQs.length == 1 && !isNaN(notationV)) {
                                  console.log('채권들 선택: notationV가 숫자이면 자동선택 ', notationV);
                                  save채권들선택자동func = () => handleTableMap채권들선택Change(tableMapVarKey, true, Number(notationV) ); //set
                                  //return  <>{tableMapVarKey},{notationV}</> //유예대상_a|0,0  or 최고대상_a|0, 0
                                  return  <> { !isNaN(tableMapV)?'채권'+(Number(tableMapV)+1) : '채권'+(Number(notationV)+1)}</>
                              }

                              return <table style={{borderCollapse: 'collapse', border: '1px solid gray'}}>
                                  <tr style={{backgroundColor:'lightgray'}}>
                                      {colObj.cmItemBoxQs.map( (item) =>
                                          <th style={{padding:8, border: '1px solid gray'}}>{item.mappingName.substring(0, item.mappingName.indexOf('_'))}</th>
                                      )}
                                      <th style={{padding:8, border: '1px solid gray'}}> 선택하기 </th>
                                  </tr>

                                  {Array.from({ length: getCmNotationFieldVArr(cm.원장, colObj.cmItemBoxQs[0].cmField).length}, (_, index) => index) //[0,1] array생성
                                      .map((row, rowId)=>
                                      <tr>
                                          {colObj.cmItemBoxQs.map( (item, colIdx) =>{
                                              let test = tableMapV;
                                              return <td style={{padding:8, border: '1px solid gray', backgroundColor:colIdx===0?'lightgray':'white'}}>
                                                  {getCmField(cm.원장, item.cmField + '|' + rowId)}
                                              </td>
                                              }

                                          )}

                                          <td style={{border: '1px solid gray'}}>
                                              {VIEW_MODE ? <>
                                                      {(tableMapV != undefined && String(tableMapV).split(',').map(Number).includes(rowId))? ' 선택': ' 미선택' }
                                               </>
                                              :
                                               <>
                                                <Checkbox checked={(tableMapV != undefined)?String(tableMapV).split(',').map(Number).includes(rowId):false} style={{marginLeft:5}}
                                                        onChange={(e) => handleTableMap채권들선택Change(tableMapVarKey, e.target.checked, rowId)}/> 선택
                                               </>
                                              }
                                          </td>
                                      </tr>
                                  )}
                              </table>

                          case '서브질문node':
                          case '서브질문node매칭':
                              const myColIndex = getMyColIndex(askQuestion.표질문, colObj.title); //서브질문node는 cmField가 없을수도 있음.

                              // 서브질문node는 cmField가 없을수 있으므로, 한칸 col의 tableMapVarKey 찾기.
                              let proceedingColObj = askQuestion.표질문[myColIndex-1];
                              let tempTableMapVarKey = getTableMapVarKeyFromRecord(proceedingColObj.cmField, record, index);

                              /** 서브질문 3 : 서비질문에서는 tempTableMapVarKey=cmBaseKey로 들어어는 오류 처리*/
                              if (notationV) {
                                  //(서브질믄Node는) tableMapBaseKey가 비어있음. => 꼭 앞에컬럼으로 설정. 필요.
                                  tableMapBaseKey = proceedingColObj.cmField;
                                  if (getNotationDepth(tableMapBaseKey) === getNotationVDepth(notationVminus1)) {
                                      tempTableMapVarKey = tableMapBaseKey + '|' + notationVminus1 ;
                                      tableMapV =  getTableMapV(tableMapBaseKey, tempTableMapVarKey) ;

                                      //feild가 notationV와 동일 depth일때.
                                  }else if (getNotationDepth(tableMapBaseKey) === getNotationVDepth(notationV) ) {
                                      tempTableMapVarKey = tableMapBaseKey + '|' + notationV ;
                                      tableMapV =  getTableMapV(tableMapBaseKey, tempTableMapVarKey) ;
                                  } else {
                                      console.log("$$$$$$$$$$$$$$$$$$ TO DO 추가구현 혹은 버그 체크");
                                  }
                              }

                              const myNotationPv = tempTableMapVarKey.substring( tempTableMapVarKey.indexOf('|')  ); //'|0';
                              console.log('서브질문node: tempTableMapVarKey', tempTableMapVarKey);
                              console.log('서브질문node: myNotationPv', myNotationPv);

                              //서브질문에 전달 필요.
                              const myNotationV = tempTableMapVarKey.substring( tempTableMapVarKey.indexOf('|')+1  ); //'0' or '0_1'향후.

                              //서브질문Select 중에 myColIndex가 있는지 찾기.
                              // existMyColIndex 안에서 예|4 사용해서 파싱중.
                              // 표질문(StColObjs)중에 답변유형이 '서브질문Select'인걸 찾아서 cmField로 tableMap에서 찾기.
                               //todo 서브질문node매칭 에서는 selBoxQ index 혹은 askFlowId(List안에 있는거) 필요함.
                              const isExistColIndex = existMyColIndex(askQuestion.표질문, tableMap, myColIndex,  myNotationPv );

                              //서브질문node매칭 일때만 존재함. : 예|4_15 에서 15만추출.
                              // const subNodeFlowId = (colObj.답변유형==='서브질문node매칭' && isExistColIndex)? askQuestion.표질문.index : undefined;
                              const subNodeFlowId = (isExistColIndex && isExistColIndex.indexOf('_') > 0)? isExistColIndex.substring(isExistColIndex.indexOf('_')+1) : undefined;
                              console.log('서브질문node매칭 ', subNodeFlowId , myNotationV, notationV);

                              /** 서브질문 3-1 : 서브질문에서 2단계 팝업호출시 SUB_ASK_DEPTH=2로 전달필요. fromHistory는? 1줄표시용도로 쓰이는 중.  */
                              return (!isExistColIndex)?  <> - </> :
                                      (subNodeFlowId)? <Button type={VIEW_MODE?'normal':'default'} onClick={() => openSubAskModal(Number(subNodeFlowId), colObj.title, myNotationV, fromHistory, notationV?2:1) }>  답변하기 </Button>
                                        : //밑에는 일반 서브질문node
                                         <Button type={VIEW_MODE?'normal':'default'} onClick={() => openSubAskModal(colObj.askFlowId, colObj.title, myNotationV, fromHistory, notationV?2:1) }>  답변하기 </Button>;


                          case 'readOnly': return <>{ (tableMapV?.date)? tableMapV.date : checkNumberAddComma(tableMapV) }</>;

                          default: return <>추가예정2</>

                      } //switch
                  }//render
                } //ONE__COL

                //마지막컬럼이면 증거 추가. (위쪽에도 반복됨, 여기가 더 중요): 마지막 필드이  ij를 따라가도록.
                // if (idx === askQuestion.표질문.length - 1 &&  askQuestion.표증거원장.cmField) {
                if (idx === askQuestion.표질문.filter((colObj,idx) => check기본숨김open(idx, colObj, selectedPv)).length-1 ) { //&&  askQuestion.표증거원장.cmField) {

                    let retVal = [ONE_COL];

                    if (askQuestion.표증거원장.cmField) {
                        const 증거컬럼 = {  //colObj=StColumnQuestionObject
                            title: '증거첨부',
                            key: colObj.cmField, dataIndex: colObj.cmField,
                            render: (text, record, index) => {

                                const cmField = askQuestion.표증거원장.cmField;
                                //채권_i:0
                                let tableMapVarKey = getTableMapVarKeyFromRecord(cmField, record, index);
                                //채권_i
                                let tableMapBaseKey = cmField;
                                //출력값.
                                let tableMapV = getTableMapV(tableMapBaseKey, tableMapVarKey);
                                console.log('*ONE_COL 표증거 cmField:', cmField); //채권_i, 대여금_ij
                                console.log('*ONE_COL 표증거 record:', record); //채권_i, 대여금_ij

                                //multiFile, or '[file]'
                                return <Flex>
                                    <Space>
                                        {/*<PublicFileUploader imageUrl={tableMapV} downloadOnly={VIEW_MODE}*/}
                                        {/*                    onChange={(returnUrl) => handleTableMapTextValueChange(tableMapVarKey, returnUrl)}*/}
                                        {/*                    buttonText={''} name={tableMapVarKey}/>*/}
                                        <EvidenceUploader cmId={cm.cmId} fileList={tableMapV} downloadOnly={VIEW_MODE}
                                                          evidenceFileList={evidenceFolderFileList}
                                                          // evidenceFileList={evidenceFolderFileList}
                                                          onChange={(returnFileList) => handleTableMapTextValueChange(tableMapVarKey, returnFileList)}
                                                          buttonText={''} name={tableMapVarKey}/>
                                    </Space>
                                </Flex>
                            }
                        }
                        retVal.push(증거컬럼);
                    }

                    //todo idx=colIdx라서 dsIdx 필요.

                    if (selectedPv) {
                        retVal.push({title:'재입력', key:'99',
                               render: (t,r,dsIndex)=>  dsIndex===checkSelectedPvIndex(selectedPv)? <Button type={'primary'} onClick={reRequestInputModal}> 수정 재입력 </Button>:<></>} );
                    }

                    return retVal;
                }

                return [ONE_COL];
        })//map notation 있는 반복 표. END
        : []; //


    //0. cm에 값존재하면 cmFieldKv 에 초기값 세팅 필요..=>history재설정 포함.
    useEffect(() => {
        console.log('## OneQuestion useEffect askQuestionID =', askQuestion._id);

        if (notationV) {
            /** 서브질문 1, 날짜전용 _ijk레벨 */ // (notationV 존재하고, cmField에 _존재시)///////////////////////////
            if (NORMAL_Q & askQuestion?.답변형식 === 'date') { //if (notationV && NORMAL_Q) { //&& askQuestion.cmField.indexOf('_') > 0) {
                let arr = getCmNotationFieldVArr(cm.원장, askQuestion.cmField + '|' + notationV);

                console.log(' 서브질문 DateArr 초기값:', arr);
                setNotationVDateArr(arr);
            }

            /** 서브질문 0, {{}} 자동변수 치환 */
            // 질문에 Variable체크.
            if (askQuestion.질문.indexOf('{{') >= 0) {
                const regex = /{{(.*?)}}/g;
                const varMappingNames = [];
                let match;
                while ((match = regex.exec(askQuestion.질문))) {
                    varMappingNames.push(match[1]);
                }
                getAndSetVarCmFieldsAndQ(varMappingNames);
                console.log(' 서브질문  varMappingNames', varMappingNames);
            }

            if (fromHistory) { /** 히스토리 서브질문 표는 1줄만 리턴.  */
                if (askQuestion?.답변형식==='표') {
                    //return 안하고 밑에 로직 태우는 중. ds세팅할때 달라잠. -> useEffect([tableMap]) 부분 변경.
                }
            }
            /** 서브질문 관련 종료 후 return할지 말지 검토 필요: return하면 서브질문완전 깔끔 분리이고,
             *
             * return 안하면 재활용인데   notionV활용이 필요함.  text/number등 간단한 답변 하려면 return안하면 밑에꺼 탐. */
            //todo check: return;

        }

        if (askQuestion?.답변형식==='표') {
            //데이터 초기값 필요함. tableCmField -> cmFiledKv로 상호변환이 필요함.  dataSource와도 자동매핑이 필요할거 같은데?
            let tableCmFieldKvMap = {}; //key(cmField) :[ {k:가변 v:}, {k:, v:} ]
            let cmFields = [];
            if (askQuestion._id == 83) {
                console.log('5% 원장 채권액 에러');
            }

            for (const col of askQuestion.표질문) {
                //tableCmFieldKvMap[col.cmField] = [];
                //원장없는 cmField:서브질문node 제외
                if (col.cmField) {
                    cmFields.push(col.cmField);
                }
            }
            //표증거원장 존재시, 증거 추가.
            if (askQuestion.표증거원장.cmField) {
                cmFields.push( askQuestion.표증거원장.cmField);
            }
            //중요: "tableMap최초 생성시점". { 채권_i:[''], 대여금액_i:['']   } 상태.

            tableCmFieldKvMap = getTableMapFromCm(cm.원장, cmFields, askQuestion.표질문);

            console.log('tableCmFieldKvMap:', tableCmFieldKvMap);

            //단일변제기 메인질문 i->i (서브질문 i) case처리. : 데이터 가공은 잘되지만 |1 데이터만 남겨도 렌더링쪽에서 |0 을 찾으려고 함.
            console.log('단일변제기 메인질문 i->i 체크', notationV, notationVminus1);

            if (notationV !== 'X' && notationV && notationVminus1 === notationV) {
                const pv = '|' + notationV;
                try {
                    for (const key of Object.keys(tableCmFieldKvMap)) {
                        //원장_i: [원장_i|0:v, 원장_i|1:v]   = key : kvArr
                        const kvArr = tableCmFieldKvMap[key];
                        //kvArr 중에 'key|notationV' 인것만 남기기.
                        // for ( const k of kvArr) {
                        for (let i = kvArr.length - 1; i >= 0; i--) {//const k of kvArr) {
                            let kv = kvArr[i];
                            const k = Object.keys(kv)[0];
                            if (k !== key + '|' + notationV) {
                                kvArr.splice(i, 1);
                            }
                        }
                    }
                }catch (e) {
                    console.log('단일변제기 메인질문 i->i ', e);
                }
                console.log('단일변제기 메인질문 i->i ', tableCmFieldKvMap);
                setTableMap(tableCmFieldKvMap);

            }else { //일반적인 질문
                setTableMap(tableCmFieldKvMap);
            }
            //증거컬럼 추가? (이건 ijk등 마지막 레벨.)
        }
        if (askQuestion?.답변형식==='히스토리') {
            getAndSetHistoryItems(askQuestion.히스토리질문);
        }



        if (NORMAL_Q) {
            //(답변) 원장.  객관식 Checkbox 초기값도 포함된. //////////////////////////////
            let cmFieldValue = getCmField(cm.원장, askQuestion.cmField );
            console.log ('cmField Value:', cmFieldValue);
            if (cmFieldValue) {
                setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : cmFieldValue}) );
            }
            //증거원장 초기값
            if (NORMAL_Q && askQuestion.증거원장?.cmField) {
                let cmFieldValue = getCmField(cm.원장, askQuestion.증거원장?.cmField );
                if (cmFieldValue) {
                    setCmFieldKv(prev => ({...prev, [askQuestion.증거원장?.cmField] : cmFieldValue}) );
                }
            }

            //객관식 Checkbox 초기값.
            if (NORMAL_Q && askQuestion?.답변형식 ==='객관식' && askQuestion.객관식Type==='Check') {
                //cmFieldValue 이미존재.
                let arr;
                if (!cmFieldValue) { arr = []; } //그냥 split하면 ''하나가 들어가는 문제 있음.
                else {arr = cmFieldValue.split(',');}
                setNormalCheckboxArr(arr);
            }

            //날짜들 여러개 초기값
            if (askQuestion.type==='[date]') {
                let arr = cmFieldValue; //array임.
                console.log(' DateArr 초기값:', arr);
                if (!arr || (Array.isArray(arr) && arr.length === 0)) {
                    arr = [undefined]; //혹시 원래 '[]' 라서 위에서 처리못해도 하나 강제삽입.
                }

                setNormalDateArr(arr);
            }
        }//end Normal_Q

    }, [cm])

    //dataSource가 row개수 만 관리하고.
    //   tableMap->cmFieldKv로 바로 저장하는 중. .
    useEffect(()=> {

        /** 서브질문 2-1: 히스토리 서브질문은 ds 한줄만 리턴 */
        // let ds = (fromHistory)?['']: convertTableMap2Ds(tableMap);
            //history에는 notationV가 i_j 즉 2가 들어오므로, notationVminus1: i가 필요.
        let ds = (fromHistory)? convertTableMap2DsFromHistory(tableMap, notationVminus1)
                              : convertTableMap2Ds(tableMap, READONLY_DEPTH);

        console.log("### USE EFFECT fromHistory TableMap =", tableMap);
        console.log("### USE EFFECT fromHistory TableMap ds=", ds);

        //단일변제기 메인질문 i->i (서브질문 i) case처리.
        console.log('단말변제기 질뭍 i->i 체크2', notationV,notationVminus1, ds );
        // if (notationV && notationVminus1 === notationV) {
        //     setDataSource(ds.filter ((data, idx) => idx === Number(notationV)));
        //     //개수는 1개로 맞췄지만 tableMap에도 하나 제거해야함.
        //
        // }else {

            setDataSource(ds); //대부분의 경우
        //}


        //cmFieldKv도 실시간 저장?
        let tableCmFieldKvArr = tableMap2kv(tableMap);
        console.log('tableCmFieldKvArr:', tableCmFieldKvArr);

        setCmFieldKv(prev => {
            let newCmFieldKv = {...prev};

            for (let kv of tableCmFieldKvArr) {
                let k = Object.keys(kv)[0];
                let v = kv[k];
                newCmFieldKv = {...newCmFieldKv, [k]:v}
            }
            console.log('newCmFieldKv', newCmFieldKv);
            return newCmFieldKv;
        }) ;

    }, [tableMap])



    //notationV를 사용해서 치환필요. : 2401 현재는 서브질문팝업 전용.
    const getAndSetVarCmFieldsAndQ = async(varMappingNames) => {

        //mappingName에 근접하는 cmField들 가져오기.  : 백엔드 param - askQuestion.cmField(마지막 . 제외해서 근접한 것 찾는용도  ).  varMappingNames
        let findBaseCmField = askQuestion.cmField;
        if (!findBaseCmField) { //표질문이라 잘 비어있음.
            if (askQuestion.표질문 && askQuestion.표질문.length >0) {
                findBaseCmField = askQuestion.표질문[0].cmField.substring(0, askQuestion.표질문[0].cmField.lastIndexOf('.') );
            }
        }
        let cmFields = await axiosPost(`/api/ipoUser/askFlow/mappingNames/cmFields/${findBaseCmField}`, varMappingNames );   //array param때문에 post사용.

        console.log('dynamicQ', cmFields);

        let Q = Object.assign(askQuestion.질문) ;

        //askQuestion.질문 대신에, dynamicQuestion 저장해서 출력.
        for (let i = 0; i < varMappingNames.length; i++) {

            let oneVar = '{{'+varMappingNames[i] + '}}';
            let cmField = cmFields[i]; //대여금_i 형태

            let cmValue ='';
            if (notationV === 'X') { //심플표에서 넘어온 서브질문 : 2402 추가.
                cmValue = getCmField(cm.원장, cmField);

            } else { //일반적인 notation테이블 ->케이스

                //일반적으로 입력데이타(날짜등)가 _ij일 경우, notationV는 _i수준에서 들어오므로 그대로 사용.
                let cmVarField = cmField + '|' + notationV;

                //특수한 경우, notationV가 _1_1 (_ij)레벨인데 cmField는 (_i)레벨이 경우도 적용.
                if (getNotationVDepth(notationV) > getCmFieldDepth(cmField)) {
                    let tempNotationV = notationV;

                    let count = 0;//MAX 3번만 하면됨. _ijkp가 max라서. ..
                    while (getNotationVDepth(tempNotationV) > getCmFieldDepth(cmField) && count < 3) {
                        tempNotationV = notationMinusDepth(tempNotationV);
                        count++;
                    }

                    console.log('서브질문 :' + notationV + ' ==> ' + tempNotationV);
                    cmVarField = cmField + '|' + tempNotationV;
                }

                console.log('oneCmVarField', cmVarField);
                cmValue = getCmField(cm.원장, cmVarField);//CmUtil에서 찾기.
            }

            if (typeof cmValue === 'string' && cmValue.indexOf('|') > 0) {
                Q = Q.replace(oneVar, cmValue.substring(0, cmValue.indexOf('|'))); //예|4_15 같은경우 는 제거.
            }else {
                Q = Q.replace(oneVar, cmValue);
            }
        }
        console.log(Q);

        setDynamicQ(Q); //+ 출력.

    }


    //240122: 아직 i,j필요는 없는 상황.
    // const handleTableMapInputChange = (e, i, j) => {
    const handleTableMapInputChange = (e, isNumberType) => {
        //console.log(e.target.name);
        let tableMapVarKey = e.target.name;
        let v = e.target.value;
        if (isNumberType) {
            v = e.target.value? (e.target.value).replace(/,/g, '') :e.target.value;
        }

        console.log('##handleTableMapInputChange :', tableMapVarKey); //, i, j);
        //setCmFieldKv(prev => ({...prev, [e.target.name] : e.target.value}) );
        const baseKey = getBaseKey(tableMapVarKey);

        // let tableMapVArr = [...tableMap[baseKey]];
        let tableMapVArr =Array.isArray(tableMap[baseKey])? [...tableMap[baseKey]] : [];


        //1. 기존값 존재시 수정.
        let found = false;
        for (let kv of tableMapVArr) {
            if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                found = true;
                let oldV = kv[tableMapVarKey]; //new v로
                console.log('oldV', oldV);
                kv[tableMapVarKey] = v;
            }
        }

        //2. 없을시 새 값.
        if (!found) {
            tableMapVArr = [...tableMapVArr, {[tableMapVarKey]:v} ];
        }
        setTableMap( prev => ({ ...prev,  [baseKey]:tableMapVArr }));
    }




    //tableMap용 데이트처리.
    const handleTableMapDatePickerChange = (date, dateString, tableMapVarKey ) => {
        console.log('handleTableMapDatePickerChange dateMaskPicker',  dateString);

        const baseKey = getBaseKey(tableMapVarKey);
        // let tableMapVArr = [...tableMap[baseKey]];
        let tableMapVArr =Array.isArray(tableMap[baseKey])? [...tableMap[baseKey]] : [];

        //1. 기존값 존재시 수정.
        let found = false;
        for (let kv of tableMapVArr) {
            if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                found = true;
                let oldV = kv[tableMapVarKey]; //new v로
                console.log('dateMaskPicker:', oldV);
                kv[tableMapVarKey] = dateString;
            }
        }
        //2. 없을시 새 값.
        if (!found) {
            tableMapVArr = [...tableMapVArr, {[tableMapVarKey]:dateString} ];
        }
        setTableMap( prev => ({ ...prev,  [baseKey]:tableMapVArr }));
    }

    const handleTableMapReactDatePickerChange = (date, dateString, tableMapVarKey ) => {
        console.log('handleTableMapDatePickerChange reactDatePicker',  dateString);

        const baseKey = getBaseKey(tableMapVarKey);
        // let tableMapVArr = [...tableMap[baseKey]];
        let tableMapVArr =Array.isArray(tableMap[baseKey])? [...tableMap[baseKey]] : [];

        //1. 기존값 존재시 수정.
        let found = false;
        for (let kv of tableMapVArr) {
            if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                found = true;
                let oldV = kv[tableMapVarKey]; //new v로
                console.log('handleTableMapReactDatePickerChange:', oldV);
                kv[tableMapVarKey] = dayjs(dateString).format('YYYY-MM-DD');
            }
        }
        //2. 없을시 새 값.
        if (!found) {
            tableMapVArr = [...tableMapVArr, {[tableMapVarKey]:dayjs(dateString).format('YYYY-MM-DD')} ];
        }
        setTableMap( prev => ({ ...prev,  [baseKey]:tableMapVArr }));
    }


    //tableMap용 File처리. SelectBox처리 : String타입 처리.
    const handleTableMapTextValueChange = (tableMapVarKey, value ) => {
        console.log('handleTableMapTextValueChange',  value);

        const baseKey = getBaseKey(tableMapVarKey);
        let tableMapVArr =Array.isArray(tableMap[baseKey])? [...tableMap[baseKey]] : [];

        console.log('handleTableMapTextValueChange tableMapVArr:', tableMapVArr);

        //1. 기존값 존재시 수정.
        let found = false;
        for (let kv of tableMapVArr) {
            if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                found = true;
                let oldV = kv[tableMapVarKey]; //new v로
                console.log('handleTableMapTextValueChange oldDate:', oldV);
                kv[tableMapVarKey] = value;
            }
        }
        //2. 없을시 새 값.
        if (!found) {
            tableMapVArr = [...tableMapVArr, {[tableMapVarKey]:value} ];
        }
        console.log('handleTableMapTextValueChange new TableMap:', baseKey, tableMapVArr);
        setTableMap( prev => ({ ...prev,  [baseKey]:tableMapVArr }));
    }


    const handleTableMap서브질문SelectChange = (cmField, tableMapVarKey, value, 심플표) => {

        const subAskColIndex = getSubAskColIndex(askQuestion.표질문, cmField, value);

        if (심플표) { //5%질문때문에 추가. 심플표는 cmField직접이용.
            if (subAskColIndex) {
                setCmFieldKv(prev => ({...prev, [cmField]: value + '|' + subAskColIndex}));
            }else {
                setCmFieldKv(prev => ({...prev, [cmField]: value}));
            }

            return;
        }

        //2402 todo 예|4_15 (서브질문node매칭시 저장 필요)
        if (subAskColIndex) {
            //분할변제여부   "예|4"  로 저장 중.,  서브질문node매칭은 예|4_15 형태로 저장 중.
            console.log('서브질문node매칭 index:',  value+'|'+ subAskColIndex  );
            handleTableMapTextValueChange(tableMapVarKey, value+'|'+ subAskColIndex );
        }else {
            handleTableMapTextValueChange(tableMapVarKey, value);
        }


    }

    //notationV : 0 와 idx를 이용해서  0_idx 조합해서 저장필요.
    const handleNotationVDateArrChange = (date, dateString, idx ) => {
        console.log('서브질문 date',  dateString);

        if (NORMAL_Q && askQuestion.cmField.indexOf('_') > 0) { //서브질문 중 NORMAL_Q이. (notation 및 notationV도 존재함)

            let updated = [...notationVDateArr];
            updated[idx] = dateString;
            setNotationVDateArr( updated );
            //notation방식 저장.

            let newNotationV = notationV + '_' + idx;

            let cmFieldBaseKey = askQuestion.cmField;
            let cmFieldVarKey = askQuestion.cmField + '|' + newNotationV;

            console.log('서브질문 date varkey:',  cmFieldVarKey, dateString);

            setCmFieldKv(prev => ({...prev, [cmFieldVarKey] : dateString}) ); //array로 저장방식
        }else {

            alert('다른 방식 추가가 필요합니다.');
        }

    }

    const handleTableMap채권들선택Change = (tableMapVarKey, checked, rowId) => {
        //console.log(e.target.name);

        console.log('##handleTableMapInputChange :', tableMapVarKey); //, i, j);
        //setCmFieldKv(prev => ({...prev, [e.target.name] : e.target.value}) );
        const baseKey = getBaseKey(tableMapVarKey);

        // let tableMapVArr = [...tableMap[baseKey]];
        let tableMapVArr =Array.isArray(tableMap[baseKey])? [...tableMap[baseKey]] : [];

        //1. 기존값 존재시 수정.
        let found = false;
        for (let kv of tableMapVArr) {
            if (Object.keys(kv).length > 0 && tableMapVarKey === Object.keys(kv)[0]) {
                let oldV = kv[tableMapVarKey]; //new v로
                console.log('채권들선택 oldV', oldV, tableMapVarKey);
                if (oldV != undefined) {
                    found = true;
                }else {
                    continue;//continue;//break?
                }
                if (checked) {
                    kv[tableMapVarKey] = oldV + ',' + rowId;
                }else {
                    if (String(oldV).split(',').length == 1) { //전체제거
                        kv[tableMapVarKey] = undefined;
                    } else { //하나제거
                        kv[tableMapVarKey] = String(oldV).split(',').filter(num => num != rowId).join(',');
                    }
                }
                console.log('채권들선택newV', oldV);
            }
        }

        //2. 없을시 새 값.
        if (!found && checked) {
            // tableMapVArr = [...tableMapVArr, {[tableMapVarKey]:rowId} ]; //2개 들어감.
            tableMapVArr = [ {[tableMapVarKey]:rowId} ];
        }
        setTableMap( prev => ({ ...prev,  [baseKey]:tableMapVArr }));
    }

    //일단 _i에 대해서만 수행중.
    const onDsAddClick = (paramTableMap) => {

        let newTableMap = addTableMapOneData( (paramTableMap)? paramTableMap: tableMap, askQuestion.표질문, notationV);
        console.log('$$ newTableMap', newTableMap);

        setTableMap(newTableMap);

        //dataSource로 변환.
        let ds = convertTableMap2Ds(newTableMap, READONLY_DEPTH);
        setDataSource(ds);
        console.log('### NEW dataSource:', ds);
    }

    /** 히스토리 관련 */ //array Of {label:날짜, notationPv:'|0_1' itemData:{..}, historyObj:참조성 }형태로 저장
    const getAndSetHistoryItems = async(historyObjs) => {

        let historyItems = [];

        //get All dates
        for (const historyObj of historyObjs) {
            const cmField =  historyObj.dateField.cmField;
            if (!cmField) {
                continue; //이자약정, 등은 없을 수 있음.
            }
            const isDateMaskType = historyObj.dateField.type === 'dateMask';

            //axiosGet dates array: 이미 front에 와있으므로, front함수 사용.
            //notationPV도 필요. 동일한레벨 notation을 item 등에도 써야 함.
            let dateKvArr = getCmNotationFieldKVArr(cm.원장, cmField);

            for (const kv of dateKvArr) {
                let k = Object.keys(kv)[0];
                let notationPv = k.substring(k.indexOf('|')); // '|1_1' 형식.
                let v = kv[k];
                if (!v) continue; //2403 추가.

                //todo dateMask처리
                if(isDateMaskType) {
                    v = v.date; //todo check 날짜가 str로 오는지 확인필요. 원인불명이나 위에선 str임.
                }

                historyItems.push({label: v, notationPv:notationPv,  itemData: undefined,  historyObj: historyObj });
            }
        }

        //날짜기준으로 sort..
        historyItems.sort((a, b) => {
            if (a.label < b.label) {return -1;}
            if (a.label > b.label) {return 1;}
            return 0;
        });

        //todo item별 데이타 세팅. .
        for (let oneItem of historyItems) {

            if (oneItem.historyObj.itemField?.cmField) {
                const itemCmField = oneItem.historyObj.itemField?.cmField; //원장.채권_i, 원장.대여일_ij 등.
                const itemCmVarField = itemCmField + oneItem.notationPv;

                let itemData = getCmField(cm.원장, itemCmVarField);
                console.log('히스토리 itemCmVarField:',itemCmVarField, itemData);
                // console.log('히스토리 itemData:', itemData);
                if (itemData) {
                    //예:4 자르기 (혹시나 들어오면)
                    if (typeof itemData === 'string' && itemData.indexOf('|') > 0) {
                        itemData = itemData.substring(0, itemData.lastIndexOf('|'));
                    }
                    // oneItem.itemData = itemData + ((oneItem.historyObj.itemPostfix)? oneItem.historyObj.itemPostfix : '');
                    //2403 '원'일때 콤마추가.
                    oneItem.itemData = (('원'===oneItem.historyObj.itemPostfix)? addComma(itemData) : itemData )
                          + ((oneItem.historyObj.itemPostfix)? oneItem.historyObj.itemPostfix : '');

                }
            }

            //2402 caseHistory추가.
            if (oneItem.historyObj.caseHistoryField?.cmField) {
                const caseCmField = oneItem.historyObj.caseHistoryField?.cmField; //원장.채권_i, 원장.대여일_ij 등.
                const caseCmVarField = caseCmField + oneItem.notationPv;

                let caseData = getCmField(cm.원장, caseCmVarField);
                console.log('히스토리 caseCmVarField:',caseCmVarField, caseData);

                if (caseData) {
                    //예:4 자르기 (혹시나 들어오면)
                    if (typeof caseData === 'string' && caseData.indexOf('|') > 0) {
                        caseData = caseData.substring(0, caseData.lastIndexOf('|'));
                    }

                    //oneItem.caseData = caseData ;// + ((oneItem.historyObj.itemPostfix)? oneItem.historyObj.itemPostfix : '');
                    oneItem.caseTitle = caseData; //치환 시도.

                    console.log('caseData:',  caseData );
                    //text와 caseHistoryCases의 case들을 비교해서 askFlowId세팅.

                    let caseIndex = oneItem.historyObj.caseHistoryCases.findIndex((entry) => entry.title === caseData);
                    if (caseIndex >= 0) {
                        let targetCaseEntry = oneItem.historyObj.caseHistoryCases[caseIndex];
                        oneItem.askFlowId = targetCaseEntry.askFlowId;
                        oneItem.추가사실입력waiting = true; //완료버튼 색깔용도.

                        oneItem.caseIndex = caseIndex; //admin용으로 추가.
                    }
                }
            }//caseHistory
        }

        console.log('히스토리 historyItems', historyItems);
        setHistoryItems(historyItems);

        console.log('historyAddIdx BEFORE', historyAddIdx);
        if (setHistoryAddIdx && historyAddIdx === undefined) { // [모든질문 입력완료] 용도. 최초한번만 추가.
            let retHistoryAddIdx = [];
            for (let i=0; i<historyItems.length; i++) {
                let item = historyItems[i];
                if (item.추가사실입력waiting) {
                    // setHistoryAddIdx(prev => [...prev, i]);
                    retHistoryAddIdx.push(i);
                }
            }
            setHistoryAddIdx(retHistoryAddIdx);
            console.log('historyAddIdx AFTER', retHistoryAddIdx);
        }
        //setHistoryItems추가사실입력idxs(historyItems.filter((hObj, idx) =>))

        if (setParentHistoryItems) { //재요청 TimeLine용
            setParentHistoryItems(historyItems);
        }
    }

    //추가사실 입력 click시 마다 추가사실입력waiting = false로 세팅하기.
    const openHistorySubAskModal = (historyIdx, askFlowId, label, notationV, fromHistory ) => {

        //추가사실 입력하기 버튼을 회색으로 바꾸기 추가.
        // setHistoryItems(prev => {
        //     let ret = [...prev];
        //     ret[historyIdx].추가사실입력waiting = false;
        //     return ret;
        // })

        //parent:스토리텔링 에 직접기록.하나씩 제거.
        if (setHistoryAddIdx) {
            setHistoryAddIdx(prev => prev.filter((item) => item != historyIdx));
        }

        //기존 팝업호출
        openSubAskModal(askFlowId,
            label,
            notationV,  //notationV가 필요.
            fromHistory   //fromHistory=true,  history테이블은 한 줄만 노출.
        )
    }

    const handleNumberInputChange = (e) => {
        console.log(e.target.name);
        let removedComma = e.target.value? (e.target.value).replaceAll(',', '') :e.target.value;
        console.log('removedComma', removedComma);

        setCmFieldKv(prev => ({...prev, [e.target.name] : removedComma}) );
    }


    const handleInputChange = (e) => {
        console.log(e.target.name);
        setCmFieldKv(prev => ({...prev, [e.target.name] : e.target.value}) );
    }

    //table일 경우? [date] array일 경우 별도처리?
    const handleDatePickerChange = (date, dateString, cmFieldName ) => {
        console.log('handleDatePickerChange',  dateString);

        if (NORMAL_Q || !NOTATION_EXISTS) { //심플표도 수용.
            setCmFieldKv(prev => ({...prev, [cmFieldName] : dateString}) );
        }
        //테이블, [date]는 별도 함수.
    }

    const handleReactDatePickerChange = (date, dateString, cmFieldName ) => {
        console.log('handleReactDatePickerChange',  dateString);

        if (NORMAL_Q || !NOTATION_EXISTS) { //심플표도 수용.
            setCmFieldKv(prev => ({...prev, [cmFieldName] : dayjs(dateString).format('YYYY-MM-DD')}) );
        }
        //테이블, [date]는 별도 함수.
    }

    const handleNormalDateArrChange = (date, dateString, idx ) => {
        console.log('handleNormalDateArrChange',  dateString);
        if (NORMAL_Q) {

            let updated = [...normalDateArr];
            console.log('before updated:', normalDateArr);
            // if (dateString) {
            updated[idx] = dateString;
            // }else {//     updated = normalCheckboxArr.filter( item => item !==  e.target.name);}

            console.log('after updated:', updated);
            setNormalDateArr( updated );

            setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : updated.filter((item) => item !== '')}) ); //array로 저장이라서, normalDateArr없어도 될듯함.
        }
        //테이블 여기서 처리?? //todo 테이블 출력시 검토필요.
    }



    const addNormalDateArr = () => {
        setNormalDateArr (prev => [...prev, '']);
    }

    const handleNormalCheckboxChange = (e) => {
        console.log(e.target.name);
        //이부분 동기화가 어려워 prev=>방식 미사용. setCmFieldKv(prev => ({...prev, [e.target.name] : e.target.value}) );

        let updated;
        console.log('before updated:', normalCheckboxArr);
        if (e.target.checked) {
            // setNormalCheckboxArr(prev => [...prev, e.target.name])
            updated = [...normalCheckboxArr, e.target.name];
        }else {
            updated = normalCheckboxArr.filter( item => item !==  e.target.name);
            //setNormalCheckboxArr(prev => prev.filter( item => item !==  e.target.name))
        }

        console.log('checkbox updated:', updated);
        console.log('checkbox updated Join:', updated.join(','));
        setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : updated.join(',')}) );
        setNormalCheckboxArr(updated);
    }

    const setNormalQ모름 = (checked) => {
        if (!모름) { //check시
            console.log('cmFieldV:', cmFieldKv[askQuestion.cmField]);
            setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : null}) );
        }
        set모름(prev => !prev);
    }


    const onSaveOneQuestion = () => {

        console.log('cmFieldKv:', cmFieldKv );
        console.log('tableMap:', tableMap );
        //유효성 체크.
        if (save채권들선택자동func) {
            //미사용 : alert('save채권들선택자동func 호출');
            save채권들선택자동func();
        }


        if (notationV !== undefined) { //서브질문일경우는 popup닫음.
            saveAll(true);
        }else {
            saveAll();
        }

    }



    return(
    <div style={{ display: hideDiv ? 'none' : 'block' }}>
        <Flex>
            {/*<Title2> {idx + 1}.&nbsp;</Title2>*/}
            <FlexColumn>
                {/*<Title2> { (dynamicQ)? dynamicQ: askQuestion.질문} </Title2>*/}
                {(askQuestion.답변형식 === '히스토리' && VIEW_MODE) ? //for BoAdmin 2402
                    <><Title2Gray> 고객 히스토리 </Title2Gray><br/></>
                    :
                    <Flex style={{marginBottom:8}}>
                        <Title2Gray> {idx + 1}.&nbsp;</Title2Gray>
                        <FlexColumn>
                            <Title2Gray> {isDevEnv?'[' + askQuestion.항목명 +'] ' :''}{(dynamicQ) ? dynamicQ : askQuestion.질문} </Title2Gray>
                            {askQuestion.질문추가설명 && !reQuestionMap && //재요청일때는 헤깔려서 제외
                                <GrayDiv> {askQuestion.질문추가설명} </GrayDiv>
                            }
                        </FlexColumn>
                    </Flex>
                }


                <Flex style={{marginLeft:16}}>
                    <Space>
                    {askQuestion?.답변형식 === 'number' &&
                        <Input readOnly={VIEW_MODE} type={'text'} value={addComma(cmFieldKv[askQuestion.cmField])} onChange = {handleNumberInputChange} disabled={모름} style={{width:140}} name={askQuestion.cmField} />
                    }
                    {askQuestion?.답변형식 === 'text' &&
                        <Input readOnly={VIEW_MODE} type={'text'} value={cmFieldKv[askQuestion.cmField]} onChange = {handleInputChange} disabled={모름} style={{width:140}} name={askQuestion.cmField} />
                    }

                    {askQuestion?.답변형식 === 'date' &&  /** 서브질문 날짜들도 포함됨 : notationV 이용.  */
                    <>
                        {(askQuestion.cmField.indexOf('_') > 0)? //normalQ : NOTATION_EXISTS 예외상황 - 분할변제약속 날짜 등.
                            <FlexColumn>
                                <>테이블로 질문구성필요.</>
                                {/*<Space direction="vertical">*/}
                                {/*    { Array.isArray(notationVDateArr) && notationVDateArr.map((item, idx) =>*/}
                                {/*        <DatePicker disabled={모름} locale={locale}   // value={creditList[index][item.title]? moment(creditList[index][item.title]):''} //defaultValue={moment()}*/}
                                {/*                    value={item? dayjs(item):''} disabled={VIEW_MODE}*/}
                                {/*                    onChange={(date, dateString) => handleNotationVDateArrChange(date, dateString, idx)}*/}
                                {/*        />*/}
                                {/*    )}*/}
                                {/*</Space>*/}
                            </FlexColumn>
                            :
                            <DatePicker //name={colObj.cmField}
                                        locale={'ko'}    className={'simple-datepicker'}
                                        selected={cmFieldKv[askQuestion.cmField]? new Date(cmFieldKv[askQuestion.cmField]) : ''}
                                        dateFormat="yyyy-MM-dd" placeholderText={'연도는 숫자입력'}
                                        onChange={(dates) => handleReactDatePickerChange(dates, dates?.toString(), askQuestion.cmField)}/>
                            // <DateMaskPicker disabled={모름||VIEW_MODE}
                            //                 selected = {cmFieldKv[askQuestion.cmField]? cmFieldKv[askQuestion.cmField] : ''}
                            //                 onChange={(dateMask ) => handleDatePickerChange(null, dateMask,  askQuestion.cmField)}
                            // />

                        }
                    </>
                    }

                    {(askQuestion?.답변형식 === '객관식') &&(
                        askQuestion.객관식Type === 'Select'?
                        <Select disabled={모름 || VIEW_MODE}
                            placeholder={'선택해 주세요'} style={{width: 300}}
                            value={cmFieldKv[askQuestion.cmField]}
                            onChange = { (value) => setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : value})) }
                            options={ //[{ value: 'jack', label: 'Jack' },]
                                askQuestion.객관식질문들.map((item) => ({value: item}))
                            }
                        />
                        :
                        askQuestion.객관식Type === 'Radio'?
                        <Radio.Group
                                     disabled={모름 || VIEW_MODE}
                            value={cmFieldKv[askQuestion.cmField]}
                            onChange = { (e) => setCmFieldKv(prev => ({...prev, [askQuestion.cmField] : e.target.value})) } >
                            <FlexColumn>
                            {askQuestion.객관식질문들.map((item) =>
                                <Radio value={item}> {item} </Radio>
                            )}
                            </FlexColumn>
                        </Radio.Group>
                        : //Checkbox
                        <FlexColumn>
                            {askQuestion.객관식질문들.map((item) =>
                                <Space>
                                    <Checkbox disabled={모름||VIEW_MODE}  checked={normalCheckboxArr.includes(item)}name={item} onChange={handleNormalCheckboxChange}/>
                                    {item}
                                </Space>
                            )}
                        </FlexColumn>

                    )}

                    {(askQuestion?.답변형식 === '표') &&
                    <FlexColumn>
                        <UserTable dataSource={dataSource} columns={columns} size={'small'} pagination={false}
                                   rowClassName={(record, index) => index === checkSelectedPvIndex(selectedPv) ? 'red-row' : ''}/>

                        { (NOTATION_EXISTS || notationV )&& //2402: 'X' 일경우도 NOTATION없지만 서브질문임. notationV &&로 하면 PlusButton버튼이 안생김//1st 컬럼 && 마지막 row체크필요.
                            <FlexColumn>
                                {(!READONLY_EXISTS && !fromHistory && !VIEW_MODE && notationV !=='X') &&
                                <PlusButton style={{width:'500px', padding:0, marginTop:10, fontSize:'20px', height:'35px'}} onClick={() => onDsAddClick(tableMap)} > + </PlusButton>
                                }
                                {askQuestion.입력완료버튼 && !VIEW_MODE && notationV &&
                                <EmptyBox style={{width:'600px'}}>
                                    <Button style={{backgroundColor: '#ffd118', color:'black'}} type={'primary'}
                                            onClick={onSaveOneQuestion}> 입력완료[저장] </Button>
                                </EmptyBox>
                                }
                            </FlexColumn>
                        }
                        {//!NOTATION_EXISTS && askQuestion.입력완료버튼 && !VIEW_MODE &&
                        // <EmptyBox>
                        //     <Button style={{backgroundColor: '#ffd118', color:'black'}} type={'primary'}
                        //             onClick={onSaveOneQuestion}> 입력완료[저장] </Button>
                        // </EmptyBox>
                        }
                    </FlexColumn>
                    }

                    {(askQuestion?.답변형식 === '히스토리' && historyItems.length > 0 && !setParentHistoryItems) &&
                    <FlexColumn>
                        <Timeline mode={'left'} style={{marginTop:10, width:'220px'}}
                            items = { historyItems.map((oneItem, idx)=> {
                              return {
                                label:<div style={{width:90}}> {oneItem.label}</div>,
                                children:
                                    <div style={{marginLeft:20, width:500}}>
                                        {/* 히스토리 타이틀 */}
                                        <div style={{fontSize:18, fontWeight:500}}>
                                            {oneItem.caseTitle? oneItem.caseTitle: oneItem.historyObj.text}
                                        </div>
                                        {oneItem.itemData &&  /* 항목 출력. */
                                            <div style={{marginTop:3, color:'#667085'}}> {oneItem.itemData} </div>
                                        }
                                        {(oneItem.historyObj.showEvidence||oneItem.historyObj.showSubAsk) && /* 증거, 추가사실 입력 */
                                            <Flex style={{marginTop:5}}>
                                                <Space>
                                                {oneItem.historyObj.evidenceField?.cmField && /*히스토리 증거보기*/
                                                // <PublicFileUploader  imageUrl={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]} //원장.증거_i + '|1_0'
                                                //                      //onChange={(returnUrl) => setCmFieldKv(prev => ({...prev, [oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]:returnUrl}) ) }
                                                //                      readOnly={'true'} />
                                                    <EvidenceUploader cmId={cm.cmId}  //ERR fileList={cmFieldKv[oneItem.historyObj.evidenceField.cmField + oneItem.notationPv]}  //evidenceFileList={getCmField(cm.원장, '원장.채권자.증거보관함')}
                                                                            fileList={getCmField(cm.원장, oneItem.historyObj.evidenceField.cmField + oneItem.notationPv)}
                                                                                           readOnly={true} />
                                                }
                                                {oneItem.historyObj.askFlowId && /*히스토리 추가사실 입력 */
                                                    <WhiteRedButton done={historyAddIdx && !historyAddIdx.includes(idx)}  style={{marginLeft: oneItem.historyObj.evidenceField?.cmField?10:0}} icon={<AliwangwangOutlined />}
                                                             onClick={() => openHistorySubAskModal(idx,
                                                                                            oneItem.historyObj.askFlowId,
                                                                                            oneItem.label + '일 ' + oneItem.historyObj.text,
                                                                                            oneItem.notationPv.substring(1),  //notationV가 필요.
                                                                                            true   //fromHistory=true,  history테이블은 한 줄만 노출.
                                                                                            )}
                                                    > 추가사실 입력하기 </WhiteRedButton>
                                                }

                                                {oneItem.askFlowId && /*case 히스토리 추가사실 : getAndSetHistoryItems 마지막부분: 905라인에서  askFlowId에 직접삽입. */
                                                <WhiteRedButton done={historyAddIdx && !historyAddIdx.includes(idx)}  style={{marginLeft: oneItem.historyObj.evidenceField?.cmField?10:0}} icon={<AliwangwangOutlined />}
                                                                 onClick={() => openHistorySubAskModal(idx,
                                                                     oneItem.askFlowId,
                                                                     oneItem.label + '일 ' + oneItem.caseTitle,
                                                                     oneItem.notationPv.substring(1),  //notationV가 필요.
                                                                     true   //fromHistory=true,  history테이블은 한 줄만 노출.
                                                                 )}
                                                > 추가사실 입력하기 </WhiteRedButton>
                                                }
                                                </Space>
                                            </Flex>
                                        }
                                        {(idx !== historyItems.length -1) && <br/> }
                                    </div>
                              };
                            })}
                        />
                        {//!VIEW_MODE &&
                        // <EmptyBox>
                        //     <Button style={{marginTop:-20, backgroundColor: '#ffd118', color:'black'}} type={'primary'}
                        //             onClick={onSaveOneQuestion}> 추가사실 입력완료 </Button>
                        // </EmptyBox>
                        }
                    </FlexColumn>
                    }
                    {NORMAL_Q && askQuestion.postFix &&
                        <>{askQuestion.postFix}</>
                    }
                    {NORMAL_Q && askQuestion.모름필요여부 &&
                        <SmallGrayBox>
                            <Flex>
                                <div style={{padding:6, color:'gray', fontSize:12}}> 모름 </div>
                                <Checkbox readOnly={VIEW_MODE} name={'모들'} checked={모름} style={{margin:3}} onChange={setNormalQ모름}/>
                            </Flex>
                        </SmallGrayBox>
                    }
                    {NORMAL_Q && askQuestion.증거원장?.cmField &&
                        // <PublicFileUploader  imageUrl={cmFieldKv[askQuestion.증거원장?.cmField]} downloadOnly={VIEW_MODE}
                        //                      onChange={(returnUrl) => setCmFieldKv(prev => ({...prev, [askQuestion.증거원장?.cmField]:returnUrl}) ) }
                        //                      buttonText={'증거첨부'} style={{width:200}} name={askQuestion.증거원장.cmField} />

                        <EvidenceUploader cmId={cm.cmId} fileList={cmFieldKv[askQuestion.증거원장?.cmField]} downloadOnly={VIEW_MODE} evidenceFileList={evidenceFolderFileList}
                                           onChange={(returnFileList) => setCmFieldKv(prev => ({...prev, [askQuestion.증거원장?.cmField]:returnFileList}) ) }
                    />
                    }
                    </Space>

                    <FlexColumn>
                    {(askQuestion.작성예시 || askQuestion.작성예시이미지  ) && !VIEW_MODE &&
                        <Collapse  bordered={false} size={'small'} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                                  items={ [{key:'1', label:'작성예시 보기', children:
                                          <FlexColumn style={{width:500}} >
                                              {askQuestion.작성예시 && <Flex> <Space> <b>입력지침:</b>  <> {askQuestion.작성예시} </> </Space></Flex>}
                                              {askQuestion.작성예시이미지 && <Image src={askQuestion.작성예시이미지}/> }
                                          </FlexColumn>
                                  }] }  />
                    }
                    {(askQuestion.표증거입력지침 || askQuestion.표증거예시  ) && !VIEW_MODE &&
                    <Collapse  bordered={false} size={'small'} expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                               items={ [{key:'1', label:'표증거 작성예시', children:
                                       <FlexColumn style={{width:500}} >
                                           {askQuestion.표증거입력지침 && <Flex> <Space> <b>표증거입력지침:</b>  <> {askQuestion.표증거입력지침} </> </Space></Flex>}
                                           {askQuestion.표증거예시 && <Image src={askQuestion.표증거예시}/> }
                                       </FlexColumn>
                               }] }  />
                    }
                    </FlexColumn>

                </Flex>

                {
                    // reQuestionMap && //고객질문 전체보기에서 재요청 한 경우.
                    // <EmptyBox>
                    //      <Button style={{backgroundColor: '#ffd118', color:'black'}} type={'primary'} onClick ={onSaveOneQuestion} > 입력완료[저장] </Button>
                    // </EmptyBox>
                }

            </FlexColumn>
        </Flex>
    </div>
    )
}




const StoryTelling = (props) => {

    const { counselId } = useParams();
    console.log('StoryTelling2 counsel:', counselId );

    const {axiosGet, axiosPost} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    const [curStepIdx, setCurStepIdx] = useState(0);
    const [totalStepIdx, setTotalStepIdx] = useState(1);
    const [fixStepIdx, setFixStepIdx] = useState(-1);
    const [prevBlockSizes, setPrevBlockSizes] = useState([0]); //번호출력용

    const [historyStepIdx, setHistoryStepIdx] = useState(-1);
    const [historyAddIdx, setHistoryAddIdx] = useState(undefined); //추가사실입력 필요한 index


    //flow, 질문, 현재cm get.
    const [askFlows, setAskFlows] = useState([]);
    //askFlow.askQuestion으로 사용. const [askQuestions, setAskQuestions] = useState([]);
    const [creditMaster, setCreditMaster] = useState();

    //답변 저장용.
    const [cmFieldKv, setCmFieldKv] = useState({}); // 'cmField':'임력값'...

    //EvidenceFolder내부에서 부모를 수정하기 위해 필요 => EvidenceUploader들의 파라미터로 사용필요.
    const [evidenceFolderFileList, setEvidenceFolderFileList] = useState([]); //[ {uid,name}, {uid,name} ]형식.


    //서브질문 모달 관련.
    const [subAskModalData, setSubAskModalData] = useState({});
    const [modalOpen, setModalOpen, selected, setSelected, setModalState, toggle] = useModal();

    //서브질문 2DEPTH 모달 관련.
    const [subAsk2ModalData, setSubAsk2ModalData] = useState(undefined);
    const [modalOpen2, setModalOpen2, selected2, setSelected2, setModalState2, toggle2] = useModal();

    const [isSaveInProgress, setIsSaveInProgress] = useState(false);

    useEffect( () => {
        if (isMobile) {
            messageApi.info('PC 화면에서 입력해주세요');
        }

        firstSearch();

    },[counselId])

    const firstSearch = async() => {

        //질문목록 가져오기. 3가지 한꺼번에 받음.
        // let flowAskCmDto  = await axiosGet(`/api/ipoUser/askFlow/${counselId}`);

        //TODO
        let flowAskCmDto  = await axiosGet(`/api/ipoUser/askFlow/${counselId}`);

        //개발용. 보안해제. http://localhost:3030/desk/storyTelling/65a625ce476548037ac94fbc 테스트때문.
        //todo test let {data:flowAskCmDto}  = await axios.get(`${BACKEND_URL}/api/ipoUser/askFlow/${counselId}`);


        console.log('cm', flowAskCmDto.cm);
        console.log('userAskFlows', flowAskCmDto.userAskFlows);

        //여기서 각 askQuestion별로 페이지 설정.
        let prevBlockSize = [0]; //제목번호 출력용.

        let historyStepIdx = -1;
        let totalStep=0;
        let filteredFlows = flowAskCmDto.userAskFlows.filter((flow) => flow.type!=='subAsk');
        filteredFlows.forEach((flow,idx) => {
            flow.askQuestion.stepIdx = totalStep;
            if (flow.askQuestion.답변형식 === '히스토리') {
                historyStepIdx = totalStep;
            }
            if (flow.askQuestion.입력완료버튼 === true) {
                prevBlockSize.push(idx+1);
                totalStep++;
            }
        })

        setPrevBlockSizes(prevBlockSize);
        setHistoryStepIdx(historyStepIdx);

        //totalPage = filteredFlows.filter( (flow, idx) => flow.askQuestion.입력완료버튼 === true).length;
        setTotalStepIdx(totalStep);
        console.log('totalStep:', totalStep);
        console.log('historyItems historyStepIdx:', historyStepIdx);
        //미사용: console.log('curpage:', flowAskCmDto.cm.curPageIdx);
        //BUG setCurPageIdx(flowAskCmDto.cm.curPageIdx);

        setAskFlows(flowAskCmDto.userAskFlows);
        setCreditMaster(flowAskCmDto.cm);

        //증거보관함 추가 /////////////////////////
        const evidenceFolderFileList = getCmField(flowAskCmDto.cm.원장, '원장.채권자.증거보관함' );
        console.log('evidenceFolderFileList:', evidenceFolderFileList);
        if (evidenceFolderFileList) {
            setEvidenceFolderFileList(evidenceFolderFileList);
        }
        //증거보관함용 kv추가필요.
        setCmFieldKv(prev => ({...prev, ['원장.채권자.증거보관함']:evidenceFolderFileList?evidenceFolderFileList:[]}) )


    }

    //flow따라가면서 현재 질문까지 저장.
    const saveAll = async(closeModal) => {

        console.log('SaveAll:', cmFieldKv);

        //await saveAll
        setIsSaveInProgress(true);
        let retInt  = //isDevEnv()?
            //await axios.post(`${BACKEND_URL}/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv) :
            await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv);

        if (retInt) {
            messageApi.info('저장되었습니다.');
        } else {
            alert('저장 오류가 발생했습니다. ');
        }

        setIsSaveInProgress(false);
        await firstSearch();

        if (closeModal) {
            // firstSearch();
            //alert('closeModal true,' + ((subAsk2ModalData)?'sub2 TRUE': 'sub2 FALSE'));

            if (subAsk2ModalData) { //2단계 모달 닫기.
                setSubAsk2ModalData(undefined);
                setModalOpen2(false);
            }else {
                setModalOpen(false);
            }
            //firstSearch();
        }
    }


    const savePage = async(blockIdx) => {
        console.log('savePage cmFieldKv:', cmFieldKv);
        let retInt = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv);
        if (retInt > 0) {
            messageApi.info('질문 단계가 저장되었습니다.');

            //미래page용도 await axiosPost(`/api/ipoUser/askFlow/curPageIdx/${creditMaster.cmId}/${curStepIdx+1}`);
        }


        console.log('page:',  curStepIdx, blockIdx, totalStepIdx, fixStepIdx);

        setCurStepIdx(prev=> (prev < totalStepIdx && prev===blockIdx && fixStepIdx===-1)?prev+1:prev);
        setFixStepIdx(-1);

        firstSearch(); //todo check.

    }


    const saveAllIpoFinish = async() => {
        let retInt  = await axiosPost(`/api/ipoUser/askFlow/cmFieldKv/${creditMaster.cmId}`, cmFieldKv);
        if (retInt > 0) {
            //messageApi.info('저장되었습니다.');

            let retInt2  = await axiosPost(`/api/ipoUser/askFlow/ipoDone/${creditMaster.cmId}`);
            if (retInt2 > 0) {
                alert('저장되었습니다.');
            }


            //myPage refresh
            window.opener.document.location.href = window.opener.document.URL;

            window.close();
        }
    }



    //param: notationV = '0' or '1_2' 등.
    const openSubAskModal = (askFlowId, colTitle, notationV, fromHistory, SUB_ASK_DEPTH ) => {

        const modalAskFlow = askFlows.find((flow) => flow._id === askFlowId);

        if (SUB_ASK_DEPTH === 2){  /** 서브짊문 관련 4 : //2단계 서브질문.*/
            setSubAsk2ModalData( {title: colTitle, askQuestion: modalAskFlow.askQuestion, notationV:(notationV)?notationV:undefined, fromHistory } );
            toggle2();

        }else { //보통 서브질문.
            setSubAskModalData( {title: colTitle, askQuestion: modalAskFlow.askQuestion, notationV:(notationV)?notationV:undefined, fromHistory } );
            toggle();
        }
    }


    if (!creditMaster) return <></>;

    return (
        <div style={{margin:24}}>
            {contextHolder}


            <FlexColumn>

                <EvidenceFolder cmId={creditMaster.cmId} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} paramEvidenceFolderFileList={evidenceFolderFileList} />


                <br/>
                <br/>


                <TitleBox> 상세 사건정보를 입력해 주세요. </TitleBox>

                {/* 메인 질문 LIST - step별로 나눠서 뿌리기 */}

                {Array.from({length: curStepIdx + 1}, (_, index) => index).map((blockIdx) => //[0,1,2] array로 구동.
                    //Block제한 테스트 코드
                 //[2].map( (blockIdx) =>
                    <GrayBox>
                        {askFlows && askFlows//.filter((flow) => flow.askQuestionId===52) //GOOD TEST CODE
                            .filter((flow) => flow.type !== 'subAsk')
                            .filter((flow) => flow.askQuestion.stepIdx === blockIdx)
                            .map((flow, idx) => {
                                    return <div style={{marginBottom:16}}>
                                        <OneQuestion
                                                // hideDiv 미사용중. 제거해도 됨.
                                                evidenceFolderFileList = {evidenceFolderFileList}
                                                VIEW_MODE = {curStepIdx !== blockIdx  && blockIdx !== fixStepIdx}
                                                ////////아래는 block테스트시 사용.
                                                //TEST_VIEW_MODE={false}
                                                idx={idx + prevBlockSizes[blockIdx] }
                                                askQuestion={flow.askQuestion} cm={creditMaster} cmFieldKv={cmFieldKv}
                                                setCmFieldKv={setCmFieldKv} saveAll={saveAll}
                                                openSubAskModal={openSubAskModal}
                                                setHistoryAddIdx={setHistoryAddIdx} //히스토리 추가사실입력 필요한 idx관리. -> 다 입력했는지 체크해서 [모든질문 입력완료] 버튼 활성화.
                                                historyAddIdx={historyAddIdx}
                                                />
                                    </div>
                                }
                            )}
                        {blockIdx < curStepIdx &&  blockIdx !== fixStepIdx &&
                            <EmptyBox>
                                <Button style={{marginTop:16}} type={'default'}
                                        onClick={()=>setFixStepIdx(blockIdx)}> 수정하기 </Button>
                            </EmptyBox>
                        }
                        {(blockIdx === curStepIdx || blockIdx === fixStepIdx)  &&  (historyStepIdx !== blockIdx) && //히스토리는 입력완료[저장] 제거중. (historyStepIdx === blockIdx)?'#ddd':'#ffd118'
                            <EmptyBox>
                                <Button style={{marginTop:16, backgroundColor: '#ffd118', color: 'black'}} type={'primary'}
                                        onClick={()=>savePage(blockIdx)}> 입력완료[저장] </Button>
                            </EmptyBox>
                        }
                    </GrayBox>

                )}
                {curStepIdx >= totalStepIdx &&  //disabled = historyAddIdx && historyAddIdx.length !== 0 (히스토리 질문이 다 입력되지 않았으면 의 의미.
                    <EmptyBox>
                        <Button disabled={historyAddIdx && historyAddIdx.length !== 0} style={{backgroundColor: (historyAddIdx && historyAddIdx.length !== 0)?'#ddd':'#ffd118', color: 'black'}} type={'primary'}
                                onClick={saveAllIpoFinish}> 모든질문 입력완료 </Button>
                    </EmptyBox>
                }

            </FlexColumn>

            {/* 1단계 서브질문 */}
            <Modal
                // title={ '[ ' + subAskModalData.title + ' 추가 질문 ]'}
                title={ <div style={{ fontSize: '18px' }}> {'[ ' + subAskModalData.title + ' ] 관련 추가 질문 '} </div>}
                visible={modalOpen}
                onCancel={toggle}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding:0}}
                width={1300}
                maskClosable={false}
            >
                <>
                    {/*서브질문으로는 noatationV: '0' or '0_1' 전달 */}
                    {/*openSubAskModal (2 : 2차팝업)용도로 전달중. */}
                    <GrayBox>
                        <OneQuestion idx={0} evidenceFolderFileList={evidenceFolderFileList} openSubAskModal={openSubAskModal}  askQuestion={subAskModalData.askQuestion} notationV={subAskModalData.notationV} fromHistory={subAskModalData.fromHistory} cm={creditMaster} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} saveAll={saveAll}/>
                        {isSaveInProgress &&
                        <EmptyBox style={{width:'630px', marginTop:-30}}>
                            <Spin/>
                        </EmptyBox>
                        }
                    </GrayBox>
                </>
            </Modal>


            {/* 2단계 서브질문 */}
            <Modal
                // title={ '[ ' + subAskModalData.title + ' 추가 질문2 ]'}
                title={ <div style={{ fontSize: '18px' }}> {'[ ' + subAskModalData.title + ' ] 관련 추가 질문2'} </div>}
                visible={modalOpen2}
                onCancel={toggle2}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding:0}}
                width={1300}
                maskClosable={false}
            >
                <>
                    {/*서브질문으로는 noatationV: '0' or '0_1' 전달 */}
                    <GrayBox>
                        <OneQuestion idx={1} evidenceFolderFileList={evidenceFolderFileList} askQuestion={subAsk2ModalData?.askQuestion} notationV={subAsk2ModalData?.notationV} fromHistory={subAsk2ModalData?.fromHistory} cm={creditMaster} cmFieldKv={cmFieldKv} setCmFieldKv={setCmFieldKv} saveAll={saveAll}/>
                        {isSaveInProgress &&
                        <EmptyBox style={{width:'630px', marginTop:-30}}>
                            <Spin/>
                        </EmptyBox>
                        }
                    </GrayBox>
                </>
            </Modal>

        </div>
    )
}

export default StoryTelling;