import { MainHeader } from "./MainPage";
import { useEffect, useState, useRef } from 'react';
import { BoNetUtil, getReturnUrl } from '../common/Util';
import { useRecoilRefresher_UNSTABLE, useRecoilState, useRecoilValue } from "recoil";
import { BoTokenAtom, BACKEND_URL, BoUserIdAtom, BoUserTypeAtom } from "../Atoms";
import { Checkbox } from 'primereact/checkbox';
import { InputNumberOnly, OrangeButton } from "../common/Common";
import { Toast } from 'primereact/toast';
import { useLocation, useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export function SocialAgreePage(props) {
    const [phone, setPhone] = useState("");

    return (
        <div>
            <MainHeader/>
            <br/>
            <NickPhoneInput onChangePhone={setPhone}/>
            <br/>
            <br/>
            <AgreeTerms phone={phone}/>
        </div>
    );
}

function NickPhoneInput(props) {
    // props.onChangePhone
    const [nickName, setNickName] = useState("");
    const [phone, setPhone] = useState("");

    const boUserId = useRecoilValue(BoUserIdAtom);

    useEffect(() => {
        // user info를 가져와본다. 
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, {username: boUserId},
            (resp) => {
                const user = resp.data;
                //console.log(`UserInfo = ${user.username}, ${user.name}, ${user.email}, ${user.userrole}`);
                setNickName(user.nickname || "");
                setPhone(user.phone || "");
            }, 
            (err) => {
                console.error(err);
            }
        );
    }, []);

    // phone 변화를 위쪽에 알려준다. 
    useEffect(() => {
        props.onChangePhone(phone);
    }, [phone]);

    return (
        <div className="text-left px-3">
            <div className="text-lg font-bold">추가 정보 입력</div>
            <br/>
            {/*
            <div className="text-sm text-700 mb-1">닉네임</div>
            <InputText value={nickName} style={{width: "100%"}}/>
            */}
            <div className="text-sm text-700 mb-1 mt-3">휴대전화(선택사항)</div>
            <div className="text-sm text-700 mb-1 mt-1 mb-2">-번호 입력시에 90% 할인 혜택의 대기 순번이 먼저 배정됩니다.</div>
            <InputNumberOnly value={phone} style={{width: "100%"}} onChange={(e) => setPhone(e.value)} placeholder="휴대전화번호를 입력하세요"/>
        </div>
    );
}

function AgreeTerms(props) {
    const [all, setAll] = useState(false);
    const [term, setTerm] = useState(false);
    const [privacy, setPrivacy] = useState(false);
    const [push, setPush] = useState(false);
    const [age, setAge] = useState(false);

    const boUserId = useRecoilValue(BoUserIdAtom);
    //2311 이전 기존코드:오타로 보임. const refreshUserId = useRecoilRefresher_UNSTABLE(BoUrlAtom);  // selector는 cache가 되어 있어서, 변경이 바로 적용안됨. 이것을 불려줘야 refresh됨.
    const refreshUserId = useRecoilRefresher_UNSTABLE(BoUserIdAtom);  //
    const toast = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();
    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const [boUserType, setBoUserType] = useRecoilState(BoUserTypeAtom);
    
    const portal = location.state?.portal;
    const userInfo = location.state?.userInfo;

    useEffect(() => {
        setAll(term === true && privacy === true && push === true && age === true);
    }, [term, privacy, push, age]);

    const selectAll = (e) => {
        setAll(e.checked);
        setTerm(e.checked);
        setPrivacy(e.checked);
        setPush(e.checked);
        setAge(e.checked);        
    }

    const submitInfo = (e) => {
        console.log(`boUserId before=${boUserId}`);
        if (term === true && privacy === true && age === true) {
            BoNetUtil.post_plain(`${BACKEND_URL}/vws/bo/auth/oauth2/${portal}/signin`, userInfo,
                (resp) => {
                    console.dir(resp.data);
                    /*
{
    "username": "6333056e50435fd1b22f4a294b04fe7bsRVkZR8m",
    "reference": "3f4ad1762b911696221a922553e242503Lcov2nl",
    "accessToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MzMzMDU2ZTUwNDM1ZmQxYjIyZjRhMjk0YjA0ZmU3YnNSVmtaUjhtIiwiaWF0IjoxNzAwMjQyNTI4LCJleHAiOjE3MDAyNDYxMjh9.gdRCDbA3N4jaozrIQUzQ24WYmdsw3vnrYhDDEiqmJGM",
    "refreshToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MzMzMDU2ZTUwNDM1ZmQxYjIyZjRhMjk0YjA0ZmU3YnNSVmtaUjhtIiwiZXhwIjoxNzAyMDY5OTE1fQ.XbKuBKA__nTfQT6LNRf5fUt0Re8RCgCMa_2GWatIZs0"
}
                    */

                    // { "accessToken": "XXXX", "refreshToken": "YYYY" }
                    const tpair = { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken};
                    console.dir(tpair);
                    setBoToken(tpair);
                    setBoUserType(portal);
                    console.log(`boUserId after=${boUserId}`);
                    refreshUserId();
                    console.log(`boUserId after refresh=${boUserId}`);  // 안먹음.... ???
                    const newUsername = jwtDecode(tpair?.accessToken)?.sub;
                    // Recoil 도 바로 반영이 안되네????? 그래서 계산해야 함. 
                    console.log(`boUserId calculated=${newUsername}`);
                    const promises = [];
                    const p1 = new Promise((resolve, reject) => {
                        // 일단 여기서는 마케팅을 거부해도, null로 넣는다. 메인에서 다시 물어보기 위해서 
                        let p = null;
                        if (push === true) {
                            p = true;
                        }
                        BoNetUtil.post(`${BACKEND_URL}/vws/bo/user/terms/${newUsername}`, { service: term, personalCollect: privacy, marketing: p, sms: p, email: p, age: age },
                            (resp) => {
                                console.dir(resp.data);
                                BoNetUtil.post(`${BACKEND_URL}/vws/bo/user/info/${newUsername}`, {phone: props.phone},
                                    (resp) => {
                                        resolve(true);
                                    },
                                    (err) => {
                                        console.error(err);
                                        reject(err);
                                    }
                                );
                            },
                            (err) => {
                                console.error(err);
                                reject(err);
                            }
                        );
                    });
                    promises.push(p1);
                    const p2 = new Promise((resolve, reject) => {
                        BoNetUtil.dcolSignInUp(
                            (resp) => {
                                // /api/v1/oauth/bo/signupMerge 
                                // #47 signupMerge를 부르지 않아서 로그인/비로그인 상태 잘 못봄??
                                //BoNetUtil.put_plain(`${BACKEND_URL}/vws/bo/chatvd/users/merge`, null,
                                console.log(`signupMerge signUp=${resp.data.signUp}, old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);

                                BoNetUtil.post(`${BACKEND_URL}/api/v1/oauth/bo/signup/merge`,
                                    {userObjectId: resp.data.userObjectId, prvUserObjectId: resp.data.prvUserObjectId},
                                    resp => {                                
                                        console.log("social userid merged=> %o", resp.data);
                                        //console.dir(resp.data);
                                        //navigate("/");
                                        resolve(resp.data);
                                    },
                                    err => {
                                        console.error(err);
                                        reject(err);
                                    }
                                );
                            },
                            (err) => {
                                console.error(err);
                                console.log(`signupMerge signUp=${resp.data.signUp}, old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);

                                BoNetUtil.post(`${BACKEND_URL}/api/v1/oauth/bo/signup/merge`,
                                    {userObjectId: resp.data.userObjectId, prvUserObjectId: resp.data.prvUserObjectId},
                                    resp => {                                
                                        console.log("social userid merged=>");
                                        console.dir(resp.data);
                                        //navigate("/");
                                        resolve(resp.data);
                                    },
                                    err => {
                                        console.error(err);
                                        reject(err);
                                    }
                                );
                            }
                        );
                    });
                    promises.push(p2);

                    Promise.allSettled(promises).then((results) => {
                        //console.dir(results);  // results는 status 와 value 의 Array임. 
                        // setCounsels(results.filter((item) => item.status === "fulfilled").map((item) => item.value));
                        console.log(`Promises settled all`);
                        let url = getReturnUrl();
                        console.log(`return url = ${url}`);
                        //
                        if (!url || url === "/") {
                            window.location.href = url || "/";
                        } else {
                            //navigate(url, {replace: true});
                            window.location.href = url || "/";
                        }
                    });
                },
                (err) => {
                    console.error(err);
                }
            );

        } else {
            toast.current.show({severity:'error', summary: '안내', detail:'필수항목을 모두 선택해주세요', life: 3000});
        }
    }

    return (
        <div className="px-3 text-left">
            <div className="my-3">
                <Checkbox checked={all} onChange={selectAll}/>
                <label className="px-2 font-bold">전체동의</label>            
            </div>
            <hr/>
            <div className="flex align-items-center my-3">
                <Checkbox checked={term} onChange={(e) => setTerm(e.checked)}/>
                <label className="px-2 text-700">[필수] 이용약관에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/terms")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={privacy} onChange={(e) => setPrivacy(e.checked)}/>
                <label className="px-2 text-700">[필수] 개인정보 수집 및 이용에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2" onClick={(e) => navigate("/privacy")}/>
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={push} onChange={(e) => setPush(e.checked)}/>
                <label className="px-2 text-700">[선택] 정보 안내 수신에 동의</label>
                <i className="pi pi-angle-right ml-auto px-2"/>  {/* TODO: 이건 어디로 링크?? */}
            </div>
            <div className="flex align-items-center my-3">
                <Checkbox checked={age} onChange={(e) => setAge(e.checked)}/>
                <label className="px-2 text-700">[필수] 본인은 만 14세 이상입니다.</label>
                {/*<i className="pi pi-angle-right ml-auto px-2"/>*/}
            </div>
            <br/>
            <OrangeButton label="로그인 완료" onClick={submitInfo} />
            <Toast ref={toast} position="bottom-center"/>
        </div>
    )
}