import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrangeButton, WhiteTopBar} from "../common/Common";
import {FlexColumnCenter} from "../common/DeskComponent";
import {Button, Card, Flex, Select, Space} from "antd";
import useAxios from "../hooks/useAxios";
import {BACKEND_URL} from "../Atoms";
import axios from "axios";
import {EmptyBox} from "../common/B2bComponent";
import {isMobile} from 'react-device-detect';
import {BoNetUtil, isDevEnv} from "../common/Util";



const FeePayment = (props) => {

    const {axiosGet, axiosPost} = useAxios();
    const navigate = useNavigate();

    const [feeAmount, setFeeAmount] = useState();
    const mobilePayForm = useRef(null);

    const [payInfo, setPayInfo] = useState();
    const SERVER_ADDR = BACKEND_URL;//(window.host === 'vws.ai')? 'https://vws.ai': 'https://creditncity-c.com';

    //개발test용 금액 down용도.
    const [divider, setDivider] = useState(isDevEnv()?1000 : 1);



    useEffect(() => {
        firstSearch();
    }, []);

    const firstSearch = async () => {

        if (isMobile) {

            //여기서 호출하면 Payment테이블에 너무 많이 쌓임.
            // let payInfo = await axiosGet('/vws/bo/payment/req/0/mobile');
            // console.log( 'payInfo, divider:', payInfo,  divider);
            //
            // setPayInfo(payInfo);
        }
    }


    const onChangeFeeAmount = async (value) => {
        if (!isMobile) {
            alert('pc결제 점검 중입니다. 휴대폰으로 결제해 주세요.');
            return;
        }

        // caseNo = 0으로 하드코딩 중 => 동일한게 발생할 수 있으므로, 결제완료시 max regdate로 검색해야 함.
        let payInfo = await axiosGet('/vws/bo/payment/req/0/mobile?userDefinedPayment='+ value);
        console.log( 'payInfo, divider:', payInfo,  divider);


        setFeeAmount(value);
        setPayInfo(payInfo);
    }

    const doPay = (e) => {
        if (!isMobile) {
            alert('pc결제 점검 중입니다. 휴대폰으로 결제해 주세요.');
            return;
        }
        if (!feeAmount) {
            alert('결제금액을 먼저 선택해 주세요.');
            return;
        }

        if (!payInfo) {
            alert(' 결제관련 정보수신 중. 잠시후 다시클릭해 주세요');
            return;
        }


        if (isMobile) {
            mobilePayForm.current.action = "https://mobile.inicis.com/smart/payment/";
            mobilePayForm.current.target = "_self";
            mobilePayForm.current.submit();
        }
    }



    return(
        <>
            <div className="">
                <div className="sticky top-0 z-5">
                    <WhiteTopBar  title={'수임료 결제'}/>
                </div>
                <br/>

                <FlexColumnCenter>
                    <Card title={<div style={{ fontSize: 18}}>결제 안내</div>} hoverable="true"
                          style={{width: "80%"}}>
                        <div className="px-3" >

                            <div style={{fontSize: 18, fontWeight: 700}}> 결제하실 수임료를 선택하신 후</div>
                            <div style={{fontSize: 18}}> 결제진행 버튼을 눌러주세요.</div>

                        </div>
                    </Card>


                    <Flex style={{marginTop: 64}}><Space>
                        <div style={{fontSize:16, fontWeight: 700}}> 결제금액 선택 </div>

                        <Select
                            placeholder={'선택해주세요'}
                            style={{ width: 150, fontSize:16 }}
                            value = {feeAmount}
                            onChange={value => onChangeFeeAmount(value)}
                            options={ [//[{ value: 'text', label: 'text' },]
                                { value: 110000, label: '11만원(vat포함)' },
                                { value: 220000, label: '22만원(vat포함)' },
                                { value: 330000, label: '33만원(vat포함)' },
                                { value: 550000, label: '55만원(vat포함)' },
                                { value: 770000, label: '77만원(vat포함)' },
                                { value: 1100000, label: '110만원(vat포함)' },

                            ]}
                        />
                    </Space></Flex>

                    <EmptyBox>
                        <Button  style={{fontSize:16, width:250, height:40, backgroundColor:'#ffd118', color:'black'}} type={'primary'} onClick ={doPay} > 결제진행 </Button>
                    </EmptyBox>

                    <br/><br/><br/><br/><br/><br/>
                    <img style={{width: '70%'}} src={'/assets/logoC.png'}/>

                </FlexColumnCenter>

                <div className="hidden">
                    {/* 결제 Form*/}
                    <form ref={mobilePayForm} method="POST" acceptCharset="EUC-KR">
                        <input type="hidden" name="P_INI_PAYMENT" value={'CARD'}/>

                        <input type="hidden" name="P_MID" value={payInfo?.mid}/>
                        <input type="hidden" name="P_OID" value={payInfo?.oid}/>
                        <input type="hidden" name="P_AMT" value={feeAmount/divider}/>
                        <input type="hidden" name="P_GOODS" value={"채권도시 수임료"}/>
                        <input type="hidden" name="P_UNAME" value={payInfo?.uname}/>
                        <input type="hidden" name="P_MOBILE" value={payInfo?.mobile}/>
                        <input type="hidden" name="P_EMAIL" value={payInfo?.email}/>
                        <input type="hidden" name="P_NEXT_URL" value={SERVER_ADDR + "/cncc/cb/inicis/mobile"}/>
                        <input type="hidden" name="P_NOTI_URL" value={SERVER_ADDR + "/cncc/cb/inicis/vbankmobile"}/>
                        <input type="hidden" name="P_CHARSET" value="utf8"/>
                        <input type="hidden" name="P_RESERVED" value="below1000=Y&vbank_receipt=Y&centerCd=Y"/>
                        <input type="hidden" name="P_NOTI" value={payInfo?.oid}/> {/* 결제 실패시, OID를 내려줘서 여기에 담음 */}
                    </form>
                </div>

            </div>
        </>
    )
}

export default FeePayment;