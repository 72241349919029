import { useEffect, useState } from 'react';
import { InputNumberOnly, WhiteTopBar } from "../common/Common";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { BoUserIdAtom, BACKEND_URL } from '../Atoms';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BoNetUtil } from "../common/Util";
import classNames from 'classnames';
import { useNavigate } from "react-router";
import useAxios from "../hooks/useAxios";

export function EditUserPage(props) {
    const boUserId = useRecoilValue(BoUserIdAtom); 

    const [userInfo, setUserInfo] = useState(null);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, { username: boUserId },
            (resp) => {
                console.log("user/info = %o", resp.data);                
                setUserInfo(resp.data);
            },
            (err) => console.error(err)
        );
    }, [boUserId]);

    return (
        <div className="px-2">
            <WhiteTopBar title="회원정보 수정"/>
            <EditUserContent userInfo={userInfo} />
            <GrayLine/>
            <AgreeMarketingTerms/>
            <GrayLine/>
            <Quit/>
        </div>
    );
}

function GrayLine(props) {
    return (
        <div style={{height: "0.5rem"}} className="surface-100"></div>
    );
}

function EditUserContent(props) {
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    // props.userInfo
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");

    useEffect(() => {
        setEmail(props.userInfo?.email || "");
        setPhone(props.userInfo?.phone || "");
    }, [props.userInfo]);

    const onChangeUserInfo = async (fieldName) => {

        let params = (fieldName==='email')? ({email:email}):({phone:phone});

        if (fieldName === 'email' && !email) {
            alert('변경하실 이메일을 입력해주세요');
            return;
        }
        if (fieldName === 'phone' && !phone) {
            alert('변경하실 휴대전화번호를 입력해주세요');
            return;
        }

        let ret = axiosPut(`/vws/bo/user/info/${props.userInfo.username}/${fieldName}`, null, params);
        if (ret) {
            alert('변경 되었습니다.');
        }
    }

    return (
        <div className="text-left p-3">
            <div className="text-lg font-bold pt-3 pb-3">
                회원정보
            </div> 
            <div>
                <div className="mt-2">
                    <div className="mb-2">이메일</div>
                    <div className="flex">
                        <InputText value={email} onChange={(e) => setEmail(e.value)}
                        className="w-10 mr-1" />
                        <Button label="변경" onClick={() => onChangeUserInfo('email')}
                        className="" style={{width: "5rem"}}/>
                    </div>
                </div>
                <div className="mt-2">
                    <div className="mb-2">휴대전화번호</div>
                    <div className="flex">
                        <InputNumberOnly value={phone} onChange={(e) => setPhone(e.value)}
                        className={classNames("w-10", "mr-1")} />
                        <Button label="변경" onClick={() => onChangeUserInfo('phone')}
                        className="" style={{width: "5rem"}}/>
                    </div>
                </div>
                {/*
                <div className="mt-2">
                        <div className="mb-2">비밀번호</div>
                        <InputText value="*******" disabled
                        className={classNames("w-10", "mr-1")} />
                        <Button label="변경" disabled
                        className={classNames("bg-white")} />
                </div>*/}
            </div>
        </div>
    );
}


function AgreeMarketingTerms(props) {

    const boUserId = useRecoilValue(BoUserIdAtom);
    const [emailAccept, setEmailAccept] = useState(false);
    const [smsAccept, setSmsAccept] = useState(false);
    const [loaded, setLoaded] = useState(false);
    //const [marketingAccept, setMarketingAccept] = useState(false);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, null,
            (resp) => {
                console.log("terms=%o", resp.data);
                setEmailAccept(resp.data.email);
                //setMarketingAccept(resp.data.marketingAccept);
                setSmsAccept(resp.data.sms);
                setLoaded(true);
            },
            (err) => {
                console.error(err);                
            }
        );
    }, []);

    useEffect(() => {
        if (!loaded) return;
        
        const m = emailAccept === true || smsAccept === true;
        BoNetUtil.put(`${BACKEND_URL}/vws/bo/user/terms/${boUserId}`, {email: emailAccept, sms: smsAccept, marketing: m},
            (resp) => {
                console.log("terms updated=%o", resp.data);                
            },
            (err) => {
                console.error(err);                
            }
        );
    }, [smsAccept, emailAccept, loaded]);

    return (
        <div className="text-left p-3">
            <div className="text-lg font-bold pt-3 pb-3">
                광고성 정보 수신 및 활용 동의
            </div> 
            <div className="my-3">
                <Checkbox checked={smsAccept} onChange={(e) => setSmsAccept(e.checked)}/>
                <label className="px-2s"> SMS, MMS 수신 동의</label>            
            </div>
            <div className="my-3">
                <Checkbox checked={emailAccept} onChange={(e) => setEmailAccept(e.checked)}/>
                <label className="px-2s"> 이메일 수신 동의</label>            
            </div>
            <div className="text-m text-700">
                서비스의 중요 안내사항에 대한 정보는 위 수신여부와 관계없이 발송됩니다.
            </div>
        </div>
    );
}

function Quit(props) {
    const navigate = useNavigate();

    return (
        <div className="flex text-left px-2 py-3 cursor-pointer" onClick={() => navigate('/quit')} >
            <span className="text-lg font-bold mx-2">회원탈퇴하기</span>
            <i className="pi pi-chevron-right ml-auto px-2"/>
        </div>
    );
}