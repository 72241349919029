import {forwardRef, useCallback, useImperativeHandle} from "react";
import {useGoogleReCaptcha} from "react-google-recaptcha-v3";

// https://www.npmjs.com/package/react-google-recaptcha-v3#react-hook-usegooglerecaptcha-recommended-approach
const GReCaptchaV3Tokener = forwardRef(({action}, _ref) => {
    const {executeRecaptcha} = useGoogleReCaptcha();

    // Create an event handler so you can call the verification on button click event or form submit
    const handleReCaptchaVerify = useCallback(async () => {
        if (!executeRecaptcha) {
            console.error('Execute Google reCaptcha not yet available');
            return undefined;
        }

        const result = await executeRecaptcha(action ? action : "default");
        // console.log("GReCaptchaV3Tokener for", action, "->", result);
        return  result;
    }, [executeRecaptcha]);

    useImperativeHandle(_ref, () => ({
        getToken: async () => {
            return await handleReCaptchaVerify();
        }
    }));

    return undefined;   // no UI
});

export default GReCaptchaV3Tokener;