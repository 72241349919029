import { useEffect, useState, useRef } from "react";
import { WhiteTopBar } from "../common/Common";
import { BACKEND_URL } from "../Atoms";
import { useRecoilValue } from "recoil";
import { BoNetUtil } from "../common/Util";

export function TermsPage(props) {
    //TODO: 일단은 Old-chat admin에서 읽는다. 
    return (
        <div className="px-2">
            <WhiteTopBar title="이용약관"/>
            <br/>
            <TermsContent/>
        </div>
    )
}

function TermsContent(props) {
    const [content, setContent] = useState("");

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/terms/service`, null,
            (resp) => {
                // 약관은 여러버전이 있음. 그 중 최신거는 제일 위에 있음. 
                if (resp.data.data) {
                    const pid = resp.data.data[0]._id;
                    BoNetUtil.get(`${BACKEND_URL}/api/v1/terms/service/${pid}`, null,
                        (resp) => {
                            setContent(resp.data.data[0].contents);
                        },
                        (err) => {
                            console.error(err);
                        }
                    );
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);
    return (
        <div className="text-left px-2">
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    );
}