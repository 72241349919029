import { useRecoilValue } from "recoil";
import { useEffect, useState } from 'react';
import { BACKEND_URL, BoUserIdAtom } from "../Atoms";
import { OrangeButton, UserPortrait, WhiteTopBar } from "../common/Common";
import { BoNetUtil } from "../common/Util";
import { Avatar } from 'primereact/avatar';
import { InputText } from 'primereact/inputtext';
import { useNavigate } from "react-router-dom";
import useAxios from "../hooks/useAxios";

export function EditProfilePage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title="프로필 수정"/>
            <br/>
            <ProfileContent/>
        </div>
    );
}

function ProfileContent(props) {
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    const boUserId = useRecoilValue(BoUserIdAtom);

    const [userInfo, setUserInfo] = useState(null);
    const [nickName, setNickName] = useState("");
    const navigate = useNavigate();



    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, { username: boUserId },
            (resp) => {
                console.log("user/info")
                console.dir(resp.data);
                setUserInfo(resp.data);
                setNickName(resp.data.nickname);
            },
            (err) => console.error(err)
        );
    }, [boUserId]);

    const onChangeProfile = async () => {

        console.log('nickName:' + nickName)
        let ret = axiosPut(`/vws/bo/user/info/${userInfo.username}/nickname`, null, {nickname:nickName});
        if (ret) {
            //alert('수정되었습니다.');
        } else {
            //alert('수정 중 오류가 발생하였습니다.')
        }

        navigate(-1);
    }

    return (
        <div>
            <UserAvatar userInfo={userInfo}/>
            <br/><br/>
            <div className="text-left p-3">
                <div className="mb-2">닉네임</div>
                <InputText value={nickName} onChange={(e) => setNickName(e.target.value)} className="w-full"/>
                <br/><br/><br/>
                <OrangeButton label="수정완료" onClick={onChangeProfile}/>
            </div>
        </div>
    );
}

function UserAvatar(props) {
    // props.userInfo
    return (
        <div className="w-full text-center">
            { props.userInfo?.imageUrl ?
                <Avatar image={props.userInfo.imageUrl} size="xlarge" shape="circle" style={{width: "7rem", height: "auto"}}/>
                :
                <Avatar label={props.userInfo?.name.substring(0, 1)} size="xlarge" shape="circle" style={{ backgroundColor: '#2196F3', color: '#ffffff', width: "7rem", height: "auto" }}/>
            }
        </div>
    );
}