import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useRecoilState, useRecoilValue } from 'recoil';
import { BoTokenAtom, BACKEND_URL, BoUserIdAtom, BoUserTypeAtom } from '../Atoms';
import { BoNetUtil, getReturnUrl, useEffectOnce } from '../common/Util';
import { Outlet } from "react-router-dom";
import { OrangeButton } from '../common/Common';

export function CallbackLayout(props) {
    useEffect(() => {
        console.log(`window.history.state = ${window.history.state.idx}`);
    }, []);
    return (
        <div className="App">
            <Outlet/>
        </div>
    );
}

export function KakaoCallback(props) {
    return (
        <SocialCallback portal="kakao"/>
    );
}

export function NaverCallback(props) {
    return (
        <SocialCallback portal="naver"/>
    );
}

export function GoogleCallback(props) {
    return (
        <SocialCallback portal="google"/>
    );
}

export function AppleCallback(props) {
    return (
        <SocialCallback portal="apple"/>
    );
}

function SocialCallback(props) {
    // props.portal: kakao, naver, google, apple ... 
    const [searchParams, setSearchParams] = useSearchParams()

    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const [boUserType, setBoUserType] = useRecoilState(BoUserTypeAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);  // 쓰지는 않아도, 불러줘야 recoil selector에 의해 자동으로 local storage에 기록됨 
    const [code, setCode] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [goTerms, setGoTerms] = useState(false);  // 약관동의로 갈 건가?
    const navigate = useNavigate();

    useEffectOnce(() => {
        const _code = searchParams.get('code');
        //const _state = searchParams.get('state');
        const _state = boUserId;  // anonymous id와 social account를 연결하기 위해, BO로 보냄 
        if (code === _code) return;  // 두번 호출되어서 KOE320 에러나는 것 막아야 함
        setCode(_code);

        const redirectUrl = `${window.location.protocol}//${window.location.host}/callback_${props.portal}`;

        BoNetUtil.get_plain(`${BACKEND_URL}/vws/bo/auth/oauth2/${props.portal}/info`, { code: _code, state: _state, redirect_uri: redirectUrl },
            (resp) => {
                const userInfo = resp.data;
                console.log("userInfo = %o", userInfo);
                if (userInfo?.userInfo?.username) {  // 이미 가입한 회원이면
                    BoNetUtil.post_plain(`${BACKEND_URL}/vws/bo/auth/oauth2/${props.portal}/signin`, userInfo,
                        (resp) => {
                            console.dir(resp.data);
                            /*
        {
            "username": "6333056e50435fd1b22f4a294b04fe7bsRVkZR8m",
            "reference": "3f4ad1762b911696221a922553e242503Lcov2nl",
            "accessToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MzMzMDU2ZTUwNDM1ZmQxYjIyZjRhMjk0YjA0ZmU3YnNSVmtaUjhtIiwiaWF0IjoxNzAwMjQyNTI4LCJleHAiOjE3MDAyNDYxMjh9.gdRCDbA3N4jaozrIQUzQ24WYmdsw3vnrYhDDEiqmJGM",
            "refreshToken": "eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiI2MzMzMDU2ZTUwNDM1ZmQxYjIyZjRhMjk0YjA0ZmU3YnNSVmtaUjhtIiwiZXhwIjoxNzAyMDY5OTE1fQ.XbKuBKA__nTfQT6LNRf5fUt0Re8RCgCMa_2GWatIZs0"
        }
                            */
        
                            // { "accessToken": "XXXX", "refreshToken": "YYYY" }
                            const tpair = { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken};
                            console.dir(tpair);
                            setBoToken(tpair);
                            setBoUserType(props.portal);
                            
                            BoNetUtil.dcolSignInUp(
                                (resp) => {
                                    // /api/v1/oauth/bo/signupMerge 
                                    // #47 signupMerge를 부르지 않아서 로그인/비로그인 상태 잘 못봄??
                                    //BoNetUtil.put_plain(`${BACKEND_URL}/vws/bo/chatvd/users/merge`, null,
                                    console.log(`signupMerge signUp=${resp.data.signUp}, old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);

                                    BoNetUtil.post(`${BACKEND_URL}/api/v1/oauth/bo/signup/merge`,
                                        {userObjectId: resp.data.userObjectId, prvUserObjectId: resp.data.prvUserObjectId},
                                        resp => {                                
                                            console.log("social userid merged=>");
                                            console.dir(resp.data);
                                            const url = getReturnUrl();
                                            console.log(`return url = ${url}`);
                                            //navigate(url || "/", {replace: true});
                                            window.location.href = url || "/";
                                        },
                                        err => {
                                            console.error(err);
                                        }
                                    );
                                },
                                (err) => {
                                    console.error(err);
                                    console.log(`signupMerge signUp=${resp.data.signUp}, old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);

                                    BoNetUtil.post(`${BACKEND_URL}/api/v1/oauth/bo/signup/merge`,
                                        {userObjectId: resp.data.userObjectId, prvUserObjectId: resp.data.prvUserObjectId},
                                        resp => {                                
                                            console.log("social userid merged=>");
                                            console.dir(resp.data);
                                            const url = getReturnUrl();
                                            console.log(`return url = ${url}`);
                                            //navigate(url || "/", {replace: true});
                                            window.location.href = url || "/";
                                        },
                                        err => {
                                            console.error(err);
                                        }
                                    );
                                }
                            );
        
                        },
                        (err) => {
                            console.error(err);
                            console.dir(err);
                            if (err.response?.data) {
                                setErrorMsg(`${err.response.data.code}: ${err.response.data.message}`);
                            } else {
                                setErrorMsg(`${err}`);
                            }
                        }
                    );
                } else {  // 신규회원이면 
                    navigate("/agree", {replace: true, state: {portal: props.portal, userInfo: userInfo}});  // callback page로 돌아오지 않게 하기, 돌아오면 A100 에러남. 
                }
            },
            (err) => {
                console.error(err)
                if (err.response?.data) {
                    setErrorMsg(`${err.response.data.code}: ${err.response.data.message}`);
                } else {
                    setErrorMsg(`${err}`);
                }
            }
        );
    }, []);

    return (
        <div>
            { errorMsg ? <ShowErrorMsg message={errorMsg}/> : <ShowOkMsg/> }
        </div>
    );
}

function ShowOkMsg(props) {
    return (
        <div className="p-3">
            <div>
                <br/><br/><br/><br/><br/><br/>
                    인증에 성공했습니다.
                <br/><br/>
            </div>            
        </div>
    );
}

function ShowErrorMsg(props) {
    // props.message
    return (
        <div className="p-3">
            <div>
                <br/><br/><br/><br/><br/><br/>
                {props.message}
                <br/><br/>
            </div>
            <OrangeButton label="돌아가기" onClick={() => window.history.back()}/>
        </div>
    );
}

// 약관동의했는지 확인해서, 안했으면 약관동의 페이지로 넘어감 
function checkTermsAgreed(props) {
    // props.boUrl: url prefix
    // props.username: 
}