import React, {useState, useEffect} from "react";
import {Button, Space, Input} from "antd";
import {Flex, FlexCenter, FlexColumn, FlexColumnCenter, TitleBox, GrayDiv, BlackDiv} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import B2bLayout from "./B2bLayout";
import axios from "axios";
import {BACKEND_URL, BoTokenAtom, BoUserIdAtom, BoUserTypeAtom, UserObjectIdAtom} from "../../Atoms";
import {useRecoilState, useRecoilValue} from "recoil";
import useAxios from "../../hooks/useAxios";


const B2bLoginLanding = (props) => {

    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const boUserId = useRecoilValue(BoUserIdAtom);                        //위 boToken에서 파생.
    //boUserType에 'b2b' 추가 시도 중.
    const [boUserType, setBoUserType]  = useRecoilState(BoUserTypeAtom);  //anonymous  google kakao naver

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();


    const navigate = useNavigate();
    const [emailLoginRequest, setEmailLoginRequest] = useState({bizType:'b2b'});



    return (
        <B2bLayout title={'채권도시 기업회원 로그인'}>

            <TitleBox style={{marginTop:70}}> 기업 채권관리의 모든 것 </TitleBox>

            <BlackDiv> 회수율 / 편리성 / 비용</BlackDiv>
            <br/>
            <BlackDiv> 어디서 어떤 견적을 받고 구상해봐도 </BlackDiv>
            <BlackDiv> 모든 면에서 따라올 서비스가 없습니다. </BlackDiv>
            <br/>
            <BlackDiv> 스트레스, 비용, 시간, 인력, 기회비용 등 불필요한 낭비를 줄이시고</BlackDiv>
            <BlackDiv> 본업에 집중하시어 매출과 이익, 회사의 기업가치를 올리시길 바랍니다.</BlackDiv>

            <br/>
            <br/>
            <br/>

            <GrayDiv> 기업회원 서비스를 이용할 경우,</GrayDiv>
            <GrayDiv> 회사 이메일로 가입/로그인하세요. </GrayDiv>


            <Button onClick={()=> {navigate('/b2bLogin');}} style={{marginTop:20, width:300}}> 이메일로 계속하기 </Button>

            <FlexCenter style={{marginTop:30}}>
                <GrayDiv> 계정을 잊으셨나요? <b>계정 찾기</b> </GrayDiv>
            </FlexCenter>

        </B2bLayout>
    )
}

export default B2bLoginLanding;