import { useEffect, useState } from 'react';
import { MainHeader } from "./MainPage";
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { OrangeButton } from '../common/Common';
import { useNavigate, useParams } from 'react-router-dom';
import { BoNetUtil, isDevEnv } from '../common/Util';
import { BACKEND_URL, UserObjectIdAtom } from '../Atoms';
import { useRecoilValue } from 'recoil';
import {isMobile} from 'react-device-detect';

export function CreditorPage(props) {
    const userObjectId = useRecoilValue(UserObjectIdAtom);

    const [userChatState, setUserChatState] = useState(null);  // data 아래의 항목임. 
    const [caseInfo, setCaseInfo] = useState(null);
    const [showInvalid, setShowInvalid] = useState(false);
    const { chatId } = useParams();

    useEffect(() => {
        console.log(`Get existing userChat ${chatId}`);
        // resp: {"userChatObjectId":"654b9cf1e88ae696d7d1aaab"}
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${chatId}`, null,
            (resp) => {
                // 다른 사용자 것이 아닌지 체크한다. 
                if (resp.data.data[0].userObjectId !== userObjectId) {
                    setShowInvalid(true);
                } else {
                    setUserChatState(resp.data.data[0]);
                    // case 번호 얻기 
                    BoNetUtil.get(`${BACKEND_URL}/vws/bo/case/info`, {chatObjectId: chatId},
                        (resp) => {
                            setCaseInfo(resp.data);
                            console.log(`Case Info = caseNo=${resp.data.caseNo}`);
                        },
                        (err) => console.error(err)
                    );
                    //console.log("ChatState=");
                    //console.dir(resp.data);
                }
            },
            (err) => {
                console.error(err);
                setShowInvalid(true);
            }   
        );        
    }, [chatId]);

    return (
        <div>
            { !!showInvalid 
            ? <div className="text-center font-bold">잘못된 상담정보입니다.</div>
            :
            <div>
                <MainHeader/>
                <GrayLine/>
                { !isMobile &&
                <div>pc결제 점검 중입니다. 여기서부터 휴대폰으로 진행해 주세요.</div>
                }
                <CreditorForm userChatState={userChatState} caseInfo={caseInfo}/>
            </div>
            }
        </div>
    );
}

function GrayLine(props) {
    return (
        <div style={{height: "0.5rem"}} className="surface-100"></div>
    );
}

/*
채권자 정보

1. 개인/개인사업자 - 본인   = PM
2. 개인/개인사업자 - 대리인  = PR
3. 개인/개인사업자 - 미성년  = PB
4. 법인   = CO
5. 외국인 - 성인  = FM
6. 외국인 - 미성년  = FB
*/

function CreditorForm(props) {
    // props.userChatState
    // props.caseInfo

    const [mode, setMode] = useState(null);  // 위의 PM, PR 등 참고 
    const navigate = useNavigate();
    const [initialData, setInitialData] = useState({
        creditorType: 1,  // 채권자 구분 1: 개인, 2: 개인사업자, 3: 법인
        isLegalAge: true,  // 성인여부
        isRepresent: false,  // 본인 or 대리인?
        representName: "", // 대리인 이름
        representPhone: "",  // 대리인 핸드폰 번호
        representBirthday: "",  // 대리인 생년월일 (주민번호 앞자리)
        representGender: "",  // 대리인 성별 (주민번호 뒷자리 중 맨 앞 숫자)
        representDocument: "",  // 대리인 증명서류
        creditorName: "",  // 채권자 이름 
        creditorPhone: "",   // 채권자 핸드폰 번호
        creditorBirthday: "",  // 채권자 생년월일
        creditorGender: "", // 채권자 성별 (주민번호 뒷자리 중 맨 앞 숫자)
        creditorVerify: false, // 채권자 핸드폰 인증 여부
        representVerify: false,  // 대리인 핸드폰 인증 여부
        address: "",  // 우편물 수령 주소
        addressDetail: "",  // 우편물 수령 주소 상세
        isOverseas: "",  // 해외 거주지 여부
        corporateName: "",  // 법인명
        corporateNo: "",  // 사업자 등록번호
        corpLicense: "",  // 법인 인증 (사업자 등록증)
        corpAddress: "",  // 법인 본점 주소
        corpAddressDetail: "",  // 법인 본점 주소 상세
        corpRepresentName: "", // 법인 대표자 이름
        corpRepresentBirthday: "",  // 법인 대표자 생년월일
        corpRepresentGender: "",  // 법인 대표자 성별 (주민번호 뒷자리 중 맨 앞 숫자)
        corpRepresentVerify: false,  // 법인 대표자 인증 여부
        creditorEmail: "",  // 채권자 이메일 주소
        creditorBankAccount: "",  // 채권자 계좌번호
        creditorBank: "" // 채권자 은행명
    });
    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            console.log("validate");
            console.dir(data);
            if (!data.creditorType) {
                errors.creditorType = "채권자 구분을 선택하세요";
            }
            if (!data.isRepresent) {
                errors.isRepresent = "채권자와의 관계를 선택하세요";
            }
            if (mode === "PM" && !data.creditorName) {
                errors.creditorName = "채권자 이름을 입력하세요";
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log("formik onSubmit");
            console.dir(data);
            // upload data
            const submitData = {};
            for (const key of Object.keys(data)) {
                if (data[key] && typeof data[key] === "object" && "code" in data[key]) {
                    submitData[key] = data[key].code;
                } else {
                    submitData[key] = data[key];
                }
            }
            console.dir(submitData);
            if (!props.caseInfo) return;
            BoNetUtil.post(`${BACKEND_URL}/vws/bo/case/${props.caseInfo.caseNo}/prepayment/creditor`, submitData,
                (resp) => {
                    console.log(resp.data);
                    navigate(`/debtor/${props.userChatState._id}`);
                },
                (err) => console.error(err)
            );
        }
    });
    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);  // !! 는 truethy, falsey 값을 true, false로 정규화함. 
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    }
    const creditorTypes = [
        { name: "개인/개인사업자", code: 1 },
        { name: "법인", code: 2 },
        { name: "외국인", code: 3 }  
    ];
    const representTypes = [
        { name: "본인", code: false },
        { name: "대리인", code: true }        
    ]
    useEffect(() => {
        if (formik.values.creditorType?.code === 1) { // 개인
            if (formik.values.isRepresent?.code === false && formik.values.isLegalAge === true) setMode("PM");
            else if (formik.values.isRepresent?.code === true && formik.values.isLegalAge === true) setMode("PR");
            else if (formik.values.isLegalAge === false) setMode("PB");
            else setMode(null);
        } else if (formik.values.creditorType?.code === 2) {  // 법인
            setMode("CO");
        } else if (formik.values.creditorType?.code === 3) {  // 외국인
            if (formik.values.isLegalAge === true) setMode("FM");
            else if (formik.values.isLegalAge === false) setMode("FB");
            else setMode(null);
        } else {
            setMode(null);
        }
    }, [formik.values.creditorType, formik.values.isLegalAge, formik.values.isRepresent]);
    return (
        <div className="text-left p-3">         
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <div className="mt-2">
                    <div className="mb-2">채권자 구분</div>
                    <Dropdown value={formik.values.creditorType} onChange={(e) => formik.setFieldValue("creditorType", e.value)} options={creditorTypes} optionLabel="name"
                        placeholder="채권자 구분을 선택하세요" 
                        className={classNames("w-full", {"p-invalid": isFormFieldInvalid("creditorType")})} />
                    {getFormErrorMessage('creditorType')}
                </div>
                <div className="flex mx-0">
                    <ToggleButton checked={formik.values.isLegalAge} onChange={(e) => formik.setFieldValue("isLegalAge", e.value)} 
                        onLabel="만 19세 이상" offLabel="만 19세 이상" className="flex-1 mr-2"/>
                    <ToggleButton checked={!formik.values.isLegalAge} onChange={(e) => formik.setFieldValue("isLegalAge", !e.value)} 
                        onLabel="만 19세 미만" offLabel="만 19세 미만" className="flex-1 ml-2"/>
                </div>
                <div className="mt-3">
                    <div className="mb-2">채권자와의 관계</div>
                    <Dropdown value={formik.values.isRepresent} onChange={(e) => formik.setFieldValue("isRepresent", e.value)} options={representTypes} optionLabel="name"
                        placeholder="채권자와의 관계를 선택하세요" 
                        className={classNames("w-full", {"p-invalid": isFormFieldInvalid("isRepresent")})} />
                    {getFormErrorMessage('isRepresent')}
                </div>
                { mode === "PM" &&
                <div className="mt-3">
                    <div className="mb-2">채권자 이름</div>
                    <InputText value={formik.values.creditorName} onChange={(e) => formik.setFieldValue("creditorName", e.target.value)}
                        placeholder="텍스트 입력" 
                        className={classNames("w-full", {"p-invalid": isFormFieldInvalid("creditorName")})} />
                    {getFormErrorMessage('creditorName')}
                </div>
                }
                { mode === "PM" &&
                <div className="mt-3">
                    <div className="mb-2">채권자 주민번호상 앞자리 및 성별</div>
                    <InputText value={formik.values.creditorBirthday} onChange={(e) => formik.setFieldValue("creditorBirthday", e.target.value)}
                        placeholder="생년월일 6자리" 
                        className={classNames("w-full", {"p-invalid": isFormFieldInvalid("creditorBirthday")})} />
                    {getFormErrorMessage('creditorBirthday')}
                </div>
                }

            </form>   
            <OrangeButton label={`다음으로 ${isDevEnv() ? mode : ""}`} onClick={formik.handleSubmit} />            
        </div>
    );
}