import React, {useState, useEffect} from "react";
import {Flex, Title2, Title3, ContentFlex, B2bTable, BlackButton, EmptyBox} from "../../../common/B2bComponent";
import  {Button, Input, Select, DatePicker, Modal, message} from "antd";
import useAxios from "../../../hooks/useAxios";
import useModal from "../../../hooks/useModal";
import dayjs from 'dayjs';
import locale from 'antd/es/date-picker/locale/ko_KR';
import {PlusOutlined, UploadOutlined} from "@ant-design/icons";
import PublicFileUploader from "../../../components/PublicFileUploader";
import B2bLayout from "../B2bLayout";
const _ = require('lodash');

//질문테이블 1개.: 채권목록에서도 readMode로 사용.
export const AskTable =({readMode, _id, title, ask, askSub, initDb, propsCreditList, onSubSave}) => {

    console.log('readMode', readMode);

    //propsCreditList와 현재화면의 creditList를 별도로 관리.
    // 현재화면의 creditList에는 빈 데이타(initDb:sampleCl)데이터가 한개 혹은 여러개 들어갈 수 있음: 사용자 입력중 데이터. TODO 서브질문도: creditList로 관리되는지 체크필요.

    //저장은 creditList로 수행.
    const [creditList, setCreditList] = useState(propsCreditList.length > 0 ? propsCreditList : [{..._.cloneDeep(initDb), creditType:_id}]);

    const [dataSource, setDataSource] = useState([]);

    const COL_NAME = (_id <= 3)? 'creditColumns':'assetColumns';
    const SUB_QUESTION_FLAG =  (_id > 3)? true:false;
    const ASSET_SUB_EXIST_FLAG = (SUB_QUESTION_FLAG)? false : (_id <= 3 && ask.filter((item) => item.title.indexOf('관련 재산') >= 0).length > 0);

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    ////// 서브질문 모달 관련 //////////
    const [modalOpen, setModalOpen, selected, setSelected, setModalState, toggle] = useModal();
    const [subTargetCreditList, setSubTargetCreditList] = useState([]);
    console.log('subTargetCreditList.length', subTargetCreditList.length);



    useEffect(() => {

        // let oneDs =  convertDbData2Ds(dbData, 'creditColumns');
        let convertedDs =  convertDbData2DataSource(creditList);

        console.log('##convertedDs', convertedDs );
        setDataSource(convertedDs);

    // }, [dbData]);
    }, [creditList]);


    const askObjByDbField = (dbField) => {
        return ask.find((item) => item.name === dbField); //채권원장에서는 name->cmField로 면경필요.
    }

    const convertDbData2DataSource = (dbRows) => {
        console.log('dbRows.length', dbRows.length, dbRows);

        let ds = [];


        // for (let dbRow of dbRows) {
        for (let i = 0; i < dbRows.length; i++) {
            const dbRow = dbRows[i];

            //한줄의 데이터.
            const cols = dbRow[COL_NAME]; //'creditColumns']; //list 임.
            console.log('dbRow cols:', cols);

            //dbField= name(cmField) 으로 title을 찾아서, title을 출력해야 함.
            let oneDs = {_id:dbRow._id, departName:dbRow.departName, key: i};

            for (let col of cols) {

                //dbField: 채무자이름 (dbField)
                //askTitle: 채무자명 (askTitle)
                const dbField = col.key;
                const askColObj = askObjByDbField(dbField);
                const askTitle = askColObj?.title;

                if (askTitle) {
                    //console.log('## dbField, askTitle, COL:', dbField, askTitle, col);
                    oneDs = {
                        ...oneDs,
                        [askTitle]: col.value, //db값. : 오류.
                        askObj: {...oneDs.askObj, [askTitle]:askColObj}, //참조데이터: ask ColumnQ Object전체 삽입.=> askObj[title]로 접근.
                    };
                }
            }

            //관련재산은 별도 추가. (db 'creditColumns' 에 없으므로)
            if (ASSET_SUB_EXIST_FLAG) {
                const askTitle = ask.find((item) => item.title.indexOf('관련 재산') >= 0).title;
                oneDs = {
                    ...oneDs,
                    [askTitle]: readMode?'상세 내역':'상세 등록',
                };
            }

            //채권목록용으로 진행상태 표시.
            if (readMode && !SUB_QUESTION_FLAG) {
                oneDs = {
                    ...oneDs,
                    status:dbRow.status?.replaceAll('_', ' '),
                };
            }

            console.log('oneDs', oneDs);
            ds.push(oneDs);
        }
        console.log('Ds:',ds);

        return ds;
    }

    const columns =
        [{title:'No', key:'_id', dataIndex:'_id', sorter:(a,b)=>a._id-b._id, render: (text) => <div> {text? text : ''} </div>},
         {title:'부서명', key:'departName', dataIndex:'departName', sorter:(a,b)=> a.departName.localeCompare(b.departName), render: (text) => <div> {text? text : ''} </div>}
        ].concat( //No는 이라인에서 출력.
            ask.map( item => ({
                title: item.title,
                key: item.title,
                dataIndex: item.title,

                // dbField: item.name, //or cmField
                // fieldType: item.답변유형,
                // placeHolder: item.항목입력지침
                render: (text, record, index) => {
                    const askObj = (record.askObj)?record.askObj[item.title] : undefined;

                    if (askObj?.답변유형 !== 'file' && ASSET_SUB_EXIST_FLAG && text && (text?.indexOf('상세 등록') === 0 || text?.indexOf('상세 내역') === 0)) {
                        return <Button size={text==='상세 내역'?'small':'normal'} onClick={() => onSelectSubQuestion(index)}> {text} </Button>;

                    } else {

                      if (readMode) {
                          if (askObj?.답변유형 !== 'file') {
                          //     let askTitle = item.title;
                          //     let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);
                          //     const colIndex = findColIndex(creditList[index][COL_NAME], dbField);
                          //     return <div>{creditList[index][COL_NAME][colIndex]['originalFilename']}</div>;
                          // }
                            return <div>{text}</div>;
                          }
                      }

                      switch(askObj?.답변유형) {
                          case 'number':
                              return <Input name={item.title} type={'number'} style={{width:130}} placeholder={askObj.항목입력지침} //value={creditList[index][item.title]}
                                            value={text?text:''} onChange={(e)=>handleInputChange(index, e)} />;

                          case 'selectBox':
                              return <Select
                                        placeholder={askObj.항목입력지침?askObj.항목입력지침:'==선택=='} //value={creditList[index][item.title]}
                                        value={text}
                                        onChange = {value => handleSelectChange(index, item.title, value)}
                                        options={
                                            askObj.selectBoxQs.map((item) => ({value:item}) )
                                        }
                                     />;

                          case 'text':
                              return <Input name={item.title}  type={'text'} placeholder={askObj.항목입력지침} //value={creditList[index][item.title]}
                                            value={text} onChange={(e)=>handleInputChange(index, e)}/>;


                          case 'date':
                              return <DatePicker name={item.title} locale={locale}   // value={creditList[index][item.title]? moment(creditList[index][item.title]):''} //defaultValue={moment()}
                                        value={ (text)? dayjs(text):'' }
                                        onChange = {(date, dateString) => handleDatePickerChange(index, item.title, date, dateString)}
                                     />

                          case 'file':
                              let askTitle = item.title;
                              let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);
                              const colIndex = findColIndex( creditList[index][COL_NAME], dbField );
                              // console.log('File:', index, creditList[index][COL_NAME][colIndex]['originalFilename']);
                              // return <PrivateFileUploader value={creditList[index][COL_NAME][colIndex]['originalFilename']} onChange={(originalFilename, uploadedFilename) => handleFileChange(index, item.title, originalFilename, uploadedFilename)}/>
                              return <PublicFileUploader downloadOnly={readMode?true:false} downloadName={askTitle + record._id} imageUrl={creditList[index][COL_NAME][colIndex]['value']['url']} onChange={(returnUrl) => handleFileChange(index, item.title, returnUrl)}/>

                          default:
                              return <div>{text}</div>;
                      }

                    }
                }

            }))
        ); //end concat.

    //채권목록 전용 상태표시.
    if(readMode) {
        columns.push({title: '진행상태', key: 'status', dataIndex: 'status', render: (text) => <div> {text ? text : ''} </div>});
    }

    // console.log('columns', columns);
    console.log('creditList', creditList);
    console.log('dataSource', dataSource);


    const findDbFieldByAskTitle = (askObj, askTitle) => {
        const matchingAskObj = askObj[askTitle];
        return matchingAskObj.name;  //원장에서는 cmField 이용필요.
    }

    const findColIndex = (cols, name) => {
        for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            //console.log('col[key]', col, col['key'], name);
            if (col['key'] === name) {
                return i;
            }
        }

        console.log('### ERR findColIndex');
        return -1;
    }

    const handleInputChange = (index, e) => {
        console.log('handleInput', index, e.target.name, e.target.value);
        console.log('##BEFORE1: ', creditList);

        // askTitle: 채무자명 (askTitle) = e.target.name
        // dbField: 채무자이름 (dbField)
        let askTitle = e.target.name;
        let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);

        //_id가 최초엔 0이므로 index이용.
        const updatedList = [...creditList];
        const colIndex = findColIndex( updatedList[index][COL_NAME], dbField );
        console.log('colIndex', colIndex, updatedList[index][COL_NAME]);

        console.log('##BEFORE2: ', updatedList);
        updatedList[index][COL_NAME][colIndex]['value'] = e.target.value; //[0].creditColumns[0].value= value.
        console.log('##AFTER: ', updatedList);

        //한글깨지는 오류 있어서 여기서는 creditList 안거치고. 직접 수정./////////////////////
        //한글 오류 setCreditList(updatedList);
        let convertedDs =  convertDbData2DataSource(updatedList);
        console.log('##handleInputChange', convertedDs );
        setDataSource(convertedDs);
    }

    const handleSelectChange = (index, name, value) => {
        //console.log('handleSelect', name, value);

        // askTitle: 채무자명 (askTitle) = e.target.name
        // dbField: 채무자이름 (dbField)
        let askTitle = name;
        let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);

        const updatedList = [...creditList];
        const colIndex = findColIndex( updatedList[index][COL_NAME], dbField );

        updatedList[index][COL_NAME][colIndex]['value'] = value;

        setCreditList(updatedList);
    }

    const handleDatePickerChange = (index, title, date, dateString) => {
        console.log('handleDatePickerChange', title,  dateString, date);
        // askTitle: 채무자명 (askTitle) = e.target.name
        // dbField: 채무자이름 (dbField)
        let askTitle = title;
        let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);

        const updatedList = [...creditList];
        const colIndex = findColIndex( updatedList[index][COL_NAME], dbField );

        updatedList[index][COL_NAME][colIndex]['value'] = dateString;

        setCreditList(updatedList);
    }

    const handleFileChange = (index, title, returnUrl) => {
        console.log('handleFileChange', title,   returnUrl);
        // askTitle: 채무자명 (askTitle) = e.target.name
        // dbField: 채무자이름 (dbField)
        let askTitle = title;
        let dbField = findDbFieldByAskTitle(dataSource[index].askObj, askTitle);

        const updatedList = [...creditList];
        const colIndex = findColIndex( updatedList[index][COL_NAME], dbField );

        // updatedList[index][COL_NAME][colIndex]['originalFilename'] = originalFilename;
        updatedList[index][COL_NAME][colIndex]['value']['url'] = returnUrl;

        setCreditList(updatedList);
    }


    const onAddCredit = () => {
        //let newInitDb = {...initDb};
        const newInitDb = {..._.cloneDeep(initDb), creditType:_id };

        const updatedList = [...creditList, newInitDb];
        setCreditList(updatedList);
    }

    //askObj의 키 = askTitle과 같음.  askObj = {akey: { title:akey,  name:'dbfieldName' }, bkey:{ title:bkey,  name:'bname' }   }
    const findAskTitleByDbField = (askObj, dbField) => {
        const foundKey = Object.keys(askObj).find(key => askObj[key].name === dbField); //원장에서는 cmField활용필요.
        return foundKey;
    }

    const onSelectSubQuestion = (index) => {
        //선택된 creditList만 넘기기.
        setSubTargetCreditList(creditList[index]);

        toggle();
    }


    const onSubQuestionSave = () => {
        console.log('서브질문 결과를 메인에 반영: 메인에서 수행 중. creditList=', creditList);
        //아무 작업없이 전달받은 creditList 1개에 반영중 임.
        toggle();
    }

    const onSave = async() => {

        // if ( SUB_QUESTION_FLAG ) { //서브질문 처리.
        //     console.log('서브질문 결과를 메인에 반영: 메인에서 수행 중. ');
        //     toggle();
        //
        // }else { //1,2,3 질문.

        //validation:
        for (let i = 0; i < creditList.length; i++) {
            const creditColumns = creditList[i].creditColumns;
            for (const col of creditColumns) {
                if (!col.value) {
                    let askTitle = findAskTitleByDbField(dataSource[i].askObj, col.key);
                    console.log('colKey', col.key);
                    if (askTitle) { //db에 미존재해도 '고객title'이 존재하는 항목만 입력필요.
                        if (col._id) {
                            alert((col._id) + '번 채권의 "' + askTitle + '" 항목을 입력해주세요.');
                        } else {
                            alert((i + 1) + '번째 채권의 "' + askTitle + '" 항목을 입력해주세요.');
                        }
                        return; //추가 입력 필요.
                    }
                }
            }
        }

        //save : 2401-부서대표 idx에 저장하도록 수정.
        let retInt = await axiosPost('/api/b2b/creditList/creditLists', creditList);
        if (retInt > 0) {
            // alert('저장되었습니다.')
            messageApi.info('저장되었습니다. 새로고침 해주세요.');

        } else {
            alert('저장 중 오류가 발생하였습니다.')
        }


    }


    if ( dataSource.length === 0) {
        console.log('dataSource: [] RETURN;');
        return <></>;
    }
    return (
        <>
            {contextHolder}

            {title &&
                <Title3> {title} </Title3>
            }

            <B2bTable dataSource={dataSource} columns={columns} size={'small'} pagination={false}/>

            {!readMode && COL_NAME === 'creditColumns' &&
            <BlackButton icon={<PlusOutlined/>} style={{marginTop: 10, marginBottom: -10}} onClick={onAddCredit}> 채권 추가 </BlackButton>
            }

            {!SUB_QUESTION_FLAG && !readMode &&
            <EmptyBox>
                <BlackButton style={{marginTop:-20, marginBottom: 20}} onClick={onSave}> 저 장 </BlackButton>
            </EmptyBox>
            }

            {SUB_QUESTION_FLAG &&
            <EmptyBox>
                <BlackButton onClick={ onSubSave }> 확인 </BlackButton>
            </EmptyBox>
            }

            <Modal
                title={'질문 상세 입력'}
                visible={modalOpen}
                onCancel={toggle}
                footer={null}
                destroyOnClose={true}
                getContainer={false}
                bodyStyle={{padding: 0}}
                width={1200}
                maskClosable={false}
            >
                <AskTable readMode={readMode} _id={4}  ask={askSub} propsCreditList={[subTargetCreditList]} onSubSave={onSubQuestionSave}/>

            </Modal>
        </>
    )
}



const CreditUnitAdd = (props) => {

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    const [initDb, setInitDb] = useState();
    const [askList, setAskList] = useState([]);
    const [creidtListAll, setCreditListAll] = useState([]);

    const ask1 = askList.find((item) => item._id === 1)?.표질문;
    const ask2 = askList.find((item) => item._id === 2)?.표질문;
    const ask3 = askList.find((item) => item._id === 3)?.표질문;
    const askSub = askList.find((item) => item._id === 4)?.표질문; //현재 Sub 1개 -> 향후 Sub1, Sub2, Sub3로 확장가능.
    //console.log('ask1', ask1);

    const creditList1 = creidtListAll.filter((item) => item.creditType === 1);//법인사업자 채권
    const creditList2 = creidtListAll.filter((item) => item.creditType === 2);//개인사업자 채권
    const creditList3 = creidtListAll.filter((item) => item.creditType === 3);//개인 채권


    useEffect(() => {
        // console.log('단위등록 - user Effect:[]');
        firstSearch();
    },[])

    const firstSearch = async() => {
        //db구조=initData
        let initDb = await axiosGet('/api/b2b/creditList/dbStructure');

        //입력된 고객질문들
        let list = await axiosGet('/api/b2b/creditList/askQuestions');

        let creditList = await axiosGet('/api/b2b/creditList/creditLists');

        // console.log(list);
        setInitDb(initDb);
        if (list) {
            setAskList(list);
        }
        if (creditList) {
            setCreditListAll(creditList);
        }

    }

    if (!initDb) return null;

    return(
        // <B2bLayout showLNB={true} >

            <ContentFlex>
                <Title2> 채권등록</Title2>

                {ask1 &&
                    <AskTable _id={1} title={'1. 법인사업자에 관하여 올려주세요.'} ask={ask1} askSub={askSub} propsCreditList={creditList1} initDb={initDb}/>
                }

                {ask2 &&
                    <AskTable _id={2} title={'2. 개인사업자에 관하여 올려주세요.'} ask={ask2} askSub={askSub} propsCreditList={creditList2} initDb={initDb}/>
                }

                {ask3 &&
                    <AskTable _id={3} title={'3. 개인에 관한 채권을 올려주세요.'} ask={ask3} askSub={askSub} propsCreditList={creditList3} initDb={initDb}/>
                }

            </ContentFlex>

        // </B2bLayout>

    )
}


export default CreditUnitAdd;