import { useEffect, useState, useRef } from "react";
import { WhiteTopBar } from "../common/Common";
import { BACKEND_URL } from "../Atoms";
import { useRecoilValue } from "recoil";
import { BoNetUtil } from "../common/Util";
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import classnames from 'classnames';

export function NewsPage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title="공지사항"/>
            <br/>
            <NewsContent/>
        </div>
    );
}

function NewsContent(props) {

    const [data, setData] = useState([]);
    const [widgets, setWidgets] = useState([]);

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/notices`, {type: "notice", isActive: true},
            (resp) => {
                setData(resp.data.data);
            },
            (err) => console.error(err)
        );
    }, []);

    const newsHeaderTemplate = (data) => {
        //console.dir(data);
        return (
            <div className="mx-2">
                <div className="text-sm text-500 font-normal text-left">{data.data.createdAt?.split('T')[0]}</div>
                <div className="flex-grow-1 text-sm text-left">{data.data.title}</div>
            </div>
        );
    }

    useEffect(() => {
        const w = [];
        data.map((it, idx) => {
            w.push(
                <AccordionTab headerTemplate={newsHeaderTemplate} data={it} key={idx}>
                    <div dangerouslySetInnerHTML={{__html: it.contents}}></div>
                </AccordionTab>
            );
        });
        setWidgets(w);
    }, [data]);

    return (
        <Accordion className="faq-accordion" collapseIcon={<i className="pi pi-angle-down text-600"/>} expandIcon={<i className="pi pi-angle-right text-600"/>}>
            {widgets}
        </Accordion >
    );
}