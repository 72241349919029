import { WhiteTopBar } from "../common/Common";

export function DisclaimerPage(props) {
    //TODO: 실제 면책공고 내용 가져와야 함. 
    return (
        <div className="px-2">
            <WhiteTopBar title="면책공고"/>
            <br/>
            <DisclaimerContent/>
        </div>
    )
}

function DisclaimerContent(props) {
    return (
        <div className="text-left px-2">
            <div className="text-sm text-700">
                이 웹사이트에 실린 내용은 {(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시 법률사무소'}의 서비스 이용자의 편의 및 이해를 돕기 위한 것일 뿐이며 어떤 특정한 사안에 관한 법률적 자문 또는 해석을 위해 제공 가능한 증빙자료가 되지 못합니다.
                사건의 구체적 사실관계와 정황 등에 따라 법에 입각한 해석이 상이할 수 있으므로, 법률사무소에 구체적인 법률적 자문을 구하지 않고 어떠한 행위를 하지 마시기 바랍니다.
                {(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시 법률사무소'} 사이트에 실려있는 내용에 근거하여 한 어떠한 행동에 대해서도 {(window.location?.host.startsWith('2ai'))?'채권 2AI':'채권도시 법률사무소'}는 책임을 지지 않음을 알려드립니다.
            </div>
            <br/>
            <div className="text-sm text-700">
                시행일자: 2023.11.17
            </div>
        </div>
    );
}