import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {OrangeButton, WhiteTopBar} from "../common/Common";
import {FlexColumnCenter} from "../common/DeskComponent";
import {Card} from "antd";

const DebtorChatLanding = (props) => {

    const { chatId } = useParams();
    const navigate = useNavigate();


    useEffect(() => {

        //채무자가 채팅을 하다가 백으로 들어온 경우는 제외.
        if (!chatId.startsWith('saved')) {
            navigate(`/dca/${chatId}`);
        }


    }, []);


    return(
        <>
            {chatId.startsWith('saved') &&
                <div className="">
                    <div className="sticky top-0 z-5">
                        <WhiteTopBar debtor={true} noBackButton={true} title={'임시저장 완료'}/>
                    </div>

                    <br/><br/><br/><br/><br/>
                    <FlexColumnCenter>
                        <Card title={<div style={{fontSize: 18}}>안 내</div>} hoverable="true"
                              style={{fontSize: 18, width: "90%"}}>
                            <div className="px-3">
                                <br/>
                                <div className="text-lg"> 입력하신 내용이 저장되었습니다.</div>
                                <div className="text-lg"> 입력완료하지 않으셨다면, 재접속해서 추가입력 부탁드립니다.</div>

                                <FlexColumnCenter>
                                    <div className="text-lg" style={{width: 150, marginTop: 32}}>
                                        <OrangeButton label="추가 입력하기" onClick={() => {
                                            navigate('/dca/' + chatId.substring(5))
                                        }}/>
                                    </div>
                                </FlexColumnCenter>

                            </div>
                        </Card>

                        <br/><br/><br/><br/><br/><br/>
                        <img style={{width: '70%'}} src={'/assets/logoC.png'}/>

                    </FlexColumnCenter>


                </div>
            }
        </>
    )
}

export default DebtorChatLanding;