import { useEffect, useState, useRef } from "react";
import { WhiteTopBar } from "../common/Common";
import { BACKEND_URL } from "../Atoms";
import { useRecoilValue } from "recoil";
import { BoNetUtil } from "../common/Util";
import { TabView, TabPanel } from 'primereact/tabview';
import { Accordion, AccordionTab } from 'primereact/accordion';
import classnames from 'classnames';

export function NoticePage(props) {
    return (
        <div className="px-2">
            <WhiteTopBar title="알림센터"/>
            <br/>
            <NoticeContent/>
        </div>
    )
}

function NoticeContent(props) {

    const [categories, setCategories] = useState([]);
    const [panels, setPanels] = useState([]);
    useEffect(() => {
        //TODO: 실제로 Push 이벤트를 가져올 수 있어야 함. 
        // https://vatdahm.com/api/v1/transmissions/users/650cec766ded60315d12233a/push?limit=10000&page=1&sort=%7B%22createdAt%22%3A-1%7D
        //     sort={"createdAt":-1}
        /*
        NetUtil.get(`${BACKEND_URL}/api/v1/faqCategories`, {limit: 200, page: 1},
            (resp) => {
                if (resp.data.data) {
                    setCategories(resp.data.data);
                }
            },
            (err) => {
                console.error(err);
            }
        );*/
    }, []);

    useEffect(() => {
        const p = [];
        p.push(<TabPanel header="전체" key="all" headerTemplate={faqTabHeaderTemplate}><NoticeBody/></TabPanel>);
        p.push(<TabPanel header="30초 상담" key="counsel" headerTemplate={faqTabHeaderTemplate}><NoticeBody/></TabPanel>);
        p.push(<TabPanel header="사건정보제출" key="submit" headerTemplate={faqTabHeaderTemplate}><NoticeBody/></TabPanel>);
        p.push(<TabPanel header="기타" key="etc" headerTemplate={faqTabHeaderTemplate}><NoticeBody/></TabPanel>);
        //console.dir(p);
        setPanels(p);
    }, []);

    const faqTabHeaderTemplate = (options) => {
        console.dir(options);
        // options.selected
        // options.index
        const bc = classnames("p-2 mx-1 border-round-xl cursor-pointer text-sm", {
            'orange-back font-bold': options.selected,
            'surface-300 text-600': !options.selected
        });
        return (
            <div className={bc} onClick={options.onClick}>
                {options.titleElement}
            </div>
        );
    }

    return (
        <TabView className="faq-tab-view">
            {panels}
        </TabView>
    );
}

function NoticeBody(props) {
    return (
        <div>

        </div>
    );
}