import axios from 'axios';
import queryString from 'query-string';
import { BoUserIdAtom, BoUserTypeAtom, BACKEND_URL, BoTokenAtom, UserObjectIdAtom } from '../Atoms';
import { getRecoil, setRecoil } from "recoil-nexus";
import { useEffect, useRef } from 'react';
import dateFormat, { i18n } from "dateformat";
import {useRecoilValue} from "recoil";
import {isMobile} from 'react-device-detect';


// 신규 BO를 호출함. 401 처리하여 refresh token 또는 anonymous 로그인한 뒤 재시도하는 로직 포함됨 
export class BoNetUtil {
    // recoil은 hook 밖에 지원하지 않으므로, localStorage를 중개로 사용한다. 
    static get(url, params, onSuccess, onFail) {

        const boToken = getRecoil(BoTokenAtom);
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}` };
        }
        axios.get(url, { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((err) => {
                //console.dir(err);
                if (err.response?.status === 401) {
                    if (err.response.data.code === "Expired") {
                        console.log("Refreshing access token");
                        auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'Authorization-refresh': `Bearer ${boToken.refreshToken}` };
                        axios.get(`${BACKEND_URL}/vws/bo/auth/token/refresh`, { headers: auth })
                            .then((resp) => {
                                console.log("Updated refresh token");
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: boToken.refreshToken });
                                // 다시 요청 
                                this.get(url, null, onSuccess, onFail);  // params가 위에서 이미 붙었으므로, 다시 할 필요없음. 
                            }).catch((err) => {
                                console.error(`Failed to refresh token, ${err}`);
                                // anonymous login
                                axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                                    .then((resp) => {
                                        console.log("Anonymous login");
                                        //console.dir(resp.data);
                                        setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                        setRecoil(BoUserTypeAtom, "anonymous");
                                        this.get(url, null, onSuccess, onFail);
                                    })
                                    .catch((err) => {
                                        onFail(err);
                                    });
                            });
                    } else {  // Expire 조건 외에 401 이라면 anonymous로 돌린다. 
                        axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                            .then((resp) => {
                                console.log("Anonymous login");
                                //console.dir(resp.data);
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                setRecoil(BoUserTypeAtom, "anonymous");
                                this.get(url, params, onSuccess, onFail);
                            })
                            .catch((err) => {
                                onFail(err);
                            });
                    }
                } else {
                    onFail(err);  // error.response.status, error.message 
                }
            });
    }

    // Social Login할 때, 저장된 token 정보로 Authorization 헤더로 넣어 보내면, BO에서 403 으로 응답함. 
    static get_plain(url, params, onSuccess, onFail) {
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        const boToken = getRecoil(BoTokenAtom);
        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}` };
        }
        axios.get(url, { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((error) => {
                onFail(error);  // error.response.status, error.message 
            });
    }

    static post(url, body, onSuccess, onFail) {

        const boToken = getRecoil(BoTokenAtom);

        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}` };
        }
        axios.post(url, body, { headers: auth })
            .then((resp) => {
                console.log('Util resp', resp);
                onSuccess(resp);
            }).catch((err) => {
                console.log('Util err', err);
                if (err.response?.status === 401) {
                    if (err.response.data.code === "Expired") {
                        console.log("Refreshing access token");
                        auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'Authorization-refresh': `Bearer ${boToken.refreshToken}` };
                        axios.get(`${BACKEND_URL}/vws/bo/auth/token/refresh`, { headers: auth })
                            .then((resp) => {
                                console.log("Updated refresh token");
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: boToken.refreshToken });
                                // 다시 요청 
                                this.post(url, body, onSuccess, onFail);
                            }).catch((err) => {
                                console.error(`Failed to refresh token, ${err}`);
                                // anonymous login
                                axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                                    .then((resp) => {
                                        console.log("Anonymous login");
                                        //console.dir(resp.data);
                                        setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                        setRecoil(BoUserTypeAtom, "anonymous");
                                        this.post(url, body, onSuccess, onFail);
                                    })
                                    .catch((err) => {
                                        onFail(err);
                                    });
                            });
                    } else {  // Expire 조건 외에 401 이라면 anonymous로 돌린다. 
                        axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                            .then((resp) => {
                                console.log("Anonymous login");
                                //console.dir(resp.data);
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                setRecoil(BoUserTypeAtom, "anonymous");
                                this.post(url, body, onSuccess, onFail);
                            })
                            .catch((err) => {
                                onFail(err);
                            });
                    }
                } else {
                    onFail(err);  // error.response.status, error.message 
                }
            });
    }

    static post_plain(url, body, onSuccess, onFail) {
        const boToken = getRecoil(BoTokenAtom);
        const boUserType = getRecoil(BoUserTypeAtom);

        let auth = null;
        if (boToken.accessToken) {
            // https://github.com/vwsai/dcol-backend/issues/2#issuecomment-1790180557  때문에, AuthType도 보냄 
            auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'AuthType': boUserType };
        }
        axios.post(url, body, { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((err) => {
                onFail(err);
            });
    }

    static put(url, body, onSuccess, onFail) {

        const boToken = getRecoil(BoTokenAtom);

        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}` };
        }
        axios.put(url, body, { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((err) => {
                if (err.response?.status === 401) {
                    if (err.response.data.code === "Expired") {
                        console.log("Refreshing access token");
                        auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'Authorization-refresh': `Bearer ${boToken.refreshToken}` };
                        axios.get(`${BACKEND_URL}/vws/bo/auth/token/refresh`, { headers: auth })
                            .then((resp) => {
                                console.log("Updated refresh token");
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: boToken.refreshToken });
                                // 다시 요청 
                                this.put(url, body, onSuccess, onFail);
                            }).catch((err) => {
                                console.error(`Failed to refresh token, ${err}`);
                                // anonymous login
                                axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                                    .then((resp) => {
                                        console.log("Anonymous login");
                                        //console.dir(resp.data);
                                        setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                        setRecoil(BoUserTypeAtom, "anonymous");
                                        this.put(url, body, onSuccess, onFail);
                                    })
                                    .catch((err) => {
                                        onFail(err);
                                    });
                            });
                    } else {  // Expire 조건 외에 401 이라면 anonymous로 돌린다. 
                        axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                            .then((resp) => {
                                console.log("Anonymous login");
                                //console.dir(resp.data);
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                setRecoil(BoUserTypeAtom, "anonymous");
                                this.put(url, body, onSuccess, onFail);
                            })
                            .catch((err) => {
                                onFail(err);
                            });
                    }
                } else {
                    onFail(err);  // error.response.status, error.message 
                }
            });
    }

    static put_plain(url, body, onSuccess, onFail) {
        const boToken = getRecoil(BoTokenAtom);
        const boUserType = getRecoil(BoUserTypeAtom);

        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'AuthType': boUserType };
        }
        axios.put(url, body, { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((err) => {
                onFail(err);
            });
    }

    static del(url, body, onSuccess, onFail) {

        const boToken = getRecoil(BoTokenAtom);

        let auth = null;
        if (boToken.accessToken) {
            auth = { 'Authorization': `Bearer ${boToken.accessToken}` };
        }
        axios.delete(url,  { headers: auth })
            .then((resp) => {
                onSuccess(resp);
            }).catch((err) => {
                if (err.response.status === 401) {
                    if (err.response.data.code === "Expired") {
                        console.log("Refreshing access token");
                        auth = { 'Authorization': `Bearer ${boToken.accessToken}`, 'Authorization-refresh': `Bearer ${boToken.refreshToken}` };
                        axios.get(`${BACKEND_URL}/vws/bo/auth/token/refresh`, { headers: auth })
                            .then((resp) => {
                                console.log("Updated refresh token");
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: boToken.refreshToken });
                                // 다시 요청 
                                this.del(url, body, onSuccess, onFail);
                            }).catch((err) => {
                                console.error(`Failed to refresh token, ${err}`);
                                // anonymous login
                                axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                                    .then((resp) => {
                                        console.log("Anonymous login");
                                        //console.dir(resp.data);
                                        setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                        setRecoil(BoUserTypeAtom, "anonymous");
                                        this.del(url, body, onSuccess, onFail);
                                    })
                                    .catch((err) => {
                                        onFail(err);
                                    });
                            });
                    } else {  // Expire 조건 외에 401 이라면 anonymous로 돌린다. 
                        axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
                            .then((resp) => {
                                console.log("Anonymous login");
                                //console.dir(resp.data);
                                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                                setRecoil(BoUserTypeAtom, "anonymous");
                                this.del(url, body, onSuccess, onFail);
                            })
                            .catch((err) => {
                                onFail(err);
                            });
                    }
                } else {
                    onFail(err);  // error.response.status, error.message 
                }
            });
    }
    // localStorage에 정보가 없으면, anonymous 생성하고, 있으면 그냥 무시함. 
    static checkAnonymous(onSuccess, onFail) {
        const boUserId = getRecoil(BoUserIdAtom);
        if (boUserId) {
            console.log("checkAnomymous. already have userid. %o", boUserId);
            onSuccess(boUserId);
            return;
        }


        axios.get(`${BACKEND_URL}/vws/bo/auth/anonymous`)
            .then((resp) => {
                console.log("Doing Anonymous login, %o", resp.data);
                //console.dir(resp.data);
                setRecoil(BoTokenAtom, { accessToken: resp.data.accessToken, refreshToken: resp.data.refreshToken });
                setRecoil(BoUserTypeAtom, "anonymous");
                //onSuccess(boUserId);  // Recoil selector 반응이 느려서 직접 응답 
                onSuccess(resp.data.userId);
            })
            .catch((err) => {
                console.error(err);
                onFail(err);
            });
    }

    static dcolSignInUp(onSuccess, onFail) {

        //dcol-backend로 로그인
        this.post_plain(`${BACKEND_URL}/api/v1/oauth/bo/signin`, null,
            (resp) => {
                //console.dir(resp.data);
                // #47 signupMerge를 위해 userObjectId 2개를 넘김 
                resp.data.userObjectId = resp.data.data?._id;
                resp.data.prvUserObjectId = getRecoil(UserObjectIdAtom);
                console.log(`Bo/SignIP old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);
                setRecoil(UserObjectIdAtom, resp.data.data?._id);
                onSuccess(resp);
            },
            (err) => {
                if (err.response?.status === 404) {
                    // dcol-backend에 생성
                    this.post_plain(`${BACKEND_URL}/api/v1/oauth/bo/signup`, null,
                        (resp) => {
                            //console.dir(resp.data);
                            resp.data.userObjectId = resp.data.data[0]?._id;
                            resp.data.prvUserObjectId = getRecoil(UserObjectIdAtom);
                            resp.data.signUp = true;  // signup인 경우에만 merge하기 위해 
                            console.log(`BO/SIGNUP old=${resp.data.prvUserObjectId} -> new=${resp.data.userObjectId}`);
                            setRecoil(UserObjectIdAtom, resp.data.data[0]?._id);
                            onSuccess(resp);
                        },
                        (err) => {
                            //console.error(err);
                            onFail(err);
                        }
                    );
                } else {
                    //console.error(err);
                    onFail(err);
                }
            }
        );
    }
}



export class AuthUtil {
    // token이 생성되어 있지 않으면, firebase에 anonymous login해서 JWT를 얻음. 
    // token이 있으면 그걸로 로그인함. 
    /*
    static initLogin(url, params, onSuccess, onFail) {
        let tk = {
            type: 'anonymous',
            token: ''
        };
        try {
            tk = JSON.parse(window.localStorage['token']);
        } catch (e) {
            //
        }        
        if (!tk.login) {
            const auth = getAuth();
            signInAnonymously(auth)
                .then(() => {
                    // Signed in..
                    console.log("Sign in anonymous");
                    tk = {
                        type: 'anonymous',
                        token: ''
                    }
                })
                .catch((error) => {
                    const errorCode = error.code;
                    const errorMessage = error.message;
                    // ...
                    console.error(error);
                });
        }
        return tk;
    }
    */
}

// React.StrictMode 시에 useEffect가 2번 불리는걸 막기 위해서임. 
// 특히 kakao/naver 로그인 시 두번 불려서 에러나는걸 막기 위함임. 
// https://taig.medium.com/prevent-react-from-triggering-useeffect-twice-307a475714d7
export function useEffectOnce(effect, dependency) {
    const initialized = useRef(false);
    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true;
            effect();
        }
    }, dependency);
}

i18n.dayNames = ["일", "월", "화", "수", "목", "금", "토"];
i18n.timeNames = ["오전", "오후", "오전", "오후", "오전", "오후", "오전", "오후"];

export function getDateStr(d) {
    return dateFormat(d, 'yyyy년 mm월 dd일 (ddd)');
}

export function getTimeStr(d) {
    return dateFormat(d, 'TT h:MM');
}

export function getDateMinute(d) {
    return dateFormat(d, 'yyyy-mm-ddThh:MM');
}

// https://jjnooys.medium.com/javascript-%EC%95%84%EB%9D%BC%EB%B9%84%EC%95%88-%EC%88%AB%EC%9E%90%EB%A5%BC-%ED%95%9C%EA%B8%80%EC%8B%9D%EC%9C%BC%EB%A1%9C-%EB%B3%80%ED%99%98%ED%95%98%EA%B8%B0-a45989559704
export function getKoreanNumber(number) {
    const koreanNumber = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
    const tenUnit = ['', '십', '백', '천'];
    const tenThousandUnit = ['조', '억', '만', ''];
    const unit = 10000;

    let answer = '';

    while (number > 0) {
        const mod = number % unit;
        const modToArray = mod.toString().split('');
        const length = modToArray.length - 1;

        const modToKorean = modToArray.reduce((acc, value, index) => {
            const valueToNumber = +value;
            if (!valueToNumber) return acc;
            // 단위가 십 이상인 '일'글자는 출력하지 않는다. ex) 일십 -> 십
            const numberToKorean = index < length && valueToNumber === 1 ? '' : koreanNumber[valueToNumber];
            return `${acc}${numberToKorean}${tenUnit[length - index]}`;
        }, '');

        answer = `${modToKorean}${tenThousandUnit.pop()} ${answer}`;
        number = Math.floor(number / unit);
    }

    return answer.replace();
}

// #30 message 기록할 때, setSeq state를 썼는데 바로 바로 반영이 안되어서
// global state인 sessionStorage 사용하기로 함. 
export function getSeq() {
    if (!window.sessionStorage['seq']) {
        window.sessionStorage['seq'] = 1;
    }
    return Number(window.sessionStorage['seq']);
}

export function setSeq(seq) {
    window.sessionStorage['seq'] = seq;
    return Number(seq);
}

export function getNextSeq() {
    let seq = getSeq();
    setSeq(seq+1);
    return seq+1;
}

// Restrict Mode에서 두번씩 호출되는 것 막기 위한 Semaphore
// 특히 채팅방이 2개씩 생성되는 문제가 있었음. 
export function getLock(name) {
    const lock = window.sessionStorage[name];
    if (!lock) {
        window.sessionStorage[name] = "locked";
        return true;
    } else {
        return false;
    }
}

export function clearLock(name) {
    window.sessionStorage.removeItem(name);
}

export function setReturnUrl(url) {
    console.log(`setReturnUrl = ${url}`);
    if (!url) window.sessionStorage.removeItem("rturl");
    else window.sessionStorage["rturl"] = url;
}

export function getReturnUrl() {
    const url = window.sessionStorage["rturl"];
    console.log(`getReturnUrl = ${url}`);
    window.sessionStorage.removeItem("rturl");
    return url;
}

export function getSessionStorage(name) {
    return window.sessionStorage[name];
}

export function setSessionStorage(name, value) {
    window.sessionStorage[name] = value;
    return value;
}

export function isDevEnv() {
    if (window?.location?.host) {
        if (window.location.host.includes('localhost') || window.location.host.includes('local.')|| window.location.host.includes('vws.ai')) {
            return true;
        }
    }
    return false;
    //localhost만 됨. return process.env.REACT_APP_PROJECT_VERSION === "development";
}

export function isCreditnCitycEnv() {
    if (isDevEnv()) {
        return false;
    }
    //2ai home도 제외. b.(b2b)도 제외.
    if (window?.location?.host) {
        if (window.location.host.startsWith('2ai.') || window.location.host.startsWith('b.')) {
            return false;
        }
    }

    return true;
}

export function getBoCounsel(counselObjectId, onSuccess, onFail) {

    BoNetUtil.get(`${BACKEND_URL}/api/v1/counsels/${counselObjectId}`, null,
        (resp) => {
            const counsel = resp.data.data[0];
            console.log(`State: progress=${counsel.state.progress}, input=${counsel.state.input}, login=${counsel.state.login}`);
            if (onSuccess) onSuccess(counsel);
        },
        (err) => {
            if (onFail) onFail(err);
            else console.error(err);
        }
    );
}


// 기존 dcol-backend를 호출하는 Library임.
// export class VdNetUtil {
//     // recoil은 hook 밖에 지원하지 않으므로, localStorage를 중개로 사용한다.
//     static get(url, params, onSuccess, onFail) {
//         const boToken = getRecoil(BoTokenAtom);
//         const boUserType = getRecoil(BoUserTypeAtom);
//
//         if (params) {
//             url = `${url}?${queryString.stringify(params)}`;
//         }
//         axios.get(url, { headers: { 'Authtype': boUserType, 'Authorization': `Bearer ${boToken.accessToken}` } })
//             //axios.get(url)
//             .then((resp) => {
//                 onSuccess(resp);
//             }).catch((error) => {
//                 onFail(error);  // error.response.status, error.message
//             });
//     }
//
//     static post(url, body, onSuccess, onFail) {
//         const boToken = getRecoil(BoTokenAtom);
//         const boUserType = getRecoil(BoUserTypeAtom);
//
//         axios.post(url, body, { headers: { 'Authtype': boUserType, 'Authorization': `Bearer ${boToken.accessToken}` } })
//             .then((resp) => {
//                 onSuccess(resp);
//             }).catch((error) => {
//                 onFail(error);  // error.response.status, error.message
//             });
//     }
//
//     static put(url, body, onSuccess, onFail) {
//         const boToken = getRecoil(BoTokenAtom);
//         const boUserType = getRecoil(BoUserTypeAtom);
//
//         axios.put(url, body, { headers: { 'Authtype': boUserType, 'Authorization': `Bearer ${boToken.accessToken}` } })
//             .then((resp) => {
//                 onSuccess(resp);
//             }).catch((error) => {
//                 onFail(error);  // error.response.status, error.message
//             });
//     }
//
//     static del(url, body, onSuccess, onFail) {
//         const boToken = getRecoil(BoTokenAtom);
//         const boUserType = getRecoil(BoUserTypeAtom);
//
//         axios.delete(url, body, { headers: { 'Authtype': boUserType, 'Authorization': `Bearer ${boToken.accessToken}` } })
//             .then((resp) => {
//                 onSuccess(resp);
//             }).catch((error) => {
//                 onFail(error);  // error.response.status, error.message
//             });
//     }
// }

export const date2str = (date) => {
    if (!date) return '';

    var year = date.getFullYear();
    var month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
    var day = date.getDate();
    // 한 자리 숫자는 앞에 0을 붙여 두 자리로 만듭니다.
    if (month < 10) {
        month = '0' + month;
    }
    if (day < 10) {
        day = '0' + day;
    }
    let dateStr= year + '-' + month + '-' + day;
    return dateStr;
}

export const yyyyMMdd2Arr = (dateStr) => {
    if (!dateStr) return ['','',''];

    return dateStr.split('-');
}

export const isFileListArrayDifferent = (source, dest) => {
    // if (!source && !dest) return true;
    if (source?.length !== dest?.length) return true;

    for (let i = 0; i < source.length; i++) {
        const sourceFile = source[i];
        const destFile = dest[i];
        if (sourceFile.name !== destFile.name || sourceFile.uid !== destFile.uid) {
            return true;
        }
    }

    return false;
}

export const addComma = (number) => {
    if (!number || isNaN(number)) return number;
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export const formatWon = (number) => {
    if (!number) return '';
    return addComma(number) + '원';
}

export const checkNumberAddComma = (v) => {
    if (v && !isNaN(v)) return addComma(v);
    return v;
}

export const homepage2aiLogo = () => {
    //dark모드 미디어쿼리 - 제대로 동작안함 : android크롬 브라우저에서만 동작하지만 화면이 하얀색, 삼성브라우저 api동작안하고 화면은 검정.
    // if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     return '/assets/logo2aiDark.png';
    // }
    return '/assets/logo2ai.png';
}

export const goToKakaoChannel = () => {
    const url = isMobile ? "https://pf.kakao.com/_xbDexfG/chat" : "https://pf.kakao.com/_xbDexfG";
    window.open(
        url,
        "_blank"
    );
};