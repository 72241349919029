import React, {useEffect, useState} from "react";
import {Flex, Title2, ContentFlex} from "../../../common/B2bComponent";
import B2bLayout from "../B2bLayout";
import useAxios from "../../../hooks/useAxios";
import {AskTable} from "../creditAdd/CreditUnitAdd";

/** 채권등록과 앞부분 동일한 소스 복사해서 사용중 */
const CreditList = (props) => {

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    const [initDb, setInitDb] = useState();
    const [askList, setAskList] = useState([]);
    const [creidtListAll, setCreditListAll] = useState([]);

    const ask1 = askList.find((item) => item._id === 1)?.표질문;
    const ask2 = askList.find((item) => item._id === 2)?.표질문;
    const ask3 = askList.find((item) => item._id === 3)?.표질문;
    const askSub = askList.find((item) => item._id === 4)?.표질문; //현재 Sub 1개 -> 향후 Sub1, Sub2, Sub3로 확장가능.
    //console.log('ask1', ask1);

    const creditList1 = creidtListAll.filter((item) => item.creditType === 1);//법인사업자 채권
    const creditList2 = creidtListAll.filter((item) => item.creditType === 2);//개인사업자 채권
    const creditList3 = creidtListAll.filter((item) => item.creditType === 3);//개인 채권


    useEffect(() => {
        // console.log('단위등록 - user Effect:[]');
        firstSearch();
    },[])

    const firstSearch = async() => {
        //db구조=initData
        let initDb = await axiosGet('/api/b2b/creditList/dbStructure');

        //입력된 고객질문들
        let list = await axiosGet('/api/b2b/creditList/askQuestions');

        let creditList = await axiosGet('/api/b2b/creditList/creditLists');

        // console.log(list);
        setInitDb(initDb);
        setAskList(list);
        setCreditListAll(creditList);

    }

    if (!initDb) return null;


    return(
        // <B2bLayout showLNB={true} >

            <ContentFlex>
                <Title2> 채권목록</Title2>

                {ask1 && creditList1.length > 0 &&
                <>
                    <AskTable readMode={true} _id={1} title={'1. 법인사업자 채권목록'} ask={ask1} askSub={askSub} propsCreditList={creditList1} />
                    <br/>
                </>
                }

                {ask2 && creditList2.length > 0 &&
                <>
                    <AskTable readMode={true} _id={2} title={'2. 개인사업자 채권목록'} ask={ask2} askSub={askSub} propsCreditList={creditList2}/>
                    <br/>
                </>
                }

                {ask3 && creditList3.length > 0 &&
                <AskTable readMode={true} _id={3} title={'3. 개인 채권목록'} ask={ask3} askSub={askSub} propsCreditList={creditList3} />
                }

            </ContentFlex>

        // </B2bLayout>
    )
}

export default CreditList;