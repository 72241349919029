import { Badge } from 'primereact/badge';
import { useNavigate } from "react-router-dom";
import {useContext, useEffect, useState} from 'react';
import {BoUserIdAtom, BACKEND_URL, BoUserTypeAtom,  CounselProgressAtom, CounselEnrollAtom} from '../Atoms';
import {useRecoilState, useRecoilValue} from 'recoil';
import { UserPortrait } from '../common/Common';
import { BoNetUtil, setReturnUrl } from '../common/Util';
import useAxios from "../hooks/useAxios";
import {AuthContext} from "../common/AuthContext";
import { goToKakaoChannel } from "../common/Util";

export function BurgerMenu(props) {
    //props.showSideBar
    //props.showLogin
    console.log('Burger MENU props: %o', props);

    //2311 AuthContext로 일원화.: recoil써도 됨.
    const {boToken, boUserId, userObjectId, boUserType, isAnonymous} = useContext(AuthContext);
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    //2311 gary count: recoil로 변경.
    const [enrolledCount, setEnrolledCount] = useRecoilState(CounselEnrollAtom);
    const [progressCount, setProgressCount] = useRecoilState(CounselProgressAtom);
    const chatClickUrl = (enrolledCount >= 1) ? '/chatlist' : 'chat'; //chatlist=등록채권 목록.

    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState({});
    const [chatUrl, setChatUrl] = useState("/chat");


    useEffect(() => {

        console.log(`REAL BURGER MENU`);
        getUserInfo();

        //OLD 코드-> 2311 checkAnonymous는 자동으로 되므로 제외하고, getUserInfo만 남김.
        // BoNetUtil.checkAnonymous(
        //     (resp) => {
        //         BoNetUtil.get(`${BACKEND_URL}/vws/bo/user/info`, { username: boUserId },
        //             (resp) => {
        //                 setUserInfo(resp.data);
        //                 setAnonymous(!resp.data.userrole || resp.data.userrole === "ROLE_GUEST");
        //                 console.log(`In burger menu2`);
        //                 console.dir(resp.data);
        //             },
        //             (err) => console.error(err)
        //         );
        //     },
        //     (err) => {}
        // );
    }, [boUserId]);

    //2311 gary 추가.
    const getUserInfo = async () => {
        //새 사용자이면, user가 존재하는지 체크.
        let accountTableData = await axiosGet('/vws/bo/user/info', {username: boUserId});
        setUserInfo(accountTableData);

        //enrollCount, progressCount 도 여기서 가져와도 되고.

    }


    const doLogin = () => {
        if (window.location?.host.startsWith('2ai')) {
            alert('시스템 점검 중입니다. ');
            return;
        }

        setReturnUrl(null);
        navigate('/signin');
    }

    const gotoChat = (e) => {
        if (window.location?.host.startsWith('2ai')) {
            alert('시스템 점검 중입니다. ');
            return;
        }

        if (isAnonymous && props.showLogin) {
            // 그냥하면 sidebar 에 가린다. 
            props.showSideBar(false);
            props.showLogin(true);
        } else {
            navigate(chatUrl);
        }
    }

    // useEffect(() => {
    //     setChatUrl(getChatUrl(props.enrolledCount, props.progressCount));
    // }, [props.enrolledCount, props.progressCount]);

    return (
        <div className="overflow-hidden">
            <UserPortrait userInfo={userInfo}/>
            <br/>
            <div className="grid mt-3">
                {boUserType === "anonymous" ?
                    <div className="col-12 orange-back text-center p-3 cursor-pointer" onClick={doLogin}>
                        의뢰인 로그인
                    </div>
                    : null}
                <div
                    className="col-6 text-center p-3 border-top-1 border-bottom-1 border-right-1 border-300 surface-100 cursor-pointer"
                    onClick={() => navigate("/mypage")}>
                    마이페이지
                </div>
                <div
                    className="col-6 text-center p-3 border-top-1 border-bottom-1 border-300 surface-100 cursor-pointer"
                    onClick={() => navigate("/notices")}>
                    <span className="p-overlay-badge">알림&nbsp;
                        {/*<Badge value="" severity="danger"></Badge>*/}
                    </span>
                </div>
                <div className="col-12 flex py-3 px-4 border-bottom-1 border-300 cursor-pointer"
                     onClick={
                         // gotoChat
                         props.showKakaoChannelGuide ? props.showKakaoChannelGuide : goToKakaoChannel
                     }>
                    <div>30초 상담</div>
                    <div className="ml-auto">
                        <i className="pi pi-chevron-right"/>
                    </div>
                </div>
                {!isAnonymous &&
                    <div className="col-12 flex py-3 px-4 border-bottom-1 border-300 cursor-pointer"
                         onClick={() => navigate("/feePayment")}>
                        <div>수임료 결제</div>
                        <div className="ml-auto">
                            <i className="pi pi-chevron-right"/>
                        </div>
                    </div>
                }
                { //기존코드  progressCount === 1 && enrolledCount === 0 ? null :
                    //로그인 + 접수완료 > 0
                    (!isAnonymous && enrolledCount > 0) &&
                    <div className="col-12 flex py-3 px-4 border-bottom-1 border-300 cursor-pointer"
                         onClick={() => navigate("/chatlist")}>
                        <div>30초 상담 목록</div>
                        <div className="ml-auto">
                            <i className="pi pi-chevron-right"/>
                        </div>
                    </div>
                }
                <div className="col-12 flex py-3 px-4 border-bottom-1 border-300 cursor-pointer"
                     onClick={() => navigate("/company")}>
                    <div>{(window.location?.host.startsWith('2ai')) ? '회사 소개' : '변호사 및 법률사무소 소개'}</div>
                    <div className="ml-auto">
                        <i className="pi pi-chevron-right"/>
                    </div>
                </div>
            </div>
            <div className="absolute bottom-0 left-auto right-auto text-xs text-center text-600 p-3">
                <span className="cursor-pointer">친구초대</span> |
                <span className="cursor-pointer" onClick={() => navigate("/terms")}> 이용약관</span> |
                <span className="cursor-pointer" onClick={() => navigate("/privacy")}> 개인정보처리방침</span> |
                <span className="cursor-pointer" onClick={() => navigate("/disclaimer")}> 면책공고</span>
            </div>
        </div>
    );
}