import React, {useState, useEffect} from 'react'


const useModal = (
    initialMode = null,
    initialSelected = null
) => {

    const [modalOpen, setModalOpen] = useState(initialMode)
    const [selected, setSelected] = useState(initialSelected)
    const toggle = () => setModalState(!modalOpen)


    useEffect(() => {
      console.log('modalOpen', modalOpen)

    }, [modalOpen])

    useEffect(() => {


    }, [])

    const setModalState = state => {
        setModalOpen(state)
        if(state === false){
            setSelected(null)
        }
    }
    return [modalOpen, setModalOpen, selected, setSelected, setModalState, toggle]
}
export default useModal
