import React, {useState, useEffect} from "react";
import {Button, Space, Input, FloatButton, Row, message,ConfigProvider } from "antd";
import { SaveOutlined } from '@ant-design/icons';
import {
    Flex,
    FlexCenter,
    FlexColumn,
    BlackButton,
    BlackCheckbox,
    GrayDiv,
    DarkDiv,
    GrayDiv2,
    DarkDiv2,
    Div,
    GrayBox,
    StyledCol,
    StyledRow, EmptyCol, EmptyBox, BlackDiv
} from "../../common/B2bComponent";
import {useNavigate} from "react-router-dom";
import {BACKEND_URL, BoUserIdAtom, BoUserTypeAtom} from "../../Atoms";
import axios from "axios";
import B2bLayout from "./B2bLayout";
import {isMobile} from 'react-device-detect';
import {useRecoilValue} from "recoil";
import {BoNetUtil} from "../../common/Util";
import useAxios from "../../hooks/useAxios";
import PublicFileUploader from "../../components/PublicFileUploader";


//항목 모듈화..
const textArr= ['채무자에게 돈을 받을 근거인 계약서 샘플을 올려주세요. (이미지나 pdf파일 업로드)',
    '미수금을 발생시키는 채무자들의 개인사업자 비율과 법인사업자비율, 개인비율을 대략적으로 알려주세요. (예 : 개인사업자 30%, 법인사업자 30%, 개인 40% )',
    '채무자 정보 중 가지고 계신 것을 모두 선택해주세요',
    '미수금 장부를 어떤 형태로 관리하고 계신지 알려주세요. 장부를 엑셀이나 문서로 관리하고 계시면 같이 올려주세요. (관련 엑셀서식 등 파일첨부)',
    '현재 미수금 관리를 어떻게 하고 있는지 구체적인 절차와 담당자 구성에 대해서 알려주세요. (예: 담당자는 어느 사업부에 누가 하고 있고 몇 명이며, 하는 역할은 ~입니다)',
    '현재까지 예전부터 누적된 미수금 규모, 그리고 회수금액을 알려주세요. (아주 정확할 필요는 없습니다만 대략적인 규모를 알려주십시요.)',
    '미수금의 회계처리(대손 등)를 어떤 기준으로 하고 있는지 알려주세요. (예: ~개월 이상이면 대손으로 처리)',
    '연체되는 채무자 1인당 채무금액의 평균액수, 최대값, 최소값을 알려주세요. (예: 평균적으로 1인당 300만원이며, 흔하지는 않지만 종종 1인당 1억원도 있고, 15만원도 있습니다.)',
    '연체 시점별로 조치하는 방법을 알려주세요. (예: 시점1(연체1주일후) : 문자 독촉, 시점2 (전화 독촉2번 이상하고 2주후) : 지급명령 -> 이런 식으로 )',
    '연체를 포함한 연간 전체 계약액과 사람수를 알려주세요. (예 : 위 1번 질문의 계약서에 따라서 거래되는 연간 총계약액 및 연간 총 계약수(명))',
    '채권관리를 하는데 있어서 가장 애로사항이 무엇인가요. (비용, 인력, 기타 심리적 장애사항, 본 사업을 방해하는 부분, 기회비용까지 등)',
    '위 11번 질문에 따라, 채권회수업체에 바라는 점을 알려주세요.',
    '외부 업체에게 맡겼다면 어떤 식으로 맡기고 조건은 어떠했는지를 알려주세요. (예 : ~부분 맡겼고, 건당 금액은 얼마이고, 성공보수는 얼마였다 등)',
    '연간 외부 업체에 지급했던 비용을 알려주세요. (예 : ~항목 착수금 ~천만원, ~항목 성공보수 ~억원 등)',
    '채권관리를 위해 연간 내부에서 투입되는 비용과 노력은 어떤 상황인지 알려주세요. (예 : 조직원 누가 연봉을 받고 ~업무를 수행 중이며 환산하면 연간 총 ~천만원 투입됨, 채용과정에서 ~시간과 노력이 들어감 등)'
];

const B2bPredata = (props) => {

    const navigate = useNavigate();
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();
    const [messageApi, contextHolder] = message.useMessage();

    // const [uploadingFile1, setUploadingFile1] = useState(); //creditDesc1
    // const [uploadingFile4, setUploadingFile4] = useState(); //creditDesc4 file

    const [check31, setCheck31] = useState(false);
    const [check31Arr, setCheck31Arr] = useState([]);
    const [check32, setCheck32] = useState(false);
    const [check32Arr, setCheck32Arr] = useState([]);
    const [check33, setCheck33] = useState(false);
    const [check33Arr, setCheck33Arr] = useState([]);

    const [isDesc3Valid, setIsDesc3Valid] = useState(false);

    //로그인 후, username=boUserId=email 일것으로 가정하고 개발.
    const boUserId = useRecoilValue(BoUserIdAtom);
    const boUserType  = useRecoilValue(BoUserTypeAtom);  //anonymous  google kakao naver  b2b추가됨.
    //postgres유저.
    //const [userInfo, setUserInfo] = useState(null);

    //mongoDB유저.
    const [b2bSignupUser, setB2bSignupUser] = useState("");

    //재요청관련.
    const [replyExist, setReplyExist] = useState(false);
    const [replyMap, setReplyMap] = useState();

    useEffect(() => {
        console.log('B2bMain- USE_EFFECT []:' + BACKEND_URL);

        //1. 로그인 필요.
        if (!boUserId || boUserType !== 'b2b') { //email로그인 체크 강화 필요. boUserType을 이용하던지.
            // 로그인이 안되어 있으면. login화면으로 이동.
            window.location.href = '/b2bLoginLanding';
        }else {
            //로그인 완료. 아래에서 처리.
        }
    }, []);

    useEffect(() => {
        getUserInfo();

    }, [boUserId]);


    useEffect(()=> {
        let desc3Valid = checkDesc3Valid();
        console.log('desc3Valid USE-EFFECT:', desc3Valid);

        setIsDesc3Valid( desc3Valid );

    },[check31, check31Arr, check32, check32Arr, check33, check33Arr])


    const getUserInfo = async() => {

        //부서추가 이전코드.
        // let data = await  axiosGet('/vws/bo/user/info', {username: boUserId});
        // console.log("B2bPredata userInfo:", data)

        let data = await  axiosGet('/api/b2b/predata', {username: boUserId});
        console.log("B2bPredata userInfo:", data);

        //2번 parse가 필요함.
        if (data && data.creditDesc2) {
            let descArr = parseCreditDesc2(data.creditDesc2);
            data.creditDesc2_1 = descArr[0];
            data.creditDesc2_2 = descArr[1];
            data.creditDesc2_3 = descArr[2];
        }

        //3번 parse가 필요함.=> setState
        if (data && data.creditDesc3) {
            parseCreditDesc3(data.creditDesc3);
        }

        setB2bSignupUser(data);

        //재요청 관련.
        if (!data.replyMap) return; //front에는 최초에 없음.
        let replyExistCheck = false;
        for (let key of Object.keys(data.replyMap)) {
            const v = data.replyMap[key];
            if (Array.isArray(v) && v.length > 1 && v.length %2 == 0 ) {
                replyExistCheck = true;
                break;
            }
        }

        if (replyExistCheck) {
            setReplyExist(true);
            setReplyMap(data.replyMap);
        }

    }

    const parseCreditDesc3 = (desc3) => {
        let lines = desc3.split('\n');
        console.log(lines);
        for (const line of lines) {
            if (line.length < 5 || line.indexOf(':') == -1) continue; //혹시 엔터키등 이상한 데이타 있다면 제외.

            const lineData = line.substring(line.indexOf(':') + 2); //space한칸 있음.
            if (line.startsWith('법인')) {
                setCheck31(true);
                setCheck31Arr( lineData.split(','));

            }else if (line.startsWith('. 개인사업자')) {
                setCheck32(true);
                setCheck32Arr( lineData.split(','));

            }else if (line.startsWith('. 개인')) {
                setCheck33(true);
                setCheck33Arr( lineData.split(','));
            }
        }
    }


    //2번을 [2_1, 2_2, 2_3] 3개로 파싱///////
    const parseCreditDesc2 = (desc2) => {
        let ret = [undefined, undefined, undefined];
        if (desc2) {
            let tempParse = desc2.split(',');
            let a = undefined;
            let b = undefined;
            let c = undefined;
            if (tempParse.length > 0) {
                a = tempParse[0];
                if (tempParse.length > 1) {
                    b = tempParse[1];
                }
                if (tempParse.length > 2) {
                    c = tempParse[2];
                }
            }
            if (a && a.indexOf(':') > 0 && a.indexOf('undefined') === -1) {
                a = a.substring( a.indexOf(':') + 1);
            }
            if (b && b.indexOf(':') > 0 && b.indexOf('undefined') === -1) {
                b = b.substring( b.indexOf(':') + 1);
            }
            if (c && c.indexOf(':') > 0 && c.indexOf('undefined') === -1) {
                c = c.substring( c.indexOf(':') + 1);
            }
            if (a) ret[0] = a;
            if (b) ret[1] = b;
            if (c) ret[2] = c;
        }
        return ret;
    }


    const handleInputChange = (e) => {
        console.log(e.target.name, e.target.value);

        // 입력 요소의 값이 변경될 때 상태를 업데이트합니다.
        setB2bSignupUser(prev => ({...prev, [e.target.name]:e.target.value})); //email => abc로 세팅.

        console.log(b2bSignupUser[e.target.name]);
    }


    const handleCredit2InputChange = (e) => {
        // 입력 요소의 값이 변경될 때 상태를 업데이트합니다.
        let credit2 = "개인사업자(%):" + (e.target.name==='creditDesc2_1'? e.target.value:b2bSignupUser.creditDesc2_1)
                    + ", 법인사업자(%):" + (e.target.name==='creditDesc2_2'? e.target.value:b2bSignupUser.creditDesc2_2)
                     + ", 개인(%):" + (e.target.name==='creditDesc2_3'? e.target.value:b2bSignupUser.creditDesc2_3);
        console.log('creditDesc2:', credit2);

        setB2bSignupUser(prev => ({...prev, [e.target.name]:e.target.value, creditDesc2:credit2})); //email => abc로 세팅.
    }

    const onDesc31MainCheck = async(e) => {
        console.log(e.target.name, e.target.checked);
        setCheck31(e.target.checked);
        //setCheck31Arr([]);
    }
    const onDesc31Check = async(e) => {
        if (e.target.checked) {
            setCheck31Arr(prev => [...prev, e.target.name])
        }else {
            setCheck31Arr(prev => prev.filter( item => item !==  e.target.name))
        }
        console.log(check31Arr);
    }

    const onDesc32MainCheck = async(e) => {
        setCheck32(e.target.checked);
        //setCheck32Arr([]);
    }
    const onDesc32Check = async(e) => {
        if (e.target.checked) {
            setCheck32Arr(prev => [...prev, e.target.name])
        }else {
            setCheck32Arr(prev => prev.filter( item => item !==  e.target.name))
        }
        console.log(check32Arr);
    }
    const onDesc33MainCheck = async(e) => {
        setCheck33(e.target.checked);
        //setCheck33Arr([]);
    }
    const onDesc33Check = async(e) => {
        if (e.target.checked) {
            setCheck33Arr(prev => [...prev, e.target.name])
        }else {
            setCheck33Arr(prev => prev.filter( item => item !==  e.target.name))
        }
        console.log(check33Arr);
    }

    const checkDesc3Valid = () => {

        if (check31 && check31Arr.length == 0) return false;
        if (check32 && check32Arr.length == 0) return false;
        if (check33 && check33Arr.length == 0) return false;

        if ( (check31 && check31Arr.length > 0) ||
             (check32 && check32Arr.length > 0) ||
             (check33 && check33Arr.length > 0)
            ) {
            return true;
        }

        return false;
    }


    const onSave = async(tempSave) => {

        let creditDesc3 = ((check31)? '법인: ' + check31Arr.join(','):'') + '\n' +
            ((check32)? '. 개인사업자: ' + check32Arr.join(','):'') + '\n' +
            ((check33)? '. 개인: ' +  check33Arr.join(','):'');

        //axiosPost로 바꾸면 더 좋을듯 함..
        // let {data:retInt} = await axios.post(`${BACKEND_URL}/apiOpen/b2b/predata`, {...b2bSignupUser, creditDesc1:retStr1, creditDesc4File:creditDesc4File });
        let retInt = await axiosPost(`/api/b2b/predata/${tempSave}`, {...b2bSignupUser, creditDesc3:creditDesc3 });
        console.log("onSave:", retInt)

        if (retInt < 0) {
            console.log("회원 프리데이터 입력오류가 발생했습니다.");
            return;
        }

        if (tempSave) {
            messageApi.info('임시저장 되었습니다.');
        }else {
            navigate('/B2bPredataWait')
        }
    }

    //항목들 모듈화.1 ///////////////////////////////////////////////////////////////////
    const Desc3Pannel = ({replyExist}) => <>
        <Row style={{ width:500}} gutter={[0,0]}>
            <StyledCol span={5} style={{textAlign:'left'}}>
                <Space>
                    <BlackCheckbox disabled={replyExist || !(b2bSignupUser.creditDesc2_1||b2bSignupUser.creditDesc2_2||b2bSignupUser.creditDesc2_3)} checked={check31} name={'법인'} onChange={onDesc31MainCheck}/>
                    법인
                </Space>
            </StyledCol>
            <StyledCol span={19}>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('법인등기부상 법인등록번호')} disabled={replyExist || !check31} name={'법인등기부상 법인등록번호'} onChange={onDesc31Check}/>
                            법인등기부상 법인등록번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('사업자등록번호')} disabled={replyExist || !check31} name={'사업자등록번호'} onChange={onDesc31Check}/>
                            사업자등록번호
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('법인주소')} disabled={replyExist || !check31} name={'법인주소'} onChange={onDesc31Check}/>
                            법인주소
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox  checked={check31Arr.includes('법인 전화번호')} disabled={replyExist || !check31} name={'법인 전화번호'} onChange={onDesc31Check}/>
                            법인 전화번호
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('사업자 계좌번호')} disabled={replyExist || !check31} name={'사업자 계좌번호'} onChange={onDesc31Check}/>
                            사업자 계좌번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('대표자 개인주소')} disabled={replyExist || !check31} name={'대표자 개인주소'} onChange={onDesc31Check}/>
                            대표자 개인주소
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('대표자 주민등록번호')} disabled={replyExist || !check31} name={'대표자 주민등록번호'} onChange={onDesc31Check}/>
                            대표자 주민등록번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check31Arr.includes('대표자 휴대폰번호')} disabled={replyExist || !check31} name={'대표자 휴대폰번호'} onChange={onDesc31Check}/>
                            대표자 휴대폰번호
                        </Space>
                    </EmptyCol>
                </Row>
            </StyledCol>
        </Row>
        <Row style={{width:500}} gutter={[0,0]}>
            <StyledCol span={5}  style={{textAlign:'left'}}>
                <Space>
                    <BlackCheckbox disabled={replyExist || !(b2bSignupUser.creditDesc2_1||b2bSignupUser.creditDesc2_2||b2bSignupUser.creditDesc2_3)} size={'small'} checked={check32} name={'개인사업자'} onChange={onDesc32MainCheck}/>
                    개인사업자
                </Space>
            </StyledCol>
            <StyledCol span={19}>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('사업자등록번호')} disabled={replyExist || !check32} name={'사업자등록번호'} onChange={onDesc32Check}/>
                            사업자등록번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('주민등록번호')} disabled={replyExist || !check32} name={'주민등록번호'} onChange={onDesc32Check}/>
                            주민등록번호
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('개인 계좌번호')} disabled={replyExist || !check32} name={'개인 계좌번호'} onChange={onDesc32Check}/>
                            개인 계좌번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('사업자 계좌번호')} disabled={replyExist || !check32} name={'사업자 계좌번호'} onChange={onDesc32Check}/>
                            사업자 계좌번호
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('휴대폰번호')} disabled={replyExist || !check32} name={'휴대폰번호'} onChange={onDesc32Check}/>
                            휴대폰번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('집주소')} disabled={replyExist || !check32} name={'집주소'} onChange={onDesc32Check}/>
                            집주소
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check32Arr.includes('회사주소')} disabled={replyExist || !check32} name={'회사주소'} onChange={onDesc32Check}/>
                            회사주소
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>

                    </EmptyCol>
                </Row>
            </StyledCol>
        </Row>
        <Row style={{width:500}} gutter={[0,0]}>
            <StyledCol span={5}  style={{textAlign:'left'}}>
                <Space>
                    <BlackCheckbox disabled={replyExist || !(b2bSignupUser.creditDesc2_1||b2bSignupUser.creditDesc2_2||b2bSignupUser.creditDesc2_3)} size={'small'} checked={check33} name={'개인'} onChange={onDesc33MainCheck}/>
                    개인
                </Space>
            </StyledCol>
            <StyledCol span={19}>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('주민등록번호')} disabled={replyExist || !check33} name={'주민등록번호'} onChange={onDesc33Check}/>
                            주민등록번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('개인 계좌번호')} disabled={replyExist || !check33} name={'개인 계좌번호'} onChange={onDesc33Check}/>
                            개인 계좌번호
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('휴대폰번호')} disabled={replyExist || !check33} name={'휴대폰번호'} onChange={onDesc33Check}/>
                            휴대폰번호
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('집주소')} disabled={replyExist || !check33} name={'집주소'} onChange={onDesc33Check}/>
                            집주소
                        </Space>
                    </EmptyCol>
                </Row>
                <Row>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('근로소득받고 있는 회사명')} disabled={replyExist || !check33} name={'근로소득받고 있는 회사명'} onChange={onDesc33Check}/>
                            근로소득받고 있는 회사명
                        </Space>
                    </EmptyCol>
                    <EmptyCol style={{width:180}}>
                        <Space>
                            <BlackCheckbox checked={check33Arr.includes('회사주소')} disabled={replyExist || !check33} name={'회사주소'} onChange={onDesc33Check}/>
                            회사주소
                        </Space>
                    </EmptyCol>
                </Row>
            </StyledCol>
        </Row>

    </>


    //항목들 모듈화.2 ///////////////////////////////////////////////////////////////////
    const OneItem = ({num, text,  value, replyExist}) => <FlexColumn style={{width:'100%'}}>
        <Flex>
            <DarkDiv2> {num}.&nbsp;</DarkDiv2>
            <FlexColumn>
                <DarkDiv2> {text}</DarkDiv2>
            </FlexColumn>
        </Flex>

        {num === 1 &&
        <PublicFileUploader imageUrl={value}
                            downloadName = {'계약서샘플'} downloadOnly={replyExist}
                            onChange={(returnUrl) => setB2bSignupUser(prev => ({...prev, creditDesc1:returnUrl}))}/>
        }


        {//num >= 4 && 이벤트처리는 .map안에서 처리.
         //<Input.TextArea disabled={!prevItem} rows={5} name={name} value={value} onChange={handleInputChange}/>
        }

        {num === 4 &&
        <PublicFileUploader imageUrl={b2bSignupUser.creditDesc4File}
                            downloadName = {'미수금장부'} downloadOnly={replyExist}
                            onChange={(returnUrl) => setB2bSignupUser(prev => ({...prev, creditDesc4File:returnUrl}))}/>
        }

    </FlexColumn>


    const oneReplyChange = (e, idx) => {
        console.log(e.target.name, idx);

        setReplyMap( prev => {
            // const newReplyMap = prev; //full refresh 방지. {...prev};
            const newReplyMap = {...prev};
            const targetReplyArr = newReplyMap[e.target.name];
            targetReplyArr[idx] = e.target.value;
            return newReplyMap;
        });
        //
    }

    //답변저장.
    const  onSaveReply = async () => {

        console.log(replyMap); //todo 저장할때 값있는 애들(홀수개 인 애들)은 뒤에 고객용 array 하나 더하기?
        //짝수번째 입력다되었는지 체크

        // let replyDone = true;
        for (let key of Object.keys(replyMap)) {
            const v = replyMap[key];
            if (Array.isArray(v) && v.length > 1 && v.length %2 == 0 ) {
                if (!v[v.length -1]) {
                    alert(' 입력되지 않은 재요청항목이 존재합니다. 입력 후 다시 저장해 주세요.');
                    return;
                }
            }
        }

        let retInt = await axiosPost(`/api/b2b/predataReply/${b2bSignupUser._id}`, replyMap);

        if (retInt > 0) {
            messageApi.info('재요청 입력이 되었습니다.');
            navigate('/B2bPredataWait');
        }else {
            alert('저장시 오류발생 ');
        }


    }

    return (
        <B2bLayout title={'채권도시 기업회원 프리데이터 입력'}>
        {contextHolder}
        <FlexCenter>
        <FlexColumn style={{marginLeft:isMobile?0:50}}>
          <FlexCenter>
            <FlexColumn style={{marginTop:50}}>
                {!replyExist &&
                <ConfigProvider
                    theme={{token: {colorPrimary: '#000',},}}
                >
                    <FloatButton style={{width: 70, right: 24}}
                                 type={'primary'}
                                 icon={<SaveOutlined/>}
                                 description="임시저장"
                                 shape="square"
                                 onClick={() => onSave(true)}
                    />
                </ConfigProvider>
                }

                <Div><b>커스터마이징 및 최적화된 솔루션을 제공드리기 위해서 채권관련 상황에 관한 정보를 입력해주세요.</b> </Div>
                <EmptyBox style={{padding:0, marginTop:-5}}>
                    <GrayDiv> 자세할수록 회수율이 올라갑니다. </GrayDiv>
                </EmptyBox>

                {(b2bSignupUser.b2bSuperUser && b2bSignupUser.departName) &&
                    <Div> <b>[{b2bSignupUser.departName}]</b> 에서는 필요한 내용만 입력해 주세요.</Div>
                }

                <br/>
                <div style={{ marginTop:10, width: 500 }}>
                    <FlexColumn>
                        <div style={{marginTop:10}}/>

                        { textArr.map((text, idx) => { //todo b2bSignupUser가 바뀌면 refesh해야하는데 안됨.

                            if(idx === 0) console.log('################## textArr map, desc4=', b2bSignupUser.creditDesc4);

                            const num = idx+1;
                            const varName = 'creditDesc' + num;
                            //const name = 'b2bSignupUser.' + varName;

                            let value = b2bSignupUser[varName];
                            let prevVar = b2bSignupUser['creditDesc'+idx];

                            const prevItem = (num === 1)? true
                                :  //desc3은 하드코딩 되므로 제외
                                  (num===4)? isDesc3Valid
                                      : prevVar;

                            //재요청 관련. : 짝수개 ('admin재요청' + '')가 존재해야 재요청임.
                            const replyKey = 'reply'+num;


                            return <>

                                <OneItem num={num} text={text}  value={value}  replyExist={replyExist}/>
                                {num >= 4 &&
                                    <Input.TextArea disabled={!prevItem || replyExist} rows={5} name={varName} value={value} onChange={handleInputChange}/>
                                }

                                {num === 2 &&
                                <GrayBox style={{padding:5}}>
                                    <Flex style={{marginTop:2, marginLeft:10, paddingBottom:3}}>
                                        <GrayDiv style={{marginTop:5}}> 개인사업자(%): </GrayDiv>
                                        <Input size={'small'} style={{width:82, marginLeft:5, marginRight:10}} disabled={!b2bSignupUser.creditDesc1 || replyExist}  name={'creditDesc2_1'} value={b2bSignupUser.creditDesc2_1} onChange={handleCredit2InputChange}/>

                                        <GrayDiv style={{marginTop:5}}> 법인사업자(%): </GrayDiv>
                                        <Input size={'small'} style={{width:82, marginLeft:5, marginRight:10}} disabled={!b2bSignupUser.creditDesc1 || replyExist}  name={'creditDesc2_2'} value={b2bSignupUser.creditDesc2_2} onChange={handleCredit2InputChange}/>

                                        <GrayDiv style={{marginTop:5}}> 개인(%): </GrayDiv>
                                        <Input size={'small'} style={{width:82, marginLeft:5, marginRight:10}} disabled={!b2bSignupUser.creditDesc1 || replyExist}  name={'creditDesc2_3'} value={b2bSignupUser.creditDesc2_3} onChange={handleCredit2InputChange}/>
                                    </Flex>
                                </GrayBox>
                                }
                                {num === 3 &&
                                <Desc3Pannel replyExist={replyExist}/>
                                }

                                {//todo 댓글
                                    (replyExist && replyMap[replyKey].length > 1) &&
                                    replyMap[replyKey].map((oneText, idx) => {
                                        //여기선 항상 userInputTime임.
                                        // const userInputTime = selectedUser.status === 1;
                                        const adminInput = idx % 2 === 0;
                                        const userInput = idx % 2 === 1;

                                        const userReadOnly = adminInput || (idx !== replyMap[replyKey].length -1); //마지막 항목만 입력가능.
                                        let rows = 3 + idx % 2;
                                        if (oneText.length > 10) {
                                            rows = 5;
                                        }

                                        return(<FlexColumn style={{width: '100%'}}>
                                            <BlackDiv> ↓ {adminInput ? '채권도시 재요청:' : '고객:'}</BlackDiv>

                                            <Input.TextArea readOnly={userReadOnly} style={{backgroundColor:userReadOnly?'#f5f5f5':'white' }} autoSize={{ minRows: 3, maxRows: 10 }} name={replyKey} value={oneText}
                                                            onChange={(e) => oneReplyChange(e, idx)}/>
                                        </FlexColumn>)
                                    })
                                }

                            </>
                            })
                        }

                    </FlexColumn>
                </div>


                <br/>


                <br/>
                {!replyExist &&
                <BlackButton disabled={!b2bSignupUser.creditDesc15} onClick={() => onSave(false)} style={{marginTop: 5, width: 500}}> 입력 완료 </BlackButton>
                }
                {replyExist &&
                <BlackButton  onClick={() => onSaveReply()} style={{marginTop: 5, width: 500}}> 재요청사항 입력완료 </BlackButton>
                }
                <br/>
                <br/>

                <br/>
                <br/>
            </FlexColumn>
          </FlexCenter>
        </FlexColumn>
        </FlexCenter>
        </B2bLayout>
    )
}

export default B2bPredata;