import { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {BoUserIdAtom, BACKEND_URL, UserObjectIdAtom, BoTokenAtom} from "../Atoms";
import { useRecoilState, useRecoilValue } from "recoil";
import {
    BoNetUtil,
    getDateStr,
    getTimeStr,
    getDateMinute,
    getKoreanNumber,
    setSeq,
    getNextSeq,
    getLock,
    clearLock,
    setReturnUrl,
    getSessionStorage,
    setSessionStorage,
    getReturnUrl,
    useEffectOnce,
    getBoCounsel,
    getSeq
} from "../common/Util";
import classnames from 'classnames';
import { InputMoney, OrangeButton, WhiteButton, WhiteTopBar } from "../common/Common";
import { Dialog } from 'primereact/dialog';
import { Checkbox } from 'primereact/checkbox';
import { Tag } from 'primereact/tag';
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import 'react-horizontal-scrolling-menu/dist/styles.css';  // 이거 안하면 세로로 스크롤됨 
import commaNumber from "comma-number";
import { Modal } from 'react-responsive-modal';
import { InputText } from "primereact/inputtext";
import { isEnrolled } from "./CaseListPage";
import _ from 'lodash';
import useAxios from "../hooks/useAxios";

/*

기본적인 순서
1. userChatObject을 생성하거나, 기존에 있으면(409 Conflict) 그걸 가져온다.  
2. userChat/messages를 가져옴. 
  - 만일 message가 있으면 addMessage
    - 이 경우라도, speak, notice등 바로 next로 넘어가야 하는 경우에는 넘김 
    - isLeafNode 이면, startNewTree 
  - 없으면 startNewTree 
3. onAnswerPost 에서 사용자의 응답을 받음
  - 사용자의 응답을 POST message하고,
  - 응답의 질문 tree를 찾아 PUT predata
  - 응답의 질문 tree에서 다음 treeNode를 찾아 fetchTreeNode 또는 postMessage 

startNewTree = tree object 가져와서 postMessage
postMessage = 인자의 treeNode를 message로 만들어 POST messages, 그리고 다음 노드로 자동 진행해야 할(speak, notice, nft 등)은 자동 진행(fetchTreeNode)
fetchTreeNode = treeNode를 가져와서 postMessage 


2. tree 가져옴 
3. 해당 tree를 messages로 보내, 그 응답을 데이터로 버블 그림 
4. 사용자가 응답하면 그것을 messages로 보내, 그 응답을 데이터로 버블 그림. 

POST messages 의 응답으로 데이터를 삼는다. 

{
    "count": 1,
    "data": [
        {
            "_id": "654b9d15e88ae696d7d1aaaf",
            "treeNodeObjectId": "63289a76c13956f731e82018",
            "type": "treeNode",
            "treeNode": {
                "_id": "63289a76c13956f731e82018",
                "type": "data_input",
                "counselType": "counsel",
                "parentObjectId": "632899a5c13956f731e82012",
                "position": {
                    "63288fe12e3abff3d1c07b8e": {
                        "x": 840.5265391623327,
                        "y": 26.39015821545786
                    }
                },
                "createdAt": "2022-09-19T16:36:06.151Z",
                "modifiedAt": "2023-08-31T04:43:39.886Z",
                "options": [
                    "카톡/문자",
                    "이체내역",
                    "차용증",
                    "계약서(근로/임대차/물품/도급/투자)",
                    "진단서/사고확인서/견적서 등",
                    "세금계산서/영수증/내역서 등",
                    "통화/대화녹음",
                    "사건관련사진",
                    "상대 사업자등록증",
                    "상대 통장사본",
                    "형사사건 서류"
                ],
                "message": "갖고 계신 증거는 무엇이 있나요?",
                "field": "evidence",
                "method": "multiSelection",
                "nextTreeNodeObjectId": "63289a740602c4f7308e0046",
                "prvTreeNodeObjectId": "63289a77c13956f731e82019"
            },
            "userChatObjectId": "654b9cf1e88ae696d7d1aaab",
            "createdAt": "2023-11-08T14:37:09.067Z",
            "extra": {
                "seq": 10,
                "type": "data_input",
                "treeId": "63288fe12e3abff3d1c07b8e"
            },
            "state": "send",
            "isStaffRead": null,
            "isUserRead": null,
            "modifiedAt": "2023-11-08T14:37:09.067Z"
        }
    ]
}

---------------------
{
    "count": 1,
    "data": [
        {
            "_id": "654b9d1485ddb396ef435984",
            "type": "user",
            "userType": "user",
            "message": "임대차보증금",
            "userObjectId": "654b9cefe88ae696d7d1aaa9",
            "state": "send",
            "extra": {
                "seq": 9,
                "sender": "system",
                "treeId": "63288fe12e3abff3d1c07b8e"
            },
            "createdAt": "2023-11-08T14:37:08.132Z",
            "userDetail": {
                "_id": "654b9cefe88ae696d7d1aaa9",
                "nickname": null,
                "name": "e10b32dd-1a36-480b-ab8e-6b4626502da2"
            },
            "userChatObjectId": "654b9cf1e88ae696d7d1aaab",
            "modifiedAt": "2023-11-08T14:37:08.132Z"
        }
    ]
}
*/

const typingDelay = 500;

export function ChatPage(props) {

    console.log('ChatPage props:', props);

    //const userObjectId = useRecoilValue(UserObjectIdAtom);
    const [userObjectId,setUserObjectId]  = useRecoilState(UserObjectIdAtom);
    const [boToken,setBoToken]  = useRecoilState(BoTokenAtom);


    const userId = useRecoilValue(BoUserIdAtom);
    const [userChatState, setUserChatState] = useState(null);  // data 아래의 항목임. 
    const [primaryNotice, setPrimaryNotice] = useState(null);  // 상단 Notice
    const [secondaryNotice, setSecondaryNotice] = useState(null);
    const [showExitDialog, setShowExitDialog] = useState(false);  // 채권등록 중 나갈 다이얼로그 
    const [showExitDoneDialog, setShowExitDoneDialog] = useState(false);  // 채권등록 완료된 후에 나갈 다이얼로그
    const [treeObject, setTreeObject] = useState(null);  // Tree정보 저장 
    const navigate = useNavigate();

    const { chatId } = useParams();


    const [showInvalid, setShowInvalid] = useState(false);
    const [state, setState] = useState(null);  // ChatBody에서 수집한 counsel.state 를 받아서 유지함. 그래야 채팅방 나가기 정책을 정할 수 있음. 
    const [enrolled, setEnrolled] = useState(false);

    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();

    const setNotice = (pn, sn) => {
        setPrimaryNotice(pn);
        setSecondaryNotice(sn);
    }

    const updateState = (st) => {
        setState(st);
        setEnrolled(isEnrolled(st));
        console.log("Counsel.state updated to %o, enrolled=%o", st, isEnrolled(st));
    }

    useEffect(() => {
        console.log("USE_EFFECT chatId", chatId)

        if (!chatId) {
            //TODO check semaphore 쓸지 말지. 용도가 모호함.

            //2312 DB삭제유저들 여기서 막혀서 제거중./////////////////////////////////////////
            // const goOn = getLock("userChat");
            //if (!goOn) return;
            let url = `${BACKEND_URL}/api/v1/userChats`;
            if (props.debtor) {
                url += '?isDebtor=true';
            }

            BoNetUtil.post(url, { userObjectId: userObjectId, type: "counsel" },
                (resp) => {
                    // 201 Created로 들어오는 경우, 새로운 counsel을 시작하는 경우임.
                    console.log(resp.status)
                    if (resp.status == 201 ) { //기존 코드. 새로운 채팅방 개설.
                        const uc = resp.data.data[0];
                        console.log(`Create new userChat ${uc._id}`);
                        setUserChatState(uc);
                        console.log(`ChatState: type=${uc.type}, userObjectId=${uc.userObjectId}, counsel=${uc.counselObjectId}, tree=${uc.treeObjectId}`);
                        clearLock("userChat");

                        navigate((props.debtor)?`/debtorChat/${uc._id}` : `/chat/${uc._id}`, {replace: true});

                    } else if (resp.status == 200 ) { //231201 로그인창종료 대비. 진행하던 채팅 찾아보기. not complete일 경우?  login, progress?
                        //231201 위 201과 똑같이  해봄.
                        //2404 - /debtorChat 용으로 테스트 중.
                        const uc = resp.data.data[0];
                        console.log(` 입력완료 미결제 (debtor UC): userChat ${uc?._id}`);
                        setUserChatState(uc);
                        //console.log(`ChatState: type=${uc?.type}, userObjectId=${uc?.userObjectId}, counsel=${uc?.counselObjectId}, tree=${uc?.treeObjectId}`);
                        clearLock("userChat");
                        navigate((props.debtor)?`/debtorChat/${uc._id}` : `/chat/${uc._id}`, {replace: true});
                    }

                },
                (err) => {
                    if (err.response?.status === 409) {    // 409 Conflict, 이미 진행중인 counsel이 있는 경우
                        console.log(`Get existing userChat409 ${err.response.data.userChatObjectId}`);
                        // resp: {"userChatObjectId":"654b9cf1e88ae696d7d1aaab"}
                        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${err.response.data.userChatObjectId}`, null,
                            (resp) => {
                                const uc = resp.data.data[0];
                                setUserChatState(uc);
                                console.log(`ChatState: type=${uc.type}, userObjectId=${uc.userObjectId}, counsel=${uc.counselObjectId}, tree=${uc.treeObjectId}`);
                                clearLock("userChat");
                                navigate((props.debtor)?`/debtorChat/${uc._id}` :`/chat/${uc._id}`, {replace: true});
                            },
                            (err) => {
                                console.error(err);
                                clearLock("userChat");
                            }   
                        );
                    } else {
                        console.dir('ERROR', err);
                        clearLock("userChat");

                        //2404 debtor refresh용으로 추가.
                        console.log('userChats', userChatState);
                        if (userChatState === null) {
                            setUserChatState(undefined);
                        }
                    }
                }
            );
        } else {
            console.log(`Get existing userChat:  ${chatId}`);
            // resp: {"userChatObjectId":"654b9cf1e88ae696d7d1aaab"}
            BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${chatId}`, null,
                (resp) => {
                    // 다른 사용자 것이 아닌지 체크한다. 
                    if (!props.debtor && resp.data.data[0].userObjectId !== userObjectId) {
                        console.log("userID data vs screen", resp.data.data[0].userObjectId, userObjectId);
                        setShowInvalid(true);
                    } else {
                        setUserChatState(resp.data.data[0]);
                    }
                },
                (err) => {
                    console.error(err);
                }   
            );        
        }
    }, [chatId]);

    //231205  채팅방 생성을 BO에 알림
    useEffect(() => {
        console.log("USE_EFFECT userChatState", userChatState)
        if (!userChatState?._id) return;

        BoNetUtil.put(`${BACKEND_URL}/vws/bo/chatvd/userChats/${userChatState._id}`, null,
            (resp) => {
                console.log(`chatObjectId registered ${userChatState._id}`);
            },
            (err) => { //500 중복에러 가끔발생.
                console.error(err)
            }
        );
    }, [userChatState]);

    // Tree 정보를 얻어와, 트리 이름, state와 notice 등을 저장 
    useEffect(() => {
        console.log("USE_EFFECT userChatState2", userChatState, chatId)
        // if (props.debtor && !userChatState) { //채무자 새상담 안되는 경우가 있어서 방어코드.
        //     navigate(); //refresh
        // }

        if (!userChatState) return;

        let url = `${BACKEND_URL}/api/v1/trees/${userChatState.treeObjectId}`;
        if (props.debtor) {
            url += '?debtorUserChatObjectId=' + chatId; //채무자 채팅방은 전송해서 멘트 원장 수정용으로 사용. 2404
        }

        BoNetUtil.get(url, null,
            (resp) => {
                setNotice(resp.data.data[0].primaryNotice, resp.data.data[0].secondaryNotice);
                setTreeObject(resp.data.data[0]);
                console.log(`TREE NAME = ${resp.data.data[0].name}, treeID:${userChatState.treeObjectId}`, resp.data.data[0]);

                console.log('2 TREE:' + userChatState.treeObjectId +  ',' + resp.data.data[0]._id);
                // if (userChatState.treeObjectId !== resp.data.data[0]._id) {
                //     console.log('/////////TREE CHANGE//////////////:', userChatState.treeObjectId + " => " + resp.data.data[0]._id);
                //     //231130 추가. //TODO 231130 입력완료_비로그인 TREE로 안바뀜. 입력중 비로그인으로 감. userChat에 잘못된 tree가 기록되어있어 시도둥.
                //     axiosPut(`/api/v1/userChats/${userChatState._id}`, {treeObjectId:userChatState.treeObjectId});
                // }
            },
            (err) => console.error(err)
        );

    }, [userChatState]);


    const startNewCounsel = () => {
        setShowExitDialog(false);
        //navigate(0);
        window.location.href = "/chat";
    }

    const clearProcess = async(clearFlag) => {
        if (!userChatState){
            console.log("비정상: DB clear등으로 인한 에러. ");
            setBoToken('');
            setUserObjectId('');

            navigate('/');  //비정상이라서 메인으로 보내야 함.
            return;
        }

        console.log('clear - USER_CHAT_ID:' + userChatState._id);

        await axiosPut(`/api/v1/counseldelete/${userChatState.counselObjectId}`, {clear: clearFlag});
        console.log(`1counseldelete ${userChatState._id} was deleted`);
        await axiosDelete(`/api/v1/userChats/${userChatState._id}/messages`);
        console.log(`2userChatsmessages ${userChatState._id} was deleted`);

        if (clearFlag) {
            await axiosDelete(`/api/v1/userChat/${userChatState._id}`);
            console.log(`3Chat  ${userChatState._id} was deleted`);
        }
    }

    const clearCounsel = async () => {
        // - PUT /api/v1/counsels/654b754c85ddb396ef435971
        // - req: { clear: true }

        await clearProcess(false);

        setShowExitDialog(false);
        navigate(0); //refresh
        //기존코드.
        // BoNetUtil.put(`${BACKEND_URL}/api/v1/counsels/${userChatState.counselObjectId}`, {clear: true},
        //     (resp) => {
        //         console.log(`Counsel ${userChatState.counselObjectId} was cleared`);
        //         BoNetUtil.del(`${BACKEND_URL}/api/v1/userChats/${userChatState._id}/messages`, null,
        //             (resp) => {
        //                 console.log(`ChatMessages ${userChatState._id} was deleted`);
        //                 navigate(0);
        //             },
        //             (err) => console.error(err)
        //         );
        //
        //     },
        //     (err) => {
        //         console.error(err);
        //         navigate(0);
        //     }
        // );
    }

    const preventGoBack = () => {
        // 브라우저의 Back 버튼을 눌렀을 때, 
        window.history.pushState(null, "", window.location.href);
        /*
이미 채권이 등록 되었습니다.
그러나 새로 처음부터 상담하실 수도 있습니다.
-[임시 저장하고 뒤로 가기] -> 채권목록 ; 등록채권1 있는 상태
-[새로운 상담하기] -> 채팅방 하나 만들어주기 ; 등록채권1 있는 상태에서 추가 채팅방 생성된 상태
        */
        if (enrolled === true) {
            setShowExitDoneDialog(true);
        } else {
            setShowExitDialog(true);
        }
    };    

    const exitChatPage = () => {
        // 상단 < 버튼 눌렀을 때  
        if (enrolled === true) {
            setShowExitDoneDialog(true);
        } else {
            setShowExitDialog(true);
        }
    }

    // 브라우저에 렌더링 시 한 번만 실행하는 코드
    useEffect(() => {
        (() => {
            window.history.pushState(null, "", window.location.href);
            window.addEventListener("popstate", preventGoBack);
        })();

        return () => {
            window.removeEventListener("popstate", preventGoBack);
        };
    },[]);

    const saveAndBack = async () => {
        setShowExitDialog(false);

        //2404 B2C:채팅메시지가 너무적으면 지우기도 하는데.. B2B:채무자는 계속 유지해야함: _id가 고유하기 때문.
        if (!props.debtor && getSeq() <= 2 ) {
            console.log(" #### seq <=2 DELETE PROCESS");
            clearProcess(true);
        }

        //2404: 채무자 if추가.
        if (props.debtor) {
            navigate('/dc/saved' + chatId);

        }else { //기존 채권자 코드
            const url = getReturnUrl();
            console.log(`return url = ${url}`);
            navigate(url || "/", {replace: true});
        }
    }

    const goBack = () => {
        setShowInvalid(false)
        navigate(-1);
    }

    const preventSwipe = (e) => {
        console.log("touch=%o", e);
        if (e.changedTouches[0].pageX < 20) {
            e.preventDefault();
            return;
        }
    }

    // 아이폰에서 왼쪽으로 스와이프를 막는다. 
    useEffect(() => {
        const handleTouchStart = (e) => {
            console.log("touch=%o", e);
            //if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
            if (e.changedTouches[0].pageX < 20) {
                e.preventDefault();
                return;
            }
        };
        // passive: false는 
        // [Intervention] Unable to preventDefault inside passive event listener due to target being treated as passive. See https://www.chromestatus.com/feature/5093566007214080
        // 에러 막기 위함임. 
        window.addEventListener('touchstart', handleTouchStart, {passive: false});
        return () => {
            window.removeEventListener('touchstart', handleTouchStart);
        };
    }, []);

    return (
        <div className="" >
            <div className="sticky top-0 z-5">
                <WhiteTopBar debtor={props.debtor} title={props.debtor?'채무자 화해플랜':'30초 상담'} onClick={exitChatPage}/>
                <ChatNotice primaryNotice={primaryNotice} secondaryNotice={secondaryNotice}/>
            </div>
            <div className="flex flex-column">
                <div style={{ width: '100%', overflow: "auto"}} className="flex-grow-1">
                    <ChatBody chatId={chatId} debtor={props.debtor}  userChatState={userChatState} setNotice={setNotice} onUpdateState={updateState}/>
                </div>
            </div>
            <Modal open={showExitDoneDialog} center classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}} 
                showCloseIcon={true} showHeader={false} onClose={() => setShowExitDoneDialog(false)}>
                <div className="pt-0 pb-3 px-3">
                    <div className="font-bold text-lg text-center">이미 채권이 등록 되었습니다.</div>
                    <br/>
                    <br/>
                    <div>그러나 새로 처음부터 상담하실 수도 있습니다.</div>
                </div>
                <div className="py-2">
                    <div className=""><OrangeButton label="임시 저장하고 뒤로 가기" onClick={saveAndBack}/></div>
                    <div className=""><WhiteButton label="새로운 상담하기" onClick={startNewCounsel} /></div>
                </div>
            </Modal>
            <Modal open={showExitDialog} center classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "80vw", height:"auto"}}} 
                showCloseIcon={true} showHeader={false} onClose={() => setShowExitDialog(false)}>
                <div className="pt-0 pb-3 px-3">
                    <div className="font-bold text-lg text-center">아직 상담이 진행중입니다.</div>
                    <br/>
                    <br/>
                    <div>지금 입력하신 정보를 임시 저장하고 뒤로 가시겠습니까?</div>
                    <div className="mt-2">아니면 상담을 종료하고 처음부터 다시 하시겠습니까?</div>
                </div>
                <div className="py-2">
                    <div className=""><OrangeButton label="임시 저장하고 뒤로 가기" onClick={saveAndBack}/></div>
                    <div className=""><WhiteButton label="상담 처음부터 다시 시작하기" onClick={clearCounsel} /></div>
                </div>
            </Modal>
            <Modal open={showInvalid} center showCloseIcon={false} onClose={goBack}
                classNames={{modal: "border-round-lg pb-0"}} styles={{modal: {width: "20rem", height:"auto"}}}>
                <div className="text-center font-bold">잘못된 상담정보입니다.</div>
                <br/>
                <div className=""><OrangeButton label="확인" onClick={goBack}/></div>
            </Modal>
        </div>
    );
}

function ChatNotice(props) {
    return (
        <div className="flex align-items-center" style={{height:"4.5rem", backgroundColor: "#fef6da" }}>
            <div className="m-3">
                <AgentLogo/>            
            </div>
            <div className="w-full text-left">
                <div className="font-bold ">{props.primaryNotice}</div>
                <div className="text-sm ">{props.secondaryNotice}</div>
            </div>
        </div>
    );
}

/*
전체적인 순서는 이렇다. 

1. 이전 히스토리를 전체 읽어온다 -> addMessages로 messages 구축 -> 다 읽음 표시 
2. 히스토리가 없으면 -> treeObject부터 읽어온다 -> postMessage
3. messages 데이터의 변화가 있으면 bubbles를 모두 새로 그림 
*/

function ChatBody(props) {
    // props.userChatState
    // props.onUpdateState
    const {axiosGet, axiosPost, axiosPut, axiosDelete} = useAxios();


    //const [userChatState, setUserChatState] = useState(props.userChatState);
    const userObjectId = useRecoilValue(UserObjectIdAtom);
    const [lastTreeNode, setLastTreeNode] = useState(null);  // check_login 등 갑자기 제어를 벗어날때, 어디로 돌아올지 기록
    const endRef = useRef(null);
    const [showDialog, setShowDialog] = useState(false);
    const [bubbles, setBubbles] = useState([]);  // bubble widgets
    const [messages, setMessages] = useState([]);  // message 데이터
    const [promotion, setPromotion] = useState(null);
    const [loadDone, setLoadDone] = useState(false);  // 기존 채팅 히스토리 다 가져왔나?
    // const [currentTreeId, setCurrentTreeId] = useState(null);  // tree의 intro를 재생할 지, faq를 재생할지 정하기 위해... 
    //const [currentTreeId, setCurrentTreeId] = useSessionStorage(null, 'treeId');  // 위의 state는 바로 반영이 안되어서 sessionStorage 사용 
    const navigate = useNavigate();
    const location = useLocation();
    const [state, setState] = useState(null);  // putPredata 할때마다 상태 가져와서 현재 채팅 완료된 상태인지 유지함.  
    //const [seq, setSeq] = useState(1); // message 업로드할 때 올려주는 seq 값 
    
    // counsel 상태를 읽어옴, 쓸데는 없다. 
    useEffect(() => {
        console.log(" (useEffect) userChatState", props.userChatState);
        if (!props.userChatState?._id) return;
        BoNetUtil.get(`${BACKEND_URL}/api/v1/counsels/${props.userChatState.counselObjectId}`, null,
            (resp) => {
                /*
        {  // Counsel
            "_id": "64ee947ecb8d5431771db457",
            "type": "counsel",
            "userObjectId": "64ee93b66ded60315d12222f",
            "email": "leejaku@gmail.com",
            "state": {
                "login": "active",
                "progress": "start",
                "input": "start"
            },
            "createdAt": "2023-08-30T00:59:42.917Z",
            "modifiedAt": "2023-08-30T00:59:43.078Z",
            "userChatObjectId": "64ee947fcb8d5431771db458",
            "chatState": "chatBot"
        }
                */
                console.log(`counsel.state: progress=${resp.data.data[0].state.progress}, input=${resp.data.data[0].state.input}, login=${resp.data.data[0].state.login}`);
                setState(resp.data.data[0].state);
            },
            (err) => console.error(err)
        );
    }, [props.userChatState])

    // state 변경이 될 때마다 위로 올려준다. 
    useEffect(() => {
        console.log(" (useEffect) state", state);
        props.onUpdateState(state);
    }, [state]);

    // 이전 히스토리를 읽어온다. 
    useEffect(() => {
        console.log(" (useEffect) props.userChatState3", props.userChatState);

        if (!props.userChatState) return;
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${props.userChatState._id}/messages`, {limit: 10000, page: 1, state: "send"},
        //BoNetUtil.get(`${BACKEND_URL}/vws/bo/chatvd/userChats/${props.userChatState._id}/message`, null,
            (resp) => {
                console.log(`Fetch messages: count=${resp.data.count}`);
                // 마지막 버블의 extra.seq를 seq로 설정함. 
                if (resp.data.count > 0) {
                    setSeq(resp.data.data[resp.data.data.length-1].extra.seq);  
                } else {
                    setSeq(1);
                }
                addMessages(resp.data.data);
                setLoadDone(true);
                // - PUT /api/v1/userChats/654b754c85ddb396ef435972/messages
                //  - req: { "userObjectId": "6549e5ac942e5b1a8937aff9", "isUserRead": true }                
                BoNetUtil.put(`${BACKEND_URL}/api/v1/userChats/${props.userChatState._id}/messages`, {userObjectId: userObjectId, isUserRead: true},
                    (resp) => {
                        console.log(`Updated messages as read`);
                    },
                    (err) => console.error(err)
                );
            },
            (err) => {
                console.error(err);
                setLoadDone(true);
            }
        );
    }, [props.userChatState]);

    useEffect(() => {
        console.log(" (useEffect) props.loadDone", props.userChatState);

        if (loadDone !== true) return;

        //(개발환경에서) 여기가 유일하게 한번 들어오는 곳이라서 여기서 채무자 startIpo
        if (props.debtor) {
            console.log('debtorUserChatObjectId:', props.chatId);
            //return은 안받음. startIpo By debtorUserChatObjectId.
            axiosPost(`/api/ipoUser/startIpo/${props.chatId}`);
        }

        console.log(`messages.length = ${messages.length}`);
        if (messages.length === 0) {
            setSeq(1);
            //2312 setSessionStorage("currentTreeId", "");
            window.localStorage['currentTreeId'] = "";
            console.log("message count=0, so startNewTree");
            startNewTree();
        } else {
            // 이미 message가 있더라도, 마지막 것이 어떤 이유에서든 질문이 아니라면
            // 예를 들어 notice 나 speak 라면 그 다음 tree를 읽어오도록 한다. 
            const lastMsg = messages[messages.length-1];

            console.log('last msg', lastMsg);
            console.log(`last msg: type=${lastMsg.treeNode?.type}, func=${lastMsg.treeNode?.function}`);
            if (lastMsg.treeNode?.type === "notice" || lastMsg.treeNode?.type === "speak") {
                fetchTreeNode(lastMsg.treeNode.nextTreeNodeObjectId);
            }
            if (lastMsg.treeNode?.type === "developer" && lastMsg.treeNode?.function === "nft") {
                fetchTreeNode(lastMsg.treeNode.nextTreeNodeObjectId);
            }
            if (lastMsg.treeNode?.type === "developer" && lastMsg.treeNode?.function === "check_login") {
                // 마지막이 User 응답이면, 이 경우는 비로그인 상태에서 입력완료하고, 로그인하면 트리가 바뀌는데... 이상황임. 
                //fetchTreeNode(lastMsg.treeNode.nextTreeNodeObjectId);
                console.log("developer-check_login, so start new tree")

                //231201 코멘트: 로그인창에서 꺼버리면 재진입시 여기 탐.
                startNewTree();
            }

            // 만일 마지막 Question이 nextTreeNodeObjectId가 없다면, Dead-end 임. 
            // 새로 채팅을 시작한다. 
            const lastQuestion = getLastQuestion();
            if (isLeafMessage(lastQuestion)) {
                console.log("last msg=leaf, state=%o", state); //231201 결제창에서 꺼버리면->채권목록 클릭진입해서 여기탐. 결제창 하드코딩?
                if (!hasNft()) {
                //if (!isEnrolled(state)) {
                    if (!props.debtor) { //2404- 채무자 상담은 새로시작하지 않게 설정.
                        startNewTree();
                    }
                } else {
                    //hasNft && 미결제 상태시.
                    if (!props.userChatState.payFinish) {
                        //결제창 띄우기.
                        paymentPopup(props.userChatState._id);
                    }
                }
                //startNewTree();
            } else if (lastMsg.type === "user" && lastMsg.userType === "user") {
                // 마지막이 User 응답이면, 이 경우는 비로그인 상태에서 입력완료하고, 로그인하면 트리가 바뀌는데... 이상황임. 
                // 등록된 채권이면 그만한다. 왜냐하면 결제를 하고도 또 결제가 나오기 때문에 
                console.log("last msg=user, state=%o", state);
                if (!hasNft()) {
                //if (!isEnrolled(state)) {
                    startNewTree();
                }
            }

            /*
            if (lastQuestion && lastQuestion.treeNode && !lastQuestion.treeNode?.nextTreeNodeObjectId) {
                startNewTree();
            }
            */
        }
    }, [loadDone]);

    const startNewTree = () => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${props.userChatState._id}`, null,
            (resp) => {
                //setUserChatState(resp.data.data[0]);
                const uc = resp.data.data[0];
                console.log(`ChatState: type=${uc.type}, userObjectId=${uc.userObjectId}, counsel=${uc.counselObjectId}, tree=${uc.treeObjectId}`);

                let url = `${BACKEND_URL}/api/v1/trees/${resp.data.data[0].treeObjectId}`;
                if (props.debtor) {
                    url += '?debtorUserChatObjectId=' + props.userChatState._id; //채무자 채팅방은 전송해서 멘트 원장 수정용으로 사용. 2404
                }
                BoNetUtil.get(url, null,
                    (resp) => {
                        //setTreeObject(resp.data.data[0]);
                        //setTreeVariant(resp.data.data[0]);
                        //console.log(resp.data.data[0].primaryNotice);
                        props.setNotice(resp.data.data[0].primaryNotice, resp.data.data[0].secondaryNotice); 
                        console.log(`Start TREE NAME = ${resp.data.data[0].name}, TreeID:${resp.data.data[0]._id}`, resp.data.data[0]);
                        postMessage(resp.data.data[0],
                            (resp) => {
                                //console.dir(resp.data);
                            },
                            (err) => console.error(err)                
                        );
                    },
                    (err) => {
                        console.error(err);
                    }
                );
            },
            (err) => {
                console.error(err);
            }   
        );
    }

    // tree를 지정해서 새로 시작한다. 
    // dcol-backend가 상태전환을 제대로 못해줘서 자체적으로 판단해서 tree를 부름. 
    const startNewTreeSpecific = (input, login) => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/trees`, {isActive: true, login: login, input: input, counselType: "counsel"},
            (resp) => {
                if (resp.data.count > 0) {
                    props.setNotice(resp.data.data[0].primaryNotice, resp.data.data[0].secondaryNotice); 
                    console.log(`Start Specific Tree name = ${resp.data.data[0].name}`);
                    postMessage(resp.data.data[0],
                        (resp) => {
                            //console.dir(resp.data);
                        },
                        (err) => console.error(err)                
                    );
                }
            },
            (err) => {
                console.error(err);
            }
        )
    }

    useEffect(() => {
        if (showDialog === true) {
            // GET https://www.vatdahm.com/api/v1/promotions?type=counsel_before
            BoNetUtil.get(`${BACKEND_URL}/api/v1/promotions`, {type: "counsel_after"},
                (resp) => {
                    console.log("promotion=%o", resp.data);
                    // isActive로 조회 한다고 돌아야 한다. 
                    let selected = null;
                    for (const data of resp.data.data) {
                        if (data.isActive === true) {
                            selected = data;
                            break;
                        }
                    }
                    if (selected) {
                        setPromotion(selected);
                    } else {
                        setPromotion(resp.data.data[0]);
                    }                    
                },
                (err) => console.error(err)
            );
        }
    }, [showDialog]);

    // bubble의 변화가 있을때마다, 제일 아래로 내린다. 
    useEffect(() => {
        // timeout으로 delay를 주지 않으면, bubbles 변경으로 인한 rendering이 완료되지 않은 상태에서 이 effect가 불리게 됨. 
        // 그래서 스크롤이 끝까지 가지 않음 
        setTimeout(() => endRef.current?.scrollIntoView( { beavior: "smooth" }), 100);
        setTimeout(() => endRef.current?.scrollIntoView( { beavior: "smooth" }), typingDelay+200);
    }, [bubbles]);

    // messages를 중복없이 추가한다. 
    const addMessages = (msgs) => {
        // key중복, useEffect 여러번 등의 문제가 있어서, _id가 같으면 등록하지 않기로 함. 
        // 입력 msgs 자체 에서 중복 제거 
        if (!Array.isArray(msgs)) msgs = [msgs];
        const filtered = [];
        for (const m of msgs) {
            if (!filtered.find((item) => item._id === m._id)) {
                filtered.push(m);
            }
        }
        
        setMessages((prev) => {
            // 기존에 있는 messages 와 중복되지 않는 것만 추가 
            const add = [];
            for (const m of filtered) {
                // prev에 없는것만 add한다. 
                if (!prev.find((elem) => elem._id === m._id)) {
                    add.push(m);
                }
            }
            return prev.concat(add);
        });
    }

    // messages 데이터를 보고 Bubble을 재구성한다. 
    useEffect(() => {
        // - type==user && userType==user 인것이 노란 사용자입력 버블임. 내용은 message에 있음
        // - type==user && userType==chatBot 인것은 Tree 임. 이건 내용이 extra.node 안에 있음. 
        // - type==treeNode 인 것은 TreeNode임. 이건 내용이 treeNode 안에 있음 
        const bbls = [];

        // 대답을 먼저 조립한다. 
        for (let i = 0; i < messages.length; i++) {
            const msg = messages[i];
            if (msg.type === "user" && msg.userType === "user") {
                if (i == 0) continue;
                // 전에거가 질문이다. 이 질문에 답을 넣는다. 
                messages[i-1]._value = msg.message;
                messages[i-1]._disabled = true;
            }
        }

        let d;  // 현재 날짜
        let m;  // 현재 시간분
        let pd;  // 직전 날짜
        let pm;  // 직전 시간분 

        const isFirstBot = (i) => {
            if (i == 0) return true;
            if (messages[i-1].type === "user" && messages[i-1].userType === "user") return true;
            // 중간에 notice node가 있을 수 있음. 눈에는 안보이지만 messages에는 있으므로
            // 이 경우 하나 더 앞을 봐야 함. (재귀적으로 처리)
            if (messages[i-1].type === "treeNode" && messages[i-1].treeNode.type === "notice") {
                return isFirstBot(i-1);
            } else if (messages[i-1].type === "treeNode" && messages[i-1].treeNode.type === "developer") {
                return isFirstBot(i-1);
            }
            return false;
        }
        
        for (let i = 0; i < messages.length; i++) {
            // 마지막 것에 typing 넣는다. 
            const typing = i === messages.length -1;
            const msg = messages[i];
            // #2 날짜가 바뀌면 날짜를 넣는다. 
            d = getDateStr(msg.createdAt);
            if (pd !== d) {
                // createdAt
                bbls.push(<Tag value={getDateStr(msg.createdAt)} rounded className="mt-3 px-3 font-normal text-xs text-white surface-700 opacity-70" key={msg.createdAt}/>);
            }
            m = getDateMinute(msg.createdAt);
            //console.log(`minutes diff for ${i} = ${m !== pm}`);
            const showAgent = isFirstBot(i);
            if (msg.type === "treeNode") {
                if (msg.treeNode.type === "notice") {
                    // notice이면 bubble을 넣지 않고, 공지를 바꾼다. 
                    // notice node = 상단의 알림 메시지를 설정하는 노드임. 
                    // 이건 왜 또 primaryMessage냐? primaryNotice가 아니고???
                    props.setNotice(msg.treeNode.primaryMessage, msg.treeNode.secondaryMessage);
                } else {
                    bbls.push(<MessageBubble message={msg} onAnswer={onAnswerPost} key={msg._id} showTime={m !== pm} showAgent={showAgent} typing={typing} fetchNext={fetchNextNode}/>);
                }
            } else if (msg.type === "user" && msg.userType === "chatBot") {
                // 처음 시작하는 Tree의 경우임. 
                bbls.push(<MessageBubble message={msg} onAnswer={onAnswerPost} key={msg._id} showTime={m !== pm} showAgent={showAgent} typing={typing} fetchNext={fetchNextNode}/>);
            } else if (msg.type === "user" && msg.userType === "user") {
                bbls.push(<UserBubble message={msg} key={msg._id} showTime={m !== pm}/>);
            } else {
                console.warn(`Unhandled message: _id=${msg._id}, type=${msg.type}, userType=${msg.userType}`);
            }
            pd = d;
            pm = m;
        }
        setBubbles(bbls);
    }, [messages]);
    
    // treeVariant 데이터를 기반으로 type을 인식하고, post message를 만들어서 post함. 그리고 받은 응답을 messages에 추가함. 그리고 결과를 리턴함. 
    // 이 함수는 사용자 입력에 반응하는 것임. 
    const postMessage = (treeVariant, onSuccess, onFail) => {
        const now = new Date().toISOString();
        let msg = null;
        const nextSeq = getNextSeq();
        if ("introElementActive" in treeVariant) {  // Tree이면 
            //TODO: Tree에는 IntroElement와 faqElement가 있음
            // 상태변화로 들어왔으면, IntroElement를... 그냥이면 faqElement를 가야 한다???
            //2312 let currentTreeId = getSessionStorage("currentTreeId");
            let currentTreeId = window.localStorage['currentTreeId'];
            console.log(`POST: Tree.Intro: Tree current=${currentTreeId}, now=${treeVariant._id}, intro=${treeVariant.introElementActive}`);
            if (currentTreeId !== treeVariant._id && treeVariant.introElementActive === true) {

                console.log(`** TREE CHANGE: Now treeId = ${currentTreeId}, t=${treeVariant._id}`);
                //2312. userChat의 Tree 수정 추가. QA1 버그.
                axiosPut(`/api/v1/userChats/${props.userChatState._id}`, {treeObjectId:treeVariant._id});

                //2312 setSessionStorage("currentTreeId", treeVariant._id);
                window.localStorage['currentTreeId'] = treeVariant._id;
                //2312 currentTreeId = getSessionStorage("currentTreeId");
                currentTreeId = window.localStorage['currentTreeId'];

                BoNetUtil.get(`${BACKEND_URL}/api/v1/treeElements/${treeVariant.introElement.treeElementObjectId}`, null,
                    (resp) => {
                        postMessage(resp.data.data[0]);
                    }, 
                    (err) => {
                        console.error(err);
                    }
                );
            } else {
                // Tree 이면
                console.log(`POST: Tree.FAQ, treeId=${treeVariant._id}`);
                //2312 setSessionStorage("currentTreeId", treeVariant._id);
                window.localStorage['currentTreeId'] = treeVariant._id;
                const ment = treeVariant.ments.join("\n");
                msg = {
                    type:"user",
                    userType:"chatBot",
                    state:"send",
                    message: ment,
                    extra: {
                    node: {
                        _id: `faq-${now}`,
                        type: "select",
                        message: ment,
                        selections: treeVariant.faqElements,                      
                        createdAt: now,
                        isSelectFaq: true,
                        extra: {
                            seq: nextSeq,
                            treeId: treeVariant._id
                        }
                    },
                    seq: nextSeq,
                    type: "select",
                    treeId: treeVariant._id
                    }
                }
            }
        } else if ('startTreeNode' in treeVariant) {
            // TreeElement 이면, startTreeNode를 찾아 treeNode 정보를 얻음 
            console.log(`POST: TreeElement`);
            fetchTreeNode(treeVariant.startTreeNode);
        } else {
            // TreeNode 이면 
            console.log(`POST: TreeNode, ID=${treeVariant._id}, type=${treeVariant.type}, msg=${treeVariant.message}`, treeVariant);
            msg = {
                type: "treeNode",
                userType: "chatBot",
                state: "send",
                treeNodeObjectId: treeVariant._id,
                message: treeVariant.message,
                extra: {
                   seq: nextSeq,
                   type: treeVariant.type,
                   treeId: props.userChatState.treeObjectId
                }
            }
        }
        if (msg !== null) {
            console.log('### NODE Type check,  treeVariant', treeVariant.type);

            if (treeVariant.type === "developer" && treeVariant.function === "price_confirm") {
                // price_confirm 특수한 상황
                console.log('###DEVELOPER NODE,  PRICE_CONFIRM #############');
                // dcol에 올리면 에러가 나므로, BO에만 올린다. 
                BoNetUtil.put(`${BACKEND_URL}/vws/bo/chatvd/userChats/${props.userChatState._id}/priceNode`, msg,
                    (resp) => {
                        console.dir(resp.data);
                        const msgData = resp.data.data[0];
                        addMessages(msgData);
                        if (onSuccess) onSuccess(resp.data.data[0]);
                    },
                    (err) => {
                        console.error(err);
                        if (onFail) onFail(err);
                    }
                );
            } else {   
                // 일반적인 상황: chatBot
                console.log("CHAT:",props.userChatState._id, msg);

                BoNetUtil.post(`${BACKEND_URL}/api/v1/userChats/${props.userChatState._id}/messages`, msg,
                    (resp) => {
                        const msgData = resp.data.data[0];
                        addMessages(msgData);
                        //231205 #23 BO에 메시지 보내준다.
                        // BoNetUtil.put(`${BACKEND_URL}/vws/bo/chatvd/userChats/${props.userChatState._id}/message/${msgData._id}`, null,
                        //     (resp) => {
                        //         console.log(`Put message to BO chat=${props.userChatState._id}, msg=${msgData._id}`);
                        //     },
                        //     (err) => console.error(err)
                        // );
                        if (onSuccess) onSuccess(resp.data.data[0]);

                        // #32 위치가 이 아래 있었는데, 여기로 옮겨서 순서 유지 
                        // 자동으로 다음 노드를 읽어야 하는 경우 처리 
                        if (treeVariant.nextTreeNodeObjectId) {
                            if (treeVariant.type === "notice") { // || treeVariant.type === "speak") {  // fetchNextNode 에서 처리 
                                // 다음 노드로 진행해야 한다. 
                                fetchTreeNode(treeVariant.nextTreeNodeObjectId);
                            /*} else if (treeVariant.type === "developer" && treeVariant.function === "nft") {
                                // 다음 노드로 진행해야 한다. 
                                fetchTreeNode(treeVariant.nextTreeNodeObjectId);*/  // fetchNextNode에서 처리 
                            } else if (treeVariant.type === "developer") {
                                if (treeVariant.function === "check_login") {
                                    // 비회원 입력완료 후, 로그인을 한다. 
                                    // 먼저 로그인 팝업을 띄워야 함. 
                                    // 로그인되면, "입력완료+로그인" FAQ트리로 간다. 
                                    // 로그인 안되면, "로그인 잘하셨나요?" 트리로 간다?? 
                                    setLastTreeNode(treeVariant);  // 임시저장 
                                    setShowDialog(true);
                                }
                            }
                        } 
                        if (isLeafTreeNode(treeVariant) && !isQuestionNode(treeVariant)) {
                            console.log("DEAD END ~~~~ counselObjectId:" + props.userChatState.counselObjectId);
                            if (props.debtor) {
                                // alert('상담이 완료되었습니다. 감사합니다!')
                                console.log('채무자 상담 완료 ');
                                return;
                            }
                            // counsel을 읽어서 nftUrl 이 채워져 있는지 확인한다? 그럼 결제전 정보를 넣을 준비가 된 것이다. 
                            // BO#1 
                            BoNetUtil.get(`${BACKEND_URL}/api/v1/counsels/${props.userChatState.counselObjectId}`, null,
                                (resp) => {
                                    const counsel = resp.data.data[0];
                                    console.log(`State: progress=${counsel.state.progress}, input=${counsel.state.input}, login=${counsel.state.login}`);
                                    setState(resp.data.data[0].state);
                                    if (!!counsel.nftUrl) {
                                        // 완료된 상태임. 결제창 팝업.
                                        paymentPopup(props.userChatState._id);
                                        //이하 기존소스.
                                        // BoNetUtil.get(`${BACKEND_URL}/vws/bo/chatvd/userChats/${props.userChatState._id}/priceNode`, null,
                                        //     (resp) => {
                                        //         postMessage(resp.data.data[0]);
                                        //     },
                                        //     (err) => console.error(err)
                                        // )
                                    } else {
                                        startNewTree();
                                    }
                                },
                                (err) => console.error(err)
                            );
                        }
                    },
                    (err) => {
                        if (onFail) onFail(err);
                    }
                );
            }
        }
    }

    //231201 결제창 모듈.
    const paymentPopup = async(userChatObjectId) => {

        let priceNodeDto = await axiosGet(`/vws/bo/chatvd/userChats/${userChatObjectId}/priceNode`);
        postMessage(priceNodeDto.data[0]);

    }


    // #3 때문에 생김. speak 노드 같은게 바로 다음 노드를 불러와야 하는데
    // 애니메이션 때문에 시간 딜레이가 있음. 그래서 애니메이션 끝나면 이 함수를 불러서 다음 노드를 가지고 오게 함. 
    const fetchNextNode = (message) => {
        console.log(`FETCH Next msg: ${message.treeNode.type}, ${message.treeNode.function}`);
        if (message.treeNode.type === "speak") {
            // 다음 노드로 진행해야 한다. 
            console.log("FETCH speak node");
            fetchTreeNode(message.treeNode.nextTreeNodeObjectId);
        } else if (message.treeNode.type === "developer" && message.treeNode.function === "nft") {
            // 다음 노드로 진행해야 한다. 
            console.log("FETCH NFT node");
            fetchTreeNode(message.treeNode.nextTreeNodeObjectId);
        } 
    }

    const isLeafMessage = (message) => {
        if (message.treeNode?.nextTreeNodeObjectId) {  // TreeNode
            return false;
        } else if (message.extra?.node?.selections) {  // Tree
            return false;
        } else if (message.treeNode?.selections) {
            // select node 
            return false;
        } else {
            return true;
        }
    }
    
    const isLeafTreeNode = (treeVariant) => {
        if ('faqElements' in treeVariant) {
             return false;
        } else if ('startTreeNode' in treeVariant) {
            return false;
        } else if ('selections' in treeVariant) {
            return false;
        } else {
            // TreeNode 이면 
            return !!!treeVariant.nextTreeNodeObjectId;
        }
    }

    const isQuestionNode = (treeVariant) => {
        if (treeVariant.type === "data_input" || treeVariant.type === "cm_input" || treeVariant.type === "select") {
            return true;
        }
        if (treeVariant.type === "developer") {
            if (treeVariant.function === "check_login" || treeVariant.function === "price_tag"
            || treeVariant.function === "price_confirm") {
                return true;
            }
        }
        return false;
    }
    /*
    const nextSeq = () => {
        const s = seq;
        setSeq((prev) => prev+1);
        return s+1;
    }
    */

    // 사용자가 클릭하여, 선택하면 POST message하고 응답을 받아 그것을 messages에 넣는다. 
    const onAnswerPost = (label) => {
        let ans;
        if (Array.isArray(label)) {
            ans = label.join(',');  // 복수 입력의 경우 
        } else if (typeof label === "number") {
            ans = label.toString();  // moneySize 입력의 경우
        } else if (typeof label === "object" && "planType" in label) {
            if (!!Number(label.deposit)) {
                // price_confirm에 대한 응답 
                ans = `착수금: ${commaNumber(label.deposit)}원, 성공보수: ${label.payRatio}%`;  // "착수금: 30,000원, 성공보수: 15%"
            } else {
                // price_tag에 대한 응답
                ans = `착수금: ${label.deposit}, 성공보수: ${label.payRatio}`;  // "착수금: 30~50만원, 성공보수: 15~25%"
            }
        } else {
            ans = label;
        }
        const nextSeq = getNextSeq();
        const msg = {
            type: "user",
            userType: "user",
            message: ans,
            userObjectId: userObjectId,

            //2404 추가. 채무자 채팅방 고유키.
            debtorUserChatObjectId:props.debtor?props.chatId:null,
            treeNodeObjectId: getLastQuestion()?.treeNode?._id, //2404- cm_input처리를 위해서 추가.

            state: "send",
            extra: {
               seq: nextSeq,
               sender: "system",
               treeId: props.userChatState.treeObjectId
            }
        };

        // 일반적인 상황: USER_ANSWER
        console.log("ANSWER:",props.userChatState._id, msg);

        BoNetUtil.post(`${BACKEND_URL}/api/v1/userChats/${props.userChatState._id}/messages`, msg,
            (resp) => {
                const msgData = resp.data.data[0];
                addMessages(msgData);

                //231205 #23 BO에 메시지 보내준다.
                // BoNetUtil.put(`${BACKEND_URL}/vws/bo/chatvd/userChats/${props.userChatState._id}/message/${msgData._id}`, null,
                //     (resp) => {
                //         console.log(`Put user message to BO chat=${props.userChatState._id}, msg=${msgData._id}`);
                //     },
                //     (err) => console.error(err)
                // );
            
                const question = getLastQuestion();
                if (question !== null) {
                    // pre-data를 업로드한다. 
                    if ("treeNode" in question) {
                        if ("field" in question.treeNode) {
                            putPredata(question.treeNode.field, ans);
                        } else if ("pricePlan" in question.treeNode) {
                            if (question.treeNode.function === "price_tag") {
                                // price_tag 인 경우에만 dcol predata에 올란다.
                                // price_confirm은 BO에 올린다.
                                putPredata("pricePlan", {pricePlan: label});
                            }
                        }
                    }
                    // 질문 노드를 찾아서 다음에 어디로 갈지 정한다.        
                    if (question.treeNode?.type === "developer" && question.treeNode?.function === "price_confirm") {
                        console.log('###DEVELOPER NODE,  PRICE_CONFIRM2 #############');
                        // caseInfo에 결정된 가격을 PUT한다.
                        // 먼저 caseNo 받아옴 
                        BoNetUtil.get(`${BACKEND_URL}/vws/bo/case/info`, {chatObjectId: props.userChatState._id},
                            (resp) => {
                                //setCaseInfo(resp.data);
                                const caseNo = resp.data.caseNo;

                                BoNetUtil.post(`${BACKEND_URL}/vws/bo/case/${caseNo}/price`, label,
                                    (resp) => {
                                        // label 이 pricePlan이다. 
                                        navigate(`/creditor/${props.userChatState._id}`);
                                    },
                                    (err) => console.error(err)
                                );
                            },
                            (err) => console.error(err)
                        );
                        return;
                    }             
                    if (question.treeNode?.nextTreeNodeObjectId) {  // TreeNode
                        fetchTreeNode(question.treeNode?.nextTreeNodeObjectId);
                    } else if (question.extra?.node?.selections) {  // Tree
                        // - GET https://www.vatdahm.com/api/v1/treeElements/6328a8260602c4f7308e005d  { 네 맞아요의 treeObjectId 임}
                        const selections = question.extra?.node?.selections;
                        for (let i = 0; i < selections.length; i++) {
                            if (selections[i].message === label) {
                                const elemId = selections[i].treeElementObjectId;
                                BoNetUtil.get(`${BACKEND_URL}/api/v1/treeElements/${elemId}`, null,
                                    (resp) => {
                                        postMessage(resp.data.data[0]);
                                    }, 
                                    (err) => {
                                        console.error(err);
                                    }
                                );
                            }
                        }                        
                    } else if (question.treeNode?.selections) {
                        // select node 
                        const selections = question.treeNode?.selections;
                        for (let i = 0; i < selections.length; i++) {
                            if (selections[i].message === label) {
                                const elemId = selections[i].treeNodeObjectId;
                                BoNetUtil.get(`${BACKEND_URL}/api/v1/treeNodes/${elemId}`, null,
                                    (resp) => {
                                        postMessage(resp.data.data[0]);
                                    }, 
                                    (err) => {
                                        console.error(err);
                                    }
                                );
                            }
                        }                        
                    } else {
                        // nextTreeNode 가 없는 경우이다. 여기 들어오지는 않음 
                        // 마지막이 질문 노드이고, 대답까지 한 경우임. 질문노드에 nextTreeNodeId 가 없다????
                        console.log(`DEAD END !!`);
                        getBoCounsel(props.userChatState.counselObjectId,
                            (counsel) => {
                                const st = counsel.state;
                                st.input = "complete";
                                startNewTreeSpecific(st.input, st.login);
                            },
                            (err) => {
                                console.error(err);
                            }
                        );
                        
                    }
                } else {
                    console.warn("Can't find previous question.")
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }

    // 사용자 대답의 질문이 되는, 가장 최근 message를 찾는다. 
    const getLastQuestion = () => {
        for (let i = messages.length-1; i >= 0; i--) {
            if (messages[i].type === "treeNode") {
                return messages[i];
            } else if (messages[i].type === "user" && messages[i].userType === "chatBot") {
                return messages[i];
            } 
        }
        return null;
    }
    // NFT가 있으면 종료하기 위해서임. 
    const hasNft = () => {
        for (let i = messages.length-1; i >= 0; i--) {
            if (!!messages[i].nftUrl) {
                return true;
            }            
        }
        return false;
    }

    const putPredata = (field, value) => {
        let body;
        if (typeof value === 'string' || value instanceof String) {
            console.log(`field=${field}, answer=${value}`);
            body = JSON.parse(`{"${field}": "${value}"}`);
        } else {
            // pricePlan 같이 객체가 넘어오는 경우도 있음. 
            body = value;
        }
        // Dcol-backend에 먼저 보낸다. 
        BoNetUtil.put(`${BACKEND_URL}/api/v1/counsels/${props.userChatState.counselObjectId}`, body,
            (resp) => {
                //console.dir(resp.data);
                setState(resp.data.data[0].state);
                //231205 3table제거. BO에 보낸다 : predata생성 - 위에서 처리.
                // BoNetUtil.put(`${BACKEND_URL}/vws/bo/chatvd/counsels/${props.userChatState.counselObjectId}`, body,
                //     (resp) => {
                //         //console.dir(resp.data);
                //     },
                //     (err) => {
                //         console.error(err);
                //     }
                // )
            },
            (err) => console.error(err)
        );
    }

    const fetchTreeNode = (treeNodeId) => {
        if (!treeNodeId) return;
        BoNetUtil.get(`${BACKEND_URL}/api/v1/treeNodes/${treeNodeId}`, null,
            (resp) => {
                //console.dir(resp.data.data[0]);
                postMessage(resp.data.data[0]);
            },
            (err) => {
                console.error(err);
            }
        );
    }

    const cancelLogin = () => {
        setShowDialog(false);
        if (!lastTreeNode) return;
        fetchTreeNode(lastTreeNode.nextTreeNodeObjectId);
    }
    
    const doLogin = () => {
        setShowDialog(false);
        setReturnUrl(location.pathname);
        navigate('/signin');
    }

    // vh -> dvh: https://abcdqbbq.tistory.com/104
    return (
        <div style={{backgroundColor: "#dce8fa", minHeight:"calc(100dvh - 7.7rem)"}}> {/* TODO: 높이 관련, 더 나이스한 방법을 찾자.  */}
            {bubbles}
            <div ref={endRef} style={{height:55}}>
                &nbsp;
            </div>
            {/* 로그인 유도 다이얼로그 */}
            <Modal open={showDialog} center showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}
                classNames={{modal: "border-round-lg p-0"}} styles={{modal: {width: "85vw", height:"auto"}}}>
                <div className="flex flex-column h-full">
                    <div className="p-3 flex flex-column flex-grow-1" style={{backgroundColor: promotion?.color, color: promotion?.fontColor}}>
                        <div className="text-xl font-bold">{promotion?.title}</div>
                        <div className="mt-2">{promotion?.subTitle}</div>
                        <div className="text-center flex-grow-1 flex align-items-center justify-content-center">
                            <div className=""><img src={promotion?.imageUrl} style={{width:"15rem", maxHeight:"11rem"}} /></div>
                        </div>
                    </div>
                    <div className="grid mt-2 mx-2">
                        <div className="col-6"><WhiteButton label="다음에 할게요" onClick={cancelLogin} /></div>
                        <div className="col-6"><OrangeButton label="로그인" onClick={doLogin}/></div>
                    </div>
                </div>
            </Modal>
        </div>
    );
}

// 실제 widget으로 변환함. 
function MessageBubble(props) {
    // props.message
    // props.onAnswer: function 
    // props.showTime: boolean
    // props.showAgent
    // props.typing: typing animation을 보여주기 display 할 것인가?
    // props.fetchNext

    // - type==user && userType==user 인것이 노란 사용자입력 버블임. 내용은 message에 있음
    // - type==user && userType==chatBot 인것은 Tree 임. 이건 내용이 extra.node 안에 있음. 
    // - type==treeNode 인 것은 TreeNode임. 이건 내용이 treeNode 안에 있음 
    if (props.message.type === "treeNode") {
        if (props.message.treeNode.type === "data_input" || props.message.treeNode.type === "cm_input") {
            if (props.message.treeNode.method === "number") {
                return (
                    <BotBubbleMoneyInput message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>
                );
            } else if (props.message.treeNode.method === "text") {
                return (
                    <BotBubbleTextInput message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>
                );
            } else if (props.message.treeNode.method === "selection") {
                return (
                    <BotBubbleSelectionInput message={props.message} onAnswer={props.onAnswer} multiple={false} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>
                )
            } else if (props.message.treeNode.method === "multiSelection") {
                return (
                    <BotBubbleSelectionInput message={props.message} onAnswer={props.onAnswer} multiple={true} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>
                )
            } else {
                return (
                    <div>Unknown data_input method {props.message.treeNode.method}</div>
                )
            }
        } else if (props.message.treeNode.type === "speak") {
            return (
                <BotBubbleSpeak message={props.message} onAnswer={props.onAnswer} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} 
                    typing={props.typing} fetchNext={props.fetchNext}/>
            )
        } else if (props.message.treeNode.type === "select") {
            return (
                <BotBubbleSelect message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>
            );
        } else if (props.message.treeNode.type === "developer") {
            if (props.message.treeNode.function === "price_tag") {
                return (
                    <PriceTag message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} function={props.message.treeNode.function} typing={props.typing}/>
                );
            } else if (props.message.treeNode.function === "price_confirm") {
                // 가격 확정용. 새로 생긴 것임. 
                return (
                    <PriceTag message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} function={props.message.treeNode.function} typing={props.typing}/>
                );
            } else if (props.message.treeNode.function === "nft") {
                return (
                    <Nft message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} 
                        typing={props.typing} fetchNext={props.fetchNext}/>
                );
            }
        }
    } else if (props.message.type === "user" && props.message.userType === "chatBot") {
        return <BotBubbleRoot message={props.message} onAnswer={props.onAnswer} value={props.message._value} disabled={props.message._disabled} showTime={props.showTime} showAgent={props.showAgent} typing={props.typing}/>;
    } else if (props.message.type === "user" && props.message.userType === "user") {
        // postMessage에서 처리함 
    } else {
        return null;
    }
}

/*
    "pricePlan": [
        {
            "planType": "type1",
            "planDescription": "초기 착수금을 아낄 수 있는 실속있는 플랜",
            "deposit": "30~50만원",
            "payRatio": "15~25%"
        },
    ]
*/

function PriceTag(props) {
    // props.message
    // props.onAnswer
    // props.value: TODO
    // props.showAgent: TODO
    // props.function: price_tag or price_confirm
    // props.typing 
    const [selected, setSelected] = useState(-1);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (props.typing === true) {            
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    const selectPlan = (index) => {
        console.log(`select ${index}`);
        setSelected(index);
    }

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div style={{marginLeft:"3.7rem"}} className="pt-3">            
                <ScrollMenu>
                    { props.message.treeNode.pricePlan.map((item, index) => 
                        <PricePlan pricePlan={item} key={index} id={index} onAnswer={props.onAnswer} onSelect={selectPlan} selected={selected} function={props.function}/>) 
                    }
                </ScrollMenu>
            </div>
        );
    }
}

function PricePlan(props) {
    console.log('props.pricePlan:', props?.pricePlan);
    // props.pricePlan
    // props.key
    // props.onAnswer
    const [agreed, setAgreed] = useState(false);
    const [deposit, setDeposit] = useState(null);  // price_confirm의 경우 그냥 숫자로 와서 humanize 하기 위함임. 
    const [payRatio, setPayRatio] = useState(null);
    useEffect(() => {
        if (props.id === props.selected) {
            setAgreed(true);
        } else {
            setAgreed(false);
        }
    }, [props.selected]);

    const clickPlan = () => {
        props.onAnswer(props.pricePlan);
        //putPredata("pricePlan", props.pricePlan);
    }

    useEffect(() => {
        if (agreed === true) {
            props.onSelect(props.id);
        }
    }, [agreed]);

    useEffect(() => {
        if (!!Number(props.pricePlan.deposit)) {
            setDeposit(commaNumber(props.pricePlan.deposit) + "원");
        } else {
            setDeposit(props.pricePlan.deposit);
        }
        if (!!Number(props.pricePlan.payRatio)) {
            setPayRatio(props.pricePlan.payRatio+ "%");
        } else {
            setPayRatio(props.pricePlan.payRatio);
        }
        console.log('props.pricePlan', props.pricePlan);
    }, [props.pricePlan]);
    
    return (
        <div className="bg-white border-round-xl p-4 text-left mx-1" style={{width:"16rem", height:"23.5rem"}}>
            <div className="text-xl font-bold">{props.pricePlan.planDescription}</div>
            {/*<div className="text-lg">-회수 못할 경우 착수금 반환 혜택(한시적)</div>*/}
            <div className="text-sm text-600 mt-2">착수금</div>
            <div className="text-lg font-bold">{deposit}</div>
            <div className="text-sm text-600 mt-2">성공보수</div>
            {/*payRatio 25%=>30% 하드코딩 2402*/}
            <div className="text-lg font-bold">{payRatio==='25%'?'30%':payRatio}</div>
            <div className="flex mt-3">
                <div><Checkbox onChange={e => setAgreed(e.checked)} checked={agreed}></Checkbox></div>
                <div className="text-xs text-600 ml-2">(필수) 본 사건의 진행과 관련된 문자, 이메일, 앱 푸시 정보 수신에 동의합니다.</div>
            </div>
            <br/>
            { props.function === "price_tag"
            ? <OrangeButton label="이 플랜으로 상담" desc="" disabled={!!!agreed} onClick={clickPlan}/>
            : <OrangeButton label="이 플랜으로 결제" desc="(신용카드,무통장입금,가상결제,간편결제 가능)" disabled={!!!agreed} onClick={clickPlan}/>
            }
            
        </div>
    )
}

function Nft(props) {
    // props.message
    // props.multiple: true or false 
    // props.onAnswer
    // props.value
    // props.disabled
    // props.showAgent
    // props.typing
    // props.fetchNext
    const [showNft, setShowNft] = useState(false);
    const [loading, setLoading] = useState(true);
    useEffectOnce(() => {
        if (props.typing === true) {            
            _.delay(() => {
                setLoading(false);
                props.fetchNext(props.message);
            }, typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>
                    <div className="cursor-pointer" onClick={() => setShowNft(true)}>
                        <img src={props.message.nftUrl} className="w-full"/>
                    </div>
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>     
                <Dialog visible={showNft} className="w-full m-2" style={{maxWidth: "36rem"}} onHide={() => setShowNft(false)}
                    draggable={false} resizable={false} closable={false} showHeader={false} dismissableMask={true}
                    contentClassName="p-0">
                    <div>
                        <img src={props.message.nftUrl} className="w-full"/>
                    </div>
                </Dialog>     
            </div>
        );
    }
}

function BotBubbleRoot(props) {
    // props.treeVariant: 안씀
    // props.message
    // props.onAnswer
    // props.value
    // props.disabled
    // props.showTime: boolean
    const [ments, setMents] = useState([]);
    const [faqElements, setFaqElements] = useState([]);
    const [canInput, setCanInput] = useState(!!!props.disabled);  // 입력이 가능한가?
    const [loading, setLoading] = useState(true);
    // @Deprecated
    /*
    useEffect(() => {
        if (!props.treeVariant) return;
        setMents(props.treeVariant.ments.join('\n'));
        setFaqElements(props.treeVariant.faqElements);
    }, [props.treeVariant]);
    */
    useEffect(() => {
        if (!props.message) return;
        setMents(props.message?.extra?.node?.message.split("\n"));
        setFaqElements(props.message.extra.node.selections);
    }, [props.message]);

    const onAnswer = (label) => {
        setCanInput(false);
        props.onAnswer(label);
    }

    useEffect(() => {
        if (!props.value) {
            // 값이 없을 때, 즉 새로 입력하는 경우에 
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo/>            
                </div>
                <div className="speech-bubble bot-bubble-w text-left">
                    <div className="mb-1">
                        {ments.map((item, idx) => <div key={idx}>{item}</div>)}
                    </div>
                    <ChatButtonGroupFaq elements={faqElements} onAnswer={onAnswer} canInput={canInput} value={props.value}/>                
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function BotBubbleSelect(props) {
    // props.message
    // props.onAnswer
    // props.disabled
    // props.showAgent
    // props.typing
    const [message, setMessage] = useState([]);
    const [selections, setSelections] = useState([]);
    const [canInput, setCanInput] = useState(!!!props.disabled);  // 입력이 가능한가?
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!props.message) return;
        setMessage(props.message?.treeNode?.message.split("\n"));
        setSelections(props.message.treeNode.selections);
    }, [props.message]);

    const onAnswer = (label) => {
        setCanInput(false);
        props.onAnswer(label);
    }

    useEffect(() => {
        if (props.typing === true) {            
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>
                    <div className="mb-1">
                        {message.map((item, idx) => <div key={idx}>{item}</div>)}
                    </div>
                    {props.message?.treeNode?.imageUrl && <div><img src={props.message.treeNode.imageUrl} className="w-full"/></div>}
                    <ChatButtonGroupFaq elements={selections} onAnswer={onAnswer} canInput={canInput}/>                
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function BotBubbleSpeak(props) {
    // props.treeVariant: not used
    // props.message
    // props.showAgent
    // props.typing
    // props.fetchNext
    const [message, setMessage] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        if (!props.treeVariant) return;
        setMessage(props.treeVariant.message);
    }, [props.treeVariant]);

    useEffect(() => {
        if (!props.message) return;
        setMessage(props.message?.treeNode?.message.split("\n"));
    }, [props.message]);

    useEffectOnce(() => {
        if (props.typing === true) {
            // 값이 없을 때, 즉 새로 입력하는 경우에 
            _.delay(() => {
                setLoading(false);
                props.fetchNext(props.message);
            }, typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>
                    <div className="mb-1">
                        {message.map((item, idx) => <div key={idx}>{item}</div>)}
                    </div>
                    {props.message?.treeNode?.imageUrl && <div><img src={props.message.treeNode.imageUrl} className="w-full"/></div>}
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function BotBubbleMoneyInput(props) {
    // props.treeVariant: not use
    // props.message
    // props.onAnswer
    // props.value
    // props.disabled: boolean
    // props.showTime
    // props.typing
    const [message, setMessage] = useState(null);
    const [money, setMoney] = useState(props.value);
    const [canGo, setCanGo] = useState(false);  // 아래 ConfirmInput을 클릭할 수 있나?
    const [canInput, setCanInput] = useState(!!!props.disabled);  // 입력이 가능한가?
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!props.message) return;
        //console.dir(props.message);
        setMessage(props.message.treeNode.message);
    }, [props.message]);

    useEffect(() => {
        //console.log(`typing ... ${money}`);
        if (money && money > 0) {
            setCanGo(true);
        } else {
            setCanGo(false);
        }
    }, [money]);

    const onConfirm = () => {
        setCanInput(false);
        props.onAnswer(money);
    };

    useEffect(() => {
        if (props.typing === true) {
            // 값이 없을 때, 즉 새로 입력하는 경우에 
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>                
                    <div className="mb-1">{message}</div>
                    {/*<InputNumber inputId="money" size={15} min={0} max={999999999999} className="w-full" value={money} onChange={refineMoney} disabled={!canInput} prefix="₩" />*/}
                    <InputMoney onChange={setMoney} value={money} className="w-full" disabled={!canInput}/>
                    <div className="mt-1 text-sm text-700">
                        <span>{getKoreanNumber(money)}</span>
                        <span>{money > 0 ? "원" : ""}</span>
                    </div>
                    <ConfirmInput onClick={onConfirm} enabled={canGo && canInput}/>
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function BotBubbleTextInput(props) {
    // props.message
    // props.onAnswer
    // props.value
    // props.disabled: boolean
    // props.showTime
    // props.typing
    const [message, setMessage] = useState(null);
    const [text, setText] = useState(props.value || "");
    const [canGo, setCanGo] = useState(false);  // 아래 ConfirmInput을 클릭할 수 있나?
    const [canInput, setCanInput] = useState(!!!props.disabled);  // 입력이 가능한가?
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!props.message) return;
        //console.dir(props.message);
        setMessage(props.message.treeNode.message);
    }, [props.message]);

    useEffect(() => {
        //console.log(`typing ... ${text}`);
        if (text && text.length > 0) {
            setCanGo(true);
        } else {
            setCanGo(false);
        }
    }, [text]);

    const onConfirm = () => {
        setCanInput(false);
        props.onAnswer(text);
    };

    useEffect(() => {
        if (props.typing === true) {
            // 값이 없을 때, 즉 새로 입력하는 경우에 
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>                
                    <div className="mb-1">{message}</div>
                    <InputText size={15} className="w-full" value={text} onChange={(e) => setText(e.target.value)} disabled={!canInput} />
                    <ConfirmInput onClick={onConfirm} enabled={canGo && canInput}/>
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function BotBubbleSelectionInput(props) {
    // props.message
    // props.multiple: true or false 
    // props.onAnswer
    // props.value
    // props.disabled
    // props.showAgent
    // props.typing
    const [message, setMessage] = useState(null);
    const [options, setOptions] = useState([]);
    const [selected, setSelected] = useState([]);  // 선택된 label
    const [canGo, setCanGo] = useState(false);  // 아래 ConfirmInput을 클릭할 수 있나?
    const [canInput, setCanInput] = useState(!!!props.disabled);  // 입력이 가능한가?
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (!props.treeVariant) return;
        setMessage(props.treeVariant.message);
        setOptions(props.treeVariant.options);
    }, [props.treeVariant]);

    useEffect(() => {
        if (!props.message) return;
        setMessage(props.message.treeNode.message);
        setOptions(props.message.treeNode.options);
    }, [props.message]);

    useEffect(() => {
        if (selected && selected.length > 0) setCanGo(true);
        else setCanGo(false);
    }, [selected]);

    const onConfirm = () => {
        setCanInput(false);
        props.onAnswer(selected);
    };

    const onSelect = (label) => {
        if (!canInput) return;
        setSelected(label);
    }

    useEffect(() => {
        if (props.typing === true) {
            // 값이 없을 때, 즉 새로 입력하는 경우에 
            _.delay(() => setLoading(false), typingDelay);
        } else {
            setLoading(false);
        }
    }, []);

    if (loading) {
        return <TypingBubble showAgent={props.showAgent}/>;
    } else {
        return (
            <div className="flex align-items-start pt-3">
                <div className="ml-3 mr-2">
                    <AgentLogo hidden={!props.showAgent}/>            
                </div>
                <div className={classnames("bot-bubble-w text-left", {"speech-bubble": props.showAgent}, {"round-bubble": !props.showAgent})}>
                    <div className="mb-1">{message}</div>
                    <ChatButtonGroup options={options} onSelect={onSelect} multiple={props.multiple} canInput={canInput} value={props.value}/>                
                    <ConfirmInput onClick={onConfirm} enabled={canGo && canInput}/>
                </div>
                <ShowTime message={props.message} showTime={props.showTime}/>            
            </div>
        );
    }
}

function ConfirmInput(props) {
    // props.enabled: 활성화 여부 
    // props.onClick

    const onClick = (e) => {
        if (props.enabled === true && props.onClick) {
            props.onClick(e);
        }
    }

    return (
        <div className={classnames("text-xs text-right mt-3 mb-1", {"underline cursor-pointer": !!props.enabled}, {"text-400": !!!props.enabled})} >
            <span onClick={onClick} className="select-none" style={{fontSize: "13px"}}>위 내용이 확실합니다.</span>
        </div>
    );
}

// Chat Button의 그룹임 
function ChatButtonGroupFaq(props) {
    // props.elements: faqElements 배열 
    // props.onAnswer
    // props.canInput
    // props.value
    const [buttons, setButtons] = useState([]);
    const [selected, setSelected] = useState(-1);  // 선택된 버튼의 인덱스. TODO: Multi-Select이면?
    const onClickButton = (label) => {
        if (props.canInput !== true) return;
        console.log(`label=${label}, len=${props.elements.length}`);
        for (let i = 0; i < props.elements.length; i++) {
            if (props.elements[i].message === label) {
                setSelected(i);
                console.log(`selected ${i}, ${props.elements[i].message}`);
                props.onAnswer(label);
            }
        }
    }

    useEffect(() => {
        if (!props.value || !props.elements) return;

        for (let i = 0; i < props.elements.length; i++) {
            if (props.elements[i].message === props.value) {
                setSelected(i);
            }
        }
    }, [props.value, props.elements]);

    useEffect(() => {
        if (!props.elements) return;
        const bts = [];
        let i = 0;
        for (const elem of props.elements) {
            const isSelected = i === selected;
            let isDisabled = false;
            if (props.canInput !== true) {
                isDisabled = true;
            }
            bts.push(<ChatButton key={i} label={elem.message} selected={isSelected} disabled={isDisabled} onClick={() => onClickButton(elem.message)}/>);
            i++;
        }
        setButtons(bts);
    }, [props.elements, selected]);

    return (
        <div>
            {buttons}
        </div>
    );
}

function ChatButtonGroup(props) {
    // props.options: options 배열 
    // props.onSelect: 선택된 label을 전달함 
    // props.multiple: true or false 
    // props.canInput: 입력이 가능한 상태인가? 
    // props.value
    const [buttons, setButtons] = useState([]);
    const [selected, setSelected] = useState([]);  // 선택된 버튼의 인덱스. TODO: Multi-Select이면?

    const onClickButton = (label) => {
        if (props.canInput !== true) return;
        const idx = props.options.findIndex(elem => elem === label);
        if (props.multiple) {
            setSelected((prev) => {
                if (prev.findIndex((item) => item === idx) < 0) {
                    // 없으면
                    const newSel = [... prev, idx];
                    //props.onSelect(newSel.map((i) => props.options[i]));
                    return newSel;
                } else {
                    const newSel = [... prev.filter((item) => item !== idx)];
                    //props.onSelect(newSel.map((i) => props.options[i]));
                    return newSel;
                }
            });            
        } else {
            setSelected(idx);
            /*
            if (idx >= 0) props.onSelect(label);
            else props.onSelect("");
            */
        }
    }

    // https://velog.io/@lyj-ooz/%EC%97%90%EB%9F%AC-cannot-update-a-component-...-while-rendering-a-different-component-
    // #22 bug fix
    // 기존에는 위의 블럭에서처럼 state를 설정하는 구문 안에, props.onSelect를 호출했는데, 그것도 상위의 state를 변경하는 것임. 
    // 그래서 상위 하위가 같이 state update되어서 경고 생긴 것임. 
    useEffect(() => {
        if (Array.isArray(selected)) {
            // multiple
            props.onSelect(selected.map((i) => props.options[i]));
        } else {
            // single
            if (selected >= 0) props.onSelect(props.options[selected]);
            else props.onSelect("");
        }
    }, [selected]);

    useEffect(() => {
        //console.log(`val=${props.value}, multi=${props.multiple}`);
        if (!props.value || !props.options) return;
        if (props.multiple === true) {
            const sel = [];
            const vs = props.value.split(',');
            for (const v of vs) {
                const idx = props.options.indexOf(v);
                if (idx >= 0) sel.push(idx);
            }
            setSelected(sel);
        } else {
            const idx = props.options.indexOf(props.value);
            if (idx >= 0) setSelected(idx);
        }
    }, [props.value, props.options]);

    useEffect(() => {
        if (!props.options) return;
        const bts = [];
        let i = 0;
        for (const elem of props.options) {
            let isSelected;
            let isDisabled = false;
            if (props.multiple) {
                isSelected = selected.findIndex((elem) => elem === i) >= 0;                
            } else {
                isSelected = i === selected;                
            }
            if (props.canInput !== true) {
                //isDisabled = !isSelected;
                isDisabled = true;
            }
            bts.push(<ChatButton key={i} label={elem} selected={isSelected} disabled={isDisabled} onClick={() => onClickButton(elem)}/>);
            i++;
        }
        setButtons(bts);
    }, [props.options, selected, props.canInput]);
    return (
        <div>
            {buttons}
        </div>
    );
}

function AgentLogo(props) {
    // props.hidden: boolean
    return (
        <div>
            { props.hidden ? <div className="AgentLogo"></div> 
                : <img src={`/assets/agent.png`} className="AgentLogo"/>
            }   
        </div>
    )
}

function ChatButton(props) {
    // props.label
    // props.selected
    // props.disabled
    // props.onClick: onClick handler
    const [btnClass, setBtnClass] = useState("");
    useEffect(() => {
        const bc = classnames({
            'cb-selected': props.selected && !props.disabled,
            'cb-disabled': props.disabled && !props.selected,
            'cb-select-disabled': props.selected && props.disabled
        });
        setBtnClass(bc);
    }, [props.selected, props.disabled])
    return (
        <div className={`chatButton mt-2 px-2 select-none ${btnClass}`} onClick={props.onClick}>
            {props.label}
        </div>
    );
}

function UserBubble(props) {
    // props.message
    // props.showTime
    const [msg, setMsg] = useState("");
    const onlyContainsNumbers = (str) => {
        return /^\d+$/.test(str);
    }
    useEffect(() => {
        const isNumber = onlyContainsNumbers(props.message.message);
        if (isNumber) {
            setMsg(commaNumber(props.message.message));
        } else {
            setMsg(props.message.message);
        }
    }, [props.message]);
    return (
        <div className="flex justify-content-end pt-3">
            <ShowTime message={props.message} showTime={props.showTime}/>
            <div className="user-bubble py-2 px-3 mr-3 ml-2">
                {msg}
            </div>
        </div>
    );
}

function ShowTime(props) {
    // props.message
    // props.showTime
    return (
        <div className="text-xs text-700 ml-2 mt-auto">
            {props.showTime === true ? getTimeStr(props.message.createdAt) : ""} 
        </div>        
    );
}

function TypingBubble(props) {
    // props.showAgent
    return (
        <div className="flex align-items-start pt-3">
            <div className="ml-3 mr-2">
                <AgentLogo hidden={!props.showAgent}/>            
            </div>
            <div className="">                
                <img src="/assets/typing.gif"/>
            </div>
        </div>
    );
}