import { useEffect, useState } from 'react';
import { MainHeader, WhiteBanner } from "./MainPage";
import { Dropdown } from 'primereact/dropdown';
import { ToggleButton } from 'primereact/togglebutton';
import { InputText } from 'primereact/inputtext';
import { useFormik } from 'formik';
import classNames from 'classnames';
import { GrayLine, OrangeButton, WhiteButton } from '../common/Common';
import { useNavigate, useParams } from 'react-router-dom';
import { BoNetUtil, isDevEnv } from '../common/Util';
import { BACKEND_URL, UserObjectIdAtom } from '../Atoms';
import { useRecoilValue } from 'recoil';
import { Modal } from 'react-responsive-modal';
import { Calendar } from 'primereact/calendar';

export function DebtorPage(props) {
    const userObjectId = useRecoilValue(UserObjectIdAtom);

    const navigate = useNavigate();
    const [userChatState, setUserChatState] = useState(null);  // data 아래의 항목임. 
    const [caseInfo, setCaseInfo] = useState(null);
    const [showInvalid, setShowInvalid] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const { chatId } = useParams();

    useEffect(() => {
        console.log(`Get existing userChat ${chatId}`);
        // resp: {"userChatObjectId":"654b9cf1e88ae696d7d1aaab"}
        BoNetUtil.get(`${BACKEND_URL}/api/v1/userChats/${chatId}`, null,
            (resp) => {
                // 다른 사용자 것이 아닌지 체크한다. 
                if (resp.data.data[0].userObjectId !== userObjectId) {
                    setShowInvalid(true);
                } else {
                    setUserChatState(resp.data.data[0]);
                    // case 번호 얻기 
                    BoNetUtil.get(`${BACKEND_URL}/vws/bo/case/info`, {chatObjectId: chatId},
                        (resp) => {
                            setCaseInfo(resp.data);
                            console.log("CaseInfo = %o", resp.data);
                        },
                        (err) => console.error(err)
                    );
                    //console.log("ChatState=");
                    //console.dir(resp.data);
                }
            },
            (err) => {
                console.error(err);
                setShowInvalid(true);
            }   
        );        
    }, [chatId]);

    const doTheNextJob = () => {
        if (!caseInfo) return;
        if (caseInfo.caseType === "대여금") {  
            navigate(`/payment/${userChatState._id}`, {state:{caseInfo:caseInfo}});
            //navigate(`/payment/${props.userChatState._id}`);
        } else {
            setShowDialog(true);
        }
    }

    const doWait = () => {
        BoNetUtil.put(`${BACKEND_URL}/vws/bo/case/${caseInfo.caseNo}/payStatus?status=${0}`, null,
            (resp) => {
                console.log("put result = %o", resp.data);
                navigate("/chatlist");
            },
            (err) => {
                console.error(err);
                navigate("/chatlist");
            }
        );
    }

    const doPayWait = () => {
        BoNetUtil.put(`${BACKEND_URL}/vws/bo/case/${caseInfo.caseNo}/payStatus?status=${1}`, null,
            (resp) => {
                console.log("put result = %o", resp.data);
                navigate(`/payment/${userChatState._id}`,{state:{caseInfo:caseInfo}});
            },
            (err) => {
                console.error(err);
                navigate(`/payment/${userChatState._id}`, {state:{caseInfo:caseInfo}});
            }
        );
    }

    return (
        <div>
            { !!showInvalid 
            ? <div className="text-center font-bold">잘못된 상담정보입니다.</div>
            :
            <div>
                <MainHeader/>
                <GrayLine/>
                <DebtorForm userChatState={userChatState} caseInfo={caseInfo} todo={doTheNextJob}/>
                <Modal open={showDialog} center showCloseIcon={false} closeOnEsc={false} closeOnOverlayClick={false}
                    classNames={{modal: "border-round-lg p-0"}} styles={{modal: {width: "85vw", height:"auto"}}}>
                    <div className="flex flex-column h-full">
                        <div className="p-3 flex flex-column flex-grow-1" style={{backgroundColor: "#5653D7", color: "#F5F4F3"}}>
                            <div className="text-xl font-bold">한시적 기간 동안만 진행하고 있어서 대기인원 순서대로 혜택(90%할인)을 드리고 있습니다.</div>
                            <div className="grid">
                                <div className="col-6">
                                    <div className="mt-3 text-sm">1.혜택은 한시적이기 때문에 결제를 하는 분까지는 기간이 끝나도 혜택가격으로 해드립니다.</div>
                                    <div className="text-sm mt-2">2.결제를 안하고 그냥 기다리시는 분은 혜택기간이 끝나면 동일 가격으로 접수가 어렵습니다.</div>
                                </div>
                                <div className="text-center flex-grow-1 flex align-items-center justify-content-center col-6" >
                                    <div className="">
                                        <img src="/assets/money.png" style={{width:"9rem", maxHeight:"11rem"}} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="grid mt-2 mx-2">
                            <div className="col-6"><WhiteButton label="결제하고 기다리기" onClick={doPayWait} /></div>
                            <div className="col-6"><OrangeButton label="기다리기" onClick={doWait}/></div>
                        </div>
                    </div>
                </Modal>
            </div>
            }
        </div>
    );
}

/*
채무자 정보

1. 개인  = PM
2. 개인사업자  = PC
3. 법인 = CO
*/

function DebtorForm(props) {
    // props.userChatState
    // props.caseInfo
    // props.todo: 폼 입력이 끝나면 위에 알려줌 

    const navigate = useNavigate();
    const [mode, setMode] = useState(null);  // 위의 PM, PR 등 참고 
    const [debtorType, setDebtorType] = useState("");  // 채무자, 대표자, 법인 대표자 
    const [initialData, setInitialData] = useState({
        debtorType: null,  // 채무자 구분 
        debtorName: "",  // 채무자 이름
        corporateName: "",  // 상호명, 법인명
        corporateNo: "",  // 사업자 등록번호  
        debtorPhone: "", // 채무자 핸드폰번호
        lastContactDate: "", // 마지막으로 연락된 날짜
        contactablePhone: "", // 연락 가능한 연락처
        debtorAddress: "", // 채무자 주소
        debtorAddressDetail: "", // 채무자 주소 상세
        corpAddress: "", // 사업장 주소
        corpAddressDetail: "", // 사업장 주소 상세
        debtorEmail: "", // 채무자 이메일주소
        debtorKakao: "",  // 채무자 카카오톡 이름
        corpOfficerName: "",  // 법인 담당자 이름
        corpOfficerPhone: "",  // 법인 담당자 핸드폰번호
        lastContactDateWithOfficer: "",  // 법인 담당자와 마지막으로 연락된 날짜
        corpOfficerEmail: "", // 법인 담당자 이메일
        corpOfficerKakao: "",  // 법인 담당자 카카오톡 이름
        debtorBank: "",  // 채무자 은행명
        debtorBankAccount: "", // 채무자 계좌번호
        debtorAwareDate: "",  // 채무자를 처음 알게 된 날짜
        debtorAwareReason: "" // 채무자를 알게 된 경위
    });
    const formik = useFormik({
        initialValues: initialData,
        enableReinitialize: true,
        validate: (data) => {
            let errors = {};
            console.log("validate");
            console.dir(data);
            if (!data.debtorType) {
                errors.debtorType = "채무자 구분을 선택하세요";
            }
            if (data.deptorPhone) {
                const pattern = new RegExp("^[0-9-]*$");
                if (!pattern.test(data.deptorPhone)) {
                    errors.deptorPhone = "잘못된 전화번호입니다";
                }
            }
            if (Object.keys(errors).length >= 0) {
                console.log("validate error %o", errors);                
            }
            return errors;
        },
        onSubmit: (data) => {
            console.log("formik onSubmit");
            console.dir(data);
            // upload data
            const submitData = {};
            for (const key of Object.keys(data)) {
                if (data[key] && typeof data[key] === "object" && "code" in data[key]) {
                    submitData[key] = data[key].code;
                } else {
                    submitData[key] = data[key];
                }
            }
            console.log("submit=%o", submitData);
            if (!props.caseInfo) return;
            BoNetUtil.post(`${BACKEND_URL}/vws/bo/case/${props.caseInfo.caseNo}/prepayment/debtor`, submitData,
                (resp) => {
                    console.log("debtor=%o", resp.data);
                    props.todo();                    
                },
                (err) => console.error(err)
            );
        }
    });
    const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);  // !! 는 truethy, falsey 값을 true, false로 정규화함. 
    const getFormErrorMessage = (name) => {
        return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
    }
    const debtorTypes = [
        { name: "개인", code: 1 },
        { name: "개인사업자", code: 2 },
        { name: "법인", code: 3 }  
    ];
    useEffect(() => {
        if (formik.values.debtorType?.code === 1) { // 개인
            setMode("PM");
        } else if (formik.values.debtorType?.code === 2) {  // 개인사업자
            setMode("PC");
        } else if (formik.values.debtorType?.code === 3) {  // 법인
            setMode("CO");
        } else {
            setMode(null);
        }
    }, [formik.values.debtorType]);

    useEffect(() => {
        setDebtorType(mode === "PM" ? "채무자" : mode === "PC" ? "대표자" : mode === "CO" ? "법인 대표자" : "");
    }, [mode]);

    return (
        <div className="text-left p-3">         
            <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
                <div className="mt-2">
                    <div className="mb-2">채무자 구분</div>
                    <Dropdown value={formik.values.debtorType} onChange={(e) => formik.setFieldValue("debtorType", e.value)} options={debtorTypes} optionLabel="name"
                        placeholder="채무자 구분을 선택하세요" 
                        className={classNames("w-full", {"p-invalid": isFormFieldInvalid("debtorType")})} />
                    {getFormErrorMessage('debtorType')}
                </div>
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 이름</div>
                    <div className="flex">
                        <InputText value={formik.values.debtorName} onChange={(e) => formik.setFieldValue("debtorName", e.target.value)}
                            placeholder="텍스트 입력" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("debtorName")})} />
                        <ToggleButton checked={!!!formik.values.debtorName} onChange={() => formik.setFieldValue("debtorName", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('debtorName')}
                </div>
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 휴대전화</div>
                    <div className="flex">
                        <InputText value={formik.values.deptorPhone} onChange={(e) => formik.setFieldValue("deptorPhone", e.target.value)}
                            placeholder="휴대전화번호를 입력해주세요" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorPhone")})} />
                        <ToggleButton checked={!!!formik.values.deptorPhone} onChange={() => formik.setFieldValue("deptorPhone", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('deptorPhone')}
                </div>
                { !!mode &&
                <div className="mt-1">
                    <div className="mb-2">위 번호로 연락된 마지막 날짜</div>
                    <div className="flex">
                        <Calendar value={formik.values.lastContactDate} onChange={(e) => formik.setFieldValue("lastContactDate", e.target.value)}
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("lastContactDate")})}
                            dateFormat="yy-mm-dd" locale="kr" placeholder="날짜를 선택하세요"/>
                        <ToggleButton checked={!!!formik.values.lastContactDate} onChange={() => formik.setFieldValue("lastContactDate", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('deptorPhone')}
                </div>
                }
                { !!mode &&
                <div className="mt-1">
                    <div className="mb-2">{debtorType}와 연락 가능한 연락처</div>
                    <div className="flex">
                        <InputText value={formik.values.contactablePhone} onChange={(e) => formik.setFieldValue("contactablePhone", e.target.value)}
                            placeholder="집 전화번호, 근무지 전화번호" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("contactablePhone")})} />
                        <ToggleButton checked={!!!formik.values.contactablePhone} onChange={() => formik.setFieldValue("contactablePhone", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('deptorPhone')}
                </div>
                }
                { !!mode && 
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 주소</div>
                    <div className="flex">
                        <InputText value={formik.values.deptorAddress} onChange={(e) => formik.setFieldValue("deptorAddress", e.target.value)}
                            placeholder="주소 검색하기" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorAddress")})} />
                        <ToggleButton checked={!!!formik.values.deptorAddress} onChange={() => formik.setFieldValue("deptorAddress", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    <div className="mt-1">
                        <InputText value={formik.values.deptorAddressDetail} onChange={(e) => formik.setFieldValue("deptorAddressDetail", e.target.value)}
                            placeholder="상세주소 입력하기" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorAddressDetail")})} />
                    </div>
                    {getFormErrorMessage('deptorAddress')} {getFormErrorMessage('deptorAddressDetail')}
                </div>
                }
                { !!mode && 
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 이메일 주소</div>
                    <div className="flex">
                        <InputText value={formik.values.deptorEmail} onChange={(e) => formik.setFieldValue("deptorEmail", e.target.value)}
                            placeholder="이메일을 입력해주세요" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorEmail")})} />
                        <ToggleButton checked={!!!formik.values.deptorEmail} onChange={() => formik.setFieldValue("deptorEmail", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('deptorEmail')}
                </div>
                }
                { !!mode && 
                <div className="mt-1">
                    <div className="mb-2">현재 {debtorType} 카카오톡 이름</div>
                    <div className="flex">
                        <InputText value={formik.values.deptorKakao} onChange={(e) => formik.setFieldValue("deptorKakao", e.target.value)}
                            placeholder="친구가 설정한 이름을 입력해주세요" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorKakao")})} />
                        <ToggleButton checked={!!!formik.values.deptorKakao} onChange={() => formik.setFieldValue("deptorKakao", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    <div>
                        <span className="text-xs">상대방 카카오톡 프로필 ⟶ 이름 옆 수정아이콘을 누르면 확인 가능</span>
                    </div>
                    {getFormErrorMessage('deptorKakao')}
                </div>
                }
                { !!mode && 
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 계좌 은행명</div>
                    <div className="flex">
                        <InputText value={formik.values.deptorBank} onChange={(e) => formik.setFieldValue("deptorBank", e.target.value)}
                            placeholder="OO은행" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("deptorBank")})} />
                        <ToggleButton checked={!!!formik.values.deptorBank} onChange={() => formik.setFieldValue("deptorBank", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('deptorBank')}
                </div>
                }
                { !!mode && 
                <div className="mt-1">
                    <div className="mb-2">{debtorType} 계좌번호</div>
                    <div className="flex">
                        <InputText value={formik.values.debtorBankAccount} onChange={(e) => formik.setFieldValue("debtorBankAccount", e.target.value)}
                            placeholder="숫자만 입력해주세요" 
                            className={classNames("w-full", {"p-invalid": isFormFieldInvalid("debtorBankAccount")})} />
                        <ToggleButton checked={!!!formik.values.debtorBankAccount} onChange={() => formik.setFieldValue("debtorBankAccount", "")} onLabel="모름" offLabel="모름" style={{width: "6rem"}} className="ml-3"/>
                    </div>
                    {getFormErrorMessage('debtorBankAccount')}
                </div>
                }

            </form>   
            <OrangeButton label={`다음으로 ${isDevEnv() ? mode: ""}`} onClick={formik.handleSubmit} />            
        </div>
    );
}