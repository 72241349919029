import {useRecoilValue} from "recoil";
import {BACKEND_URL, BoTokenAtom, UserObjectIdAtom} from "../Atoms";
import axios from "axios";
import queryString from "query-string";
import {AuthContext} from "../common/AuthContext";


const useAxios = () => {

    //순환참조 에러: const {boToken, boUserId, userObjectId, boUserType} = useContext(AuthContext);
    const boToken = useRecoilValue(BoTokenAtom);
    const userObjectId = useRecoilValue(UserObjectIdAtom);

    //2403 getAuth로 변경- 실시간 조회.
    // const auth = (boToken.accessToken)? { 'Authorization': `Bearer ${boToken.accessToken}` } : null;

    const getAuth = () => {
       return (boToken.accessToken)? { 'Authorization': `Bearer ${boToken.accessToken}` }:null;
    }


    const axiosGet = async (url, params) => {
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        try {
            const response = await axios.get(BACKEND_URL + url, { headers: getAuth() });
            const data = response.data;
            // console.log(data);
            return data;
        }catch (error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized Error 처리
                //todo check- 개발환경에서 체크용 alert: (b2b는 로그인화면으로 보내도 될것 같은데 검토필요 )
                console.error('================= 401에러 다시 로그인해 주세요 =============================', window.location.href);
                if ((window.location.href.includes('localhost') || window.location.href.includes('local.'))
                    && (window.location.href !== 'http://localhost:3030' && window.location.href !== 'http://b.local.com:3030/b2b')) {
                    //alert('다시 로그인해 주세요 - 401Error ');
                }

            } else {
                // 다른 에러 처리
                console.error(error);
            }
            return null;
        }
    }

    const axiosGetNoAuth = async (url, params) => {
        console.log('axiosGetNoAuth:',url);
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        try {
            const response = await axios.get(BACKEND_URL + url);
            const data = response.data;
            // console.log(data);
            return data;
        }catch (error) {
            if (error.response) {
                console.error(error.response.status, '=================NO_AUTH인데도 에러  401에러 다시 로그인해 주세요 =============================', window.location.href);
            }
            return null;
        }
    }

    const axiosPost = async (url, body, params) => {
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        try {
            const response = await axios.post(BACKEND_URL + url, body, {headers: getAuth()});
            const data = response.data;
            console.log(data);
            return data;
        }catch (error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized Error 처리
                alert('다시 로그인해 주세요');
            } else {
                // 다른 에러 처리
                console.error(error);
            }
            return null;
        }
    }

    const axiosPut = async (url, body, params) => {
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        try {
            const response = await axios.put(BACKEND_URL + url, body, { headers: getAuth() });
            const data = response.data;
            console.log('PUT RESULT,', data);
            return data;
        }catch (error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized Error 처리
                alert('다시 로그인해 주세요');
            } else {
                // 다른 에러 처리
                console.error(error);
            }
            return null;
        }
    }

    const axiosDelete = async (url, params) => {
        if (params) {
            url = `${url}?${queryString.stringify(params)}`;
        }
        try {
            const response = await axios.delete(BACKEND_URL + url, {headers: getAuth()});
            const data = response.data;
            console.log('DEL RESULT,', data);
            return data;
        }catch (error) {
            if (error.response && error.response.status === 401) {
                // 401 Unauthorized Error 처리
                alert('다시 로그인해 주세요');
            } else {
                // 다른 에러 처리
                console.error(error);
            }
            return null;
        }
    }

    return {axiosGet, axiosPost, axiosPut, axiosDelete, axiosGetNoAuth};
}

export default useAxios;