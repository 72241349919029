import { useLocation, useNavigate } from "react-router-dom";
import { OrangeButton, WhiteButton, WhiteTopBar } from "../common/Common";
import { useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { BoTokenAtom, BACKEND_URL, BoUserTypeAtom } from "../Atoms";
import { BoNetUtil } from "../common/Util";
import { Checkbox } from 'primereact/checkbox';
import { WhiteBanner } from "./MainPage";

export function QuitEndPage(props) {
    const location = useLocation();
    const [privacy, setPrivacy] = useState(false);
    const [boToken, setBoToken] = useRecoilState(BoTokenAtom);
    const [boUserType, setBoUserType] = useRecoilState(BoUserTypeAtom);

    const navigate = useNavigate();

    console.log("location = %o", location);

    const quitUser = () => {        
        BoNetUtil.post(`${BACKEND_URL}/vws/bo/user/quit`, location.state,
            (resp) => {
                console.log(`quit reason saved`);
                console.dir(resp);
                setBoToken({});
                setBoUserType("");
                //navigate("/");
                window.location.href = "/";
            },
            (err) => {
                console.error(err);
                setBoToken({});
                setBoUserType("");
                //navigate("/");
                window.location.href = "/";
            }
        );        
    }

    return (
        <div className="px-2">
            <WhiteTopBar title="회원탈퇴"/>
            <TermsContent/>
            <div className="flex align-items-center my-3 mx-2">
                <Checkbox checked={privacy} onChange={(e) => setPrivacy(e.checked)}/>
                <label className="px-2 text-700">위 내용을 모두 확인했습니다. (필수)</label>
            </div>
            <div className="w-full grid">
                <div className="col-6">
                    <WhiteButton label="취소하기" onClick={() => navigate(-3)} />
                </div>
                <div className="col-6">
                    <OrangeButton label="다음" onClick={quitUser} disabled={!!!privacy}/>
                </div>
            </div>
        </div>
    );
}

function TermsContent(props) {
    const [content, setContent] = useState("");

    useEffect(() => {
        BoNetUtil.get(`${BACKEND_URL}/api/v1/terms/user_quit`, null,
            (resp) => {
                // 약관은 여러버전이 있음. 그 중 최신거는 제일 위에 있음. 
                if (resp.data.data) {
                    const pid = resp.data.data[0]._id;
                    BoNetUtil.get(`${BACKEND_URL}/api/v1/terms/user_quit/${pid}`, null,
                        (resp) => {
                            setContent(resp.data.data[0].contents);
                        },
                        (err) => {
                            console.error(err);
                        }
                    );
                }
            },
            (err) => {
                console.error(err);
            }
        );
    }, []);
    return (
        <div className="text-left px-2">
            <div dangerouslySetInnerHTML={{__html: content}}></div>
        </div>
    );
}